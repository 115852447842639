/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import ReactDOM from 'react-dom';

import App from './features/app/App';
import AppProvider from './components/AppProvider';
import * as serviceWorker from './serviceWorker';
import theme from './Theme';
import { configureWePay } from './util/WePay';
import { MUI_LICENSE_KEY } from './util/Constants';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
ReactDOM.hydrate(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline>
        <AppProvider>
          <App wepayInit={configureWePay} />
        </AppProvider>
      </CssBaseline>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
