import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Skeleton,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridOverlay,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { DateTime } from 'luxon';
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentsDueRowData } from '../custom-types/PaymentsDueRowData';
import { selectUpdateFromPagination, captureUpdateFromPagination } from '../features/multipleMethodsHome/MultipleMethodsHomeSlice';
import {
  CurrencyType,
  CustomerRole,
  Maybe,
  MerchantInfo,
  OrderDirection,
  PayerTransaction,
  PayerTransactionConnection,
  PaymentRequestStatus,
  PaymentStatus,
} from '../gql-types.generated';
import theme from '../Theme';
import { paymentStatusDisplay } from '../util/PaymentStatus';
import PartialPaymentEdit from './PartialPaymentEdit';
import PaymentsDueFooter from './PaymentsDueFooter';
import PaymentsDueRow from './PaymentsDueRow';
import SkeletonGrid from './SkeletonGrid';

const useStylesMobile = makeStyles(() =>
  createStyles({
    skeletonWrap: {
      width: '100%',
      display: 'table',
    },
    tableWrapContainer: {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
    },
    table: {
      overflow: 'auto',
      margin: '0px',
    },
    tableWrap: {
      flex: '1 1 auto',
      overflow: 'auto',
    },
    tableFooter: {
      flex: '0 1 auto',
    },
    firstTableCell: {
      padding: '5px 10px 5px 16px',
    },
    cellData: {
      display: 'flex',
      padding: '2px',
    },
    cellDetails: {
      width: '75%',
      fontSize: '16px',
    },
    cellDate: {
      fontSize: '14px',
      margin: '2px 1px 0px auto',
      width: '25%',
      textAlign: 'right',
    },
    cellReferenceNumber: {
      marginTop: '2px',
      color: '#276EF1',
      wordBreak: 'break-word',
    },
    cellAmount: {
      color: '#000000DE',
    },
    failedCellAmount: {
      color: '#FF3B30',
    },
    emptyMessage: {
      fontSize: '14px',
      textAlign: 'center',
      fontStyle: 'oblique',
    },
    loadingSpinner: {
      height: 60,
      width: 60,
      display: 'inline-block',
    },
    errorIcon: {
      fontSize: '20px',
      verticalAlign: 'text-bottom',
    },
    errorMessage: {
      fontSize: '18px',
      textAlign: 'center',
      fontStyle: 'oblique',
      color: 'red',
    },
    bottomSummary: {
      padding: 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    invoiceText: {
      marginLeft: 4,
      fontWeight: 200,
    },
    totalDueText: {
      marginLeft: 8,
    },
    gridWrap: {
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
    },
  }),
);

const useStylesDesktop = makeStyles(() =>
  createStyles({
    table: {
      overflow: 'auto',
      margin: '0px',
    },
    tableWrap: {
      overflow: 'auto',
      height: '100%',
    },
    tableCell: {
      fontSize: 16,
      wordWrap: 'break-word',
      padding: '16px 4px',
    },
    emptyMessage: {
      fontSize: '14px',
      textAlign: 'center',
      fontStyle: 'oblique',
    },
    loadingSpinner: {
      height: 60,
      width: 60,
      display: 'inline-block',
    },
    errorIcon: {
      fontSize: '20px',
      verticalAlign: 'text-bottom',
    },
    failedCell: {
      wordWrap: 'break-word',
      color: '#FF3B30',
    },
    errorMessage: {
      fontSize: '18px',
      textAlign: 'center',
      fontStyle: 'oblique',
      color: 'red',
    },
    referenceCell: {
      fontSize: 16,
      color: '#276EF1',
      wordWrap: 'break-word',
      wordBreak: 'break-word',
      paddingLeft: '35px',
    },
    bottomSummary: {
      padding: '32px 16px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    invoiceText: {
      marginLeft: 8,
      fontWeight: 200,
    },
    totalDueText: {
      marginLeft: 24,
    },
    payButton: {
      marginLeft: 64,
      width: 140,
    },
    footer: {
      padding: theme.spacing(1, 6, 1, 0),
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderTop: '1px solid #e0e0e0',
    },
    gridWrap: {
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
    },
    overlay: {
      '&.MuiDataGrid-overlay': {
        display: 'inline',
      },
    },
    noRowsOverlayText: {
      fontStyle: 'italic',
      lineHeight: 2,
      borderBottom: '1px solid #e0e0e0',
    },
    dataGrid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell': {
        // Unfortunately the cell rendering has inline lineHeight styling, so !important is required.
        lineHeight: '100% !important',
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#F5F5F5',
        maxHeight: 60,
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: 16,
      },
      '& .MuiDataGrid-cell': {
        fontSize: 16,
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    dataGridHiddenHeaderBox: {
      '& div.MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-checkboxInput': {
        display: 'none',
      },
    },
    progressOverlay: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'flex',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      opacity: 0.02,
    },
    dueErrorIcon: {
      verticalAlign: 'text-bottom',
      fontSize: '17px',
      marginRight: theme.spacing(0.5),
    },
    gridHide: {
      display: 'none',
    },
    totalBeforeDiscount: {
      textDecoration: 'line-through',
      display: 'inline-block',
    },
    total: {
      display: 'inline-block',
    },
  }),
);

export interface PaymentsDueListHandles {
  consolidatedOrPartialOnPay: (payment: PayerTransaction) => void;
}

interface PaymentsDueListProps {
  platform: string;
  paymentsDue: PayerTransaction[] | undefined;
  paymentsDueConnection: PayerTransactionConnection | undefined;
  loadPage: () => void;
  showPaymentDetails: (paymentDetails: PayerTransaction | null | undefined, detailsIndex?: number, isSelected?: boolean) => void;
  paymentsDueError: Error | undefined;
  merchantName: string | undefined;
  settingsMenuVisible: boolean;
  paymentsDueDataSort: (
    paymentsDue: PaymentsDueRowData[] | undefined,
    paymentsDueOrder: OrderDirection,
    paymentsDueOrderBy: string,
  ) => PaymentsDueRowData[] | undefined;
  onClickPayConsolidatedOrPartial: (selectedData: PaymentsDueRowData[]) => void;
  paymentsDueData: PaymentsDueRowData[] | undefined;
  fetchPaymentsDueData: (payments: PaymentsDueRowData[] | undefined) => void;
  consolidatedOrPartialCancelClicked: boolean;
  selectedMerchantInfo: MerchantInfo | undefined;
  switchedToRecentPayments: boolean;
  clearPaymentRequestInfoList: () => void;
  customerRole: CustomerRole | undefined;
  gridApiRef: React.MutableRefObject<GridApiPro>;
}

const PaymentsDueList: ForwardRefRenderFunction<PaymentsDueListHandles, PaymentsDueListProps> = (props, ref) => {
  const {
    platform,
    paymentsDue,
    paymentsDueConnection,
    loadPage,
    showPaymentDetails,
    paymentsDueError,
    settingsMenuVisible,
    paymentsDueDataSort,
    onClickPayConsolidatedOrPartial,
    paymentsDueData,
    fetchPaymentsDueData,
    selectedMerchantInfo,
    switchedToRecentPayments,
    clearPaymentRequestInfoList,
    customerRole,
    gridApiRef: gridApi,
  } = props;
  const dispatch = useDispatch();
  const isUpdateFromPagination = useSelector(selectUpdateFromPagination);
  const classesMobile = useStylesMobile();
  const classesDesktop = useStylesDesktop();
  const [selectedInvoices, setSelectedInvoices] = useState<number>(0);
  const [selectedInvoicesAmount, setSelectedInvoicesAmount] = useState<number>(0);
  const [hideUnselected, setHideUnselected] = useState<boolean>(false);
  const [paymentEditDialogOpen, setPaymentEditDialogOpen] = useState<boolean>(false);
  const [paymentInEdit, setPaymentInEdit] = useState<GridRowModel>();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
  const [paymentsDueRows, setPaymentsDueRows] = useState<GridRowModel[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: localStorage.getItem('paymentsDueDataOrderBy') || 'receivedDate',
      sort: localStorage.getItem('paymentsDueDataOrder') === 'asc' ? 'asc' : 'desc',
    },
  ]);
  const [hasCreditApplied, setHasCreditApplied] = useState<boolean>(false);

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down(1400));
  const consolidatedAllowed = !!selectedMerchantInfo?.features?.paymentRequests.consolidatedPayment;
  const partialAllowed = !!selectedMerchantInfo?.features?.paymentRequests.partialPayment;
  const hasDiscounts = !!paymentsDue?.find(paymentDue => {
    return !!paymentDue.paymentRequest?.discountEndDate && !!paymentDue.paymentRequest.discountAmount;
  });
  const creditAllowed = !!selectedMerchantInfo?.features?.creditMemos?.enabled;
  const isMobileView = platform === 'mobile' || (settingsMenuVisible && matches);

  const selectedOnlyOperators = [
    {
      label: 'Selected',
      value: 'selected',
      getApplyFilterFn: () => {
        return (params: GridCellParams) => {
          return gridApi.current.isRowSelected(params.row.id);
        };
      },
    },
  ];
  const isRestrictedRole = customerRole && customerRole === CustomerRole.CustomerReader;

  useEffect(() => {
    if (!switchedToRecentPayments) {
      const paymentRequests: PaymentsDueRowData[] = [];
      if (paymentsDue) {
        paymentsDue.forEach(paymentDue => {
          // check if we already have an existing entry so that we won't lose selections and local state between querying for paginated data
          const existingPaymentData = paymentsDueData?.find(
            e => e.paymentDue.paymentRequest?.referenceNumber === paymentDue.paymentRequest?.referenceNumber,
          );
          if (isUpdateFromPagination && existingPaymentData) {
            paymentRequests.push(existingPaymentData);
          } else {
            //calculating discount amount
            const discountEndDate = DateTime.fromISO(paymentDue.paymentRequest?.discountEndDate);
            const discountEndValid = discountEndDate.isValid && discountEndDate > DateTime.utc();
            const discountAmount = (discountEndValid && paymentDue.paymentRequest?.discountAmount) || 0;

            //calculating credit amount
            let creditAmount = 0;
            paymentDue?.paymentRequest?.payments?.forEach(paymentData => {
              if (paymentData?.status === PaymentStatus.Completed || paymentData?.status === PaymentStatus.Pending) {
                creditAmount += paymentData?.creditAmount || 0;
              }
            });

            paymentRequests.push({
              paymentDue,
              amount: (paymentDue.paymentRequest?.totalDue || 0) - discountAmount,
              discountAmount,
              creditAmount,
              isExpanded: false,
            });
          }
        });
        const localOrderBy = sortModel[0] ? sortModel[0].field : null;
        const localOrder = sortModel[0] ? sortModel[0].sort : null;
        if (localOrder && localOrderBy) {
          fetchPaymentsDueData(
            paymentsDueDataSort(paymentRequests, localOrder === 'asc' ? OrderDirection.Asc : OrderDirection.Desc, localOrderBy),
          );
        } else {
          fetchPaymentsDueData(paymentRequests);
        }
      }
      if (!isUpdateFromPagination) {
        if (selectedInvoices !== 0) {
          setSelectedInvoices(0);
          gridApi.current.setSelectionModel([]);
        }
        if (selectedInvoicesAmount !== 0) setSelectedInvoicesAmount(0);
        if (hideUnselected) setHideUnselected(false);
      }
      dispatch(captureUpdateFromPagination(false));
    }
    clearPaymentRequestInfoList();
  }, [paymentsDue]);

  useEffect(() => {
    if (hideUnselected) {
      gridApi.current.setFilterModel({
        items: [
          {
            id: 1,
            columnField: 'reference',
            value: true,
            operatorValue: 'selected',
          },
        ],
      });
    } else {
      gridApi.current.setFilterModel({ items: [] });
    }
  }, [hideUnselected]);

  const getPaymentsDueRows = () => {
    let creditApplied = false;
    const paymentsDueMappedData = paymentsDueData
      ? (paymentsDueData?.map((row: Maybe<PaymentsDueRowData>) => {
          const node = row;
          if (!node) {
            return {} as GridRowModel;
          }
          const { paymentDue } = node;
          const { paymentRequest } = paymentDue;
          let creditAmount = 0;
          paymentRequest?.payments?.forEach(paymentData => {
            if (paymentData?.status === PaymentStatus.Completed || paymentData?.status === PaymentStatus.Pending) {
              creditAmount += paymentData?.creditAmount || 0;
            }
          });
          if (creditAmount > 0 && !creditApplied) {
            creditApplied = true;
          }

          const dueDate = DateTime.fromISO(paymentRequest?.dueDate);
          const overDue = dueDate.isValid && dueDate.startOf('day') < DateTime.now().startOf('day');
          const discountEndDate = DateTime.fromISO(paymentRequest?.discountEndDate);
          const doesDiscountApply = discountEndDate.isValid && discountEndDate > DateTime.utc();
          const discountAmount = doesDiscountApply && paymentRequest?.discountAmount ? paymentRequest.discountAmount : 0;
          const paymentMethodAmountCalc = (paymentRequest?.totalPaid || 0) - creditAmount;
          const paymentMethodAmount = paymentMethodAmountCalc < 0 ? 0 : paymentMethodAmountCalc;
          const totalAmount = (paymentRequest?.amount || 0) - discountAmount;
          const amountBeforeDiscount = paymentRequest?.amount || 0;
          return {
            _raw: node,
            id: paymentRequest?.referenceNumber,
            reference: paymentRequest?.referenceNumber?.trim(),
            discountEnd: paymentRequest?.discountEndDate ? new Date(paymentRequest?.discountEndDate).toLocaleDateString() : '',
            overdue: overDue,
            doesDiscountApply,
            dueDate: paymentRequest?.dueDate ? new Date(paymentRequest?.dueDate).toLocaleDateString() : '',
            receivedDate: new Date(paymentRequest?.createdAt).toLocaleDateString(),
            status: paymentStatusDisplay.find(item => {
              return item.name === paymentRequest?.status;
            })?.display,
            failedStatus: paymentRequest?.status === PaymentRequestStatus.Failed,
            amount: new Intl.NumberFormat('en', {
              style: 'currency',
              currency: paymentDue?.currency || 'USD',
              currencyDisplay: 'symbol',
            }).format(totalAmount / 100),
            amountBeforeDiscount: new Intl.NumberFormat('en', {
              style: 'currency',
              currency: paymentDue?.currency || 'USD',
              currencyDisplay: 'symbol',
            }).format(amountBeforeDiscount / 100),
            credit:
              typeof creditAmount === 'number'
                ? new Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: paymentDue?.currency || 'USD',
                    currencyDisplay: 'symbol',
                  }).format(creditAmount / 100)
                : null,
            paid: new Intl.NumberFormat('en', {
              style: 'currency',
              currency: paymentDue?.currency || 'USD',
              currencyDisplay: 'symbol',
            }).format(paymentMethodAmount / 100),
            balance:
              typeof paymentRequest?.totalDue === 'number'
                ? new Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: paymentDue?.currency || 'USD',
                    currencyDisplay: 'symbol',
                  }).format((paymentRequest?.totalDue - discountAmount) / 100)
                : null,
            payment:
              partialAllowed && typeof paymentRequest?.totalDue === 'number'
                ? new Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: paymentDue?.currency || 'USD',
                    currencyDisplay: 'symbol',
                  }).format(node.amount / 100)
                : null,
          } as GridRowModel;
        }) as GridRowModel[])
      : [];

    if (creditApplied && !hasCreditApplied) {
      setHasCreditApplied(true);
    } else if (!creditApplied && hasCreditApplied) {
      setHasCreditApplied(false);
    }
    return paymentsDueMappedData;
  };

  useEffect(() => {
    setPaymentsDueRows(getPaymentsDueRows());
  }, [paymentsDueData]);

  const getTotalAmountDue = () => {
    let totalAmount = 0;
    if (paymentsDueData) {
      paymentsDueData.forEach(row => {
        totalAmount += row.amount;
      });
    }
    return totalAmount;
  };
  const getRowDataIndex = (refNumber: string) => {
    let rowIndex = -1;
    if (paymentsDueData) {
      paymentsDueData.forEach((row: PaymentsDueRowData, index: number) => {
        if (row?.paymentDue?.paymentRequest?.referenceNumber === refNumber) {
          rowIndex = index;
        }
      });
    }
    return rowIndex;
  };
  const handleRowClicked = (payment: PayerTransaction) => {
    if (paymentsDueData && payment?.paymentRequest?.referenceNumber && !isRestrictedRole) {
      const rowIndex = getRowDataIndex(payment?.paymentRequest?.referenceNumber);
      if (typeof rowIndex === 'number' && rowIndex > -1) {
        gridApi.current.selectRow(payment?.paymentRequest?.referenceNumber);
        const paymentsDueItems = [...paymentsDueData];
        const paymentsDueItem = { ...paymentsDueItems[rowIndex] };
        const discountEndDate = DateTime.fromISO(paymentsDueItem.paymentDue.paymentRequest?.discountEndDate);
        const discountEndValid = discountEndDate.isValid && discountEndDate > DateTime.utc();
        const discountAmount = (discountEndValid && paymentsDueItem.paymentDue.paymentRequest?.discountAmount) || 0;
        const totalDue = paymentsDueItem.paymentDue.paymentRequest?.totalDue;
        paymentsDueItem.amount = totalDue ? totalDue - discountAmount : 0;
        paymentsDueItem.partialReason = undefined;
        paymentsDueItems[rowIndex] = paymentsDueItem;
        fetchPaymentsDueData(paymentsDueItems);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    consolidatedOrPartialOnPay: (payment: PayerTransaction) => {
      handleRowClicked(payment);
    },
  }));
  const onSortModelChange = (model: GridSortModel) => {
    if (model && model.length === 1 && model[0]?.sort) {
      fetchPaymentsDueData(
        paymentsDueDataSort(paymentsDueData, model[0]?.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc, model[0]?.field),
      );
      localStorage.setItem('paymentsDueDataOrder', model[0]?.sort);
      localStorage.setItem('paymentsDueDataOrderBy', model[0]?.field);
    }
    setSortModel(model);
  };

  const getTotalPartialAmount = () => {
    if (paymentsDueData) {
      let totalAmount = 0;
      paymentsDueData.forEach(row => {
        if (
          row?.paymentDue?.paymentRequest?.referenceNumber &&
          gridApi.current.isRowSelected(row?.paymentDue?.paymentRequest?.referenceNumber)
        ) {
          totalAmount += row.amount;
        }
      });
      return totalAmount;
    }
    return 0;
  };

  const getInvoicesById = (ids: GridRowId[]) => {
    const rowData: PaymentsDueRowData[] = [];
    ids.forEach(id => {
      paymentsDueData?.forEach(row => {
        if (id === row?.paymentDue?.paymentRequest?.referenceNumber) {
          rowData.push(row);
        }
      });
    });
    return rowData;
  };

  const handlePayment = (payAll: boolean) => {
    onClickPayConsolidatedOrPartial((payAll ? paymentsDueData : getInvoicesById(selectionModel || [])) || []);
  };

  const handlePartialEditClicked = (payment: GridRowModel) => {
    if (payment) {
      setPaymentInEdit(payment);
      setPaymentEditDialogOpen(true);
    }
  };

  const handlePaymentEditClose = () => {
    setPaymentInEdit(undefined);
    setPaymentEditDialogOpen(false);
  };

  const handlePaymentEditSave = (amount: number, reason?: string) => {
    if (paymentsDueData && paymentInEdit?.id) {
      const rowIndex = getRowDataIndex(paymentInEdit?.id);
      if (typeof rowIndex === 'number' && rowIndex > -1) {
        const paymentsDueItems = [...paymentsDueData];
        const paymentsDueItem = { ...paymentsDueItems[rowIndex] };
        paymentsDueItem.amount = amount;
        paymentsDueItem.partialReason = reason || paymentsDueItem.partialReason;
        paymentsDueItems[rowIndex] = paymentsDueItem;
        fetchPaymentsDueData(paymentsDueItems);
      }
      handlePaymentEditClose();
    }
  };

  // Keep working towards all grids conversion to datagridpro in payer portal.
  const paymentsDueColumns: GridColDef[] = [
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'mobileRender',
      headerName: 'mobileRender',
      flex: 1,
      hide: !isMobileView,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row, api } = params;
        // eslint-disable-next-line no-underscore-dangle
        const rowData = row._raw;
        return (
          <PaymentsDueRow
            row={row}
            isSelected={api.isRowSelected(row.id)}
            partialAllowed={!!selectedMerchantInfo?.features?.paymentRequests.partialPayment}
            creditAllowed={!!selectedMerchantInfo?.features?.creditMemos.enabled}
            showPaymentDetails={() => {
              showPaymentDetails(rowData?.paymentDue);
            }}
            discountEndVisible={hasDiscounts}
            handlePartialEditClicked={() => {
              handlePartialEditClicked(row);
            }}
          />
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'reference',
      sortable: true,
      headerName: 'Reference #',
      flex: 1,
      minWidth: 115,
      hide: isMobileView,
      filterOperators: selectedOnlyOperators,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row, api } = params;
        const refNumber = params.value;
        // eslint-disable-next-line no-underscore-dangle
        const rowData = row._raw;
        return (
          <Link
            href="#"
            underline="hover"
            onClick={() => {
              const rowIndex = api.getRowIndex(params.id);
              const isSelected = api.isRowSelected(params.id);
              showPaymentDetails(rowData?.paymentDue, rowIndex, isSelected);
            }}
            aria-label={`open payment request details${refNumber ? ` reference ${refNumber}` : ''}`}
          >
            {refNumber && refNumber.length > 9 ? `***${refNumber.slice(-9)}` : refNumber}
          </Link>
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'dueDate',
      sortable: true,
      headerName: 'Due Date',
      flex: 1,
      hide: isMobileView,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <div>
            {params.value}
            {row.overdue ? <ErrorIcon color="error" className={classesDesktop.dueErrorIcon} /> : ''}
          </div>
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'discountEnd',
      sortable: true,
      hide: isMobileView || !hasDiscounts,
      headerName: 'Discount End',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'receivedDate',
      sortable: true,
      headerName: 'Received On',
      flex: 1,
      hide: isMobileView,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'status',
      sortable: true,
      headerName: 'Status',
      flex: 1,
      hide: isMobileView,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return row.failedStatus ? <div className={classesDesktop.failedCell}>{params.value}</div> : params.value;
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'amount',
      sortable: true,
      headerName: 'Total',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      hide: isMobileView,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <div className={`${row.failedStatus ? classesDesktop.failedCell : undefined}`}>
            {row.failedStatus && <ErrorOutlineIcon className={classesMobile.errorIcon} />}
            {row.doesDiscountApply && (
              <Typography className={classesDesktop.totalBeforeDiscount} color="error" align="right">
                {row.amountBeforeDiscount}
              </Typography>
            )}
            <Typography className={`${row.doesDiscountApply ? undefined : classesDesktop.total}`} align="right">
              {params.value}
            </Typography>
          </div>
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'credit',
      sortable: true,
      headerAlign: 'right',
      align: 'right',
      hide: isMobileView || !creditAllowed || !hasCreditApplied,
      headerName: 'Credit Applied',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'paid',
      sortable: true,
      headerAlign: 'right',
      align: 'right',
      hide: isMobileView || !partialAllowed,
      headerName: 'Paid',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'balance',
      sortable: true,
      headerAlign: 'right',
      align: 'right',
      hide: isMobileView || !partialAllowed,
      headerName: 'Balance',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'payment',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      hide: isMobileView || !partialAllowed,
      headerName: 'Payment',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const { row, api } = params;
        return api.isRowSelected(row.id) ? params.value : '';
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'actions',
      sortable: false,
      headerName: '',
      flex: 0.75,
      headerAlign: 'center',
      align: 'center',
      hide: isMobileView || !partialAllowed,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        return (
          params.api.isRowSelected(params.row.id) && (
            <Button
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => {
                handlePartialEditClicked(params.row);
              }}
            >
              Edit
            </Button>
          )
        );
      },
    },
  ];
  const onSelectionModelChange = (selectionModel: GridSelectionModel) => {
    const rowData = getInvoicesById(selectionModel);
    let invoicesTotal = 0;
    if (rowData) {
      rowData.forEach((row: PaymentsDueRowData) => {
        invoicesTotal += row.amount;
      });
    }
    setSelectedInvoices(selectionModel.length);
    setSelectedInvoicesAmount(invoicesTotal);

    if (selectionModel.length > 0 && ((!consolidatedAllowed && partialAllowed) || !(consolidatedAllowed || partialAllowed))) {
      setHideUnselected(true);
    }

    if (selectionModel.length === 0) {
      setHideUnselected(false);
    }
    setSelectionModel(selectionModel);
  };
  const loadingOverlay = () => {
    return (
      <GridOverlay>
        <div className={classesDesktop.progressOverlay}></div>
        <CircularProgress aria-label={'progress spinner'} key={'payments-spinner'} size={42} />
      </GridOverlay>
    );
  };
  const noRowsOverlay = () => {
    return (
      <GridOverlay className={classesDesktop.overlay}>
        {paymentsDueError && (
          <Typography className={classesDesktop.noRowsOverlayText} variant="body2">
            Unable to load data. Please try again later.
          </Typography>
        )}
        {!paymentsDueError && paymentsDue?.length === 0 && (
          <Typography variant="body2" className={classesDesktop.noRowsOverlayText}>
            No payments due.
          </Typography>
        )}
      </GridOverlay>
    );
  };
  const gridFooter = () => {
    return (
      <Box className={isMobileView ? '' : classesDesktop.footer}>
        <PaymentsDueFooter
          isMobileView={isMobileView}
          currency={paymentsDue && paymentsDue[0] ? paymentsDue[0]?.payment?.currency || paymentsDue[0]?.currency : CurrencyType.Usd}
          consolidatedAllowed={!!selectedMerchantInfo?.features?.paymentRequests.consolidatedPayment}
          partialAllowed={!!selectedMerchantInfo?.features?.paymentRequests.partialPayment}
          totalAmount={getTotalAmountDue()}
          totalInvoices={paymentsDueConnection?.totalCount || 0}
          selectedInvoices={selectedInvoices}
          selectedInvoiceAmount={selectedInvoicesAmount}
          toggleHideUnselected={() => {
            setHideUnselected(!hideUnselected);
          }}
          handleMakePayment={handlePayment}
          totalPartialAmount={getTotalPartialAmount()}
          hideUnselected={hideUnselected}
          customerRole={customerRole}
        />
      </Box>
    );
  };
  const skeletonMobile: JSX.Element[] = [];
  for (let i = 0; i < 7; i += 1) {
    skeletonMobile.push(
      <TableRow>
        <TableCell>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '80%' }}>
              <Skeleton height="1.5em" width="8em" />
              <Skeleton height="1.5em" width="6em" />
              <Skeleton height="1.5em" width="4em" />
            </div>
            <div style={{ width: '20%', textAlign: 'right' }}>
              <Skeleton height="1.5em" width="5em" />
              <Skeleton height="1.5em" width="5em" />
              <Skeleton height="1.5em" width="5em" />
            </div>
          </div>
        </TableCell>
      </TableRow>,
    );
  }

  return (
    <div className={classesMobile.tableWrapContainer}>
      <TableContainer className={isMobileView ? classesMobile.tableWrap : classesDesktop.tableWrap} data-cy="payments-due-list">
        {isMobileView && !paymentsDueError && !paymentsDue && <div className={classesMobile.skeletonWrap}>{skeletonMobile}</div>}
        {!isMobileView && !paymentsDueError && !paymentsDue && <SkeletonGrid hasCheckbox headers={paymentsDueColumns} />}
        <Box
          className={`${isMobileView ? classesMobile.gridWrap : classesDesktop.gridWrap} ${
            !paymentsDue ? classesDesktop.gridHide : ''
          }`}
          data-cy="payments-due-table"
        >
          <DataGridPro
            apiRef={gridApi}
            loading={false}
            headerHeight={isMobileView ? 0 : 56}
            rowHeight={isMobileView ? 128 : 58}
            checkboxSelection={customerRole !== CustomerRole.CustomerReader}
            aria-label="Payments Due List"
            paginationMode="server"
            pageSize={25}
            rowsPerPageOptions={[25]}
            hideFooterPagination
            hideFooterSelectedRowCount
            disableSelectionOnClick
            className={`${classesDesktop.dataGrid} ${consolidatedAllowed ? '' : classesDesktop.dataGridHiddenHeaderBox}`}
            rows={paymentsDueRows}
            columns={paymentsDueColumns}
            sortingOrder={['desc', 'asc']}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            components={{
              // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
              LoadingOverlay: loadingOverlay,
              // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
              NoRowsOverlay: noRowsOverlay,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              Footer: gridFooter,
            }}
            onRowsScrollEnd={() => {
              // Prevent page load if switching from hide unselected payment requests
              if (!hideUnselected) {
                loadPage();
              }
            }}
            selectionModel={selectionModel}
            onSelectionModelChange={onSelectionModelChange}
            disableColumnFilter
          />
        </Box>
        {paymentInEdit && paymentsDueData && (
          <PartialPaymentEdit
            isOpen={paymentEditDialogOpen}
            close={handlePaymentEditClose}
            row={paymentInEdit}
            needReason={!!selectedMerchantInfo?.options?.payments?.requirePartialPaymentReason}
            saveChanges={handlePaymentEditSave}
          />
        )}
      </TableContainer>
    </div>
  );
};

export default forwardRef(PaymentsDueList);
