import CreditCardIcon from '@mui/icons-material/CreditCard';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  TableContainer,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import {
  DataGridPro,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { PaymentsDueRowData } from '../custom-types/PaymentsDueRowData';
import {
  AcceptedPaymentMethod,
  CardType,
  CreditCard,
  CurrencyType,
  CustomerInfo,
  CustomerRole,
  Maybe,
  MerchantInfo,
  PayerPaymentMethod,
  PaymentMethod,
  PaymentMethodHolder,
  PaymentMethodStatus,
  PaymentMethodType,
  PaymentRequestInfo,
  Person,
  Token,
} from '../gql-types.generated';
import { AllAcceptedPaymentMethodForDisplay } from '../util/AcceptedPaymentMethodForDisplay';
import AlertBanner from './AlertBanner';
import DownloadInvoices from './DownloadInvoices';
import LoadingMask from './LoadingMask';
import PaymentMethodTimerBanner from './PaymentMethodTimerBanner';
import InfoIcon from '@mui/icons-material/Info';
import { CardTypeConvenienceFee } from '../custom-types/CardTypeConvenienceFee';
import { RoutePath } from '../util/Routes';
import { DateTime } from 'luxon';
import PaymentMethodTile from './PaymentMethodTile';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    base: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 620,
      },
    },
    padding: {
      padding: theme.spacing(1, 2),
    },
    paddingPaymentMethod: {
      padding: theme.spacing(1, 2, 0, 2),
    },
    paddingRadio: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    refundPolicyLink: {
      fontSize: 14,
      fontWeight: 400,
      cursor: 'pointer',
    },
    invoiceLink: {
      fontSize: 14,
      fontWeight: 400,
      cursor: 'pointer',
    },
    refundContent: {
      padding: theme.spacing(2, 3),
    },
    refundTitle: {
      padding: theme.spacing(2),
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    refundActions: {
      padding: theme.spacing(2),
    },
    closeRefundPolicyButton: {
      width: '100%',
    },
    total: {
      paddingLeft: theme.spacing(1),
      fontWeight: 'normal',
    },
    amount: {
      fontWeight: 400,
      fontSize: 18,
    },
    cancelPadding: {
      paddingRight: theme.spacing(1.5),
    },
    submitPadding: {
      paddingTop: theme.spacing(1.5),
    },
    namePadding: {
      padding: theme.spacing(0, 2),
    },
    refundPolicy: {
      textAlign: 'right',
      verticalAlign: 'middle',
    },
    totalAmount: {
      padding: theme.spacing(1, 2),
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    actionPadding: {
      padding: theme.spacing(0.5, 2, 2, 2),
    },
    tableContainer: {
      maxHeight: '250px',
    },
    grid: {
      fontSize: '16px',
    },
    gridText: {
      fontWeight: 'bold',
    },
    gridInvoice: {
      textAlign: 'right',
    },
    mobileCellText: {
      padding: '6px 0px',
    },
    gridReferenceText: {
      width: '50%',
    },
    paymentSummaryPadding: {
      padding: theme.spacing(2, 2, 1, 2),
    },
    subTotalText: {
      textAlign: 'right',
      fontSize: '16px',
    },
    subTotalAmount: {
      textAlign: 'right',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    subTotalContainer: {
      paddingTop: theme.spacing(1),
    },
    checkboxIcon: {
      alignItems: 'flex-start',
      margin: 0,
      padding: 0,
    },
    checkboxLabel: {
      paddingLeft: theme.spacing(1),
    },
    checkboxRoot: {
      alignItems: 'flex-start',
      marginLeft: 0,
    },
    nameWrap: {
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    paymetMethodCardAction: {
      padding: theme.spacing(0.5),
    },
    paymentMethodAddCardContainer: {
      width: '88px',
      height: '58px',
      margin: theme.spacing(0.5, 1, 0.5, 0),
      border: 'dashed 1px #0275d8',
      backgroundColor: '#EEF4FE',
    },
    addCardIconContainer: {
      textAlign: 'center',
    },
    addCardIcon: {
      color: '#0275d8',
      fontSize: 28,
    },
    addCardText: {
      fontWeight: 500,
      fontSize: '14px',
      textAlign: 'center',
      color: '#0275d8',
    },
    table: {
      width: '100%',
      maxHeight: 250,
    },
    dataGrid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
      '& .MuiDataGrid-cell': {
        fontSize: '0.875rem',
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
      '& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell': {
        // Unfortunately the cell rendering has inline lineHeight styling, so !important is required.
        lineHeight: '100% !important',
      },
    },
    partialReasonDetail: {
      padding: 6,
      backgroundColor: '#EEF4FE',
    },
    bannerGrid: {
      padding: theme.spacing(0.5, 2, 0.5, 2),
      margin: theme.spacing(1, 0, 0.5, 0),
      background: '#E4EDFE',
      color: '#276EF1',
      fontSize: '14px',
    },
    infoIcon: {
      fontSize: '18px',
      verticalAlign: 'text-bottom',
      marginRight: '5px',
    },
    totalText: {
      textAlign: 'right',
      fontSize: '24px',
    },
    totalAmountText: {
      textAlign: 'right',
      fontSize: '24px',
      fontWeight: 'bold',
    },
    creditAmount: {
      textAlign: 'right',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#00D115',
    },
  }),
);

interface PayPaymentRequestProps {
  merchantInfo?: MerchantInfo;
  allowCreditCard: boolean;
  allowECheck: boolean;
  changePaymentMethod: (paymentMethod: PayerPaymentMethod | undefined) => void;
  continueWithCredit?: () => void;
  hasPaymentMethods?: boolean;
  paymentMethod?: PayerPaymentMethod | null;
  paymentRequestInfo?: PaymentRequestInfo;
  paymentRequestInfoList?: PaymentsDueRowData[];
  submitPayment?: (useSelectedPaymentMethod: boolean, convenienceFee: number) => void;
  displayRefundPolicy: boolean;
  toggleRefundPolicyDisplay: () => void;
  viewerUser?: Person;
  paymentMethods?: PayerPaymentMethod[] | null;
  sortedPaymentMethods?: PayerPaymentMethod[] | null;
  billingAddresses?: PaymentMethodHolder[] | null;
  handleAddCard: (isPaymentScreen?: boolean, isLoading?: boolean) => void;
  handleAddBank: () => void;
  defaultPaymentMethod?: PayerPaymentMethod | null;
  paymentError?: Error;
  isCreditCardAccepted: (
    paymentMethod: CreditCard | undefined | null,
    acceptedPaymentMethods?: AcceptedPaymentMethod[] | null,
  ) => boolean;
  loading: boolean;
  paymentAddVisible?: boolean;
  paymentMethodAdded?: boolean;
  cancelPayment?: (merchant?: string) => void;
  isMobileView: boolean;
  getPaymentRequestInfoAmount?: () => number;
  loadingCreditMemoBalance: boolean;
  creditMemoBalance: number;
  creditMemoBalanceError?: Error;
  fetchCreditMemoBalance: (tenantId?: string) => void;
  setMerchantInfo: (tenantId?: string) => void;
  useCreditMemo: boolean;
  setUseCreditMemo: (useCredtMemo: boolean) => void;
  sendingPayment: boolean;
  handleCreditMemoCardClose: () => void;
  customerRole: CustomerRole | undefined;
  oneTimeUsePaymentMethod: Token | undefined;
  clearOneTimePaymentMethod: () => void;
  customerInfo: CustomerInfo | undefined;
  calculateConvenienceFee: (amount: number, cardType: CardType) => void;
  calculatedConveninceFee?: CardTypeConvenienceFee;
  isCalculatedConvenienceFeeInFlight: boolean;
  clearCalculatedConvenienceFees: () => void;
}

const PayPaymentRequest: React.FC<PayPaymentRequestProps> = props => {
  const classes = useStyles();
  const {
    merchantInfo,
    allowCreditCard,
    allowECheck,
    paymentMethod,
    paymentRequestInfo,
    paymentRequestInfoList,
    submitPayment,
    displayRefundPolicy,
    toggleRefundPolicyDisplay,
    viewerUser,
    changePaymentMethod,
    paymentMethods,
    handleAddCard,
    defaultPaymentMethod,
    paymentError,
    isCreditCardAccepted,
    loading,
    paymentAddVisible,
    paymentMethodAdded,
    cancelPayment,
    isMobileView,
    getPaymentRequestInfoAmount,
    loadingCreditMemoBalance,
    creditMemoBalance,
    creditMemoBalanceError,
    fetchCreditMemoBalance,
    setMerchantInfo,
    useCreditMemo,
    setUseCreditMemo,
    sendingPayment,
    handleCreditMemoCardClose,
    sortedPaymentMethods,
    customerRole,
    oneTimeUsePaymentMethod,
    clearOneTimePaymentMethod,
    customerInfo,
    calculateConvenienceFee,
    calculatedConveninceFee,
    isCalculatedConvenienceFeeInFlight,
    clearCalculatedConvenienceFees,
  } = props;
  const currency = (paymentRequestInfo?.currency as CurrencyType) || (merchantInfo?.defaultCurrency as CurrencyType) || 'USD';
  const acceptedPaymentMethods = paymentRequestInfo?.acceptedPaymentMethods || merchantInfo?.acceptedPaymentMethods;
  const isCustomerReader = customerRole && customerRole === CustomerRole.CustomerReader;
  const isCreditMemoEnabled = !!merchantInfo?.features?.creditMemos.enabled;
  const isConvenienceFeesEnabled = !!merchantInfo?.features?.payments?.convenienceFees;
  const history = useHistory();

  if (window.location.pathname === RoutePath.Payment && !paymentRequestInfoList) {
    history.push(RoutePath.Home);
    return <></>;
  }

  const isPaymentMethodAllowed = (paymentMethod: PaymentMethod | null | undefined) => {
    return (
      paymentMethod &&
      paymentMethod.status === PaymentMethodStatus.Verified &&
      ((paymentMethod.type === PaymentMethodType.CreditCard &&
        allowCreditCard &&
        isCreditCardAccepted(paymentMethod.creditCard, acceptedPaymentMethods)) ||
        (paymentMethod.type === PaymentMethodType.PaymentBankUs && allowECheck))
    );
  };

  let defaultAllowed = !customerInfo && isPaymentMethodAllowed(defaultPaymentMethod?.paymentMethod);

  const [selectedMethod, setSelectedMethod] = useState<string | undefined>(
    defaultAllowed ? defaultPaymentMethod?.paymentMethod?.id : paymentMethod?.paymentMethod?.id,
  );
  const [paymentRequestInfoListLocal, setPaymentRequestInfoListLocal] = useState<PaymentsDueRowData[] | undefined>();
  const matchesLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const [showPaymentSummary, setShowPaymentSummary] = useState(!isMobileView);
  const [isOneTimeUsePaymentMethodSelected, setIsOneTimeUsePaymentMethodSelected] = useState(false);
  const [isValidOneTimeMethod, setIsValidOneTimeMethod] = useState(false);
  const [oneTimeMethodLimit, setOneTimeMethodLimit] = useState<number>(0);
  const [requiresDetail, setRequiresDetail] = useState<boolean>(false);
  const [cardTypeConvenienceFees, setCardTypeConvenienceFees] = useState<CardTypeConvenienceFee[]>([]);
  const [cardTypeConvenienceFeesWithCredit, setCardTypeConvenienceFeesWithCredit] = useState<CardTypeConvenienceFee[]>([]);
  const [cardConvenienceFee, setCardConvenienceFee] = useState<CardTypeConvenienceFee | undefined>(undefined);

  const isCreditApplied = isCreditMemoEnabled && !creditMemoBalanceError && creditMemoBalance > 0 && useCreditMemo;
  const isConvenienceFeesApplied = isConvenienceFeesEnabled && cardConvenienceFee && cardConvenienceFee.amount !== 0;

  const clearConvenienceFee = () => {
    setCardTypeConvenienceFees([]);
    setCardTypeConvenienceFeesWithCredit([]);
    setCardConvenienceFee(undefined);
    clearCalculatedConvenienceFees();
  };

  useEffect(() => {
    clearConvenienceFee();
    setSelectedMethod(undefined);
    setUseCreditMemo(false);

    return () => {
      handleCreditMemoCardClose();
      changePaymentMethod(undefined);
    };
  }, []);

  useEffect(() => {
    if (oneTimeUsePaymentMethod) {
      const validCard = allowCreditCard && isCreditCardAccepted(oneTimeUsePaymentMethod?.creditCard, acceptedPaymentMethods);
      setIsValidOneTimeMethod(validCard);
      if (validCard) {
        setIsOneTimeUsePaymentMethodSelected(true);
        setSelectedMethod(oneTimeUsePaymentMethod?.id);
        changePaymentMethod(undefined);
      }
      const limit = new Date().getTime() + 900000;
      setOneTimeMethodLimit(limit);
    } else if (isOneTimeUsePaymentMethodSelected) {
      setIsOneTimeUsePaymentMethodSelected(false);
      setSelectedMethod(undefined);
      setOneTimeMethodLimit(0);
    }
  }, [oneTimeUsePaymentMethod]);

  useEffect(() => {
    if (
      loading === false &&
      paymentAddVisible === false &&
      paymentMethodAdded === false &&
      paymentMethods &&
      paymentMethods.length === 0 &&
      merchantInfo
    ) {
      handleAddCard(true);
    }

    if (paymentRequestInfoList) {
      setPaymentRequestInfoListLocal(paymentRequestInfoList);
    }
  }, [loading, paymentRequestInfoList, merchantInfo]);

  useEffect(() => {
    changePaymentMethod(undefined);

    defaultAllowed = !customerInfo && isPaymentMethodAllowed(defaultPaymentMethod?.paymentMethod);
    //If the default payment method type is supported by the payment request, set the selected payment method to the default payment method
    if (defaultPaymentMethod && defaultAllowed && changePaymentMethod) {
      changePaymentMethod(defaultPaymentMethod);
      setSelectedMethod(defaultPaymentMethod?.paymentMethod?.id);
      if (isOneTimeUsePaymentMethodSelected) setIsOneTimeUsePaymentMethodSelected(false);
    }
  }, [defaultPaymentMethod]);

  useEffect(() => {
    if (customerInfo) {
      if (!selectedMethod && sortedPaymentMethods && sortedPaymentMethods.length > 0 && !oneTimeUsePaymentMethod) {
        const [firstPaymentMethod] = sortedPaymentMethods;
        const methodAllowed = isPaymentMethodAllowed(firstPaymentMethod.paymentMethod);
        if (methodAllowed) {
          changePaymentMethod(firstPaymentMethod);
          setSelectedMethod(firstPaymentMethod?.paymentMethod?.id);
        }
      }
    } else {
      if (paymentAddVisible === true && paymentMethods && paymentMethods.length > 0) {
        handleAddCard(true, true);
      }

      if (paymentMethods?.length === 1 && !defaultPaymentMethod) {
        changePaymentMethod(undefined);

        const [firstPaymentMethod] = paymentMethods;
        const methodAllowed = isPaymentMethodAllowed(firstPaymentMethod.paymentMethod);
        //Selecting when only one payment method is present
        //If the first payment method type is supported by the payment request, set the selected payment method to the first payment method
        if (methodAllowed && changePaymentMethod) {
          changePaymentMethod(firstPaymentMethod);
          setSelectedMethod(firstPaymentMethod?.paymentMethod?.id);
        }
      }

      if (paymentMethods?.length === 0) {
        changePaymentMethod(undefined);
        setSelectedMethod(undefined);
      }
    }
  }, [paymentMethods]);

  useEffect(() => {
    if (paymentRequestInfo && !merchantInfo) {
      setMerchantInfo(paymentRequestInfo.tenantId);
    }
  }, [paymentRequestInfo]);

  useEffect(() => {
    if (isCreditMemoEnabled) {
      fetchCreditMemoBalance(merchantInfo?.owner?.tenantId);
    }
  }, [merchantInfo]);

  useEffect(() => {
    setShowPaymentSummary(!isMobileView);
  }, [isMobileView]);

  const getSubTotalAmount = () => {
    if (paymentRequestInfo) {
      return getPaymentRequestInfoAmount ? getPaymentRequestInfoAmount() : paymentRequestInfo.totalDue;
    }

    if (paymentRequestInfoList) {
      let totalAmount = 0;
      paymentRequestInfoList.forEach(paymentRequest => {
        totalAmount += paymentRequest.amount || 0;
      });
      return totalAmount;
    }
    return 0;
  };

  const getCreditAmount = () => {
    if (!creditMemoBalanceError && creditMemoBalance > 0 && useCreditMemo && isCreditMemoEnabled) {
      const subTotalAmount = getSubTotalAmount();
      const creditAmount = creditMemoBalance > subTotalAmount ? subTotalAmount : creditMemoBalance;

      return creditAmount;
    }
    return 0;
  };

  const getCardAmount = () => {
    const subTotalAmount = getSubTotalAmount();
    const creditAmount = getCreditAmount();

    return subTotalAmount - creditAmount;
  };

  const getConvenienceFeesAmount = () => {
    if (cardConvenienceFee) {
      return cardConvenienceFee.amount;
    }
    return 0;
  };

  const getTotalAmount = () => {
    const cardAmount = getCardAmount();
    const fees = getConvenienceFeesAmount();

    return cardAmount + fees;
  };

  useEffect(() => {
    if (isConvenienceFeesEnabled && (paymentMethod || isOneTimeUsePaymentMethodSelected)) {
      const type = isOneTimeUsePaymentMethodSelected
        ? (oneTimeUsePaymentMethod?.creditCard?.details?.type as Maybe<CardType> | undefined)
        : (paymentMethod?.paymentMethod?.creditCard?.details?.type as Maybe<CardType> | undefined);
      if (type) {
        const cardTypeConvenienceFee = isCreditApplied
          ? cardTypeConvenienceFeesWithCredit.find(c => c.cardType === type)
          : cardTypeConvenienceFees.find(c => c.cardType === type);
        if (!cardTypeConvenienceFee) {
          const cardAmount = getCardAmount();
          if (cardAmount !== 0) {
            calculateConvenienceFee(cardAmount, type);
          } else {
            setCardConvenienceFee(undefined);
          }
        } else {
          setCardConvenienceFee(cardTypeConvenienceFee);
        }
      } else {
        setCardConvenienceFee(undefined);
      }
    }
  }, [isConvenienceFeesEnabled, paymentMethod, isCreditApplied, isOneTimeUsePaymentMethodSelected]);

  useEffect(() => {
    if (calculatedConveninceFee) {
      if (isCreditApplied) {
        const convenienceFees = [...cardTypeConvenienceFeesWithCredit];
        convenienceFees.push(calculatedConveninceFee);
        setCardTypeConvenienceFeesWithCredit(convenienceFees);
      } else {
        const convenienceFees = [...cardTypeConvenienceFees];
        convenienceFees.push(calculatedConveninceFee);
        setCardTypeConvenienceFees(convenienceFees);
      }

      setCardConvenienceFee(calculatedConveninceFee);
      clearCalculatedConvenienceFees();
    }
  }, [calculatedConveninceFee]);

  const billingInfo = isOneTimeUsePaymentMethodSelected
    ? oneTimeUsePaymentMethod?.creditCard?.cardHolder?.address
    : paymentMethod?.paymentMethod?.creditCard?.cardHolder?.address ||
      paymentMethod?.paymentMethod?.paymentBank?.accountHolder?.address;

  const handleMethodSelectChange = (methodId: string | undefined, isOneTimeUsePaymentMethod?: boolean) => {
    if (selectedMethod !== methodId) {
      setSelectedMethod(methodId);
      // checking if temporary method is selected
      if (isOneTimeUsePaymentMethod) {
        setIsOneTimeUsePaymentMethodSelected(true);
        changePaymentMethod(undefined);
      } else if (paymentMethods) {
        const paymentMethod = paymentMethods.find(method => {
          return method.paymentMethod?.id && method.paymentMethod.id.localeCompare(methodId || '') === 0;
        });
        setIsOneTimeUsePaymentMethodSelected(false);
        changePaymentMethod(paymentMethod);
      }
    }
  };

  const toggleUseCreditMemo = () => {
    setUseCreditMemo(!useCreditMemo);
  };
  const toggleShowPaymentSummary = () => {
    setShowPaymentSummary(!showPaymentSummary);
  };
  const getDetailPanelContent = (params: GridRowParams) => {
    const { row } = params;
    return <Box className={classes.partialReasonDetail}>{`Partial Payment Reason: ${row.partialReason}`}</Box>;
  };
  const paymentSummaryColumns: GridColDef[] = [
    ...(isMobileView
      ? [
          {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            // eslint-disable-next-line react/display-name
            renderCell: (params: GridRenderCellParams) => {
              const { row } = params;
              return row.partialReason && GRID_DETAIL_PANEL_TOGGLE_COL_DEF && GRID_DETAIL_PANEL_TOGGLE_COL_DEF.renderCell ? (
                GRID_DETAIL_PANEL_TOGGLE_COL_DEF.renderCell(params)
              ) : (
                <></>
              );
            },
            hide: !requiresDetail,
          },
        ]
      : []),
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'mobileRender',
      headerName: 'mobileRender',
      flex: 1,
      hide: !isMobileView,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <Grid container className={classes.grid}>
            <Grid item container xs={12} className={`${classes.gridText} ${classes.mobileCellText}`}>
              Complete Payment
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={requiresDetail ? 6 : 8} className={classes.mobileCellText}>
                <div className={classes.gridReferenceText}>{row.reference}</div>
              </Grid>
              <Grid item xs={requiresDetail ? 6 : 4} className={classes.mobileCellText}>
                <div className={classes.gridInvoice}>
                  <DownloadInvoices
                    isLink={true}
                    isMobileView={true}
                    invoiceUrl={row.invoiceLink}
                    invoicesUrlList={row.invoiceLinks}
                    originTop={true}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.mobileCellText}>
              {row.payment}
            </Grid>
          </Grid>
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'reference',
      sortable: false,
      headerName: 'Reference #',
      flex: 0.25,
      hide: isMobileView,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'invoice',
      sortable: false,
      headerName: 'Invoice',
      flex: 0.25,
      hide: isMobileView,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <DownloadInvoices
            isLink={true}
            invoiceUrl={row.invoiceLink}
            invoicesUrlList={row.invoiceLinks}
            stringWhenEmpty={true}
            originTop={true}
          />
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'totalDue',
      headerName: 'Total Due',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      flex: 0.25,
      hide: isMobileView,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'payment',
      sortable: false,
      headerName: 'Payment',
      headerAlign: 'right',
      align: 'right',
      flex: 0.25,
      hide: isMobileView,
    },
    ...(!isMobileView
      ? [
          {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            // eslint-disable-next-line react/display-name
            renderCell: (params: GridRenderCellParams) => {
              const { row } = params;
              return row.partialReason && GRID_DETAIL_PANEL_TOGGLE_COL_DEF && GRID_DETAIL_PANEL_TOGGLE_COL_DEF.renderCell ? (
                GRID_DETAIL_PANEL_TOGGLE_COL_DEF.renderCell(params)
              ) : (
                <></>
              );
            },
            hide: !requiresDetail,
          },
        ]
      : []),
  ];
  const getPaymentSummaryRows = () => {
    const rowModels: GridRowModel[] = [];
    if (paymentRequestInfo) {
      const total = new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
        (getPaymentRequestInfoAmount ? getPaymentRequestInfoAmount() : paymentRequestInfo?.totalDue || 0) / 100,
      );
      rowModels.push({
        _raw: paymentRequestInfo,
        id: paymentRequestInfo?.paymentRequestId,
        reference:
          paymentRequestInfo.referenceNumber?.trim().length > 9
            ? `***${paymentRequestInfo.referenceNumber?.trim().slice(-9)}`
            : paymentRequestInfo.referenceNumber?.trim(),
        invoiceLink: paymentRequestInfo?.invoiceLink,
        invoiceLinks: paymentRequestInfo?.invoiceLinks,
        totalDue: total,
        payment: total,
      } as GridRowModel);
    }
    paymentRequestInfoListLocal?.forEach((row: Maybe<PaymentsDueRowData>) => {
      const node = row;
      const paymentDue = row?.paymentDue;
      const paymentAmount = row?.amount || 0;
      const paymentRequest = paymentDue?.paymentRequest;

      //calculating discount amount
      const discountEndDate = DateTime.fromISO(paymentRequest ? paymentRequest.discountEndDate : undefined);
      const discountEndValid = discountEndDate.isValid && discountEndDate > DateTime.utc();
      const discountAmount = discountEndValid && paymentRequest ? paymentRequest.discountAmount || 0 : 0;

      if (node && paymentRequest) {
        rowModels.push({
          _raw: node,
          id: `${paymentRequest.id}`,
          reference:
            paymentRequest?.referenceNumber?.trim() && paymentRequest.referenceNumber?.trim().length > 9
              ? `***${paymentRequest.referenceNumber?.trim().slice(-9)}`
              : paymentRequest.referenceNumber?.trim(),
          invoiceLink: paymentRequest.invoiceLink,
          invoiceLinks: paymentRequest.invoiceLinks,
          totalDue: new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
            (paymentRequest.totalDue ? paymentRequest.totalDue - discountAmount : 0) / 100,
          ),
          payment: new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(paymentAmount / 100),
          partialReason: node.partialReason,
        } as GridRowModel);
        if (node.partialReason && node.partialReason.length > 0 && !requiresDetail) {
          setRequiresDetail(true);
        }
      }
    });
    return rowModels;
  };

  const methodsSelection = (
    <>
      {oneTimeUsePaymentMethod && (
        <PaymentMethodTile
          token={oneTimeUsePaymentMethod}
          isSelected={isOneTimeUsePaymentMethodSelected}
          isDisabled={!isValidOneTimeMethod}
          handleMethodSelectChange={handleMethodSelectChange}
          dataCy={`temporary-payment-method-${isOneTimeUsePaymentMethodSelected ? 'selected' : 'unselected'}`}
        />
      )}
      {sortedPaymentMethods?.map((method, index) => {
        let isDisabled =
          method.paymentMethod?.type === PaymentMethodType.CreditCard
            ? !isCreditCardAccepted(method?.paymentMethod?.creditCard, acceptedPaymentMethods)
            : !allowECheck;
        isDisabled = isDisabled || method.paymentMethod?.status !== PaymentMethodStatus.Verified;

        const isSelected = selectedMethod === method?.paymentMethod?.id;
        return (
          <PaymentMethodTile
            key={index}
            method={method}
            isSelected={isSelected}
            isDisabled={isDisabled}
            handleMethodSelectChange={handleMethodSelectChange}
            dataCy={`saved-payment-method-${index}-${isSelected ? 'selected' : 'unselected'}`}
          />
        );
      })}
      {allowCreditCard && !isCustomerReader && (
        <Card variant="outlined" className={classes.paymentMethodAddCardContainer}>
          <CardActionArea
            className={classes.paymetMethodCardAction}
            onClick={() => {
              handleAddCard(true);
            }}
            data-cy="add-credit-card"
          >
            <Grid item container xs={12}>
              <Grid item xs={12} className={classes.addCardIconContainer}>
                <CreditCardIcon className={classes.addCardIcon} />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.addCardText}>Add Card</Typography>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      )}
    </>
  );

  const cancelOrSubmitPayment = () => {
    const canSubmitPayment =
      submitPayment &&
      (paymentRequestInfo || paymentRequestInfoList) &&
      ((isOneTimeUsePaymentMethodSelected &&
        oneTimeUsePaymentMethod &&
        oneTimeUsePaymentMethod.status !== PaymentMethodStatus.Unknown) ||
        (!isOneTimeUsePaymentMethodSelected &&
          paymentMethod &&
          paymentMethod.paymentMethod?.status !== PaymentMethodStatus.Unknown &&
          paymentMethod.paymentMethod?.status !== PaymentMethodStatus.ProcessingError));

    const fees = getConvenienceFeesAmount();

    return (
      <>
        <Grid item xs={12} lg={6} className={matchesLg ? '' : classes.cancelPadding}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            data-cy="cancel-payment-button"
            onClick={() => {
              if (cancelPayment) cancelPayment(merchantInfo?.owner?.tenantId);
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} lg={6} className={matchesLg ? classes.submitPadding : ''}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            data-cy="submit-payment-button"
            disabled={!canSubmitPayment || isCustomerReader}
            onClick={() => {
              if (submitPayment) submitPayment(!isOneTimeUsePaymentMethodSelected, fees);
            }}
          >
            Submit Payment
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <>
      <LoadingMask loading={sendingPayment || isCalculatedConvenienceFeeInFlight} />

      <Grid item container xs={12} direction="column" className={classes.base}>
        <Paper>
          {paymentError && (
            <AlertBanner
              message={`Payment failed. ${
                paymentError.message.length > 0 ? paymentError.message : 'Please verify your information and try again.'
              }`}
            />
          )}
          <Grid tabIndex={0} item container xs={12} className={classes.padding}>
            <Typography variant="title">Payment Information</Typography>
          </Grid>
          <Grid tabIndex={0} item container xs={12} direction="column" className={classes.padding}>
            <Typography variant="body2" color="textSecondary">
              Email
            </Typography>
            <Typography variant="body1">{viewerUser?.email}</Typography>
          </Grid>

          {/* credit memo */}
          {isCreditMemoEnabled && (
            <Grid tabIndex={0} item container xs={12} style={{ position: 'relative' }} className={classes.padding}>
              <LoadingMask loading={loadingCreditMemoBalance} />
              <Typography variant="body2" color="textSecondary">
                Credit Memos
              </Typography>
              {(creditMemoBalanceError || creditMemoBalance === 0) && (
                <Grid item xs={12}>
                  No Credit Balance available
                </Grid>
              )}
              {!creditMemoBalanceError && creditMemoBalance > 0 && (
                <Grid item xs={12}>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel, root: classes.checkboxRoot }}
                    control={
                      <Checkbox
                        color="primary"
                        checked={useCreditMemo}
                        onClick={toggleUseCreditMemo}
                        className={classes.checkboxIcon}
                      />
                    }
                    label={
                      <>
                        <Typography>Apply Credit to balance</Typography>
                        <Typography>
                          {typeof creditMemoBalance === 'number'
                            ? new Intl.NumberFormat('en', {
                                style: 'currency',
                                currency: currency || 'USD',
                                currencyDisplay: 'symbol',
                              }).format(loadingCreditMemoBalance ? 0 : creditMemoBalance / 100)
                            : null}
                        </Typography>
                      </>
                    }
                  />
                </Grid>
              )}
            </Grid>
          )}

          {/* payment method */}
          <Grid item container xs={12}>
            {!paymentMethod && !(isOneTimeUsePaymentMethodSelected && oneTimeUsePaymentMethod) && (
              <AlertBanner message="Please select a payment method." />
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            className={classes.paddingPaymentMethod}
          >
            <Grid tabIndex={0} item container xs={12}>
              <Typography variant="body2" color="textSecondary">
                Payment Method
              </Typography>
            </Grid>
            {acceptedPaymentMethods &&
              (acceptedPaymentMethods.length < 7 ||
                (acceptedPaymentMethods.length === 7 && acceptedPaymentMethods.indexOf(AcceptedPaymentMethod.Unknown) !== -1)) && (
                <Grid item container xs={12}>
                  <Typography>{`Some payment methods are not supported in this region. Please use a supported payment method (${acceptedPaymentMethods
                    .map(method => {
                      return AllAcceptedPaymentMethodForDisplay.find(data => data.key === method)?.value;
                    })
                    .join(', ')}).`}</Typography>
                </Grid>
              )}
          </Grid>

          {/* payment method list */}
          <Grid item container xs={12} className={classes.paddingRadio}>
            <PaymentMethodTimerBanner timeLimit={oneTimeMethodLimit} clearOneTimePaymentMethod={clearOneTimePaymentMethod} />
            {methodsSelection}
            {isConvenienceFeesApplied && (
              <Grid item xs={12} className={classes.bannerGrid}>
                <InfoIcon className={classes.infoIcon} />
                {`A convenience fee of ${(cardConvenienceFee?.rateBps || 0) / 100}% will apply to this transaction`}
              </Grid>
            )}
          </Grid>

          {/* billing address */}
          {billingInfo && (
            <>
              <Grid tabIndex={0} item container xs={12} direction="column" className={classes.padding}>
                <Typography variant="body2" color="textSecondary">
                  Billing Address
                </Typography>
                <Typography>{`${billingInfo?.line1} ${billingInfo?.line2 || ''}`}</Typography>
                <Typography color="textSecondary">{`${billingInfo?.postalCode}, ${billingInfo?.country}`}</Typography>
              </Grid>
            </>
          )}
        </Paper>
        <Paper className={classes.marginTop}>
          <Grid item container xs={12} direction="column">
            <Grid item container xs={12} className={classes.paymentSummaryPadding}>
              <Grid item container xs={10} md={11}>
                <Grid tabIndex={0} item xs={12}>
                  <Typography variant="subtitle2">PAYMENT SUMMARY</Typography>
                </Grid>
                <Grid tabIndex={0} item xs={12}>
                  <Typography variant="subtitle2">{`${paymentRequestInfoList ? paymentRequestInfoList.length : 1} Selected Invoice${
                    paymentRequestInfoList && paymentRequestInfoList.length > 1 ? 's' : ''
                  }`}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={2} md={1} justifyContent="flex-end">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={toggleShowPaymentSummary}
                  aria-label={`${showPaymentSummary ? 'collapse' : 'expand'} payment summary`}
                  data-cy="collapse-payment-summary"
                >
                  {showPaymentSummary ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Grid>
            </Grid>
            <Grid item container xs={12} className={classes.namePadding}>
              <Grid tabIndex={0} item sm={7} xs={12}>
                <Typography variant="title" className={classes.nameWrap}>
                  {paymentRequestInfo?.merchantName || merchantInfo?.name}
                </Typography>
              </Grid>
              <Grid item sm={5} xs={12} className={classes.refundPolicy}>
                <Link
                  variant="body2"
                  className={classes.refundPolicyLink}
                  href="#"
                  onClick={() => {
                    toggleRefundPolicyDisplay();
                  }}
                  hidden={!paymentRequestInfo?.refundPolicy && !merchantInfo?.refundPolicy}
                  data-cy="view-refund-policy"
                  underline="hover"
                >
                  {displayRefundPolicy ? 'Hide refund policy' : 'View refund policy'}
                </Link>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
          {showPaymentSummary && (
            <Grid tabIndex={0} item container xs={12}>
              <TableContainer className={classes.table}>
                <DataGridPro
                  headerHeight={isMobileView ? 0 : 56}
                  rowHeight={isMobileView ? 102 : 58}
                  aria-label="payment summary"
                  hideFooterPagination
                  hideFooter
                  hideFooterSelectedRowCount
                  className={classes.dataGrid}
                  rows={getPaymentSummaryRows()}
                  columns={paymentSummaryColumns}
                  autoHeight
                  rowThreshold={0}
                  getDetailPanelContent={getDetailPanelContent}
                  getDetailPanelHeight={() => 32}
                  components={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    DetailPanelExpandIcon: ExpandMoreIcon,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    DetailPanelCollapseIcon: ExpandLessIcon,
                  }}
                />
              </TableContainer>
            </Grid>
          )}

          <Grid tabIndex={0} item container xs={12} className={classes.totalAmount}>
            {(isCreditApplied || isConvenienceFeesApplied) && (
              <Grid item container xs={12} className={classes.subTotalContainer}>
                <Grid item xs={8} className={classes.subTotalText}>
                  Invoice Amount
                </Grid>
                <Grid item xs={4} className={classes.subTotalAmount}>
                  {new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
                    getSubTotalAmount() / 100,
                  )}
                </Grid>
              </Grid>
            )}
            {isCreditApplied && (
              <Grid item container xs={12} className={classes.subTotalContainer}>
                <Grid item xs={8} className={classes.subTotalText}>
                  Credit Balance
                </Grid>
                <Grid item xs={4} className={classes.creditAmount}>
                  -
                  {new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
                    getCreditAmount() / 100,
                  )}
                </Grid>
              </Grid>
            )}
            {isConvenienceFeesApplied && (
              <Grid item container xs={12} className={classes.subTotalContainer}>
                <Grid item xs={8} className={classes.subTotalText}>
                  Convenience Fees (non-refundable)
                </Grid>
                <Grid item xs={4} className={classes.subTotalAmount}>
                  {new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
                    getConvenienceFeesAmount() / 100,
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item container xs={12} className={classes.subTotalContainer}>
              <Grid item xs={8} className={classes.totalText}>
                Total Amount
              </Grid>
              <Grid item xs={4} className={classes.totalAmountText}>
                {new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
                  getTotalAmount() / 100,
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item container xs={12} className={classes.actionPadding}>
            {cancelOrSubmitPayment()}
          </Grid>
        </Paper>
      </Grid>

      <Dialog open={displayRefundPolicy} aria-labelledby="refund-policy-header" aria-describedby="refund-policy-content">
        <DialogTitle className={classes.refundTitle} id="refund-policy-header">
          {paymentRequestInfo?.merchantName || merchantInfo?.name} {'Refund Policy'}
        </DialogTitle>
        <DialogContent className={classes.refundContent}>
          <DialogContentText color={'textPrimary'} id="refund-policy-content">
            {paymentRequestInfo?.refundPolicy || merchantInfo?.refundPolicy}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.refundActions}>
          <Button
            className={classes.closeRefundPolicyButton}
            color={'primary'}
            variant={'contained'}
            onClick={() => {
              toggleRefundPolicyDisplay();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PayPaymentRequest;
