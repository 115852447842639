/** Display of route in crumbs */
export enum RouteDisplay {
  UserManagement = 'User Management',
  Payments = 'Payments',
  Payment = 'Payment',
  History = 'History',
  Home = 'Home',
}

/** root text of the path */
export enum RoutePath {
  UserManagement = '/user-management',
  Payments = '/payments',
  Payment = '/make-payment',
  History = '/history',
  Home = '/',
}
