import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MerchantInfo, PayerTransactionSummary, PaymentMethodRequest, Person, UpsertPersonStatus } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
  error?: Error;
  multiplePaymentMethodsEnabled: boolean;
  payerAccountStatus?: UpsertPersonStatus;
  requestsInFlight: number;
  userExists?: boolean;
  userNoEmail?: boolean;
  viewerUser?: Person;
  upsertingPayerBankAccount: boolean;
  menuVisible: boolean;
  walletButtonVisible: boolean;
  selectedMerchant?: string;
  selectedMerchantName?: string;
  selectedMerchantInfo?: MerchantInfo;
  payerTransactionSummaryByMerchant?: PayerTransactionSummary;
  isOnHome: boolean;
  paymentMethodRequest?: PaymentMethodRequest;
  isPaymentMethodRequestCompleted: boolean;
}

const initialState: SliceState = {
  error: undefined,
  multiplePaymentMethodsEnabled: true,
  payerAccountStatus: undefined,
  requestsInFlight: 0,
  userExists: undefined,
  viewerUser: undefined,
  upsertingPayerBankAccount: false,
  menuVisible: false,
  walletButtonVisible: false,
  isOnHome: false,
  isPaymentMethodRequestCompleted: false,
};

export const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    fetchError: (state, action: PayloadAction<Error>) => {
      // eslint-disable-next-line no-param-reassign
      state.error = action.payload;
    },
    fetchViewerUserByEmailSuccess: (state, action: PayloadAction<Person>) => {
      // eslint-disable-next-line no-param-reassign
      state.viewerUser = action.payload;
    },
    fetchUserExists: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.userExists = action.payload;
    },
    fetchPayerAccountStatus: (state, action: PayloadAction<UpsertPersonStatus | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.payerAccountStatus = action.payload;
    },
    fetchUpsertingPayerBankAccount: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.upsertingPayerBankAccount = action.payload;
    },
    incrementRequestsInFlight: state => {
      // eslint-disable-next-line no-param-reassign
      state.requestsInFlight += 1;
    },
    decrementRequestsInFlight: state => {
      // eslint-disable-next-line no-param-reassign
      if (state.requestsInFlight > 0) state.requestsInFlight -= 1;
    },
    fetchUserNoEmail: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.userNoEmail = action.payload;
    },
    fetchMenuVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.menuVisible = action.payload;
    },
    fetchWalletButtonVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.walletButtonVisible = action.payload;
    },
    fetchSelectedMerchant: (state, action: PayloadAction<string | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedMerchant = action.payload;
    },
    fetchSelectedMerchantName: (state, action: PayloadAction<string | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedMerchantName = action.payload;
    },
    fetchSelectedMerchantInfo: (state, action: PayloadAction<MerchantInfo | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedMerchantInfo = action.payload;
    },
    fetchPayerTransactionSummaryByMerchant: (state, action: PayloadAction<PayerTransactionSummary | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.payerTransactionSummaryByMerchant = action.payload;
    },
    fetchIsOnHome: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isOnHome = action.payload;
    },
    fetchPaymentMethodRequest: (state, action: PayloadAction<PaymentMethodRequest | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentMethodRequest = action.payload;
    },
    fetchIsPaymentMethodRequestCompleted: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isPaymentMethodRequestCompleted = action.payload;
    },
  },
});

export const selectViewerUser = (state: RootState): Person | undefined => state.app.viewerUser;
export const selectMultiplePaymentMethodsEnabled = (state: RootState): boolean => state.app.multiplePaymentMethodsEnabled;
export const selectPayerAccountStatus = (state: RootState): UpsertPersonStatus | undefined => state.app.payerAccountStatus;
export const selectUserExists = (state: RootState): boolean | undefined => state.app.userExists;
export const selectRequestsInFlight = (state: RootState): number => state.app.requestsInFlight;
export const selectNetworkBusy = (state: RootState): boolean => state.app.requestsInFlight > 0;
export const selectAppError = (state: RootState): Error | undefined => state.app.error;
export const selectUpsertingPayerBankAccount = (state: RootState): boolean => state.app.upsertingPayerBankAccount;
export const selectUserNoEmail = (state: RootState): boolean | undefined => state.app.userNoEmail;
export const selectMenuVisible = (state: RootState): boolean => state.app.menuVisible;
export const selectWalletButtonVisible = (state: RootState): boolean => state.app.walletButtonVisible;
export const selectSelectedMerchant = (state: RootState): string | undefined => state.app.selectedMerchant;
export const selectSelectedMerchantName = (state: RootState): string | undefined => state.app.selectedMerchantName;
export const selectSelectedMerchantInfo = (state: RootState): MerchantInfo | undefined => state.app.selectedMerchantInfo;
export const selectPayerTransactionSummaryByMerchant = (state: RootState): PayerTransactionSummary | undefined =>
  state.app.payerTransactionSummaryByMerchant;
export const selectIsOnHome = (state: RootState): boolean => state.app.isOnHome;
export const selectPendingPaymentMethodRequest = (state: RootState): PaymentMethodRequest | undefined =>
  state.app.paymentMethodRequest;
export const selectIsPaymentMethodRequestCompleted = (state: RootState): boolean => state.app.isPaymentMethodRequestCompleted;

export const {
  fetchPayerAccountStatus,
  fetchUserExists,
  fetchUserNoEmail,
  fetchViewerUserByEmailSuccess,
  fetchError,
  incrementRequestsInFlight,
  decrementRequestsInFlight,
  fetchUpsertingPayerBankAccount,
  fetchMenuVisible,
  fetchWalletButtonVisible,
  fetchSelectedMerchant,
  fetchSelectedMerchantName,
  fetchSelectedMerchantInfo,
  fetchPayerTransactionSummaryByMerchant,
  fetchIsOnHome,
  fetchPaymentMethodRequest,
  fetchIsPaymentMethodRequestCompleted,
} = slice.actions;

export default slice.reducer;
