import CreateIcon from '@mui/icons-material/Create';
import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FC } from 'react';

import { PaymentMethodHolder } from '../gql-types.generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(1.5, 2, 1, 2),
    },
    headerPadding: {
      padding: theme.spacing(1, 1, 1, 2),
    },
    container: {
      marginBottom: '10px',
    },
    billingInfo: {
      padding: theme.spacing(1, 2, 2, 2),
      fontSize: 14,
    },
    icon: {
      opacity: 0.54,
    },
    smallLine: {
      fontSize: 6,
    },
  }),
);

interface BillingInfoProps {
  holder: PaymentMethodHolder;
  edit?: () => void;
}

const BillingInfo: FC<BillingInfoProps> = props => {
  const classes = useStyles();
  const { edit, holder } = props;
  const { holderName, address, email, phone } = holder;
  if (holderName) {
    return (
      <Box className={classes.container}>
        <Grid container alignItems={'center'} className={classes.headerPadding} justifyContent="space-between">
          <Grid item>
            <Typography variant={'body2'}>Billing Address</Typography>
          </Grid>
          {edit && (
            <Grid item>
              <Button
                size="small"
                onClick={() => {
                  edit();
                }}
              >
                <CreateIcon className={classes.icon} />
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid container className={classes.billingInfo}>
          <Grid item xs={12}>
            {holderName}
            <br />
            {email}
            <br />
            <p className={classes.smallLine} />
            {address?.line1}
            {address?.line2 ? `, ${address?.line2}` : ''}
            <br />
            {address?.postalCode}
            {address?.country ? `, ${address?.country}` : ''}
            <br />
            <p className={classes.smallLine} />
            {phone?.number &&
              `${phone?.countryCode} (${phone?.number?.substring(0, 3)}) ${phone?.number?.substring(3, 6)}-${phone?.number?.substring(
                6,
                10,
              )}`}
          </Grid>
        </Grid>
      </Box>
    );
  }
  return <></>;
};

export default BillingInfo;
