import { alpha, Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { PaymentRequestStatus } from '../gql-types.generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: alpha(theme.palette.primary.light, 0.15),
      color: theme.palette.primary.main,
      padding: theme.spacing(2),
    },
    upperHeaderText: {
      fontSize: 10,
      letterSpacing: 1.5,
      fontWeight: 500,
    },
    mainHeaderText: {
      fontWeight: 500,
    },
    body: {
      padding: theme.spacing(2),
    },
  }),
);
interface PaymentProcessingProps {
  status: PaymentRequestStatus;
}
const PaymentProcessing: React.FC<PaymentProcessingProps> = props => {
  const classes = useStyles();
  const { status } = props;
  let info;
  switch (status) {
    case PaymentRequestStatus.RefundPending:
      info = {
        title: 'REFUND PROCESSING',
        subTitle: 'A refund is being processed for the payment of this request',
        body: 'The refund is pending.',
      };
      break;
    case PaymentRequestStatus.Pending:
      info = {
        title: 'PAYMENT PROCESSING',
        subTitle: 'A payment has already been sent for this request',
        body: 'The payment is pending.',
      };
      break;
    case PaymentRequestStatus.Closed:
      info = {
        title: 'PAYMENT REQUEST CLOSED',
        subTitle: 'This payment link is no longer valid as the payment request is closed.',
      };
      break;

    default:
      info = {
        title: status,
        subTitle: 'An existing payment for this request has been located.',
      };
      break;
  }
  return (
    <Box id="payment-request-not-found" data-cy="payment-request-processing">
      <Grid container className={classes.header}>
        <Grid item xs={12} lg={12}>
          <Typography variant="body2" className={classes.upperHeaderText}>
            {info.title}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography className={classes.mainHeaderText}>{info.subTitle}</Typography>
        </Grid>
      </Grid>
      {info.body && (
        <Grid container spacing={2} className={classes.body}>
          <Grid item xs={12} lg={12}>
            <Typography variant="body2">{info.body}</Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default PaymentProcessing;
