import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Container, FormControl, Grid, InputLabel, Select, SvgIcon, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { Address, PaymentMethodHolder, Phone } from '../gql-types.generated';
import { allCountries } from '../util/Countries';
import {
  checkHolderInfoValid,
  checkIsCountryCodeValid,
  checkIsEmailValid,
  checkIsPhoneNumberValid,
  checkIsPostalCodeValid,
  checkIsTextValid,
  getPostalValidation,
} from '../util/Validators';

interface NumberFormatCustomProps {
  onChange: (event: { target: { value: string } }) => void;
}

const PhoneNumberFormat = (props: NumberFormatCustomProps) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      format="(###) ###-####"
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonPadding: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    arrowBack: {
      cursor: 'pointer',
    },
  }),
);

/*
  This component edits billing information for payer payment methods.
  It can be utilized in two ways:
    - handleContinue: Configure with a button return function to return the gathered information
        This puts the ownership of a button within this component.
    - handleHolderChange: Configure a function to handle any updates to the holder information
        This allows the owner of this component the ability to submit the info at their own pace via their own button.
*/
interface BillingEditProps {
  isAdd: boolean;
  holder?: PaymentMethodHolder;
  backText?: string;
  continueText?: string;
  handleBack?: () => void;
  handleHolderChange?: (holder: PaymentMethodHolder, isValid: boolean) => void;
  handleContinue?: (holder: PaymentMethodHolder) => void;
  onMobile: boolean;
  useDefault?: boolean;
}

const BillingEdit: React.FC<BillingEditProps> = props => {
  const classes = useStyles();
  const { isAdd, holder, backText, continueText, handleContinue, handleBack, handleHolderChange, onMobile, useDefault } = props;
  const [email, setEmail] = useState<string | null | undefined>(holder?.email);
  const [line1, setLine1] = useState<string | null | undefined>(holder?.address?.line1);
  const [line2, setLine2] = useState<string | null | undefined>(holder?.address?.line2);
  const [country, setCountry] = useState<string | null | undefined>(holder?.address?.country);
  const [postalCode, setPostalCode] = useState<string | null | undefined>(holder?.address?.postalCode);
  const [phoneNumber, setPhoneNumber] = useState<string | null | undefined>(holder?.phone?.number);
  const [countryCode, setCountryCode] = useState<string | null | undefined>(
    holder?.phone?.countryCode || country
      ? `+${
          allCountries.find(item => {
            return item.abbr === country;
          })?.code
        }`
      : '',
  );
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [addressOneValid, setAddressOneValid] = useState<boolean>(true);
  const [addressTwoValid, setAddressTwoValid] = useState<boolean>(true);
  const [phoneNumberValid, setPhoneNumberValid] = useState<boolean>(true);
  const [countryCodeValid, setCountryCodeValid] = useState<boolean>(true);
  const [postalValidation, setPostalValidation] = useState<{ name: string; maxLength: number }>(
    getPostalValidation(countryCode || ''),
  );
  const [postalCodeValid, setPostalCodeValid] = useState<boolean>(true);
  const [billingInfoValid, setBillingInfoValid] = useState<boolean>(!isAdd);
  const handleBillingInfoChange = () => {
    const holder = {
      email,
      address: {
        line1,
        line2,
        postalCode,
        country,
      } as Address,
      phone: {
        number: phoneNumber,
        countryCode,
      } as Phone,
    } as PaymentMethodHolder;
    const billingValid = checkHolderInfoValid(holder);
    setBillingInfoValid(billingValid);
    if (handleHolderChange) {
      handleHolderChange(holder, billingValid);
    }
  };
  useEffect(() => {
    handleBillingInfoChange();
  }, []);
  useEffect(() => {
    handleBillingInfoChange();
  }, [email, line1, line2, postalCode, country, phoneNumber, countryCode]);

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const onAddressLine1Change = (event: ChangeEvent<HTMLInputElement>) => {
    setLine1(event.target.value);
  };
  const onAddressLine2Change = (event: ChangeEvent<HTMLInputElement>) => {
    setLine2(event.target.value);
  };
  const onCountryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const countrySelected = event.target.value;
    const zipCode = countrySelected
      ? `+${
          allCountries.find(item => {
            return item.abbr === countrySelected;
          })?.code
        }`
      : '';
    setCountry(countrySelected);
    setCountryCode(zipCode);
  };
  const onPostalCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPostalCode(event.target.value);
  };
  const onPhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };
  const onCountryCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCountryCode(event.target.value);
  };
  const isBlurOnCancel = (eventTarget: HTMLBaseElement) => {
    return (
      eventTarget?.getAttribute && (eventTarget.getAttribute('data-cy') === 'cancel' || eventTarget.getAttribute('data-cy') === 'back')
    );
  };
  const onEmailBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (isBlurOnCancel(event.relatedTarget as HTMLBaseElement)) return;
    const emailValue = event.target.value;
    setEmailValid(checkIsEmailValid(emailValue));
  };
  const onAddressLine1Blur = (event: FocusEvent<HTMLInputElement>) => {
    if (isBlurOnCancel(event.relatedTarget as HTMLBaseElement)) return;
    setAddressOneValid(checkIsTextValid(event.target.value));
  };
  const onAddressLine2Blur = (event: FocusEvent<HTMLInputElement>) => {
    if (isBlurOnCancel(event.relatedTarget as HTMLBaseElement)) return;
    setAddressTwoValid(checkIsTextValid(event.target.value, true));
  };
  const onCountryBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (isBlurOnCancel(event.relatedTarget as HTMLBaseElement)) return;
    const countryCode = event.target.value;
    const validation = getPostalValidation(countryCode);
    setPostalValidation(validation);
  };
  const onPostalCodeBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (isBlurOnCancel(event.relatedTarget as HTMLBaseElement)) return;
    const postalCodeValue = event.target.value;
    setPostalCodeValid(checkIsPostalCodeValid(postalCodeValue, country));
  };
  const onCountryCodeBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (isBlurOnCancel(event.relatedTarget as HTMLBaseElement)) return;
    const countryCode = event.target.value;
    setCountryCodeValid(checkIsCountryCodeValid(countryCode));
  };
  const onPhoneNumberBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (isBlurOnCancel(event.relatedTarget as HTMLBaseElement)) return;
    setPhoneNumberValid(!!phoneNumber && checkIsPhoneNumberValid(phoneNumber));
  };
  const clearBillingInfo = () => {
    setEmail('');
    setLine1('');
    setLine2('');
    setCountry('');
    setPostalCode('');
    setPhoneNumber('');
    setCountryCode('');
  };

  return (
    <Box data-cy="billing-address-modal" aria-label="billing address form">
      <Grid container p={2}>
        {onMobile && handleBack && (
          <Grid item xs={2}>
            <SvgIcon
              color={'primary'}
              fontSize={'large'}
              className={classes.arrowBack}
              onClick={() => {
                handleBack();
              }}
              data-cy="back"
            >
              <ArrowBackIcon />
            </SvgIcon>
          </Grid>
        )}
        <Grid item xs={onMobile ? 10 : 12}>
          <Typography variant="title" id="edit-billing-address">
            {isAdd ? 'Add Billing Address' : 'Edit Billing Information'}
          </Typography>
        </Grid>
      </Grid>
      <Container disableGutters>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              variant={'outlined'}
              error={!emailValid}
              helperText={emailValid ? null : 'Invalid Email Address.'}
              FormHelperTextProps={{ id: 'email-address-helper-text' }}
              value={email}
              onBlur={onEmailBlur}
              onChange={onEmailChange}
              label="Email address"
              type="email"
              data-cy="email"
              disabled={useDefault}
              id="email-address"
              inputProps={{ 'aria-describedby': `${emailValid ? undefined : 'email-address-helper-text'}` }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant={'outlined'}
              fullWidth
              error={!addressOneValid}
              helperText={addressOneValid ? null : 'Invalid Street Address.'}
              FormHelperTextProps={{ id: 'street-address-helper-text' }}
              value={line1}
              onBlur={onAddressLine1Blur}
              onChange={onAddressLine1Change}
              label="Street address"
              data-cy="street-address"
              disabled={useDefault}
              id="street-address"
              inputProps={{ 'aria-describedby': `${addressOneValid ? undefined : 'street-address-helper-text'}` }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant={'outlined'}
              fullWidth
              error={!addressTwoValid}
              helperText={addressTwoValid ? null : 'Invalid Apt, suite, etc.'}
              FormHelperTextProps={{ id: 'street-address-2-helper-text' }}
              value={line2}
              onBlur={onAddressLine2Blur}
              onChange={onAddressLine2Change}
              label="Apt, suite, etc (optional)"
              data-cy="secondary-address"
              disabled={useDefault}
              aria-label="street address 2"
              id="street-address-2"
              inputProps={{ 'aria-describedby': `${addressTwoValid ? undefined : 'street-address-2-helper-text'}` }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant={'outlined'} fullWidth onChange={onCountryChange} onBlur={onCountryBlur} disabled={useDefault}>
              <InputLabel id="LabelCountry">Country</InputLabel>
              <Select
                displayEmpty={true}
                labelId="LabelCountry"
                native
                label="Country"
                value={country}
                data-cy="country"
                inputProps={{ 'aria-label': 'country' }}
              >
                <option key={-1} value={''}></option>
                {allCountries.map(country => {
                  return (
                    <option key={country.abbr} value={country.abbr}>
                      {country.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant={'outlined'}
              label={postalValidation?.name}
              error={!postalCodeValid}
              helperText={postalCodeValid ? null : `Invalid ${postalValidation?.name}.`}
              FormHelperTextProps={{ id: 'postal-code-helper-text ' }}
              inputProps={{
                maxLength: postalValidation?.maxLength,
                'aria-describedby': `${postalCodeValid ? undefined : 'postal-code-helper-text'}`,
              }}
              value={postalCode}
              onBlur={onPostalCodeBlur}
              onChange={onPostalCodeChange}
              data-cy="zipcode"
              disabled={useDefault}
              aria-label="postal code"
              id="postal-code"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              value={countryCode || ''}
              onBlur={onCountryCodeBlur}
              onChange={onCountryCodeChange}
              error={!countryCodeValid}
              helperText={countryCodeValid ? null : `Invalid Country Code.`}
              FormHelperTextProps={{ id: 'country-code-helper-text' }}
              label="Country Code"
              data-cy="country-code"
              disabled={useDefault}
              aria-label="country code"
              id="country-code"
              inputProps={{ 'aria-describedby': `${countryCodeValid ? undefined : 'country-code-helper-text'}` }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              variant="outlined"
              fullWidth
              value={phoneNumber}
              type="tel"
              aria-required="false"
              autoComplete="off"
              error={!phoneNumberValid}
              helperText={phoneNumberValid ? null : `Invalid Phone Number.`}
              FormHelperTextProps={{ id: 'phone-number-helper-text' }}
              onBlur={onPhoneNumberBlur}
              onChange={onPhoneNumberChange}
              label="Phone Number"
              data-cy="phone-number"
              InputProps={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                inputComponent: PhoneNumberFormat as any,
                'aria-describedby': `${phoneNumberValid ? undefined : 'phone-number-helper-text'}`,
              }}
              InputLabelProps={phoneNumber ? { shrink: true } : {}} //workaround for label overlap when a phone number is present
              disabled={useDefault}
              aria-label="phone number"
              id="phone-number"
            />
          </Grid>
        </Grid>
      </Container>
      {(handleContinue || handleBack) && (
        <Grid container className={classes.buttonPadding} justifyContent={onMobile ? undefined : 'space-between'} spacing={1}>
          {!onMobile && handleBack && (
            <Grid item>
              <Button
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  handleBack();
                  clearBillingInfo();
                }}
                data-cy="back"
              >
                {backText || 'Back'}
              </Button>
            </Grid>
          )}
          {handleContinue && (
            <Grid item xs={onMobile ? 12 : undefined}>
              <Button
                variant="contained"
                color="primary"
                disabled={!billingInfoValid}
                onClick={() => {
                  handleContinue({
                    email,
                    address: {
                      line1,
                      line2,
                      postalCode,
                      country,
                    } as Address,
                    phone: {
                      number: phoneNumber,
                      countryCode,
                    } as Phone,
                  } as PaymentMethodHolder);
                  clearBillingInfo();
                }}
                data-cy="continue-to-payment"
              >
                {continueText || 'Continue'}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default BillingEdit;
