import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { AI_INSTRUMENTATION_KEY } from './Constants';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: AI_INSTRUMENTATION_KEY,
    maxBatchInterval: 0,
    disableFetchTracking: false,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: browserHistory,
      },
    },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
