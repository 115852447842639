import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, Grid, Link, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import React from 'react';

import { PaymentRequestStatus } from '../gql-types.generated';

const useStylesMobile = makeStyles((theme: Theme) =>
  createStyles({
    cellAmount: {
      color: '#000000DE',

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    failedCell: {
      color: '#FF3B30',
    },
    errorIcon: {
      fontSize: '20px',
      verticalAlign: 'text-bottom',
    },
    closeIconDiv: {
      width: '15%',
      textAlign: 'right',
      color: theme.palette.grey[600],
    },
    partialCloseIconDiv: {
      width: '15%',
      textAlign: 'center',
      color: theme.palette.grey[600],
    },
    textField: {
      backgroundColor: '#FFFFFF',
    },
    totalBeforeDiscount: {
      textDecoration: 'line-through',
      paddingRight: theme.spacing(0.5),
      display: 'inline-block',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    total: {
      display: 'inline-block',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    cellDisplay: {
      display: 'inline-block',
    },
    cellDiscount: {
      display: 'inline-block',
      fontStyle: 'italic',
      textAlign: 'right',
    },
    dueErrorIcon: {
      verticalAlign: 'text-bottom',
      fontSize: '17px',
      marginRight: theme.spacing(0.5),
    },
    cellPayment: {
      color: '#000000DE',
      display: 'flex',
      fontSize: '16px',
      fontWeight: 'bold',
      height: 20,
    },
    paymentPlaceholder: {
      height: 20,
    },
    leftData: {
      flexBasis: '40%',
      width: '40%',
      textAlign: 'left',
    },
    rightData: {},
    leftDataStatus: {
      padding: '6px 0px 6px 0px',
    },
    leftDataLink: {
      padding: '10px 0px 8px 0px',
    },
    leftDataValues: {
      padding: '6px 0px 6px 0px',
    },
    rightDataPad: {
      height: 36,
      padding: '6px 0px 6px 0px',
      textAlign: 'right',
    },
    editButton: {
      textAlign: 'right',
      padding: '6px 0px 6px 0px',
    },
    dataContainer: {
      height: '100%',
    },
    editPlaceholder: {
      height: 43,
    },
  }),
);

interface PaymentsDueRowProps {
  row: GridRowModel;
  partialAllowed: boolean;
  isSelected: boolean;
  creditAllowed: boolean;
  showPaymentDetails: () => void;
  discountEndVisible: boolean;
  handlePartialEditClicked: (row: GridRowModel) => void;
}

const PaymentsDueRow: React.FC<PaymentsDueRowProps> = props => {
  const { row, partialAllowed, isSelected, showPaymentDetails, handlePartialEditClicked } = props;
  // eslint-disable-next-line no-underscore-dangle
  const rawData = row._raw;
  const referenceNumber = row.id?.trim();
  const paymentDue = rawData?.paymentDue;
  const paymentRequest = paymentDue?.paymentRequest;
  const rawStatus = paymentRequest?.status;
  const dueDate = DateTime.fromISO(paymentRequest?.dueDate);
  const overDue = dueDate.isValid && dueDate.startOf('day') < DateTime.now().startOf('day');
  const discountEndDate = DateTime.fromISO(paymentRequest?.discountEndDate);
  const doesDiscountApply = discountEndDate.isValid && discountEndDate > DateTime.utc();
  const discountAmount = doesDiscountApply && paymentRequest?.discountAmount ? paymentRequest.discountAmount : 0;
  const classesMobile = useStylesMobile();
  return (
    <Grid container className={classesMobile.dataContainer} alignItems={'stretch'} alignContent={'stretch'}>
      <Grid container item xs={6} alignContent={'stretch'} alignItems={'stretch'} className={classesMobile.leftData}>
        <Grid item xs={12} className={classesMobile.leftDataLink}>
          <Link
            href="#"
            onClick={showPaymentDetails}
            underline="hover"
            aria-label={`open payment request details${referenceNumber ? ` reference ${referenceNumber}` : ''}`}
          >
            {referenceNumber && referenceNumber.length > 9 ? `***${referenceNumber.slice(-9)}` : referenceNumber}
          </Link>
        </Grid>
        <Grid item xs={12} className={classesMobile.leftDataStatus}>
          <div className={rawStatus === PaymentRequestStatus.Failed ? classesMobile.failedCell : ''}>{row.status}</div>
        </Grid>
        <Grid item xs={12} className={classesMobile.leftDataValues}>
          <div className={rawStatus === PaymentRequestStatus.Failed ? classesMobile.failedCell : classesMobile.cellAmount}>
            {rawStatus === PaymentRequestStatus.Failed && <ErrorOutlineIcon className={classesMobile.errorIcon} />}
            {discountAmount > 0 && typeof paymentRequest?.totalDue === 'number' && (
              <Typography className={classesMobile.totalBeforeDiscount} color="error">
                {typeof paymentRequest?.totalDue === 'number'
                  ? new Intl.NumberFormat('en', {
                      style: 'currency',
                      currency: paymentDue.currency || 'USD',
                      currencyDisplay: 'symbol',
                    }).format(paymentRequest?.totalDue / 100)
                  : null}
              </Typography>
            )}
            {typeof paymentRequest?.totalDue === 'number' ? (
              <Typography className={classesMobile.total}>
                {new Intl.NumberFormat('en', {
                  style: 'currency',
                  currency: paymentDue.currency || 'USD',
                  currencyDisplay: 'symbol',
                }).format((paymentRequest?.totalDue - discountAmount) / 100)}
              </Typography>
            ) : null}
          </div>
        </Grid>
        {partialAllowed && isSelected && (
          <Grid item xs={12} className={classesMobile.cellPayment}>
            Payment:{' '}
            {new Intl.NumberFormat('en', {
              style: 'currency',
              currency: paymentDue.currency || 'USD',
              currencyDisplay: 'symbol',
            }).format(rawData.amount / 100)}
          </Grid>
        )}
        {!isSelected && <Grid item xs={12} className={classesMobile.paymentPlaceholder}></Grid>}
      </Grid>
      <Grid container item xs={6} alignItems={'stretch'} alignContent={'stretch'} className={classesMobile.rightData}>
        {partialAllowed && isSelected && (
          <Grid item xs={12} className={classesMobile.editButton}>
            <Button
              color="primary"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => {
                handlePartialEditClicked(row);
              }}
            >
              Edit
            </Button>
          </Grid>
        )}
        {!isSelected && <Grid item xs={12} className={classesMobile.editPlaceholder}></Grid>}
        <Grid item xs={12} className={classesMobile.rightDataPad}>
          <div className={classesMobile.cellDisplay}>Billed {new Date(paymentRequest?.createdAt).toLocaleDateString()}</div>
        </Grid>
        {paymentRequest?.dueDate && (
          <Grid item xs={12} className={classesMobile.rightDataPad}>
            <div className={classesMobile.cellDisplay}>
              {overDue && <ErrorIcon color="error" className={classesMobile.dueErrorIcon} />}
              Due {new Date(paymentRequest?.dueDate).toLocaleDateString()}
            </div>
          </Grid>
        )}
        {paymentRequest?.discountEndDate && (
          <Grid item xs={12} className={classesMobile.cellDiscount}>
            Discount Ends{' '}
            {new Date(paymentRequest?.discountEndDate).toLocaleDateString(undefined, {
              month: 'numeric',
              day: 'numeric',
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PaymentsDueRow;
