import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { CreditMemoConnection, CreditMemoOrder, CreditMemoStatus } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_CREDITMEMOS_CONNECTION = gql`
  query openCreditMemos(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: CreditMemoOrder!
    $status: [CreditMemoStatus!]
  ) {
    payerCreditMemos(after: $after, before: $before, first: $first, last: $last, orderBy: $orderBy, status: $status) {
      totalCount
      totalBalance
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        id
        amount
        remainingBalance
        createdAt
        reason
        referenceNumber
        currency
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_CREDITMEMOS_CONNECTION_DETAILS = gql`
  query creditMemoDetails(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: CreditMemoOrder!
    $status: [CreditMemoStatus!]
  ) {
    payerCreditMemos(after: $after, before: $before, first: $first, last: $last, orderBy: $orderBy, status: $status) {
      totalBalance
    }
  }
`;

export const queryCreditMemoConnection = async (
  client: ApolloClient<NormalizedCacheObject>,
  after: string | undefined,
  before: string | undefined,
  first: number | undefined,
  last: number | undefined,
  orderBy: CreditMemoOrder,
  status: CreditMemoStatus[],
  isTotal: boolean,
  tenantId: string,
): Promise<CreditMemoConnection> => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.query<{ payerCreditMemos: CreditMemoConnection }>({
    variables: { after, before, first, last, orderBy, status },
    query: isTotal ? QUERY_CREDITMEMOS_CONNECTION_DETAILS : QUERY_CREDITMEMOS_CONNECTION,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  return data.payerCreditMemos;
};
