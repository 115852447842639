import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { CreatePaymentMethodStatus } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_CREATE_PAYMENT_METHOD = gql`
  mutation createPaymentMethod($input: CreatePaymentMethodInput!) {
    createPaymentMethod(input: $input) {
      code
      message
      error
      paymentMethod {
        id
        creditCard {
          cardBrand
        }
        createdAt
        createdBy
      }
    }
  }
`;

export const createPaymentMethod = async (
  client: ApolloClient<NormalizedCacheObject>,
  token: string,
  attachToResourceId: string | undefined,
  isDefault: boolean,
  tenantId: string,
  shareWithMerchant: boolean,
): Promise<CreatePaymentMethodStatus | undefined> => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.mutate<{ createPaymentMethod: CreatePaymentMethodStatus }>({
    variables: { input: { token, attachToResourceId, isDefault, shareWithMerchant } },
    mutation: MUTATION_CREATE_PAYMENT_METHOD,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the person.
    throw new Error('An error has occurred during payment method creation.');
  }

  if (data && data.createPaymentMethod) {
    return data.createPaymentMethod;
  }

  return undefined;
};
