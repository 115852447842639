import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';

import multipleMethodsHomeReducer from '../features/multipleMethodsHome/MultipleMethodsHomeSlice';
import appReducer from '../features/app/AppSlice';
import manageUsersReducer from '../features/manage-users/ManageUsersSlice';

export const rootReducer = combineReducers({
  app: appReducer,
  multipleMethodsHome: multipleMethodsHomeReducer,
  manageUsers: manageUsersReducer,
});

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
