import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DeletePersonStatus } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_REMOVE_CUSTOMER_USER = gql`
  mutation removeCustomerUser($input: RemoveCustomerUserInput!) {
    removeCustomerUser(input: $input) {
      code
      message
      error
    }
  }
`;

export const mutationRemoveCustomerUser = async (
  client: ApolloClient<NormalizedCacheObject>,
  tenantId: string,
  customerId: string,
  email: string,
): Promise<DeletePersonStatus | undefined> => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.mutate<DeletePersonStatus>({
    variables: { input: { customerId, email } },
    mutation: MUTATION_REMOVE_CUSTOMER_USER,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the person.
    throw new Error('An error has occurred during deletion of user.');
  }

  if (data) {
    return data;
  }

  return undefined;
};
