import { AcceptedPaymentMethod } from '../gql-types.generated';

type AcceptedPaymentMethodForDisplay = {
  key: AcceptedPaymentMethod;
  value: string;
};
export const AllAcceptedPaymentMethodForDisplay: AcceptedPaymentMethodForDisplay[] = [
  { key: AcceptedPaymentMethod.Amex, value: 'Amex' },
  { key: AcceptedPaymentMethod.Diners, value: 'Diners' },
  { key: AcceptedPaymentMethod.Discover, value: 'Discover' },
  { key: AcceptedPaymentMethod.Jcb, value: 'Jcb' },
  { key: AcceptedPaymentMethod.Mastercard, value: 'Mastercard' },
  { key: AcceptedPaymentMethod.PaymentBank, value: 'Bank Account' },
  { key: AcceptedPaymentMethod.Unknown, value: 'Unknown' },
  { key: AcceptedPaymentMethod.Visa, value: 'Visa' },
];
