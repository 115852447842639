import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Grid, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ChangeEvent, FC, useEffect, useState } from 'react';

import CreditCards from '../credit_cards.svg';
import { AcceptedPaymentMethod, PaymentMethodHolder, PaymentRequestInfo, Person } from '../gql-types.generated';
import { AllAcceptedPaymentMethodForDisplay } from '../util/AcceptedPaymentMethodForDisplay';
import { checkIsFullNameValid } from '../util/Validators';
import LoadingMask from './LoadingMask';
import { ApteanPaySDK, ApteanPaySDKCardComponent, useApteanPay } from '../util/ApteanPay';
import { useSelector } from 'react-redux';
import { selectTenantIntegrationSettings } from '../features/multipleMethodsHome/MultipleMethodsHomeSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(2),
    },
    iFramePadding: {
      padding: theme.spacing(0, 0, 1.5, 0),
      minHeight: 156,
    },
    iFramePaddingGuest: {
      padding: theme.spacing(0, 0, 1.5, 0),
      position: 'relative',
      minHeight: 156,
    },
    paymentAction: {
      marginTop: 6,
      marginLeft: 4,
    },
    creditCardInformationWrap: {
      padding: theme.spacing(1, 1.5, 0, 1.5),
      flexGrow: 1,
    },
    rightAlign: {
      textAlign: 'right',
    },
    creditCardInfoText: {
      width: 180,
      paddingTop: 14,
    },
    creditCardImage: {
      width: 'calc(100% - 180px)',
      minWidth: 180,
    },
    bannerPadding: {
      padding: theme.spacing(0, 1, 1, 2),
    },
    holderName: {
      padding: theme.spacing(0, 2, 1, 2),
    },
    holderNameFieldError: {
      '& ::placeholder': {
        color: theme.palette.error.main,
        opacity: 1,
      },
    },
    cciFrame: {
      padding: theme.spacing(0, 2),
      minHeight: 65,
    },
  }),
);

interface CardInfoProps {
  backText?: string;
  creditError?: string;
  setCreditError: (message?: string) => void;
  handleBack?: () => void;
  onTokenizeInjectSuccess?: (apteanPay: ApteanPaySDK, cardComponent: ApteanPaySDKCardComponent) => void;
  viewerUser?: Person;
  paymentRequestInfo?: PaymentRequestInfo;
  holderName: string;
  setHolderName: (cardHolderName: string) => void;
  holderNameValid: boolean;
  setHolderNameValid: (holderNameValid: boolean) => void;
  isIFrameVisible: boolean;
  setIsIFrameVisible: (isIFrameVisible: boolean) => void;
  isGuestUser?: boolean;
  setSendingPayment?: (sendingPayment: boolean) => void;
  sendingPayment?: boolean;
  submitText?: string;
  cardInfoDisabled?: boolean;
  merchantTenantId?: string;
  customerId?: string;
  holderInformation?: PaymentMethodHolder | null;
  onTokenize?: (apteanPay: ApteanPaySDK, cardComponent: ApteanPaySDKCardComponent) => void;
}

/*
  This component allows the input of credit card information within a WePay iFrame.
  It can be utilized in two ways:
    - tokenizeSuccess: Configured with a button return function to return the tokenized cc info.
        This puts the ownership of a button within this component.
    - onTokenizeInjectSuccess: Configure a function to accept the tokenize function which is used to use the WePay iFrame tokenize function.
        This allows the owner of this component the ability to submit the cc info for tokenize at their own pace via their own button.

  Error state is handled either internally or by the owning component.
*/
const CardInfo: FC<CardInfoProps> = props => {
  const classes = useStyles();
  const {
    backText,
    creditError,
    setCreditError,
    handleBack,
    onTokenizeInjectSuccess,
    viewerUser,
    paymentRequestInfo,
    holderName,
    setHolderName,
    holderNameValid,
    setHolderNameValid,
    isIFrameVisible,
    setIsIFrameVisible,
    isGuestUser,
    setSendingPayment,
    sendingPayment,
    submitText,
    cardInfoDisabled,
    merchantTenantId,
    customerId,
    onTokenize,
  } = props;
  const { apteanPay, sdkComponent, configure, mountComponent } = useApteanPay();
  const integrationSettings = useSelector(selectTenantIntegrationSettings);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (merchantTenantId) {
      configure(merchantTenantId, customerId, !!isGuestUser, 'card');
    }
  }, [merchantTenantId, customerId]);

  useEffect(() => {
    if (apteanPay && sdkComponent) {
      setShowLoading(true);
      setIsIFrameVisible(true);
      mountComponent('js_sdk_card', '#js_sdk_button', () => {
        setShowLoading(false);
      });
      if (onTokenizeInjectSuccess) {
        onTokenizeInjectSuccess(apteanPay, sdkComponent);
      }
    }
  }, [sdkComponent]);

  // allow making the cc payment if the user is logged with billing information or is doing a guest checkout, in which case the billing information is required to proceed
  const enablePayment = !!viewerUser?.payerProfile || !viewerUser;

  const onCreditSubmit = () => {
    setHolderNameValid(checkIsFullNameValid(holderName));
    // Validate PTT card fields.
    if (integrationSettings?.payFacType === 'PTT') {
      setCreditError(undefined);
      const isCardNumberValid = !!document.querySelector('#CollectJSInlineccnumber.CollectJSValid');
      const isCardExpValid = !!document.querySelector('#CollectJSInlineccexp.CollectJSValid');
      const isCardCvvValid = !!document.querySelector('#CollectJSInlinecvv.CollectJSValid');
      if (!isCardNumberValid || !isCardExpValid || !isCardCvvValid) {
        setCreditError('Please ensure card information is valid.');
        return;
      }
    }
    if (onTokenize && apteanPay && sdkComponent && checkIsFullNameValid(holderName)) {
      if (setSendingPayment) setSendingPayment(true);
      onTokenize(apteanPay, sdkComponent);
    }
  };
  const onHolderNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value as string;
    setHolderName(name);
    setHolderNameValid(checkIsFullNameValid(name));
  };

  return (
    <Box>
      <LoadingMask loading={showLoading} />
      <Grid container justifyContent="space-between" className={classes.creditCardInformationWrap}>
        <Grid item className={classes.creditCardInfoText}>
          <Typography id="credit-card-information">Credit Card Information</Typography>
        </Grid>
        <Grid item className={`${classes.rightAlign} ${classes.creditCardImage}`}>
          <img src={CreditCards} alt="credit_cards"></img>
        </Grid>
      </Grid>
      {paymentRequestInfo &&
        paymentRequestInfo?.acceptedPaymentMethods &&
        (paymentRequestInfo.acceptedPaymentMethods.length < 7 ||
          (paymentRequestInfo.acceptedPaymentMethods.length === 7 &&
            paymentRequestInfo.acceptedPaymentMethods.indexOf(AcceptedPaymentMethod.Unknown) !== -1)) && (
          <Grid className={classes.bannerPadding}>
            <Typography>{`Some payment methods are not supported in this region. Please use a supported payment method (${paymentRequestInfo.acceptedPaymentMethods
              .map(method => {
                return AllAcceptedPaymentMethodForDisplay.find(data => data.key === method)?.value;
              })
              .join(', ')}).`}</Typography>
          </Grid>
        )}
      {isIFrameVisible && (
        <Grid item xs={12} className={classes.holderName}>
          <TextField
            variant={'outlined'}
            fullWidth
            autoFocus
            error={!holderNameValid}
            InputLabelProps={{ shrink: false }}
            value={holderName}
            onChange={onHolderNameChange}
            disabled={cardInfoDisabled}
            placeholder="Card Holder Name"
            inputProps={{
              maxLength: 26,
              'aria-label': 'Input field for the name of the card holder',
              'aria-placeholder': 'Card Holder Name',
            }}
            className={holderNameValid ? undefined : classes.holderNameFieldError}
            data-cy="holder-name"
          />
        </Grid>
      )}
      <Box className={isGuestUser ? classes.iFramePaddingGuest : classes.iFramePadding}>
        {isGuestUser === true && <LoadingMask loading={!isIFrameVisible} />}
        <Box id="js_sdk_card" className={classes.cciFrame} />
      </Box>
      {(onTokenize || handleBack) && (
        <Grid container justifyContent="space-between" className={classes.padding}>
          {handleBack && (
            <Button
              className={classes.paymentAction}
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                handleBack();
              }}
              data-cy="back-to-billing"
            >
              {backText || 'Back'}
            </Button>
          )}
          {onTokenize && (
            <Button
              className={classes.paymentAction}
              variant="contained"
              color="primary"
              onClick={() => {
                onCreditSubmit();
              }}
              data-cy="make-payment"
              disabled={!enablePayment || sendingPayment}
              id="js_sdk_button"
            >
              {submitText || 'Make Payment'}
            </Button>
          )}
        </Grid>
      )}
      {creditError && (
        <Grid container justifyContent="space-between" className={classes.padding}>
          <Typography color="error" variant="subtitle2">
            {creditError}
          </Typography>
        </Grid>
      )}
    </Box>
  );
};

export default CardInfo;
