import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { MerchantInfo, Person } from '../gql-types.generated';
import CreditMemoCard from './CreditMemoCard';
import LoadingMask from './LoadingMask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootSm: {
      textAlign: 'start',
      width: 372,
    },
    rootXs: {
      textAlign: 'start',
      width: '81vw',
    },
    userInfo: {
      padding: theme.spacing(3, 1.5, 3, 3),
    },
    darkUserInfo: {
      padding: theme.spacing(3),
      color: '#ffffff',
      backgroundColor: '#000000',
    },
    userInfoContainer: {
      width: '100%',
    },
    signOutButton: {
      textDecoration: 'none',
      minWidth: 40,
      height: (props: { isMobile: boolean }) => (props.isMobile ? 35 : 40),
      color: (props: { isMobile: boolean }) => theme.palette.getContrastText(props.isMobile ? theme.palette.text.primary : '#ffffff'),
    },
    signOutButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    wallet: {
      minWidth: '100%',
    },
    drawerPaper: {
      paddingTop: document.getElementById('primary-app-bar')?.clientHeight || 0,
      overflowY: 'hidden',
    },
    drawerPaperSm: {
      overflowY: 'hidden',
    },
    closeIcon: {
      color: '#ffffff',
      position: 'fixed',
      marginLeft: '82vw',
      marginTop: theme.spacing(3),
    },
    closeIconSm: {
      color: '#ffffff',
      position: 'fixed',
      marginLeft: '392px',
      marginTop: theme.spacing(3),
    },
    iconSize: {
      fontSize: '1.8em',
    },
    paymentMethodsMobile: {
      maxHeight: 'calc(100vh - 107px)',
    },
    userInfoText: {
      wordBreak: 'break-word',
    },
    creditMemoCard: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      backgroundColor: '#FFFFFF',
    },
  }),
);

interface SettingsMenuProps {
  viewerUser?: Person;
  paymentMethodsListView: JSX.Element;
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  creditBalance: number;
  creditBalanceError?: Error;
  creditMemoCardClick: () => void;
  loadingCreditBalance: boolean;
  merchantInfo?: MerchantInfo;
  deletingPaymentMethod: boolean;
}

const SettingsMenu: React.FC<SettingsMenuProps> = props => {
  const {
    paymentMethodsListView,
    isMenuOpen,
    setIsMenuOpen,
    creditBalance,
    creditMemoCardClick,
    loadingCreditBalance,
    merchantInfo,
    creditBalanceError,
    deletingPaymentMethod,
  } = props;
  const matchesSmDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const matchesXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isMobile: matchesSmDown });

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <Drawer
        open={isMenuOpen}
        variant={matchesSmDown ? 'temporary' : 'persistent'}
        anchor="left"
        classes={{
          paper: matchesSmDown ? classes.drawerPaperSm : classes.drawerPaper,
        }}
        style={{ zIndex: matchesSmDown ? 1 : 0 }}
        data-cy="settings-menu"
        aria-label="settings menu"
      >
        <Grid container className={matchesXsDown ? classes.rootXs : classes.rootSm}>
          {matchesSmDown && isMenuOpen && (
            <IconButton
              className={matchesXsDown ? classes.closeIcon : classes.closeIconSm}
              onClick={handleMenuClose}
              data-cy="settings-menu-close"
              aria-label="close settings menu"
              size="large"
            >
              <CloseIcon className={classes.iconSize} />
            </IconButton>
          )}
          <Grid item container className={matchesSmDown ? classes.paymentMethodsMobile : undefined} aria-label="wallet">
            <LoadingMask loading={deletingPaymentMethod} />
            {paymentMethodsListView}
          </Grid>
          <Grid item container className={classes.creditMemoCard} id="merchant-credit-memo-card" aria-label="credit memos">
            <CreditMemoCard
              merchantInfo={merchantInfo}
              creditBalance={creditBalance}
              cardClick={creditMemoCardClick}
              isLoadingBalance={loadingCreditBalance}
              creditBalanceError={creditBalanceError}
            />
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default SettingsMenu;
