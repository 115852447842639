import { OrderDirection } from '../gql-types.generated';
import { DateTime } from 'luxon';
import { PaymentsDueRowData } from '../custom-types/PaymentsDueRowData';

export const sortPaymentsDueByStatus = (
  paymentsDueData: PaymentsDueRowData[] | undefined,
  order: OrderDirection,
): PaymentsDueRowData[] | undefined => {
  if (!paymentsDueData) return paymentsDueData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;

  const sortedPaymentsDueData = paymentsDueData?.slice().sort((a: PaymentsDueRowData, b: PaymentsDueRowData) => {
    const aStatus = a.paymentDue.paymentRequest?.status;
    const bStatus = b.paymentDue.paymentRequest?.status;

    if (aStatus && bStatus) {
      return aStatus > bStatus ? sortResOne : sortResTwo;
    }
    return 0;
  });

  return sortedPaymentsDueData;
};

export const sortPaymentsDueByTotalDueAmount = (
  paymentsDueData: PaymentsDueRowData[] | undefined,
  order: OrderDirection,
): PaymentsDueRowData[] | undefined => {
  if (!paymentsDueData) return paymentsDueData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;

  const sortedPaymentsDueData = paymentsDueData?.slice().sort((a: PaymentsDueRowData, b: PaymentsDueRowData) => {
    const aAmount = a.paymentDue.paymentRequest?.totalDue;
    const bAmount = b.paymentDue.paymentRequest?.totalDue;

    if (typeof aAmount === 'number' && typeof bAmount === 'number') {
      return aAmount > bAmount ? sortResOne : sortResTwo;
    }
    return 0;
  });

  return sortedPaymentsDueData;
};

export const sortPaymentsDueByRecievedDate = (
  paymentsDueData: PaymentsDueRowData[] | undefined,
  order: OrderDirection,
): PaymentsDueRowData[] | undefined => {
  if (!paymentsDueData) return paymentsDueData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;

  const sortedPaymentsDueData = paymentsDueData?.slice().sort((a: PaymentsDueRowData, b: PaymentsDueRowData) => {
    const aDate = DateTime.fromISO(a.paymentDue.paymentRequest?.createdAt || new Date(0).toISOString());
    const bDate = DateTime.fromISO(b.paymentDue.paymentRequest?.createdAt || new Date(0).toISOString());

    if (aDate !== bDate) {
      return aDate > bDate ? sortResOne : sortResTwo;
    }
    return 0;
  });

  return sortedPaymentsDueData;
};

export const sortPaymentsDueByReference = (
  paymentsDueData: PaymentsDueRowData[] | undefined,
  order: OrderDirection,
): PaymentsDueRowData[] | undefined => {
  if (!paymentsDueData) return paymentsDueData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;

  const sortedPaymentsDueData = paymentsDueData?.slice().sort((a: PaymentsDueRowData, b: PaymentsDueRowData) => {
    const aReferenceNumber = a.paymentDue.paymentRequest?.referenceNumber;
    const bReferenceNumber = b.paymentDue.paymentRequest?.referenceNumber;

    if (aReferenceNumber && bReferenceNumber) {
      return aReferenceNumber > bReferenceNumber ? sortResOne : sortResTwo;
    }
    return 0;
  });

  return sortedPaymentsDueData;
};

export const sortPaymentsDueByTotalAmount = (
  paymentsDueData: PaymentsDueRowData[] | undefined,
  order: OrderDirection,
): PaymentsDueRowData[] | undefined => {
  if (!paymentsDueData) return paymentsDueData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;

  const sortedPaymentsDueData = paymentsDueData?.slice().sort((a: PaymentsDueRowData, b: PaymentsDueRowData) => {
    const aAmount = a.paymentDue.paymentRequest?.amount;
    const bAmount = b.paymentDue.paymentRequest?.amount;

    if (typeof aAmount === 'number' && typeof bAmount === 'number') {
      return aAmount > bAmount ? sortResOne : sortResTwo;
    }
    return 0;
  });

  return sortedPaymentsDueData;
};

export const sortPaymentsDueByTotalPaidAmount = (
  paymentsDueData: PaymentsDueRowData[] | undefined,
  order: OrderDirection,
): PaymentsDueRowData[] | undefined => {
  if (!paymentsDueData) return paymentsDueData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;

  const sortedPaymentsDueData = paymentsDueData?.slice().sort((a: PaymentsDueRowData, b: PaymentsDueRowData) => {
    const aAmount = (a.paymentDue.paymentRequest?.totalPaid || 0) - a.creditAmount;
    const bAmount = (b.paymentDue.paymentRequest?.totalPaid || 0) - b.creditAmount;

    if (typeof aAmount === 'number' && typeof bAmount === 'number') {
      return aAmount > bAmount ? sortResOne : sortResTwo;
    }
    return 0;
  });

  return sortedPaymentsDueData;
};

export const sortPaymentsDueByDueDate = (
  paymentsDueData: PaymentsDueRowData[] | undefined,
  order: OrderDirection,
): PaymentsDueRowData[] | undefined => {
  if (!paymentsDueData) return paymentsDueData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;

  const sortedPaymentsDueData = paymentsDueData?.slice().sort((a: PaymentsDueRowData, b: PaymentsDueRowData) => {
    const aDate = DateTime.fromISO(a.paymentDue.paymentRequest?.dueDate || new Date(0).toISOString());
    const bDate = DateTime.fromISO(b.paymentDue.paymentRequest?.dueDate || new Date(0).toISOString());

    if (aDate !== bDate) {
      return aDate > bDate ? sortResOne : sortResTwo;
    }
    return 0;
  });

  return sortedPaymentsDueData;
};

export const sortPaymentsDueByDiscountEndDate = (
  paymentsDueData: PaymentsDueRowData[] | undefined,
  order: OrderDirection,
): PaymentsDueRowData[] | undefined => {
  if (!paymentsDueData) return paymentsDueData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;

  const sortedPaymentsDueData = paymentsDueData?.slice().sort((a: PaymentsDueRowData, b: PaymentsDueRowData) => {
    const aDate = DateTime.fromISO(a.paymentDue.paymentRequest?.discountEndDate || new Date(0).toISOString());
    const bDate = DateTime.fromISO(b.paymentDue.paymentRequest?.discountEndDate || new Date(0).toISOString());

    if (aDate !== bDate) {
      return aDate > bDate ? sortResOne : sortResTwo;
    }
    return 0;
  });

  return sortedPaymentsDueData;
};

export const sortPaymentsDueByCreditAmount = (
  paymentsDueData: PaymentsDueRowData[] | undefined,
  order: OrderDirection,
): PaymentsDueRowData[] | undefined => {
  if (!paymentsDueData) return paymentsDueData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;

  const sortedPaymentsDueData = paymentsDueData?.slice().sort((a: PaymentsDueRowData, b: PaymentsDueRowData) => {
    const aAmount = a.creditAmount;
    const bAmount = b.creditAmount;

    return aAmount > bAmount ? sortResOne : sortResTwo;
  });

  return sortedPaymentsDueData;
};
