import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PayerTransactionConnection, PayerTransactionOrder, PaymentRequestStatus } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_TRANSACTION_CONNECTION = gql`
  query payerTransactions(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: PayerTransactionOrder!
    $status: [PaymentRequestStatus!]
  ) {
    payerTransactions(after: $after, before: $before, first: $first, last: $last, orderBy: $orderBy, status: $status) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        currency
        amount
        tenantId
        merchantName
        merchantCountry
        paymentDate
        paymentId
        paymentRequestCreatedTimestamp
        paymentRequestId
        personId
        invoiceReferenceNumber
        status
        paymentUrlToken
        paymentRequest {
          id
          additionalInfo
          amount
          referenceNumber
          invoiceLink
          invoiceLinks
          status
          dueDate
          amount
          createdAt
          totalDue
          totalPaid
          discountAmount
          discountEndDate
          customerPONumber
          invoiceNumber
          orderNumber
          owner {
            tenantId
          }
          payments {
            id
            status
            attemptTimestamp
            immediateCapture
            createdAt
            currency
            authorizationCode
            amount
            amountBeforeFees
            convenienceFee
            amountDisputed
            creditAmount
            paymentMethod {
              type
              creditCard {
                cardBrand
                lastFour
              }
              paymentBank {
                lastFour
              }
            }
          }
        }
        payment {
          authorizationCode
          status
          pendingReason
          cancelReason
          failureReason
          amount
          amountDisputed
          amountRefunded
          attemptTimestamp
          completedTimestamp
          creditAmount
          currency
        }
      }
      edges {
        cursor
        node {
          paymentId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_TRANSACTION_CONNECTION_WITH_TOKEN = gql`
  query payerTransactionsWithToken(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: PayerTransactionOrder!
    $status: [PaymentRequestStatus!]
  ) {
    payerTransactions(after: $after, before: $before, first: $first, last: $last, orderBy: $orderBy, status: $status) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        currency
        amount
        tenantId
        merchantName
        merchantCountry
        paymentDate
        paymentId
        paymentRequestCreatedTimestamp
        paymentRequestId
        personId
        invoiceReferenceNumber
        status
        paymentUrlToken
        paymentRequest {
          id
          additionalInfo
          amount
          referenceNumber
          invoiceLink
          invoiceLinks
          status
          discountAmount
          discountEndDate
          dueDate
          amount
          createdAt
          totalDue
          totalPaid
          customerPONumber
          invoiceNumber
          orderNumber
          owner {
            tenantId
          }
          payments {
            id
            status
            attemptTimestamp
            createdAt
            currency
            authorizationCode
            amount
            amountBeforeFees
            convenienceFee
            creditAmount
            amountDisputed
            paymentMethod {
              creditCard {
                cardBrand
                lastFour
              }
              paymentBank {
                lastFour
              }
            }
          }
        }
        payment {
          authorizationCode
          status
          pendingReason
          cancelReason
          failureReason
          amount
          currency
          amountDisputed
          amountRefunded
          completedTimestamp
        }
      }
      edges {
        cursor
        node {
          paymentId
        }
      }
    }
  }
`;

export const queryTransactionConnection = async (
  client: ApolloClient<NormalizedCacheObject>,
  after: string | undefined,
  before: string | undefined,
  first: number | undefined,
  last: number | undefined,
  orderBy: PayerTransactionOrder,
  status: PaymentRequestStatus[],
  includeToken: boolean,
  tenantId: string,
): Promise<PayerTransactionConnection> => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.query<{ payerTransactions: PayerTransactionConnection }>({
    variables: { after, before, first, last, orderBy, status },
    query: includeToken ? QUERY_TRANSACTION_CONNECTION_WITH_TOKEN : QUERY_TRANSACTION_CONNECTION,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  return data.payerTransactions;
};
