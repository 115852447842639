import { Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerError: {
      backgroundColor: '#ffefee',
      color: '#ff574d',
      padding: theme.spacing(2),
    },
    upperHeaderText: {
      fontSize: 10,
      letterSpacing: 1.5,
      fontWeight: 500,
    },
    mainHeaderText: {
      fontWeight: 500,
      fontSize: 18,
    },
  }),
);

interface PaymentErrorProps {
  errorMessage: string;
  isPaymentMethod: boolean;
}

const PaymentError: React.FC<PaymentErrorProps> = props => {
  const classes = useStyles();
  const { errorMessage, isPaymentMethod } = props;
  return (
    <Grid container className={classes.headerError}>
      <Grid item xs={12} lg={12}>
        <Typography variant="body2" className={classes.upperHeaderText}>
          {`PAYMENT${isPaymentMethod ? ' METHOD ' : ' '}UNSUCCESSFUL`}
        </Typography>
        <Typography className={classes.mainHeaderText}>{errorMessage}</Typography>
      </Grid>
    </Grid>
  );
};

export default PaymentError;
