import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/GetApp';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, Grid, IconButton, Link, Popover, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogButtons: {
      padding: theme.spacing(1, 0),
      marginBottom: theme.spacing(1),
    },
    containerGrid: {
      minWidth: 300,
      maxWidth: 300,
      overflowY: 'hidden',
    },
    link: {
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    linkContainer: {
      maxHeight: '175px',
      overflowY: 'auto',
      overflowX: 'hidden',
      padding: theme.spacing(1),
    },
    childGridContainer: {
      padding: theme.spacing(1),
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 500,
    },
    buttonText: {
      textTransform: 'none',
      width: '100%',
    },
    downArrow: {
      height: '22.75px',
      width: '24px',
    },
    launchIcon: {
      fontSize: '1rem',
      verticalAlign: 'text-bottom',
    },
    guestUserLink: {
      fontSize: 14,
      fontWeight: 400,
      cursor: 'pointer',
    },
    guestInvoiceLinkWrap: {
      width: '100%',
      textAlign: 'right',
      display: 'inline-block',
    },
    invoiceLink: {
      cursor: 'pointer',
    },
  }),
);

interface DownloadInvoicesProps {
  isIconButton?: boolean;
  isLink?: boolean;
  isGuestUser?: boolean;
  invoiceUrl?: string | null;
  invoicesUrlList?: string[] | null;
  originTop?: boolean;
  stringWhenEmpty?: boolean;
  isMobileView?: boolean;
}

const DownloadInvoices: React.FC<DownloadInvoicesProps> = props => {
  const { isIconButton, isLink, isGuestUser, invoiceUrl, invoicesUrlList, originTop, stringWhenEmpty, isMobileView } = props;
  // in case no invoice is present
  if (!invoiceUrl && (!invoicesUrlList || invoicesUrlList?.length === 0)) {
    return stringWhenEmpty ? <p>{'-- --'}</p> : null;
  }

  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | HTMLAnchorElement | null>(null);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  // for cases when there is only one invoice
  let singleUrl = invoiceUrl;

  if (invoicesUrlList?.length === 1) {
    [singleUrl] = invoicesUrlList;
  }

  if (singleUrl) {
    if (isLink) {
      return isGuestUser ? (
        <span className={classes.guestInvoiceLinkWrap}>
          <Link href={singleUrl} underline="hover" className={classes.guestUserLink} data-cy="download-invoice">
            Download Invoice
            <LaunchIcon className={classes.launchIcon} />
          </Link>
        </span>
      ) : (
        <Link href={singleUrl} underline="hover" className={classes.invoiceLink} data-cy="download-invoice">
          {isMobileView ? 'Download Invoice' : 'Download'}
        </Link>
      );
    }

    return isIconButton ? (
      <IconButton color="primary" href={singleUrl} aria-label="download invoice" data-cy="download-invoice" size="large">
        <DownloadIcon />
      </IconButton>
    ) : (
      <Button size="small" variant="outlined" color="primary" fullWidth href={singleUrl} data-cy="download-invoice">
        DOWNLOAD INVOICE
      </Button>
    );
  }

  let downloadOption = isIconButton ? (
    <IconButton
      color="primary"
      aria-label="invoices"
      onClick={handleMenuOpen}
      aria-describedby={isMenuOpen ? 'invoice-heading' : undefined}
      data-cy="invoices"
      size="large"
    >
      <DownloadIcon />
    </IconButton>
  ) : (
    <Button
      size="small"
      variant="outlined"
      color="primary"
      fullWidth
      onClick={handleMenuOpen}
      endIcon={<ArrowDropDownIcon />}
      aria-describedby={isMenuOpen ? 'invoice-heading' : undefined}
      data-cy="invoices"
    >
      INVOICES
    </Button>
  );

  if (isLink) {
    downloadOption = isGuestUser ? (
      <span className={classes.guestInvoiceLinkWrap}>
        <Link
          onClick={handleMenuOpen}
          className={classes.guestUserLink}
          aria-describedby={isMenuOpen ? 'invoice-heading' : undefined}
          data-cy="invoices"
          underline="hover"
        >
          Invoices
          <LaunchIcon className={classes.launchIcon} />
        </Link>
      </span>
    ) : (
      <Link
        onClick={handleMenuOpen}
        className={classes.invoiceLink}
        aria-describedby={isMenuOpen ? 'invoice-heading' : undefined}
        data-cy="invoices"
        underline="hover"
      >
        Invoices
      </Link>
    );
  }

  return (
    <>
      {downloadOption}
      <Popover
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: originTop ? 'top' : 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: originTop ? 'bottom' : 'top', horizontal: 'right' }}
      >
        <Grid container className={classes.containerGrid}>
          <Grid item container xs={12} className={classes.childGridContainer}>
            <Grid item container xs={10} alignContent="center" id="invoice-heading">
              <Typography className={classes.headerText}>Download Invoices</Typography>
            </Grid>
            <Grid item container xs={2} justifyContent="flex-end">
              <IconButton size="small" onClick={handleMenuClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.linkContainer}>
            {invoicesUrlList?.map((url, index) => {
              const fileNameWithUUID = url
                .slice(url.lastIndexOf('/') + 1)
                .replace('.pdf', '')
                .trim();
              const fileNameWithoutUUID = fileNameWithUUID.slice(0, -37).trim();
              const fileName = fileNameWithoutUUID || fileNameWithUUID || 'invoice';

              return (
                <Button variant="text" size="small" color="primary" key={index} href={url} className={classes.buttonText}>
                  <Grid item container xs={12}>
                    <Grid item container xs={10} alignContent="center">
                      <Typography className={classes.link}>{fileName}</Typography>
                    </Grid>
                    <Grid item container xs={2} justifyContent="flex-end">
                      <DownloadIcon />
                    </Grid>
                  </Grid>
                </Button>
              );
            })}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default DownloadInvoices;
