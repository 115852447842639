import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Theme, Tooltip, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Payment, PaymentStatus } from '../gql-types.generated';
import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { paymentStatusDisplay } from '../util/PaymentStatus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentSection: {
      padding: theme.spacing(0.5, 1.5, 1, 2),
    },
    closeContainer: {
      textAlign: 'end',
    },
    closeButton: {
      color: theme.palette.grey[800],
    },
    tooltip: {
      cursor: 'pointer',
      marginLeft: '0.3rem',
    },
    titleText: {
      padding: 0,
      margin: 0,
    },
    paymentIdLink: {
      fontWeight: 'bold',
      margin: 0,
    },
    multiLineValue: {
      wordBreak: 'break-word',
      wordWrap: 'break-word',
    },
    paymentIdTooltip: {
      cursor: 'pointer',
      paddingBottom: 0,
    },
    paymentIdContent: {
      fontWeight: 'bold',
      margin: 0,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    copyIcon: {
      fontSize: '1rem',
      marginLeft: 15,
    },
    noPadding: {
      padding: 0,
    },
    dialogTitlePadding: {
      padding: 12,
    },
  }),
);

interface SpecificPaymentsDetailsProps {
  paymentRecord: Payment;
  open: boolean;
  close: () => void;
  isCreditMemoEnabled: boolean;
}

const SpecificPaymentsDetails: React.FC<SpecificPaymentsDetailsProps> = props => {
  const { paymentRecord, open, close, isCreditMemoEnabled } = props;
  const classes = useStyles();

  const [paymentidCopied, setPaymentidCopied] = useState(false);

  const getPaymentMethod = () => {
    if (paymentRecord?.paymentMethod?.creditCard) {
      return `${paymentRecord?.paymentMethod.creditCard.cardBrand?.substring(0, 1)}${paymentRecord?.paymentMethod.creditCard.cardBrand
        ?.substring(1)
        .toLowerCase()} ${paymentRecord?.paymentMethod.creditCard.lastFour}`;
    }
    if (paymentRecord?.paymentMethod?.paymentBank) {
      return `Bank ${paymentRecord?.paymentMethod.paymentBank.lastFour}`;
    }
    return 'None';
  };

  const getPaidBy = () => {
    return (
      paymentRecord?.paymentMethod?.creditCard?.cardHolder?.email ||
      paymentRecord?.paymentMethod?.paymentBank?.accountHolder?.email ||
      ''
    );
  };

  const getFields = (label: string, value?: string | null) => {
    if (!value) return null;
    return (
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="bodyMediumEmphasis">{label}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" className={classes.multiLineValue}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog aria-label="payment details dialog" aria-labelledby="modal-title" open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle classes={{ root: classes.dialogTitlePadding }}>
        <Grid container>
          <Grid item xs={10}>
            <Typography variant="subtitle2" className={classes.titleText} id="modal-title">
              PAYMENT DETAILS
            </Typography>
            <Typography className={classes.titleText}>
              <Tooltip
                className={classes.paymentIdTooltip}
                title={`${paymentidCopied ? 'Copied' : 'Copy'} to clipboard`}
                placement="bottom-start"
              >
                <Link
                  underline={'hover'}
                  className={classes.paymentIdContent}
                  color="primary"
                  onClick={() => {
                    setPaymentidCopied(true);
                    navigator.clipboard.writeText(paymentRecord?.id || '');
                  }}
                  onMouseEnter={() => {
                    if (paymentidCopied) setPaymentidCopied(false);
                  }}
                  onFocus={() => {
                    if (paymentidCopied) setPaymentidCopied(false);
                  }}
                  href="#"
                  data-cy="payment-id-copy"
                >
                  {paymentRecord?.id}
                  <CopyIcon className={classes.copyIcon} />
                </Link>
              </Tooltip>
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.closeContainer}>
            <IconButton
              aria-label="close"
              title="close"
              className={classes.closeButton}
              onClick={close}
              data-cy="specific-payment-details-close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.noPadding}>
        <Box className={classes.noPadding} data-cy="specific-payment-details-modal">
          <Grid container spacing={1} className={classes.contentSection}>
            {getFields('Description', paymentRecord.description)}
            {getFields('Order#', paymentRecord.orderNumber)}
            {getFields('Customer PO#', paymentRecord.customerPONumber)}
            {getFields('Invoice#', paymentRecord.invoiceNumber)}
            {getFields(
              getPaymentMethod(),
              new Intl.NumberFormat('en', {
                style: 'currency',
                currency: paymentRecord.currency || 'USD',
                currencyDisplay: 'symbol',
              }).format((paymentRecord.amountBeforeFees || paymentRecord.amount || 0) / 100),
            )}
            {getFields(
              'Convenience Fee',
              paymentRecord.convenienceFee
                ? new Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: paymentRecord.currency || 'USD',
                    currencyDisplay: 'symbol',
                  }).format((paymentRecord.convenienceFee || 0) / 100)
                : null,
            )}
            {getFields(
              'Credit Applied',
              isCreditMemoEnabled
                ? new Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: paymentRecord.currency || 'USD',
                    currencyDisplay: 'symbol',
                  }).format((paymentRecord.creditAmount || 0) / 100)
                : null,
            )}
            {getFields(
              'Total Payment',
              isCreditMemoEnabled || paymentRecord.convenienceFee
                ? new Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: paymentRecord.currency || 'USD',
                    currencyDisplay: 'symbol',
                  }).format(
                    ((paymentRecord.amountBeforeFees || paymentRecord?.amount || 0) +
                      (paymentRecord?.convenienceFee || 0) +
                      (paymentRecord?.creditAmount || 0)) /
                      100,
                  )
                : null,
            )}
            {getFields(
              'Cancelled Reason',
              paymentRecord?.status === PaymentStatus.Canceled && paymentRecord.cancelReason ? paymentRecord.cancelReason : null,
            )}
            {getFields(
              'Failure Reason',
              paymentRecord?.status === PaymentStatus.Failed && paymentRecord.failureReason ? paymentRecord.failureReason : null,
            )}
            {getFields(
              'Pending Reason',
              paymentRecord?.status === PaymentStatus.Pending && paymentRecord.pendingReason ? paymentRecord.pendingReason : null,
            )}
            {getFields('Paid By', getPaidBy())}
            {getFields(
              'Status',
              paymentRecord.amountDisputed
                ? 'Disputed'
                : paymentStatusDisplay.find(item => {
                    return item.name === paymentRecord.status;
                  })?.display,
            )}
            {getFields(
              'Created On',
              paymentRecord?.createdAt || paymentRecord?.completedTimestamp
                ? new Date((paymentRecord.createdAt || paymentRecord.completedTimestamp) as string).toLocaleDateString()
                : null,
            )}
            {getFields('Authorization code', paymentRecord.authorizationCode)}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SpecificPaymentsDetails;
