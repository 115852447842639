import { CardBrand } from '../gql-types.generated';

type CardBrandForDisplayEnum = {
  key: CardBrand;
  value: string;
};
export const CardBrandForDisplay: CardBrandForDisplayEnum[] = [
  { key: CardBrand.Amex, value: 'American Express' },
  { key: CardBrand.Diners, value: 'Diners' },
  { key: CardBrand.Discover, value: 'Discover' },
  { key: CardBrand.Jcb, value: 'JCB' },
  { key: CardBrand.Mastercard, value: 'Mastercard' },
  { key: CardBrand.Unknown, value: 'Unknown' },
  { key: CardBrand.Visa, value: 'Visa' },
];
