import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PaymentsDueRowData } from '../custom-types/PaymentsDueRowData';
import {
  CreatePaymentStatus,
  CurrencyType,
  PaymentRequestAllocationInput,
  PaymentRequestInfo,
  RiskMetadataPaymentInput,
} from '../gql-types.generated';
import {
  getCreatePaymentHeaders,
  getCreatePaymentHeadersWithAuth,
  getCreateConsolidatedOrPartialPaymentHeadersWithAuth,
} from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_CREATE_PAYMENT = gql`
  mutation createPayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      code
      message
      error
      payment {
        amount
        amountDisputed
        amountRefunded
        attemptTimestamp
        cancelReason
        completedTimestamp
        currency
        failureReason
        feeAmount
        id
        initiatedBy
        pendingReason
        pendingReasonCode
        status
      }
    }
  }
`;

export const createPayment = async (
  client: ApolloClient<NormalizedCacheObject>,
  paymentMethodId: string,
  paymentRequestId: string,
  amount: number,
  currency: CurrencyType,
  immediateCapture: boolean,
  riskMetadata: RiskMetadataPaymentInput,
  paymentRequestInfo: PaymentRequestInfo,
  isGuestUser: boolean,
  failOnReview: boolean,
  creditAmount: number,
  customerId: string | undefined,
  amountBeforeFees: number | undefined,
  convenienceFee: number | undefined,
): Promise<CreatePaymentStatus | null> => {
  const headers = isGuestUser
    ? await getCreatePaymentHeaders(paymentRequestInfo)
    : await getCreatePaymentHeadersWithAuth(paymentRequestInfo);
  const { data, errors } = await client.mutate<{ createPayment: CreatePaymentStatus }>({
    variables: {
      input: {
        paymentMethodId,
        paymentRequestId,
        amount,
        currency,
        immediateCapture,
        riskMetadata,
        failOnReview,
        creditAmount,
        customerId,
        amountBeforeFees,
        convenienceFee,
      },
    },
    mutation: MUTATION_CREATE_PAYMENT,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      console.error(error);
    });
    throw new Error('An error has occurred during payment creation.');
  }

  if (data && data.createPayment) {
    return data.createPayment;
  }

  return null;
};

export const createConsolidatedOrPartialPayment = async (
  client: ApolloClient<NormalizedCacheObject>,
  amount: number,
  paymentMethodId: string,
  tenantId: string,
  currency: CurrencyType,
  immediateCapture: boolean,
  riskMetadata: RiskMetadataPaymentInput,
  failOnReview: boolean,
  paymentRequestAllocation: PaymentRequestAllocationInput[],
  paymentRequestInfoList: PaymentsDueRowData[],
  creditAmount: number,
  customerId: string | undefined,
  amountBeforeFees: number | undefined,
  convenienceFee: number | undefined,
): Promise<CreatePaymentStatus | null> => {
  const headers = await getCreateConsolidatedOrPartialPaymentHeadersWithAuth(tenantId, paymentRequestInfoList);
  const { data, errors } = await client.mutate<{ createPayment: CreatePaymentStatus }>({
    variables: {
      input: {
        amount,
        paymentMethodId,
        currency,
        immediateCapture,
        riskMetadata,
        failOnReview,
        paymentRequestAllocation,
        creditAmount,
        customerId,
        amountBeforeFees,
        convenienceFee,
      },
    },
    mutation: MUTATION_CREATE_PAYMENT,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      console.error(error);
    });
    throw new Error('An error has occurred during payment creation.');
  }

  if (data && data.createPayment) {
    return data.createPayment;
  }

  return null;
};
