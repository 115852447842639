import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { CalculatedConvenienceFees, CardType } from '../gql-types.generated';
import { getTenantHeaders, getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_CALCULATE_CONVENIENCE_FEES = gql`
  query calculateConvenienceFees($amount: Int!, $cardType: CardType!, $customerId: String) {
    calculateConvenienceFees(amount: $amount, cardType: $cardType, customerId: $customerId) {
      amount
      rateBps
    }
  }
`;

export const queryCalculateConvenienceFees = async (
  client: ApolloClient<NormalizedCacheObject>,
  isGuestUser: boolean,
  amount: number,
  cardType: CardType,
  tenantId: string,
  customerId?: string,
): Promise<CalculatedConvenienceFees> => {
  const headers = isGuestUser ? getTenantHeaders(tenantId) : await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.query<{ calculateConvenienceFees: CalculatedConvenienceFees }>({
    variables: { amount, cardType, customerId },
    query: QUERY_CALCULATE_CONVENIENCE_FEES,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }
  return data.calculateConvenienceFees;
};
