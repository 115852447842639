import { Alert, Snackbar, Theme, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { PaymentMethodRequest } from '../gql-types.generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackBar: {
      minWidth: '100%',
      justifyContent: 'center',
    },
    snackBarMobile: {
      minWidth: '100%',
      justifyContent: 'center',
      left: 0,
      right: 0,
      bottom: 0,
    },
    toolbarButton: {
      marginRight: 4,
      [theme.breakpoints.down('xs')]: {
        minWidth: 40,
        minHeight: 48,
        marginRight: 0,
        padding: 0,
      },
    },
  }),
);

interface PaymentMethodRequestSnackBarProps {
  paymentMethodRequest?: PaymentMethodRequest | null;
  isPaymentMethodRequestCompleted: boolean;
  clearIsPaymentMethodRequestCompleted: () => void;
}

const PaymentMethodRequestSnackBar: React.FC<PaymentMethodRequestSnackBarProps> = props => {
  const { paymentMethodRequest, isPaymentMethodRequestCompleted, clearIsPaymentMethodRequestCompleted } = props;
  const classes = useStyles();
  const isMobileSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (isPaymentMethodRequestCompleted) {
      setTimeout(() => {
        clearIsPaymentMethodRequestCompleted();
      }, 5000);
    }
  }, [isPaymentMethodRequestCompleted]);

  return (
    <Snackbar
      className={isMobileSize ? classes.snackBarMobile : classes.snackBar}
      open={isPaymentMethodRequestCompleted && !paymentMethodRequest}
      autoHideDuration={5000}
      onClose={() => {
        clearIsPaymentMethodRequestCompleted();
      }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert className={classes.snackBar} severity={'success'}>
        {'Payment preferences updated'}
      </Alert>
    </Snackbar>
  );
};

export default PaymentMethodRequestSnackBar;
