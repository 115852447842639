import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Customer, CustomerInfo, CustomerUser, DeletePersonStatus, UpsertPersonStatus } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
  customerInfo?: CustomerInfo;
  customer?: Customer;
  customerUsers?: CustomerUser[];
  customerUserStatus?: UpsertPersonStatus;
  removedUserStatus?: DeletePersonStatus;
  isLoadingCustomer: boolean;
  upsertCustomerUserError?: Error;
  removeCustomerUserError?: Error;
  customerError?: Error;
  isUpsertCustomerUserInFlight: boolean;
  isRemoveCustomerUserInFlight: boolean;
}

const initialState: SliceState = {
  isLoadingCustomer: false,
  isUpsertCustomerUserInFlight: false,
  isRemoveCustomerUserInFlight: false,
};

export const slice = createSlice({
  name: 'manageUsers',
  initialState,
  reducers: {
    fetchCustomerInfo: (state, action: PayloadAction<CustomerInfo | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.customerInfo = action.payload;
    },
    fetchCustomerSuccess: (state, action: PayloadAction<Customer | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.customer = action.payload;
    },
    fetchCustomer: (state, action: PayloadAction<Customer | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.customer = action.payload;
    },
    fetchCustomerUsersSuccess: (state, action: PayloadAction<Customer | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.customerUsers = action.payload?.customerUsers || undefined;
    },
    fetchCustomerUserStatus: (state, action: PayloadAction<UpsertPersonStatus | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.customerUserStatus = action.payload;
    },
    fetchRemovedUserStatus: (state, action: PayloadAction<DeletePersonStatus | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.removedUserStatus = action.payload;
    },
    fetchUpsertCustomerUserError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.upsertCustomerUserError = action.payload;
    },
    fetchRemoveCustomerUserError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.removeCustomerUserError = action.payload;
    },
    fetchIsLoadingCustomer: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isLoadingCustomer = action.payload;
    },
    fetchCustomerError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.customerError = action.payload;
    },
    fetchIsUpsertCustomerUserInFlight: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isUpsertCustomerUserInFlight = action.payload;
    },
    fetchIsRemoveCustomerUserInFlight: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isRemoveCustomerUserInFlight = action.payload;
    },
  },
});

export const selectCustomerInfo = (state: RootState): CustomerInfo | undefined => state.manageUsers.customerInfo;
export const selectCustomer = (state: RootState): Customer | undefined => state.manageUsers.customer;
export const selectCustomerUsers = (state: RootState): CustomerUser[] | undefined => state.manageUsers.customerUsers;
export const selectCustomerUserStatus = (state: RootState): UpsertPersonStatus | undefined => state.manageUsers.customerUserStatus;
export const selectRemovedUserStatus = (state: RootState): DeletePersonStatus | undefined => state.manageUsers.removedUserStatus;
export const selectUpsertCustomerUserError = (state: RootState): Error | undefined => state.manageUsers.upsertCustomerUserError;
export const selectRemoveCustomerUserError = (state: RootState): Error | undefined => state.manageUsers.removeCustomerUserError;
export const selectIsLoadingCustomer = (state: RootState): boolean => state.manageUsers.isLoadingCustomer;
export const selectCustomerError = (state: RootState): Error | undefined => state.manageUsers.customerError;
export const selectIsUpsertCustomerUserInFlight = (state: RootState): boolean => state.manageUsers.isUpsertCustomerUserInFlight;
export const selectIsRemoveCustomerUserInFlight = (state: RootState): boolean => state.manageUsers.isRemoveCustomerUserInFlight;

export const {
  fetchCustomerInfo,
  fetchCustomerSuccess,
  fetchCustomerUserStatus,
  fetchCustomerUsersSuccess,
  fetchIsLoadingCustomer,
  fetchRemoveCustomerUserError,
  fetchRemovedUserStatus,
  fetchUpsertCustomerUserError,
  fetchCustomerError,
  fetchCustomer,
  fetchIsRemoveCustomerUserInFlight,
  fetchIsUpsertCustomerUserInFlight,
} = slice.actions;

export default slice.reducer;
