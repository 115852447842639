/* eslint-disable no-await-in-loop */
import { gqlClient } from '../../components/AppProvider';

import { AppDispatch } from '../../store';
import { queryTransactionConnection } from '../../gql/QueryTransactionConnection';
import { getPaymentRequestFromUrlToken } from '../../gql/QueryPaymentRequest';
import {
  fetchError,
  fetchPaymentError,
  fetchPaymentRequestInfoSuccess,
  fetchVerifyPaymentMethodStatus,
  decrementRequestsInFlight,
  incrementRequestsInFlight,
  fetchRecentTransactionsSuccess,
  fetchPaymentsDueSuccess,
  fetchDeletePayerPaymentMethodStatus,
  fetchCreatePayerPaymentMethodStatus,
  fetchUpdatePaymentMethodStatus,
  fetchCreatePaymentStatus,
  fetchDeletePaymentMethodError,
  fetchPaymentMethodError,
  fetchPaymentsDueError,
  fetchRecentPaymentsError,
  fetchPaymentMethodAdded,
  fetchPaymentsDueData,
  fetchOpenCreditMemoSuccess,
  fetchOpenCreditMemosError,
  fetchCreditMemoBalanceError,
  captureCreditMemoBalance,
  fetchLoadingCreditMemoBalance,
  clearCreditMemoBalance,
  fetchCreditMemoHistorySuccess,
  fetchCreditMemoHistoryError,
  fetchLoadingPaymentMethods,
  fetchDeletingPaymentMethod,
  fetchPayerTransactionSummaryError,
  fetchOneTimeUsePaymentMethod,
  captureCalculatedConvenienceFees,
  captureIsCalculatedConvenienceFeesInFlight,
  fetchGuestCheckoutPaymentMethodToken,
  captureIsSpecificPaymentDetailRecordInFlight,
  captureSpecificPaymentDetailRecord,
  fetchCompletePaymentMethodRequestError,
  fetchCompletePaymentMethodRequestStatus,
  fetchCompletePaymentMethodRequestInFlight,
  fetchTenantIntegrationSettings,
} from './MultipleMethodsHomeSlice';
import {
  PaymentRequestInfo,
  PayerTransactionConnection,
  PaymentRequestStatus,
  OrderDirection,
  PayerTransactionOrderField,
  PaymentMethodType,
  PaymentMethodHolderInput,
  PaymentMethodStatus,
  RiskMetadataPaymentInput,
  MutationStatusCode,
  CreatePaymentStatus,
  PaymentRequestAllocationInput,
  CurrencyType,
  CreditMemoStatus,
  CreditMemoOrderField,
  PayerCreditMemoConnection,
  CreditMemoUsageHistoryOrderField,
  CreditMemoUsageConnection,
  Person,
  PayerPaymentMethod,
  CardType,
  PaymentMethod,
  Token,
} from '../../gql-types.generated';
import { verifyPaymentMethod } from '../../gql/VerifyPaymentMethod';
import { deletePaymentMethod } from '../../gql/DeletePaymentMethod';
import { createPaymentMethod } from '../../gql/CreatePaymentMethod';
import { convertPayfacPaymentMethodToken } from '../../gql/ConvertPayfacPaymentMethodToken';
import { queryPaymentMethodByToken } from '../../gql/QueryPaymentMethodByToken';
import { updatePaymentMethod } from '../../gql/UpdatePaymentMethod';
import { createPayment, createConsolidatedOrPartialPayment } from '../../gql/CreatePayment';
import { queryTransactionSummaryByMerchant } from '../../gql/QueryPayerTransactionSummaryByMerchant';
import { PaymentsDueRowData } from '../../custom-types/PaymentsDueRowData';
import { DateTime } from 'luxon';
import { queryCreditMemoConnection } from '../../gql/QueryCreditMemoConnection';
import { queryCreditMemoUsageConnection } from '../../gql/QueryCreditMemoUsageConnection';
import { queryPersonByEmail } from '../../gql/QueryPersonByEmail';
import {
  fetchViewerUserByEmailSuccess,
  fetchPayerTransactionSummaryByMerchant,
  fetchIsPaymentMethodRequestCompleted,
} from '../app/AppSlice';
import { fetchViewerUserByEmail } from '../app/AppActions';
import { capturePayerCustomer } from '../manage-users/ManageUsersAction';
import { queryCalculateConvenienceFees } from '../../gql/QueryCalculateConvenienceFees';
import { CardTypeConvenienceFee } from '../../custom-types/CardTypeConvenienceFee';
import { queryPaymentConnection } from '../../gql/QueryPaymentConnection';
import { completePaymentMethodRequest } from '../../gql/CompletePaymentMethodRequestMutation';
import { ApteanPaySDKToken } from '../../util/ApteanPay';
import { queryTenantIntegrationSettings } from '../../gql/QueryTenantIntegrationSettings';

export const fetchRecentTransactions =
  (endCursor: string | undefined, pageSize: number | undefined, tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const after = endCursor || undefined;
      const before = undefined;
      const first = pageSize || 25;
      const last = 0;
      const orderBy = { direction: OrderDirection.Desc, field: PayerTransactionOrderField.AttemptTimestamp };
      const status = [
        PaymentRequestStatus.Completed,
        PaymentRequestStatus.Pending,
        PaymentRequestStatus.FullyRefunded,
        PaymentRequestStatus.PartiallyRefunded,
        PaymentRequestStatus.RefundFailed,
        PaymentRequestStatus.RefundPending,
        PaymentRequestStatus.Unknown,
        PaymentRequestStatus.Closed,
      ];
      dispatch(fetchRecentPaymentsError(undefined));

      const recentTransactions = await queryTransactionConnection(
        gqlClient,
        after,
        before,
        first,
        last,
        orderBy,
        status,
        false,
        tenantId,
      );
      if (recentTransactions) {
        dispatch(fetchRecentTransactionsSuccess(recentTransactions as PayerTransactionConnection));
      }
    } catch (e) {
      dispatch(fetchRecentPaymentsError(e));
      dispatch(fetchError(e));
    }
  };

export const fetchPaymentsDue =
  (tenantId: string, endCursor?: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const after = endCursor || undefined;
      const before = undefined;
      const first = 50;
      const last = undefined;
      const orderBy = { direction: OrderDirection.Desc, field: PayerTransactionOrderField.Timestamp };
      const status = [
        PaymentRequestStatus.Unpaid,
        PaymentRequestStatus.Failed,
        PaymentRequestStatus.Canceled,
        PaymentRequestStatus.PartiallyPaid,
      ];
      dispatch(fetchPaymentsDueError(undefined));
      if (!after) {
        dispatch(fetchPaymentsDueData(undefined));
      }

      const paymentsDue = await queryTransactionConnection(gqlClient, after, before, first, last, orderBy, status, true, tenantId);
      if (paymentsDue) {
        dispatch(fetchPaymentsDueSuccess(paymentsDue as PayerTransactionConnection));
      }
    } catch (e) {
      dispatch(fetchPaymentsDueError(e));
      dispatch(fetchError(e));
      dispatch(fetchPaymentsDueData(undefined));
    }
  };

export const fetchPaymentRequestInfo =
  (urlToken: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    // code to retrieve payment request info
    dispatch(incrementRequestsInFlight());

    try {
      const paymentRequestInfo = await getPaymentRequestFromUrlToken(gqlClient, urlToken);
      if (paymentRequestInfo) {
        dispatch(fetchPaymentRequestInfoSuccess(paymentRequestInfo as PaymentRequestInfo));
      }
      dispatch(decrementRequestsInFlight());
    } catch (e) {
      dispatch(fetchError(e));
      dispatch(decrementRequestsInFlight());
    }
  };

export const createPayerPayment =
  (
    paymentMethodId: string,
    paymentRequestInfo: PaymentRequestInfo,
    immediateCapture: boolean,
    riskMetadata: RiskMetadataPaymentInput,
    isCreditApplied: boolean,
    creditBalance: number,
    isConvenienceFeeApplied: boolean,
    convenienceFee: number,
    callback?: (createPaymentStatus: CreatePaymentStatus, merchant?: string, hasNoCompleteRequest?: boolean) => void,
    customerId?: string,
  ) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    dispatch(fetchPaymentError());
    try {
      //calculating amount considering discount amount
      const discountEndDate = DateTime.fromISO(paymentRequestInfo?.discountEndDate);
      const discountEndValid = discountEndDate.isValid && discountEndDate > DateTime.utc();
      const discountAmount = (discountEndValid && paymentRequestInfo?.discountAmount) || 0;
      let paymentRequestInfoAmount = paymentRequestInfo?.totalDue ? paymentRequestInfo?.totalDue - discountAmount : 0;

      // dividing amount into credit memo amount and payment method amount
      let creditAmount = 0;
      if (isCreditApplied) {
        creditAmount = creditBalance > paymentRequestInfoAmount ? paymentRequestInfoAmount : creditBalance;
        paymentRequestInfoAmount -= creditAmount;
      }

      // checking regarding convenienceFee
      let amountBeforeFees: number | undefined;
      if (isConvenienceFeeApplied) {
        amountBeforeFees = paymentRequestInfoAmount;
        paymentRequestInfoAmount += convenienceFee;
      }

      const createPaymentStatus = await createPayment(
        gqlClient,
        paymentMethodId,
        paymentRequestInfo.paymentRequestId,
        paymentRequestInfoAmount,
        paymentRequestInfo.currency,
        immediateCapture,
        riskMetadata,
        paymentRequestInfo,
        false,
        false,
        creditAmount,
        customerId,
        amountBeforeFees,
        convenienceFee,
      );
      if (createPaymentStatus) {
        dispatch(fetchCreatePaymentStatus(createPaymentStatus));
      }
      if (createPaymentStatus?.code === MutationStatusCode.Error) {
        dispatch(
          fetchPaymentError({ name: 'Payment Failed', message: createPaymentStatus.error || createPaymentStatus.message || '' }),
        );
      }
      if (createPaymentStatus && callback) {
        callback(createPaymentStatus, paymentRequestInfo.tenantId, false);
      }
      dispatch(decrementRequestsInFlight());
    } catch (e) {
      dispatch(fetchPaymentError(e));
      dispatch(fetchError(e));
      dispatch(decrementRequestsInFlight());
    }
  };

export const createPayerConsolidatedOrPartialPayment =
  (
    amount: number,
    paymentMethodId: string,
    tenantId: string,
    currency: CurrencyType,
    immediateCapture: boolean,
    riskMetadata: RiskMetadataPaymentInput,
    paymentRequestAllocation: PaymentRequestAllocationInput[],
    paymentRequestInfoList: PaymentsDueRowData[],
    creditAmount: number,
    amountBeforeFees: number | undefined,
    convenienceFee: number | undefined,
    callback?: (createPaymentStatus: CreatePaymentStatus | null, merchant?: string, hasNoCompleteRequest?: boolean) => void,
    customerId?: string,
    hasNoCompleteRequest?: boolean,
  ) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    dispatch(fetchPaymentError());
    try {
      const createPaymentStatus = await createConsolidatedOrPartialPayment(
        gqlClient,
        amount,
        paymentMethodId,
        tenantId,
        currency,
        immediateCapture,
        riskMetadata,
        false,
        paymentRequestAllocation,
        paymentRequestInfoList,
        creditAmount,
        customerId,
        amountBeforeFees,
        convenienceFee,
      );
      if (createPaymentStatus) {
        dispatch(fetchCreatePaymentStatus(createPaymentStatus));
      }
      if (createPaymentStatus?.code === MutationStatusCode.Error) {
        dispatch(
          fetchPaymentError({ name: 'Payment Failed', message: createPaymentStatus.error || createPaymentStatus.message || '' }),
        );
      }
      if (callback) {
        callback(createPaymentStatus, tenantId, hasNoCompleteRequest);
      }
      dispatch(decrementRequestsInFlight());
    } catch (e) {
      dispatch(fetchPaymentError(e));
      dispatch(fetchError(e));
      dispatch(decrementRequestsInFlight());
      //Stop the loading mask if there was a thrown error
      if (callback) {
        callback(null, tenantId);
      }
    }
  };

// function to check whether a payment method has finished processing
const waitForPaymentMethodProcessing = async (token: string, isGuestUser: boolean): Promise<PaymentMethodStatus> => {
  let status = PaymentMethodStatus.Processing;
  let i = 0;
  while (i < 30 && status === PaymentMethodStatus.Processing) {
    const paymentMethod = await queryPaymentMethodByToken(gqlClient, token, isGuestUser);
    const currentStatus = paymentMethod?.nodes[0]?.status;
    if (currentStatus) status = currentStatus;

    // wait 500 ms before next query
    await new Promise(resolve => setTimeout(resolve, 500));

    i += 1;
  }

  return status;
};

const waitForSDKPaymentMethodProcessing = async (token: string, isGuestUser: boolean): Promise<PaymentMethod | undefined | null> => {
  let status = PaymentMethodStatus.Processing;
  let paymentMethod: PaymentMethod | undefined | null;
  let i = 0;
  while (i < 30 && status === PaymentMethodStatus.Processing) {
    const paymentMethods = await queryPaymentMethodByToken(gqlClient, token, isGuestUser);
    paymentMethod = paymentMethods?.nodes[0];
    const currentStatus = paymentMethod?.status;
    if (currentStatus) status = currentStatus;

    // wait 500 ms before next query
    await new Promise(resolve => setTimeout(resolve, 500));

    i += 1;
  }
  return paymentMethod;
};

export const makeGuestPayment =
  (paymentMethodId: string, paymentRequestInfo: PaymentRequestInfo, riskMetadata: RiskMetadataPaymentInput, convenienceFee?: number) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    dispatch(fetchPaymentMethodError());
    try {
      //calculating amount considering discount amount
      const discountEndDate = DateTime.fromISO(paymentRequestInfo?.discountEndDate);
      const discountEndValid = discountEndDate.isValid && discountEndDate > DateTime.utc();
      const discountAmount = (discountEndValid && paymentRequestInfo?.discountAmount) || 0;
      let paymentRequestInfoAmount = paymentRequestInfo?.totalDue ? paymentRequestInfo?.totalDue - discountAmount : 0;

      let amountBeforeFees: number | undefined;
      if (convenienceFee) {
        amountBeforeFees = paymentRequestInfoAmount;
        paymentRequestInfoAmount += convenienceFee;
      }

      const customerId = paymentRequestInfo?.customerId || undefined;
      const createPaymentStatus = await createPayment(
        gqlClient,
        paymentMethodId,
        paymentRequestInfo.paymentRequestId,
        paymentRequestInfoAmount,
        paymentRequestInfo.currency,
        true,
        riskMetadata,
        paymentRequestInfo,
        true,
        false,
        0,
        customerId,
        amountBeforeFees,
        convenienceFee,
      );
      if (createPaymentStatus) {
        dispatch(fetchCreatePaymentStatus(createPaymentStatus));
      }

      if (createPaymentStatus?.code === MutationStatusCode.Error) {
        throw new Error(
          `Payment Failed. ${
            createPaymentStatus.error || createPaymentStatus.message || 'Please verify your information and try again.'
          }`,
        );
      }
    } catch (e) {
      dispatch(fetchPaymentError(e));
      dispatch(fetchError(e));
    } finally {
      dispatch(decrementRequestsInFlight());
    }
  };

export const createGuestPayment =
  (
    token: ApteanPaySDKToken,
    paymentRequestInfo: PaymentRequestInfo,
    riskMetadata: RiskMetadataPaymentInput,
    isConvenienceFeesEnabled: boolean,
  ) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    dispatch(fetchPaymentMethodError());
    dispatch(fetchPaymentError());
    dispatch(fetchGuestCheckoutPaymentMethodToken(undefined));
    try {
      if (token) {
        const paymentMethod = await waitForSDKPaymentMethodProcessing(token.id, true);

        if (paymentMethod && paymentMethod.status !== PaymentMethodStatus.Processing) {
          if (
            paymentMethod.status === PaymentMethodStatus.ProcessingError ||
            paymentMethod.status === PaymentMethodStatus.VerificationFailed
          ) {
            throw new Error('Verify your billing address and credit card details and try again.');
          }
        }
        const tokenWithCreditCard: Token = {
          ...token,
          status: paymentMethod?.status,
          creditCard: paymentMethod?.creditCard,
          createTime: token.created,
        };
        if (isConvenienceFeesEnabled) {
          dispatch(fetchGuestCheckoutPaymentMethodToken(tokenWithCreditCard));
        } else {
          dispatch(makeGuestPayment(token.id, paymentRequestInfo, riskMetadata));
        }
      }
      dispatch(decrementRequestsInFlight());
    } catch (e) {
      dispatch(fetchPaymentError(e));
      dispatch(fetchError(e));
      dispatch(decrementRequestsInFlight());
    }
  };

const fetchPaymentMethods =
  (email: string, tenantId: string, callback: () => void | undefined) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchLoadingPaymentMethods(true));
    try {
      const viewerUser = await queryPersonByEmail(gqlClient, email, tenantId);
      if (viewerUser) {
        dispatch(fetchViewerUserByEmailSuccess(viewerUser as Person));
        if (callback) {
          callback();
        }
        dispatch(fetchLoadingPaymentMethods(false));
      }
    } catch (e) {
      dispatch(fetchError(e));
      dispatch(fetchLoadingPaymentMethods(false));
    }
  };

export const createPayerPaymentMethod =
  (
    type: PaymentMethodType,
    token: string,
    attachToResourceId: string | undefined,
    holder: PaymentMethodHolderInput,
    isDefault: boolean,
    email: string | undefined,
    tenantId: string,
    shareWithMerchant: boolean,
    completingPaymentMethodRequest: boolean,
    callback: () => void | undefined,
  ) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchPaymentMethodError());
    dispatch(fetchPaymentMethodAdded(false));
    dispatch(fetchLoadingPaymentMethods(true));
    if (completingPaymentMethodRequest) dispatch(fetchIsPaymentMethodRequestCompleted(false));
    try {
      const convertStatus = await convertPayfacPaymentMethodToken(gqlClient, type, token, holder, false, tenantId);
      if (convertStatus?.code === MutationStatusCode.Error) {
        throw new Error(
          convertStatus.error || convertStatus.message || 'Verify your billing address and credit card details and try again.',
        );
      }

      if (convertStatus && convertStatus.token) {
        const status = await waitForPaymentMethodProcessing(convertStatus.token.id, false);

        if (status !== PaymentMethodStatus.Processing) {
          if (status === PaymentMethodStatus.ProcessingError || status === PaymentMethodStatus.VerificationFailed) {
            throw new Error('Verify your billing address and credit card details and try again.');
          }

          const createStatus = await createPaymentMethod(
            gqlClient,
            convertStatus.token.id,
            attachToResourceId,
            isDefault,
            tenantId,
            shareWithMerchant,
          );

          if (createStatus) {
            dispatch(fetchCreatePayerPaymentMethodStatus(createStatus));
            if (email) {
              dispatch(fetchPaymentMethods(email, tenantId, callback));
            }
            if (tenantId && attachToResourceId) {
              dispatch(capturePayerCustomer(attachToResourceId, tenantId));
            }
            dispatch(fetchPaymentMethodAdded(true));

            if (completingPaymentMethodRequest) {
              dispatch(fetchIsPaymentMethodRequestCompleted(true));
            }
          }
        }
      }
    } catch (e) {
      dispatch(fetchPaymentMethodError(e));
      dispatch(fetchError(e));
      dispatch(fetchLoadingPaymentMethods(false));
    }
  };

export const createPaymentMethodSDK =
  (
    token: ApteanPaySDKToken,
    attachToResourceId: string | undefined,
    isDefault: boolean,
    email: string | undefined,
    tenantId: string,
    shareWithMerchant: boolean,
    completingPaymentMethodRequest: boolean,
    callback: () => void | undefined,
  ) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchPaymentMethodError());
    dispatch(fetchPaymentMethodAdded(false));
    dispatch(fetchLoadingPaymentMethods(true));
    if (completingPaymentMethodRequest) dispatch(fetchIsPaymentMethodRequestCompleted(false));
    try {
      const paymentMethod = await waitForSDKPaymentMethodProcessing(token.id, false);
      if (paymentMethod && paymentMethod.status !== PaymentMethodStatus.Processing) {
        if (
          paymentMethod.status === PaymentMethodStatus.ProcessingError ||
          paymentMethod.status === PaymentMethodStatus.VerificationFailed
        ) {
          throw new Error('Verify your billing address and credit card details and try again.');
        }
        const createStatus = await createPaymentMethod(
          gqlClient,
          token.id,
          attachToResourceId,
          isDefault,
          tenantId,
          shareWithMerchant,
        );
        if (createStatus) {
          dispatch(fetchCreatePayerPaymentMethodStatus(createStatus));
          if (email) {
            dispatch(fetchPaymentMethods(email, tenantId, callback));
          }
          if (tenantId && attachToResourceId) {
            dispatch(capturePayerCustomer(attachToResourceId, tenantId));
          }
          dispatch(fetchPaymentMethodAdded(true));

          if (completingPaymentMethodRequest) {
            dispatch(fetchIsPaymentMethodRequestCompleted(true));
          }
        }
        dispatch(decrementRequestsInFlight());
      }
    } catch (e) {
      dispatch(fetchPaymentMethodError(e));
      dispatch(fetchError(e));
      dispatch(fetchLoadingPaymentMethods(false));
    }
  };

export const setDefaultPaymentMethod =
  (paymentMethod: PayerPaymentMethod, attachToResourceId: string, userEmail: string, tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    if (paymentMethod?.paymentMethod) {
      try {
        const setDefaultStatus = await updatePaymentMethod(
          gqlClient,
          paymentMethod.paymentMethod.id,
          true,
          attachToResourceId,
          tenantId,
        );
        if (setDefaultStatus) {
          dispatch(fetchUpdatePaymentMethodStatus(setDefaultStatus));
          dispatch(fetchViewerUserByEmail(userEmail, tenantId));
          if (attachToResourceId && tenantId) {
            dispatch(capturePayerCustomer(attachToResourceId, tenantId));
          }
        }
        setTimeout(() => {
          dispatch(decrementRequestsInFlight());
        }, 1500);
      } catch (e) {
        dispatch(fetchPaymentError(e));
        dispatch(fetchError(e));
        dispatch(decrementRequestsInFlight());
      }
    }
  };

export const setSharedWithMerchantPaymentMethod =
  (paymentMethod: PayerPaymentMethod, attachToResourceId: string, userEmail: string, tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    if (paymentMethod?.paymentMethod) {
      try {
        const setSharedStatus = await updatePaymentMethod(
          gqlClient,
          paymentMethod.paymentMethod.id,
          false,
          attachToResourceId,
          tenantId,
          true,
        );
        if (setSharedStatus) {
          dispatch(fetchUpdatePaymentMethodStatus(setSharedStatus));
          dispatch(fetchViewerUserByEmail(userEmail, tenantId));
          if (attachToResourceId && tenantId) {
            dispatch(capturePayerCustomer(attachToResourceId, tenantId));
          }
        }
        setTimeout(() => {
          dispatch(decrementRequestsInFlight());
        }, 1500);
      } catch (e) {
        dispatch(fetchPaymentError(e));
        dispatch(fetchError(e));
        dispatch(decrementRequestsInFlight());
      }
    }
  };

export const deletePayerPaymentMethod =
  (paymentMethod: PayerPaymentMethod, customerId: string | undefined, tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchDeletingPaymentMethod(true));
    if (paymentMethod?.paymentMethod) {
      try {
        const validPaymentMethod = await queryPaymentMethodByToken(gqlClient, paymentMethod.paymentMethod.id, false);
        if (!validPaymentMethod) {
          throw new Error('This payment method is invalid and is unable to be deleted.');
        }
        // TODO: Need the commented out info from the data once it is available.
        const deleteStatus = await deletePaymentMethod(gqlClient, paymentMethod.paymentMethod.id, tenantId);
        if (deleteStatus) {
          dispatch(fetchDeletePayerPaymentMethodStatus(deleteStatus));
        }
        if (customerId && tenantId) {
          dispatch(capturePayerCustomer(customerId, tenantId));
        }
        dispatch(fetchDeletePaymentMethodError(undefined));
        dispatch(fetchDeletingPaymentMethod(false));
      } catch (e) {
        dispatch(fetchDeletePaymentMethodError(e));
        dispatch(fetchError(e));
        dispatch(fetchDeletingPaymentMethod(false));
      }
    }
  };

export const verifyBankPaymentMethod =
  (paymentMethodId: string | undefined, microDeposits: number[]) =>
  async (dispatch: AppDispatch): Promise<void> => {
    // code to retrieve payment request info
    dispatch(incrementRequestsInFlight());
    try {
      // TODO: Need the commented out info from the data once it is available.
      const verifyStatus = await verifyPaymentMethod(gqlClient, {
        paymentMethodId,
        microDeposits,
      });
      if (verifyStatus) {
        dispatch(fetchVerifyPaymentMethodStatus(verifyStatus));
      }
      dispatch(decrementRequestsInFlight());
    } catch (e) {
      dispatch(fetchError(e));
      dispatch(decrementRequestsInFlight());
    }
  };

export const getPayerTransactionSummaryByMerchant =
  () =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    try {
      const queryStatus = await queryTransactionSummaryByMerchant(gqlClient);
      if (queryStatus) {
        dispatch(fetchPayerTransactionSummaryByMerchant(queryStatus));
      }
      dispatch(decrementRequestsInFlight());
    } catch (e) {
      dispatch(fetchPayerTransactionSummaryError(e));
      dispatch(decrementRequestsInFlight());
    }
  };

export const fetchOpenCreditMemos =
  (endCursor: string | undefined, pageSize: number | undefined, tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const after = endCursor || undefined;
      const before = undefined;
      const first = pageSize || 25;
      const last = 0;
      const orderBy = { direction: OrderDirection.Asc, field: CreditMemoOrderField.Timestamp };
      const status = [CreditMemoStatus.Open];

      dispatch(fetchOpenCreditMemosError(undefined));

      const creditMemos = await queryCreditMemoConnection(gqlClient, after, before, first, last, orderBy, status, false, tenantId);
      if (creditMemos) {
        dispatch(fetchOpenCreditMemoSuccess(creditMemos as PayerCreditMemoConnection));
      }
    } catch (e) {
      dispatch(fetchOpenCreditMemosError(e));
    }
  };

export const fetchCreditMemoBalance =
  (tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const after = undefined;
      const before = undefined;
      const first = 0;
      const last = 0;
      const orderBy = { direction: OrderDirection.Desc, field: CreditMemoOrderField.Timestamp };
      const status = [CreditMemoStatus.Open];

      dispatch(fetchCreditMemoBalanceError(undefined));
      dispatch(fetchLoadingCreditMemoBalance(true));
      dispatch(clearCreditMemoBalance());

      const creditMemos = await queryCreditMemoConnection(gqlClient, after, before, first, last, orderBy, status, true, tenantId);
      if (creditMemos) {
        dispatch(captureCreditMemoBalance(creditMemos as PayerCreditMemoConnection));
      }
      dispatch(fetchLoadingCreditMemoBalance(false));
    } catch (e) {
      dispatch(fetchCreditMemoBalanceError(e));
      dispatch(fetchLoadingCreditMemoBalance(false));
    }
  };

export const fetchCreditMemosHistory =
  (endCursor: string | undefined, pageSize: number | undefined, tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const after = endCursor || undefined;
      const before = undefined;
      const first = pageSize || 25;
      const last = 0;
      const orderBy = { direction: OrderDirection.Desc, field: CreditMemoUsageHistoryOrderField.Timestamp };
      const status = [CreditMemoStatus.Open, CreditMemoStatus.Redeemed];

      dispatch(fetchCreditMemoHistoryError(undefined));

      const creditMemosHistory = await queryCreditMemoUsageConnection(
        gqlClient,
        after,
        before,
        first,
        last,
        orderBy,
        status,
        tenantId,
      );
      if (creditMemosHistory) {
        dispatch(fetchCreditMemoHistorySuccess(creditMemosHistory as CreditMemoUsageConnection));
      }
    } catch (e) {
      dispatch(fetchCreditMemoHistoryError(e));
    }
  };

export const createOneTimeUsePaymentMethod =
  (token: ApteanPaySDKToken, callback: () => void | undefined) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchPaymentMethodError());
    dispatch(fetchPaymentMethodAdded(false));
    dispatch(fetchLoadingPaymentMethods(true));
    try {
      const paymentMethod = await waitForSDKPaymentMethodProcessing(token.id, false);
      if (paymentMethod && paymentMethod.status !== PaymentMethodStatus.Processing) {
        if (
          paymentMethod.status === PaymentMethodStatus.ProcessingError ||
          paymentMethod.status === PaymentMethodStatus.VerificationFailed
        ) {
          throw new Error('Verify your billing address and credit card details and try again.');
        }
      }
      const tokenWithCard: Token = {
        ...token,
        createTime: token.created,
        status: paymentMethod?.status,
        creditCard: paymentMethod?.creditCard,
      };
      dispatch(fetchOneTimeUsePaymentMethod(tokenWithCard));
      callback();
      dispatch(fetchLoadingPaymentMethods(false));
    } catch (e) {
      dispatch(fetchPaymentMethodError(e));
      dispatch(fetchError(e));
      dispatch(fetchLoadingPaymentMethods(false));
    }
  };

export const fetchCalculatedConvenienceFees =
  (isGuestUser: boolean, amount: number, cardType: CardType, tenantId: string, customerId?: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(captureIsCalculatedConvenienceFeesInFlight(true));
      dispatch(captureCalculatedConvenienceFees(undefined));

      const convenienceFee = await queryCalculateConvenienceFees(gqlClient, isGuestUser, amount, cardType, tenantId, customerId);
      if (convenienceFee) {
        const cardTypeConvenienceFee: CardTypeConvenienceFee = {
          cardType,
          amount: convenienceFee.amount,
          rateBps: convenienceFee.rateBps,
        };
        dispatch(captureCalculatedConvenienceFees(cardTypeConvenienceFee));
      }
      dispatch(captureIsCalculatedConvenienceFeesInFlight(false));
    } catch (e) {
      console.log(e);
      dispatch(captureIsCalculatedConvenienceFeesInFlight(false));
    }
  };

export const fetchSpecificPaymentDetailRecord =
  (id: string, tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(captureIsSpecificPaymentDetailRecordInFlight(true));
      dispatch(captureSpecificPaymentDetailRecord(undefined));

      const paymentConnection = await queryPaymentConnection(gqlClient, id, tenantId);
      const { nodes } = paymentConnection;
      const payment = nodes.length === 1 ? nodes[0] : undefined;
      if (payment) {
        dispatch(captureSpecificPaymentDetailRecord(payment));
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(captureIsSpecificPaymentDetailRecordInFlight(false));
    }
  };

export const captureCompletePaymentMethodRequest =
  (userEmail: string, customerId: string, tenantId: string, id: string, paymentMethodId: string, callback: () => void | undefined) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(fetchIsPaymentMethodRequestCompleted(false));
      dispatch(fetchCompletePaymentMethodRequestInFlight(true));
      dispatch(fetchCompletePaymentMethodRequestError(undefined));
      dispatch(fetchCompletePaymentMethodRequestStatus(undefined));
      const completePaymentMethodRequestStatus = await completePaymentMethodRequest(gqlClient, tenantId, id, paymentMethodId);
      if (completePaymentMethodRequestStatus) {
        dispatch(fetchCompletePaymentMethodRequestStatus(completePaymentMethodRequestStatus));
        dispatch(fetchPaymentMethods(userEmail, tenantId, callback));
        dispatch(capturePayerCustomer(customerId, tenantId));
        dispatch(fetchIsPaymentMethodRequestCompleted(true));
      }
    } catch (e) {
      dispatch(fetchCompletePaymentMethodRequestError(e));
    } finally {
      dispatch(fetchCompletePaymentMethodRequestInFlight(false));
    }
  };

export const getTenantIntegrationSettings =
  (tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(incrementRequestsInFlight());
      const integrationSettings = await queryTenantIntegrationSettings(gqlClient, tenantId);
      if (integrationSettings) {
        dispatch(fetchTenantIntegrationSettings(integrationSettings));
      }
      dispatch(decrementRequestsInFlight());
    } catch (e) {
      console.log(e);
    }
  };
