import { Grid, Paper, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createStyles, makeStyles } from '@mui/styles';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import BillingEdit from '../../components/BillingEdit';
import BillingInfo from '../../components/BillingInfo';
import CardInfo from '../../components/CardInfo';
import GuestCardDetails from '../../components/GuestCardDetails';
import LoadingMask from '../../components/LoadingMask';
import MissingRefundPolicy from '../../components/MissingRefundPolicy';
import PaymentError from '../../components/PaymentError';
import PaymentInfoNotFound from '../../components/PaymentInfoNotFound';
import PaymentProcessing from '../../components/PaymentProcessing';
import PaymentReceived from '../../components/PaymentReceived';
import PaymentRequest from '../../components/PaymentRequest';
import PaymentSuccess from '../../components/PaymentSuccess';
import PaymentUnknown from '../../components/PaymentUnknown';
import UserOptions from '../../components/UserOptions';
import {
  Address,
  CardType,
  MutationStatusCode,
  PaymentMethodHolder,
  PaymentMethodType,
  PaymentRequestStatus,
  Phone,
  RiskMetadataPaymentInput,
} from '../../gql-types.generated';
import { authProvider } from '../../util/AuthProvider';
import { getIsRequestPayable, getPaymentErrorMessage } from '../../util/Util';
import {
  createGuestPayment,
  fetchCalculatedConvenienceFees,
  fetchPaymentRequestInfo,
  makeGuestPayment,
} from '../multipleMethodsHome/MultipleMethodsHomeActions';
import {
  captureCalculatedConvenienceFees,
  clearPaymentRequestInfo,
  decrementPaymentStep,
  decrementRequestsInFlight,
  fetchDisplayRefundPolicy,
  fetchGuestCheckoutPaymentMethodToken,
  fetchHasSearchString,
  fetchPaymentError,
  fetchPaymentMethodError,
  fetchUrlToken,
  incrementPaymentStep,
  incrementRequestsInFlight,
  selectCalculatedConvenienceFees,
  selectCreatePaymentStatus,
  selectDisplayRefundPolicy,
  selectGuestCheckoutPaymentMethodToken,
  selectHasSearchString,
  selectIsCalculatedConvenienceFeesInFlight,
  selectNetworkBusy,
  selectPaymentError,
  selectPaymentMethodError,
  selectPaymentRequestInfo,
  selectPaymentStep,
} from '../multipleMethodsHome/MultipleMethodsHomeSlice';
import {
  ApteanPaySDK,
  ApteanPaySDKCardComponent,
  ApteanPaySDKCreateTokenData,
  ApteanPaySDKError,
  ApteanPaySDKToken,
} from '../../util/ApteanPay';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPaper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    paymentRequest: {
      height: '100%',
    },
    paymentRequestLarge: {
      minHeight: 600,
    },
    paperSpace: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    paperSpaceMobile: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    hidden: {
      display: 'none',
    },
  }),
);

const GuestHome: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const paymentStep = useSelector(selectPaymentStep);
  const createPaymentStatus = useSelector(selectCreatePaymentStatus);
  const paymentError = useSelector(selectPaymentError);
  const paymentRequestInfo = useSelector(selectPaymentRequestInfo);
  const hasSearchString = useSelector(selectHasSearchString);
  const displayRefundPolicy = useSelector(selectDisplayRefundPolicy);
  const networkBusy = useSelector(selectNetworkBusy);
  const paymentMethodError = useSelector(selectPaymentMethodError);
  const guestCheckoutPaymentMethodToken = useSelector(selectGuestCheckoutPaymentMethodToken);
  const calculatedConvenienceFees = useSelector(selectCalculatedConvenienceFees);
  const isCalculatedConvenienceFeesInFlight = useSelector(selectIsCalculatedConvenienceFeesInFlight);
  const isMobileSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  let paymentHeader: undefined | JSX.Element;
  const [sendingPayment, setSendingPayment] = useState<boolean>(false);
  const defaultGuestHolder = {
    address: {
      country: 'US',
    } as Address,
    phone: {} as Phone,
  } as PaymentMethodHolder;
  const [guestHolder, setGuestHolder] = useState<PaymentMethodHolder>(defaultGuestHolder);
  const [closeAfterPolling, setCloseAfterPolling] = useState<boolean>(false);
  const [paymentBeingProcessed, setPaymentBeingProcessed] = useState(false);
  const [creditToken, setCreditToken] = useState<ApteanPaySDKToken>();
  const [cardAllowed, setCardAllowed] = useState(false);
  const [holderName, setHolderName] = useState<string>('');
  const [cardHolderNameValid, setCardHolderNameValid] = useState(true);
  const [isIFrameVisible, setIsIFrameVisible] = useState(false);
  const [creditError, setCreditError] = useState<string>();
  const isConvenienceFeesEnabled = !!paymentRequestInfo?.features?.payments?.convenienceFees;

  useEffect(() => {
    if (paymentRequestInfo && paymentRequestInfo.supportedPaymentMethods && paymentRequestInfo.supportedPaymentMethods.length > 0) {
      setCardAllowed(paymentRequestInfo.supportedPaymentMethods.includes(PaymentMethodType.CreditCard));
    }
  }, [paymentRequestInfo]);

  // detects changes to url and acts accordingly
  useEffect(() => {
    if (location.search === '') {
      dispatch(fetchHasSearchString(false));
      dispatch(clearPaymentRequestInfo());
    } else {
      dispatch(fetchHasSearchString(true));
      dispatch(fetchUrlToken(window.location.search.substring(1)));
      dispatch(fetchPaymentRequestInfo(window.location.search.substring(1)));
    }
  }, [location]);

  useEffect(() => {
    if (guestCheckoutPaymentMethodToken?.id) {
      if (paymentRequestInfo && paymentRequestInfo?.tenantId && paymentRequestInfo?.tenantId.length > 0) {
        const cardType = guestCheckoutPaymentMethodToken?.creditCard?.details?.type || CardType.Unknown;
        //calculating amount considering discount amount
        const discountEndDate = DateTime.fromISO(paymentRequestInfo?.discountEndDate);
        const discountEndValid = discountEndDate.isValid && discountEndDate > DateTime.utc();
        const discountAmount = (discountEndValid && paymentRequestInfo?.discountAmount) || 0;
        const paymentRequestInfoAmount = paymentRequestInfo.totalDue ? paymentRequestInfo.totalDue - discountAmount : 0;

        dispatch(
          fetchCalculatedConvenienceFees(
            true,
            paymentRequestInfoAmount,
            cardType,
            paymentRequestInfo.tenantId,
            paymentRequestInfo?.customerId as string | undefined,
          ),
        );
      }
    }
  }, [guestCheckoutPaymentMethodToken]);

  const requestPayable = getIsRequestPayable(paymentRequestInfo);
  const getPaymentHeader = () => {
    // Determine the payment header - this will contain current status/error information.
    if (paymentBeingProcessed) {
      return <PaymentSuccess email={guestHolder?.email} referenceNumber={paymentRequestInfo?.referenceNumber} />;
    }
    if (hasSearchString && !paymentRequestInfo) {
      return <PaymentInfoNotFound />;
    }
    if (paymentRequestInfo) {
      if (!paymentRequestInfo.refundPolicy) {
        return <MissingRefundPolicy />;
      }
      if (paymentRequestInfo.status === PaymentRequestStatus.Completed) {
        return <PaymentReceived />;
      }
      if (paymentRequestInfo.status === PaymentRequestStatus.Unknown) {
        return <PaymentUnknown email={guestHolder?.email} />;
      }
      if (!requestPayable) {
        // Catch for status's which will not allow a payment to be processed for this request.
        // TODO: What should unknown be handled as?
        return <PaymentProcessing status={paymentRequestInfo.status} />;
      }
    }
    if (paymentMethodError) {
      return <PaymentError errorMessage={getPaymentErrorMessage(paymentMethodError)} isPaymentMethod={true} />;
    }
    if (paymentError) {
      return <PaymentError errorMessage={getPaymentErrorMessage(paymentError)} isPaymentMethod={false} />;
    }
    return undefined;
  };
  // What happens after the polling for payment request ends
  useEffect(() => {
    if (closeAfterPolling && !networkBusy) {
      // If there is a payment error show that error
      if (paymentError) {
        setPaymentBeingProcessed(false);
      } else if (
        createPaymentStatus?.code === MutationStatusCode.Success ||
        createPaymentStatus?.code === MutationStatusCode.Pending
      ) {
        // If the payment has been created and haven't failed or throwed any error then the ui can show that the payment is being processed.
        setPaymentBeingProcessed(true);
      } else if (paymentRequestInfo && paymentRequestInfo.status === PaymentRequestStatus.Failed) {
        // set the user to retry payment if payment request status comes back as failed
        setPaymentBeingProcessed(false); //We want to keep the ui showing the payment components so the user can try again.
        dispatch(
          fetchPaymentError({ name: 'Payment Failed', message: 'Payment failed. Please verify your information and try again.' }),
        );
      }
      paymentHeader = getPaymentHeader();
      setSendingPayment(false);
      setCloseAfterPolling(false);
    }
  }, [networkBusy, closeAfterPolling]);

  useEffect(() => {
    paymentHeader = getPaymentHeader();
  }, [paymentBeingProcessed]);

  const finalizeCreditSubmit = () => {
    if (
      creditToken &&
      paymentRequestInfo &&
      guestHolder &&
      guestHolder.address &&
      guestHolder.address.postalCode &&
      guestHolder.address.country &&
      guestHolder.phone &&
      guestHolder.phone.countryCode &&
      guestHolder.phone.number
    ) {
      const riskMetadata: RiskMetadataPaymentInput = {
        address: {
          line1: guestHolder.address.line1,
          line2: guestHolder.address.line2,
          city: guestHolder.address.city,
          region: guestHolder.address.region,
          postalCode: guestHolder.address.postalCode,
          country: guestHolder.address.country,
        },
        phone: {
          countryCode: guestHolder.phone.countryCode,
          number: guestHolder.phone.number,
        },
        lineItems: [],
      };
      if (guestCheckoutPaymentMethodToken?.id) {
        dispatch(
          makeGuestPayment(guestCheckoutPaymentMethodToken.id, paymentRequestInfo, riskMetadata, calculatedConvenienceFees?.amount),
        );
      } else {
        dispatch(createGuestPayment(creditToken, paymentRequestInfo, riskMetadata, isConvenienceFeesEnabled));
      }
      setCloseAfterPolling(true);
    } else {
      setSendingPayment(false);
    }
  };

  useEffect(() => {
    if (creditToken && creditToken.id.length > 0) {
      finalizeCreditSubmit();
    }
  }, [creditToken]);

  // Return immediately if condition allows.
  if (networkBusy && !sendingPayment) {
    return <Paper className={classes.loadingPaper}>Loading!</Paper>;
  }
  const onContinueAsGuest = () => {
    dispatch(incrementPaymentStep());
    setGuestHolder(defaultGuestHolder);
  };
  const onContinue = () => {
    dispatch(incrementPaymentStep());
  };
  const onGuestHolderChange = (holder: PaymentMethodHolder) => {
    setGuestHolder(holder);
  };
  const createAccount = () => {
    // TODO: How can we send the user directly to the create account page?
    authProvider.loginRedirect();
  };
  const signIn = () => {
    authProvider.loginRedirect();
  };

  const getPaymentRequestInfoAmount = () => {
    const discountEndDate = DateTime.fromISO(paymentRequestInfo?.discountEndDate);
    const discountEndValid = discountEndDate.isValid && discountEndDate > DateTime.utc();
    const discountAmount = (discountEndValid && paymentRequestInfo?.discountAmount) || 0;

    return paymentRequestInfo?.totalDue ? paymentRequestInfo?.totalDue - discountAmount : 0;
  };

  const onTokenizeCallback = (token?: ApteanPaySDKToken, errors?: ApteanPaySDKError[]) => {
    if (token) {
      setCreditToken(token);
    }
    if (errors) {
      const messages = errors.map(error => error.message).join('. ');
      dispatch(
        fetchPaymentMethodError({
          name: 'Payment Method Unsuccessful',
          message: messages || 'Verify your billing address and credit card details and try again.',
        }),
      );
      setCreditError(messages);
      setSendingPayment(false);
    }
    dispatch(decrementRequestsInFlight());
  };

  const onTokenize = (apteanPay: ApteanPaySDK, cardComponent: ApteanPaySDKCardComponent) => {
    if (
      guestHolder &&
      guestHolder.address &&
      guestHolder.address.postalCode &&
      guestHolder.address.country &&
      guestHolder.phone &&
      guestHolder.phone.countryCode &&
      guestHolder.phone.number
    ) {
      setCreditError(undefined);
      dispatch(fetchPaymentMethodError(undefined));
      dispatch(incrementRequestsInFlight());
      const data: ApteanPaySDKCreateTokenData = {
        addressCountry: guestHolder.address.country,
        addressZip: guestHolder.address.postalCode,
        name: holderName,
        addressLine1: guestHolder.address.line1 ?? undefined,
        addressLine2: guestHolder.address.line2 ?? undefined,
        addressCity: guestHolder.address.city ?? undefined,
        addressState: guestHolder.address.region ?? undefined,
        emailAddress: guestHolder.email ?? undefined,
        phoneCountryCode: guestHolder.phone.countryCode,
        phoneNumber: guestHolder.phone.number,
      };
      apteanPay.createTokenCallback(cardComponent, data, onTokenizeCallback);
    }
  };
  const handleToggleRefundPolicyDisplay = () => {
    dispatch(fetchDisplayRefundPolicy(!displayRefundPolicy));
  };
  const onBack = () => {
    if (isIFrameVisible) {
      setIsIFrameVisible(false);
    }
    dispatch(decrementPaymentStep());
  };
  const handlePaymentMethodBack = () => {
    dispatch(fetchGuestCheckoutPaymentMethodToken(undefined));
    dispatch(captureCalculatedConvenienceFees(undefined));
  };
  const handleGuestPaymentSubmit = () => {
    setSendingPayment(true);
    finalizeCreditSubmit();
  };

  const paymentRequest =
    paymentRequestInfo && !paymentBeingProcessed ? (
      <Grid item xs={12} md={6} className={isMobileSize ? '' : classes.paymentRequestLarge}>
        <Paper className={classes.paymentRequest}>
          <PaymentRequest
            displayRefundPolicy={displayRefundPolicy}
            paymentRequestInfo={paymentRequestInfo}
            toggleRefundPolicyDisplay={handleToggleRefundPolicyDisplay}
            getPaymentRequestInfoAmount={getPaymentRequestInfoAmount}
            convenienceFee={calculatedConvenienceFees?.amount}
            inFlight={sendingPayment || isCalculatedConvenienceFeesInFlight}
            makePayment={handleGuestPaymentSubmit}
          />
        </Paper>
      </Grid>
    ) : (
      false
    );
  paymentHeader = getPaymentHeader(); // initialize header, but will get overwritten if polling occurs
  return (
    <>
      <LoadingMask loading={sendingPayment || isCalculatedConvenienceFeesInFlight} />
      <Grid container item spacing={2} justifyContent="center">
        {!!paymentHeader && !sendingPayment && (
          <Grid item xs={paymentBeingProcessed ? 'auto' : 12} md={10}>
            <Paper>{paymentHeader}</Paper>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          {isMobileSize && <Paper className={isMobileSize ? classes.paperSpace : undefined}>{paymentRequest}</Paper>}
          {!paymentBeingProcessed && (
            <Paper className={isMobileSize ? classes.paperSpace : undefined}>
              {paymentStep === 0 && (
                <>
                  <UserOptions
                    requestPayable={requestPayable && cardAllowed}
                    continueAsGuest={onContinueAsGuest}
                    createAccount={createAccount}
                    signIn={signIn}
                  />
                </>
              )}
              {paymentStep === 1 && (
                <BillingEdit
                  isAdd={true}
                  handleContinue={onContinue}
                  handleHolderChange={onGuestHolderChange}
                  holder={guestHolder}
                  handleBack={onBack}
                  backText={'Back'}
                  continueText={'Continue to Payment'}
                  onMobile={isMobileSize}
                />
              )}
              {paymentStep === 2 && <BillingInfo holder={guestHolder} />}
            </Paper>
          )}
          {paymentStep === 2 && !paymentBeingProcessed ? (
            <>
              {guestCheckoutPaymentMethodToken ? (
                <Paper className={isMobileSize ? classes.paperSpace : undefined}>
                  <GuestCardDetails
                    token={guestCheckoutPaymentMethodToken}
                    handleBack={handlePaymentMethodBack}
                    convenienceFee={calculatedConvenienceFees}
                  />
                </Paper>
              ) : null}
              <Paper
                className={`${isMobileSize ? classes.paperSpace : undefined} ${guestCheckoutPaymentMethodToken ? classes.hidden : ''}`}
              >
                <CardInfo
                  handleBack={onBack}
                  onTokenize={onTokenize}
                  backText={'Billing'}
                  paymentRequestInfo={paymentRequestInfo}
                  holderName={holderName}
                  setHolderName={setHolderName}
                  holderNameValid={cardHolderNameValid}
                  setHolderNameValid={setCardHolderNameValid}
                  isIFrameVisible={isIFrameVisible}
                  setIsIFrameVisible={setIsIFrameVisible}
                  isGuestUser={true}
                  setSendingPayment={setSendingPayment}
                  sendingPayment={sendingPayment}
                  submitText={isConvenienceFeesEnabled ? 'Add Card' : undefined}
                  holderInformation={guestHolder}
                  merchantTenantId={paymentRequestInfo?.tenantId}
                  creditError={creditError}
                  setCreditError={setCreditError}
                />
              </Paper>
            </>
          ) : null}
        </Grid>
        {!isMobileSize && paymentRequest}
      </Grid>
    </>
  );
};

export default GuestHome;
