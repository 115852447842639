import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PayerTransactionSummary } from '../gql-types.generated';
import { getStandardHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_TRANSACTION_SUMMARY_BY_MERCHANT = gql`
  query payerTransactionSummaryByMerchant {
    payerTransactionSummaryByMerchant {
      merchantSummary {
        earliestCreatedDate
        merchantInfo {
          name
          country
          defaultCurrency
          id
          refundPolicy
          statementDescription
          supportedPaymentMethods
          acceptedPaymentMethods
          logoUrl
          owner {
            tenantId
          }
          features {
            creditMemos {
              enabled
            }
            paymentRequests {
              consolidatedPayment
              partialPayment
            }
            payments {
              convenienceFees
            }
          }
          options {
            payments {
              requirePartialPaymentReason
            }
          }
          logoUrl
        }
        numberOfUnpaidRequests
        totalDue
        pendingPaymentMethodRequest {
          id
          status
          restriction
          paymentAttempts {
            amount
            customerPONumber
            invoiceNumber
            orderNumber
            paymentRequest {
              referenceNumber
            }
            paymentMethod {
              id
            }
          }
        }
      }
    }
  }
`;

export const queryTransactionSummaryByMerchant = async (
  client: ApolloClient<NormalizedCacheObject>,
): Promise<PayerTransactionSummary> => {
  const headers = await getStandardHeadersWithAuth();
  const { data, errors } = await client.query<{ payerTransactionSummaryByMerchant: PayerTransactionSummary }>({
    query: QUERY_TRANSACTION_SUMMARY_BY_MERCHANT,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }
  return data.payerTransactionSummaryByMerchant;
};
