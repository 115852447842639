import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MerchantGrid from '../../components/MerchantGrid';
import { OrderDirection, PayerTransactionSummary } from '../../gql-types.generated';
import { RoutePath } from '../../util/Routes';
import { sortByName } from '../../util/SortMerchantData';
import { fetchSelectedMerchant, selectMenuVisible, selectPayerTransactionSummaryByMerchant } from '../app/AppSlice';
import {
  clearPaymentsDue,
  clearPaymentsDueConnection,
  clearRecentPayments,
  clearRecentPaymentsConnection,
  fetchConsolidatedOrPartialCancelClicked,
  fetchPaymentsDueError,
  fetchRecentPaymentsError,
  selectPayerTransactionSummaryError,
} from '../multipleMethodsHome/MultipleMethodsHomeSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsPaper: {
      textAlign: 'center',
      flexGrow: 1,
      maxWidth: '100%',
    },
    selectedTab: {
      borderBottomWidth: 0,
      backgroundColor: '#EEF4FE',
    },
    tabRoot: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.grey[300],
    },
    gridIcon: {
      marginRight: theme.spacing(0.5),
      fontSize: '1.2rem',
      verticalAlign: 'text-bottom',
    },
    listIcon: {
      marginRight: theme.spacing(0.5),
      fontSize: '1.5rem',
      verticalAlign: 'text-bottom',
    },
  }),
);

const MerchantSummaryView: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const payerTransactionSummaryByMerchant = useSelector(selectPayerTransactionSummaryByMerchant);
  const settingsMenuVisible = useSelector(selectMenuVisible);
  const payerTransactionSummaryError = useSelector(selectPayerTransactionSummaryError);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const merchantTabRef = useRef<any>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      merchantTabRef?.current?.updateIndicator();
    }, theme.transitions.duration.enteringScreen);
    return () => {
      clearTimeout(timeout);
    };
  }, [settingsMenuVisible, theme]);

  useEffect(() => {
    dispatch(fetchConsolidatedOrPartialCancelClicked(false));
    dispatch(fetchSelectedMerchant(undefined));

    dispatch(clearPaymentsDueConnection());
    dispatch(clearPaymentsDue());
    dispatch(fetchPaymentsDueError(undefined));

    dispatch(clearRecentPaymentsConnection());
    dispatch(clearRecentPayments());
    dispatch(fetchRecentPaymentsError(undefined));
  }, []);

  const handleMerchantSelect = (id: string | undefined) => {
    dispatch(fetchSelectedMerchant(id));
    history.push(RoutePath.Payments);
  };

  const handleMerchantDataSort = (merchantData: PayerTransactionSummary | undefined, order: OrderDirection) => {
    const sortedMerchantData = sortByName(merchantData, order);

    return sortedMerchantData;
  };

  return (
    <>
      <Box className={classes.tabsPaper}>
        <MerchantGrid
          payerTransactionSummaryByMerchant={payerTransactionSummaryByMerchant}
          handleMerchantSelect={handleMerchantSelect}
          merchantDataSort={handleMerchantDataSort}
          settingsMenuVisible={settingsMenuVisible}
          merchantDataError={payerTransactionSummaryError}
        />
      </Box>
    </>
  );
};

export default MerchantSummaryView;
