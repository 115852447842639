import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { PayerPaymentMethod, PaymentMethodRequest, PaymentMethodStatus, PaymentMethodType } from '../gql-types.generated';
import PaymentMethodTile from './PaymentMethodTile';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    methodDetailsText: {
      borderBottom: 'none',
      padding: theme.spacing(0.5, 0.5, 0, 0.5),
    },
    methodDetailsRowText: {
      color: theme.palette.uxGrey.main,
      borderBottom: 'none',
      padding: theme.spacing(0, 0.5, 1, 0.5),
    },
    addressContainer: {
      paddingTop: theme.spacing(2),
    },
    addressText: {
      fontSize: '0.875rem',
    },
    alreadySharedBanner: {
      padding: theme.spacing(1, 2),
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.caution.light,
      fontWeight: 400,
      color: theme.palette.caution.main,
    },
  }),
);

interface PaymentMethodShareSelectionProps {
  paymentMethods: PayerPaymentMethod[] | null | undefined;
  paymentMethodRequest: PaymentMethodRequest | null | undefined;
  selectedMethod: PayerPaymentMethod | undefined;
  setSelectedMethod: (selectedMethod: PayerPaymentMethod | undefined) => void;
  disabledView: boolean;
}

const PaymentMethodShareSelection: React.FC<PaymentMethodShareSelectionProps> = props => {
  const { paymentMethods, paymentMethodRequest, selectedMethod, setSelectedMethod, disabledView } = props;
  const classes = useStyles();

  const [alreadySharedMethod, setAlreadySharedMethod] = useState<boolean>(false);

  const handlePaymentMethodSelectChange = (method: PayerPaymentMethod | undefined) => {
    const selectedPaymentMethod = method?.paymentMethod?.id === selectedMethod?.paymentMethod?.id ? undefined : method;
    setSelectedMethod(selectedPaymentMethod);
  };

  const selectedMethodAddress = selectedMethod?.paymentMethod?.creditCard?.cardHolder;

  useEffect(() => {
    const isAlreadyUsedMethod = selectedMethod
      ? paymentMethodRequest?.paymentAttempts?.find(
          attempt => attempt?.paymentMethod && attempt?.paymentMethod?.id === selectedMethod?.paymentMethod?.id,
        )
      : undefined;
    setAlreadySharedMethod(!!isAlreadyUsedMethod);
  }, [selectedMethod]);

  return (
    <Grid item xs={12}>
      <Grid container item xs={12}>
        {paymentMethods?.map((method, index) => {
          const isCard = method?.paymentMethod?.type === PaymentMethodType.CreditCard;
          const isDisabled = !isCard || method?.paymentMethod?.status !== PaymentMethodStatus.Verified;

          const isSelected = selectedMethod?.paymentMethod?.id === method?.paymentMethod?.id;
          return (
            <PaymentMethodTile
              key={index}
              method={method}
              isSelected={isSelected}
              isDisabled={isDisabled || disabledView}
              handlePaymentMethodSelectChange={handlePaymentMethodSelectChange}
              dataCy={`saved-payment-method-${index}-${isSelected ? 'selected' : 'unselected'}`}
            />
          );
        })}
      </Grid>
      {alreadySharedMethod && (
        <Grid item xs={12} className={classes.alreadySharedBanner}>
          This card has already been attempted for payment, select Save to submit or choose another card
        </Grid>
      )}
      <Grid item xs={12} hidden={!selectedMethod} className={classes.addressContainer}>
        <Table aria-label="selected method billing details" data-cy={'method-details'}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.methodDetailsText}>Name on Card</TableCell>
              <TableCell className={classes.methodDetailsText}>Expiry</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.methodDetailsRowText}>
                {selectedMethod?.paymentMethod?.creditCard?.cardHolder?.holderName}
              </TableCell>
              <TableCell
                className={classes.methodDetailsRowText}
              >{`${selectedMethod?.paymentMethod?.creditCard?.expirationMonth}/${selectedMethod?.paymentMethod?.creditCard?.expirationYear}`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table aria-label="selected method billing details" data-cy={'method-details'}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.methodDetailsText}>Billing address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.methodDetailsRowText}>
                <Grid item container direction="column">
                  <Typography className={classes.addressText}>{selectedMethodAddress?.email}</Typography>
                  <Typography className={classes.addressText}>{`${selectedMethodAddress?.address?.line1 || ''} ${
                    selectedMethodAddress?.address?.line2 || ''
                  }`}</Typography>
                  <Typography
                    className={classes.addressText}
                  >{`${selectedMethodAddress?.address?.country}, ${selectedMethodAddress?.address?.postalCode}`}</Typography>
                  {selectedMethodAddress?.phone?.number && (
                    <Typography className={classes.addressText}>{`${
                      selectedMethodAddress?.phone?.countryCode
                    } (${selectedMethodAddress?.phone?.number?.slice(0, 3)}) ${selectedMethodAddress?.phone?.number?.slice(
                      3,
                      6,
                    )}-${selectedMethodAddress?.phone?.number?.slice(-4)}`}</Typography>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default PaymentMethodShareSelection;
