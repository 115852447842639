import { Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: '#eaf9ee',
      color: '#3d9452',
      padding: theme.spacing(2),
    },
    upperHeaderText: {
      fontSize: 10,
      letterSpacing: 1.5,
      fontWeight: 500,
      color: '#3d9452',
    },
    mainHeaderText: {
      fontWeight: 500,
      color: '#3d9452',
    },
    receivedBody: {
      padding: theme.spacing(2),
    },
  }),
);

const PaymentReceived: React.FC = () => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container className={classes.header}>
        <Grid item xs={12} lg={12}>
          <Typography variant="body2" className={classes.upperHeaderText}>
            PAYMENT RECEIVED
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography className={classes.mainHeaderText}>This payment has already been completed</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.receivedBody}>
        <Grid item xs={12} lg={12}>
          <Typography variant="body2">You can safely close this window.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentReceived;
