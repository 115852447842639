import { Box, Button, Checkbox, Divider, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { CurrencyType, CustomerRole, Maybe } from '../gql-types.generated';

const useStylesMobile = makeStyles((theme: Theme) =>
  createStyles({
    bottomSummary: {
      padding: theme.spacing(1),
    },
    invoiceText: {
      marginLeft: 4,
      fontWeight: 200,
    },
    totalDueText: {
      marginLeft: 8,
    },
    hideContainer: {
      fontWeight: 'bold',
      padding: theme.spacing(1.5, 0),
      backgroundColor: '#EEF4FE',
    },
    gridContainer: {
      display: 'grid',
    },
    invoiceContainer: {
      display: 'inline-flex',
    },
  }),
);

const useStylesDesktop = makeStyles(() =>
  createStyles({
    bottomSummary: {
      padding: '32px 16px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    invoiceText: {
      marginLeft: 8,
      fontWeight: 200,
    },
    totalDueText: {
      marginLeft: 24,
    },
    payButton: {
      marginLeft: 64,
      minWidth: 140,
    },
    invoiceContainer: {
      display: 'inline-flex',
    },
  }),
);

interface PaymentsDueFooterProps {
  isMobileView: boolean;
  totalAmount: number;
  selectedInvoices: number;
  selectedInvoiceAmount: number;
  totalInvoices: number;
  consolidatedAllowed: boolean;
  partialAllowed: boolean;
  handleMakePayment: (payAll: boolean) => void;
  toggleHideUnselected: () => void;
  currency?: Maybe<CurrencyType>;
  totalPartialAmount: number;
  hideUnselected: boolean;
  customerRole: CustomerRole | undefined;
}

const PaymentsDueFooter: React.FC<PaymentsDueFooterProps> = props => {
  const {
    isMobileView,
    currency,
    consolidatedAllowed,
    partialAllowed,
    selectedInvoices,
    selectedInvoiceAmount,
    totalAmount,
    totalInvoices,
    toggleHideUnselected,
    handleMakePayment,
    totalPartialAmount,
    hideUnselected,
    customerRole,
  } = props;

  const classesMobile = useStylesMobile();
  const classesDesktop = useStylesDesktop();
  const isRestrictedRole = customerRole && customerRole === CustomerRole.CustomerReader;

  if (isMobileView) {
    return (
      <>
        {consolidatedAllowed && selectedInvoices > 0 && (
          <div className={classesMobile.gridContainer}>
            <Box className={classesMobile.hideContainer} onClick={toggleHideUnselected}>
              <Typography variant="subtitle2" color="primary">
                {hideUnselected ? 'SHOW ALL' : 'HIDE UNSELECTED'}
              </Typography>
            </Box>
            <Divider />
          </div>
        )}
        <Box className={classesMobile.bottomSummary}>
          <div className={classesMobile.invoiceContainer}>
            {selectedInvoices > 0 && (
              <>
                <Typography variant="subtitle2">{`${
                  partialAllowed && !consolidatedAllowed ? '' : selectedInvoices
                } Selected`}</Typography>
                <Typography variant="subtitle2" className={classesMobile.invoiceText}>
                  {selectedInvoices === 1 ? 'Invoice' : 'Invoices'}
                </Typography>
              </>
            )}
            {selectedInvoices < 1 && (
              <>
                <Typography variant="subtitle2">{`${totalInvoices} Total`}</Typography>
                <Typography variant="subtitle2" className={classesMobile.invoiceText}>
                  {totalInvoices < 2 ? 'Invoice' : 'Invoices'}
                </Typography>
              </>
            )}
          </div>
          <div>
            <Typography variant="title" className={classesMobile.totalDueText}>
              {partialAllowed
                ? new Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: currency || 'USD',
                    currencyDisplay: 'symbol',
                  }).format((selectedInvoices > 0 ? totalPartialAmount : totalAmount) / 100)
                : new Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: currency || 'USD',
                    currencyDisplay: 'symbol',
                  }).format((selectedInvoices > 0 ? selectedInvoiceAmount : totalAmount) / 100)}
            </Typography>
          </div>
          {consolidatedAllowed && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleMakePayment(!(selectedInvoices > 0));
              }}
              fullWidth
              disabled={isRestrictedRole}
            >
              {selectedInvoices > 0 ? 'PAY SELECTED' : 'PAY ALL'}
            </Button>
          )}
          {((!consolidatedAllowed && partialAllowed) || !(consolidatedAllowed || partialAllowed)) && selectedInvoices > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleMakePayment(!(selectedInvoices > 0));
              }}
              fullWidth
            >
              {'PAY'}
            </Button>
          )}
        </Box>
      </>
    );
  }
  return (
    <>
      <Box className={classesDesktop.bottomSummary}>
        <div>
          <div className={classesDesktop.invoiceContainer}>
            {selectedInvoices > 0 && (
              <>
                <Typography variant="h5">{`${partialAllowed && !consolidatedAllowed ? '' : selectedInvoices} Selected`}</Typography>
                <Typography variant="h5" className={classesDesktop.invoiceText}>
                  {selectedInvoices === 1 ? 'Invoice' : 'Invoices'}
                </Typography>
              </>
            )}
            {selectedInvoices < 1 && (
              <>
                <Typography variant="h5">{`${totalInvoices} Total`}</Typography>
                <Typography variant="h5" className={classesDesktop.invoiceText}>
                  {totalInvoices < 2 ? 'Invoice' : 'Invoices'}
                </Typography>
              </>
            )}
          </div>
          {consolidatedAllowed && selectedInvoices > 0 && (
            <div>
              <Checkbox color="primary" checked={hideUnselected} onClick={toggleHideUnselected} /> Hide Unselected
            </div>
          )}
        </div>
        <Typography variant="h5" className={classesDesktop.totalDueText}>
          {partialAllowed
            ? new Intl.NumberFormat('en', {
                style: 'currency',
                currency: currency || 'USD',
                currencyDisplay: 'symbol',
              }).format((selectedInvoices > 0 ? totalPartialAmount : totalAmount) / 100)
            : new Intl.NumberFormat('en', {
                style: 'currency',
                currency: currency || 'USD',
                currencyDisplay: 'symbol',
              }).format((selectedInvoices > 0 ? selectedInvoiceAmount : totalAmount) / 100)}
        </Typography>
        {consolidatedAllowed && (
          <Button
            className={classesDesktop.payButton}
            onClick={() => {
              handleMakePayment(!(selectedInvoices > 0));
            }}
            variant="contained"
            color="primary"
            disabled={totalInvoices === 0 || isRestrictedRole}
          >
            {selectedInvoices > 0 ? 'PAY SELECTED' : 'PAY ALL'}
          </Button>
        )}
        {((!consolidatedAllowed && partialAllowed) || !(consolidatedAllowed || partialAllowed)) && selectedInvoices > 0 && (
          <Button
            className={classesDesktop.payButton}
            onClick={() => {
              handleMakePayment(!(selectedInvoices > 0));
            }}
            variant="contained"
            color="primary"
            disabled={totalInvoices === 0}
          >
            {'PAY'}
          </Button>
        )}
      </Box>
    </>
  );
};

export default PaymentsDueFooter;
