import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, Grid, IconButton, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';

import { CustomerUser, DeletePersonStatus, MutationStatusCode } from '../gql-types.generated';
import LoadingMask from './LoadingMask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: theme.spacing(1, 1, 1, 2),
    },
    headerText: {
      fontSize: '16px',
      fontWeight: 500,
    },
    content: {
      padding: theme.spacing(2, 3, 2, 2),
      wordBreak: 'break-word',
    },
    dialogActionsContainer: {
      padding: theme.spacing(1, 3, 3, 3),
    },
    deleteButton: {
      color: theme.palette.getContrastText('#f44336'),
      backgroundColor: '#f44336',
      '&:hover': {
        backgroundColor: '#d32f2f',
      },
    },
  }),
);

interface RemoveCustomerUserProps {
  open: boolean;
  onClose: () => void;
  user: CustomerUser;
  handleDelete: () => void;
  isLoading: boolean;
  customerName: string;
  removeCustomerUserStatus?: DeletePersonStatus;
  reloadCustomer: () => void;
}

const RemoveCustomerUser: React.FC<RemoveCustomerUserProps> = props => {
  const { open, onClose, isLoading, customerName, handleDelete, removeCustomerUserStatus, reloadCustomer } = props;
  const classes = useStyles();

  useEffect(() => {
    if (removeCustomerUserStatus && removeCustomerUserStatus.code !== MutationStatusCode.Error) {
      reloadCustomer();
      onClose();
    }
  }, [removeCustomerUserStatus]);

  return (
    <Dialog
      aria-label={'modify user dialog'}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      maxWidth={'xs'}
      fullWidth
      disableEscapeKeyDown={isLoading}
      disableScrollLock
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box>
        <LoadingMask loading={isLoading} />
        <Box className={classes.header}>
          <Grid container>
            <Grid item container xs={10} alignContent="center">
              <Typography className={classes.headerText} id="modal-title">
                Remove User
              </Typography>
            </Grid>
            <Grid item container xs={2} justifyContent="flex-end">
              <IconButton size="small" onClick={onClose} disabled={isLoading}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.content}>{`Are you sure you want to remove this user from ${customerName}?`}</Box>

        <DialogActions className={classes.dialogActionsContainer}>
          <Button color="primary" size="small" disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            classes={{ root: classes.deleteButton }}
            disabled={isLoading}
            onClick={handleDelete}
          >
            Yes, Remove
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default RemoveCustomerUser;
