import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import {
  PaymentRequestInfo,
  DeletePaymentMethodStatus,
  UpsertPayoutSettingsStatus,
  MutationStatusCode,
  PayerPaymentMethod,
  PayerTransactionConnection,
  PayerTransaction,
  PayerProfile,
  CreatePaymentMethodStatus,
  PaymentMethodHolder,
  UpdatePaymentMethodStatus,
  CreatePaymentStatus,
  VerifyPaymentMethodStatus,
  PayerCreditMemoConnection,
  CreditMemo,
  CreditMemoUsageConnection,
  Token,
  CustomerRole,
  Payment,
  CompletePaymentMethodRequestStatus,
  CustomerPaymentMethod,
  TenantIntegrationSettings,
} from '../../gql-types.generated';
import { PaymentsDueRowData } from '../../custom-types/PaymentsDueRowData';
import { CreditMemoHistoryData } from '../../custom-types/CreditMemoHistoryData';
import { CardTypeConvenienceFee } from '../../custom-types/CardTypeConvenienceFee';

interface SliceState {
  confirmDeleteVisible: boolean;
  createPayerPaymentMethodStatus?: CreatePaymentMethodStatus;
  creditToken?: string;
  defaultPaymentMethod?: PayerPaymentMethod;
  deletePayerPaymentMethodStatus?: DeletePaymentMethodStatus;
  displayRefundPolicy: boolean;
  editingBilling: boolean;
  error?: Error;
  hasSearchString: boolean;
  payerProfile?: PayerProfile;
  payerProfileHolderInfo?: PaymentMethodHolder;
  paymentError?: Error;
  paymentAddVisible: boolean;
  paymentListVisible: boolean;
  paymentMethodForEdit?: PayerPaymentMethod;
  paymentRequestInfo?: PaymentRequestInfo;
  paymentsDue?: PayerTransaction[];
  paymentsDueConnection?: PayerTransactionConnection;
  isUpdateFromPagination: boolean;
  paymentDetails?: PayerTransaction | null;
  paymentDetailsVisible: boolean;
  paymentSettings?: PayerPaymentMethod | null;
  paymentSettingsError?: Error;
  paymentSettingsErrorMessage?: string;
  paymentSettingsSatus?: UpsertPayoutSettingsStatus;
  paymentStep: number;
  recentTransactions?: PayerTransaction[];
  recentTransactionsConnection?: PayerTransactionConnection;
  requestsInFlight: number;
  selectedPaymentMethod?: PayerPaymentMethod;
  verificationVisible: boolean;
  verifyInfoVisible: boolean;
  verifyPaymentMethodStatus?: VerifyPaymentMethodStatus;
  urlToken?: string;
  updatePaymentMethodStatus?: UpdatePaymentMethodStatus;
  createPaymentStatus?: CreatePaymentStatus;
  deletePaymentMethodError?: Error;
  billingAddresses?: PaymentMethodHolder[];
  paymentMethodError?: Error;
  paymentsDueError?: Error;
  recentPaymentsError?: Error;
  paymentMethodAdded: boolean;
  snackBarOpen: boolean;
  snackBarAlert?: string;
  snackBarAlertSeverity?: string;
  confirmationVisible: boolean;
  lastTabPath: string;
  paymentRequestInfoList?: PaymentsDueRowData[];
  paymentsDueData?: PaymentsDueRowData[];
  consolidatedOrPartialCancelClicked: boolean;
  recentPaymentDetailsVisible: boolean;
  recentPaymentDetails?: PayerTransaction | null;
  paymentDetailsIndex?: number;
  paymentDetailsSelected?: boolean;
  openCreditMemos?: CreditMemo[];
  openCreditMemosConnection?: PayerCreditMemoConnection;
  openCreditMemosError?: Error;
  creditMemoHistory?: CreditMemoHistoryData[];
  creditMemoHistoryConnection?: CreditMemoUsageConnection;
  creditMemoHistoryError?: Error;
  creditMemoBalance: number;
  creditMemoBalanceError?: Error;
  loadingCreditMemoBalance: boolean;
  creditMemoDetailsOpen: boolean;
  loadingPaymentMethods: boolean;
  deletingPaymentMethod: boolean;
  payerTransactionSummaryError?: Error;
  customerRole?: CustomerRole;
  oneTimeUsePaymentMethod?: Token;
  calculatedConvenienceFees?: CardTypeConvenienceFee;
  isCalculatedConvenienceFeesInFlight: boolean;
  guestCheckoutPaymentMethodToken?: Token;
  specificPaymentDetailRecord?: Payment;
  isSpecificPaymentDetailRecordInFlight: boolean;
  completePaymentMethodRequestError?: Error;
  completePaymentMethodRequestStatus?: CompletePaymentMethodRequestStatus;
  completePaymentMethodRequestInFlight: boolean;
  integrationSettings?: TenantIntegrationSettings;
}

const initialState: SliceState = {
  confirmDeleteVisible: false,
  displayRefundPolicy: false,
  editingBilling: false,
  hasSearchString: false,
  paymentAddVisible: false,
  paymentDetailsVisible: false,
  paymentListVisible: false,
  paymentStep: 0,
  requestsInFlight: 0,
  verificationVisible: false,
  verifyInfoVisible: false,
  paymentMethodAdded: false,
  snackBarOpen: false,
  confirmationVisible: false,
  lastTabPath: '',
  consolidatedOrPartialCancelClicked: false,
  recentPaymentDetailsVisible: false,
  creditMemoBalance: 0,
  loadingCreditMemoBalance: false,
  creditMemoDetailsOpen: false,
  loadingPaymentMethods: false,
  deletingPaymentMethod: false,
  isUpdateFromPagination: false,
  isCalculatedConvenienceFeesInFlight: false,
  isSpecificPaymentDetailRecordInFlight: false,
  completePaymentMethodRequestInFlight: false,
};

export const slice = createSlice({
  name: 'multipleMethodsHome',
  initialState,
  reducers: {
    clearPaymentRequestInfo: state => {
      // eslint-disable-next-line no-param-reassign
      state.paymentRequestInfo = undefined;
    },
    fetchPayerProfile: (state, action: PayloadAction<PayerProfile | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.payerProfile = action.payload;
    },
    fetchPaymentRequestInfoSuccess: (state, action: PayloadAction<PaymentRequestInfo>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentRequestInfo = action.payload;
    },
    fetchRecentTransactionsSuccess: (state, action: PayloadAction<PayerTransactionConnection>) => {
      // eslint-disable-next-line no-param-reassign
      state.recentTransactionsConnection = action.payload;
      // eslint-disable-next-line no-param-reassign
      let items = state.recentTransactions;

      // Initialise the array if this is the first run, or a refresh. We know its a refresh
      // when the connection contains the first page of data.
      if (!items || !state.recentTransactionsConnection.pageInfo.hasPreviousPage) {
        items = [];
      }
      action.payload.nodes.map(node => {
        if (!items?.some(i => i.paymentRequestId === node?.paymentRequestId)) {
          items?.push(node as PayerTransaction);
        }
        return node;
      });
      // eslint-disable-next-line no-param-reassign
      state.recentTransactions = items;
    },
    fetchPaymentsDueSuccess: (state, action: PayloadAction<PayerTransactionConnection>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentsDueConnection = action.payload;
      // eslint-disable-next-line no-param-reassign
      let items = state.paymentsDue;

      // Initialise the array if this is the first run, or a refresh. We know its a refresh
      // when the connection contains the first page of data.
      if (!items || !state.paymentsDueConnection.pageInfo.hasPreviousPage) {
        items = [];
      }
      action.payload.nodes.map(node => {
        if (!items?.some(i => i.paymentRequestId === node?.paymentRequestId)) {
          items?.push(node as PayerTransaction);
        }
        return node;
      });
      // eslint-disable-next-line no-param-reassign
      state.paymentsDue = items;
    },
    fetchHasSearchString: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.hasSearchString = action.payload;
    },
    fetchCreditToken: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.creditToken = action.payload;
    },
    fetchDisplayRefundPolicy: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.displayRefundPolicy = action.payload;
    },
    fetchEditingBilling: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.editingBilling = action.payload;
    },
    fetchConfirmDeleteVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.confirmDeleteVisible = action.payload;
    },
    fetchPaymentListVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentListVisible = action.payload;
    },
    fetchPaymentAddVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentAddVisible = action.payload;
    },
    fetchVerificationVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.verificationVisible = action.payload;
    },
    fetchVerifyInfoVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.verifyInfoVisible = action.payload;
    },
    incrementPaymentStep: state => {
      // eslint-disable-next-line no-param-reassign
      state.paymentStep += 1;
    },
    decrementPaymentStep: state => {
      // eslint-disable-next-line no-param-reassign
      if (state.paymentStep > 0) state.paymentStep -= 1;
    },
    fetchCreatePaymentStatus: (state, action: PayloadAction<CreatePaymentStatus | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.createPaymentStatus = action.payload;
    },
    fetchDeletePayerPaymentMethodStatus: (state, action: PayloadAction<DeletePaymentMethodStatus | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.deletePayerPaymentMethodStatus = action.payload;
    },
    fetchUpdatePaymentMethodStatus: (state, action: PayloadAction<UpdatePaymentMethodStatus | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.updatePaymentMethodStatus = action.payload;
    },
    fetchCreatePayerPaymentMethodStatus: (state, action: PayloadAction<CreatePaymentMethodStatus | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.createPayerPaymentMethodStatus = action.payload;
    },
    fetchVerifyPaymentMethodStatus: (state, action: PayloadAction<VerifyPaymentMethodStatus | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.verifyPaymentMethodStatus = action.payload;
    },
    fetchPaymentStep: (state, action: PayloadAction<number>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentStep = action.payload;
    },
    fetchPaymentError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentError = action.payload;
    },
    fetchSelectedPaymentMethod: (state, action: PayloadAction<PayerPaymentMethod | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedPaymentMethod = action.payload;
    },
    fetchPaymentMethodForEdit: (state, action: PayloadAction<PayerPaymentMethod | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentMethodForEdit = action.payload;
    },
    fetchError: (state, action: PayloadAction<Error>) => {
      // eslint-disable-next-line no-param-reassign
      state.error = action.payload;
    },
    incrementRequestsInFlight: state => {
      // eslint-disable-next-line no-param-reassign
      state.requestsInFlight += 1;
    },
    decrementRequestsInFlight: state => {
      // eslint-disable-next-line no-param-reassign
      if (state.requestsInFlight > 0) state.requestsInFlight -= 1;
    },
    fetchPaymentSettingsError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentSettingsError = action.payload;
    },
    fetchPaymentSettingsTokenErrorMessage: (state, action: PayloadAction<string | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentSettingsErrorMessage = action.payload;
    },
    fetchPaymentSettingsStatus: (state, action: PayloadAction<UpsertPayoutSettingsStatus>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentSettingsSatus = action.payload;
    },
    fetchPaymentSettings: (state, action: PayloadAction<PayerPaymentMethod | null | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentSettings = action.payload;
    },
    fetchUrlToken: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.urlToken = action.payload;
    },
    fetchDeletePaymentMethodError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.deletePaymentMethodError = action.payload;
    },
    fetchPaymentDetailsVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentDetailsVisible = action.payload;
    },
    fetchPaymentDetails: (state, action: PayloadAction<PayerTransaction | null | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentDetails = action.payload;
    },
    fetchBillingAddresses: (state, action: PayloadAction<PaymentMethodHolder[] | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.billingAddresses = action.payload;
    },
    addBillingAddress: (state, action: PayloadAction<PaymentMethodHolder>) => {
      if (!state.billingAddresses) {
        // eslint-disable-next-line no-param-reassign
        state.billingAddresses = [action.payload];
      } else {
        // eslint-disable-next-line no-param-reassign
        state.billingAddresses.push(action.payload);
      }
    },
    fetchPaymentMethodError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentMethodError = action.payload;
    },
    fetchPaymentsDueError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentsDueError = action.payload;
    },
    fetchRecentPaymentsError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.recentPaymentsError = action.payload;
    },
    fetchPaymentMethodAdded: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentMethodAdded = action.payload;
    },
    fetchSnackBarOpen: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.snackBarOpen = action.payload;
    },
    fetchSnackBarAlert: (state, action: PayloadAction<string | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.snackBarAlert = action.payload;
    },
    fetchSnackBarAlertSeverity: (state, action: PayloadAction<string | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.snackBarAlertSeverity = action.payload;
    },
    fetchConfirmationVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.confirmationVisible = action.payload;
    },
    fetchLastTabPath: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.lastTabPath = action.payload;
    },
    clearPaymentsDueConnection: state => {
      // eslint-disable-next-line no-param-reassign
      state.paymentsDueConnection = undefined;
    },
    clearPaymentsDue: state => {
      // eslint-disable-next-line no-param-reassign
      state.paymentsDue = undefined;
    },
    clearRecentPaymentsConnection: state => {
      // eslint-disable-next-line no-param-reassign
      state.recentTransactionsConnection = undefined;
    },
    clearRecentPayments: state => {
      // eslint-disable-next-line no-param-reassign
      state.recentTransactions = undefined;
    },
    fetchPaymentRequestInfoList: (state, action: PayloadAction<PaymentsDueRowData[] | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentRequestInfoList = action.payload;
    },
    fetchPaymentsDueData: (state, action: PayloadAction<PaymentsDueRowData[] | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentsDueData = action.payload;
    },
    fetchConsolidatedOrPartialCancelClicked: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.consolidatedOrPartialCancelClicked = action.payload;
    },
    fetchRecentPaymentDetailsVisible: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.recentPaymentDetailsVisible = action.payload;
    },
    fetchRecentPaymentDetails: (state, action: PayloadAction<PayerTransaction | null | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.recentPaymentDetails = action.payload;
    },
    fetchPaymentDetailsIndex: (state, action: PayloadAction<number | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentDetailsIndex = action.payload;
    },
    fetchPaymentDetailsSelected: (state, action: PayloadAction<boolean | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.paymentDetailsSelected = action.payload;
    },
    fetchOpenCreditMemoSuccess: (state, action: PayloadAction<PayerCreditMemoConnection>) => {
      if (state.creditMemoDetailsOpen) {
        // eslint-disable-next-line no-param-reassign
        state.openCreditMemosConnection = action.payload;
        // eslint-disable-next-line no-param-reassign
        let items = state.openCreditMemos;

        // Initialise the array if this is the first run, or a refresh. We know its a refresh
        // when the connection contains the first page of data.
        if (!items || !state.openCreditMemosConnection.pageInfo.hasPreviousPage) {
          items = [];
        }
        action.payload.nodes.map(node => {
          if (!items?.some(i => i.id === node?.id)) {
            items?.push(node as CreditMemo);
          }
          return node;
        });
        // eslint-disable-next-line no-param-reassign
        state.openCreditMemos = items;
      }
    },
    fetchOpenCreditMemosError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.openCreditMemosError = action.payload;
    },
    captureCreditMemoBalance: (state, action: PayloadAction<PayerCreditMemoConnection>) => {
      // eslint-disable-next-line no-param-reassign
      state.creditMemoBalance = action.payload.totalBalance;
    },
    clearCreditMemoBalance: state => {
      // eslint-disable-next-line no-param-reassign
      state.creditMemoBalance = 0;
    },
    fetchCreditMemoBalanceError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.creditMemoBalanceError = action.payload;
    },
    fetchLoadingCreditMemoBalance: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.loadingCreditMemoBalance = action.payload;
    },
    clearOpenCreditMemoConnection: state => {
      // eslint-disable-next-line no-param-reassign
      state.openCreditMemosConnection = undefined;
    },
    clearOpenCreditMemos: state => {
      // eslint-disable-next-line no-param-reassign
      state.openCreditMemos = undefined;
    },
    fetchCreditMemoDetailsOpen: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.creditMemoDetailsOpen = action.payload;
    },
    fetchCreditMemoHistorySuccess: (state, action: PayloadAction<CreditMemoUsageConnection>) => {
      if (state.creditMemoDetailsOpen) {
        // eslint-disable-next-line no-param-reassign
        state.creditMemoHistoryConnection = action.payload;
        // eslint-disable-next-line no-param-reassign
        let items = state.creditMemoHistory;

        // Initialise the array if this is the first run, or a refresh. We know its a refresh
        // when the connection contains the first page of data.
        if (!items || !state.creditMemoHistoryConnection.pageInfo.hasPreviousPage) {
          items = [];
        }
        action.payload.nodes.map(node => {
          if (!items?.some(i => i.id.indexOf(`${node?.creditMemoReferenceNumber || ''}-${node?.paymentId}`) > -1)) {
            if (node?.paymentRequestCreditAllocation && node?.paymentRequestCreditAllocation.length > 0) {
              node?.paymentRequestCreditAllocation?.forEach(record => {
                const data: CreditMemoHistoryData = {
                  id: `${node?.creditMemoReferenceNumber || ''}-${node?.paymentId || ''}-${record?.paymentRequestId}`,
                };
                data.amountUsed = record?.amount;
                data.creditMemoReference = node?.creditMemoReferenceNumber;
                data.dateUsed = node?.createdAt;
                data.paymentRequestReference = record?.paymentRequestReferenceNumber;

                items?.push(data);
              });
            } else {
              const data: CreditMemoHistoryData = { id: `${node?.creditMemoReferenceNumber || ''}-${node?.paymentId}` };
              data.amountUsed = node?.amount;
              data.creditMemoReference = node?.creditMemoReferenceNumber;
              data.dateUsed = node?.createdAt;
              data.paymentRequestReference = '';

              items?.push(data);
            }
          }
          return node;
        });
        // eslint-disable-next-line no-param-reassign
        state.creditMemoHistory = items;
      }
    },
    fetchCreditMemoHistoryError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.creditMemoHistoryError = action.payload;
    },
    clearCreditMemoHistoryConnection: state => {
      // eslint-disable-next-line no-param-reassign
      state.creditMemoHistoryConnection = undefined;
    },
    clearCreditMemoHistory: state => {
      // eslint-disable-next-line no-param-reassign
      state.creditMemoHistory = undefined;
    },
    fetchLoadingPaymentMethods: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.loadingPaymentMethods = action.payload;
    },
    fetchDeletingPaymentMethod: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.deletingPaymentMethod = action.payload;
    },
    fetchPayerTransactionSummaryError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.payerTransactionSummaryError = action.payload;
    },
    fetchCustomerRole: (state, action: PayloadAction<CustomerRole | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.customerRole = action.payload;
    },
    fetchOneTimeUsePaymentMethod: (state, action: PayloadAction<Token | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.oneTimeUsePaymentMethod = action.payload;
    },
    clearRecentTransactions: state => {
      // eslint-disable-next-line no-param-reassign
      state.recentTransactions = undefined;
    },
    captureUpdateFromPagination: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isUpdateFromPagination = action.payload;
    },
    captureCalculatedConvenienceFees: (state, action: PayloadAction<CardTypeConvenienceFee | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.calculatedConvenienceFees = action.payload;
    },
    captureIsCalculatedConvenienceFeesInFlight: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isCalculatedConvenienceFeesInFlight = action.payload;
    },
    fetchGuestCheckoutPaymentMethodToken: (state, action: PayloadAction<Token | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.guestCheckoutPaymentMethodToken = action.payload;
    },
    captureSpecificPaymentDetailRecord: (state, action: PayloadAction<Payment | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.specificPaymentDetailRecord = action.payload;
    },
    captureIsSpecificPaymentDetailRecordInFlight: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isSpecificPaymentDetailRecordInFlight = action.payload;
    },
    fetchCompletePaymentMethodRequestError: (state, action: PayloadAction<Error | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.completePaymentMethodRequestError = action.payload;
    },
    fetchCompletePaymentMethodRequestStatus: (state, action: PayloadAction<CompletePaymentMethodRequestStatus | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.completePaymentMethodRequestStatus = action.payload;
    },
    fetchCompletePaymentMethodRequestInFlight: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.completePaymentMethodRequestInFlight = action.payload;
    },
    fetchTenantIntegrationSettings: (state, action: PayloadAction<TenantIntegrationSettings | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.integrationSettings = action.payload;
    },
  },
});

export const selectError = (state: RootState): Error | undefined => state.multipleMethodsHome.error;
export const selectPaymentError = (state: RootState): Error | undefined => state.multipleMethodsHome.paymentError;
export const selectPaymentRequestInfo = (state: RootState): PaymentRequestInfo | undefined =>
  state.multipleMethodsHome.paymentRequestInfo;
export const selectRequestsInFlight = (state: RootState): number => state.multipleMethodsHome.requestsInFlight;
export const selectNetworkBusy = (state: RootState): boolean => state.multipleMethodsHome.requestsInFlight > 0;
export const selectPaymentStep = (state: RootState): number => state.multipleMethodsHome.paymentStep;
export const selectCreditToken = (state: RootState): string | undefined => state.multipleMethodsHome.creditToken;
export const selectDisplayRefundPolicy = (state: RootState): boolean => state.multipleMethodsHome.displayRefundPolicy;
export const selectCreatePaymentStatus = (state: RootState): CreatePaymentStatus | undefined =>
  state.multipleMethodsHome.createPaymentStatus;
export const selectHasSearchString = (state: RootState): boolean | undefined => state.multipleMethodsHome.hasSearchString;
export const selectEditingBilling = (state: RootState): boolean => state.multipleMethodsHome.editingBilling;
export const selectConfirmDeleteVisible = (state: RootState): boolean => state.multipleMethodsHome.confirmDeleteVisible;
export const selectPaymentAddVisible = (state: RootState): boolean => state.multipleMethodsHome.paymentAddVisible;
export const selectPaymentDetailsVisible = (state: RootState): boolean => state.multipleMethodsHome.paymentDetailsVisible;
export const selectPaymentListVisible = (state: RootState): boolean => state.multipleMethodsHome.paymentListVisible;
export const selectVerificationVisible = (state: RootState): boolean => state.multipleMethodsHome.verificationVisible;
export const selectVerifyInfoVisible = (state: RootState): boolean => state.multipleMethodsHome.verifyInfoVisible;
export const selectPaymentSettingsTokenError = (state: RootState): Error | undefined => {
  if (state.multipleMethodsHome.paymentSettingsErrorMessage) {
    return { message: state.multipleMethodsHome.paymentSettingsErrorMessage } as Error;
  }
  if (state.multipleMethodsHome.paymentSettingsSatus?.code === MutationStatusCode.Error) {
    // Force error state if a status is an error - FetchStatusPayload and queryStatus should bring over the pull payload status with error message if possible.
    return { message: state.multipleMethodsHome.paymentSettingsSatus?.error || '' } as Error;
  }
  return undefined;
};
export const selectPaymentSettings = (state: RootState): PayerPaymentMethod | null | undefined =>
  state.multipleMethodsHome.paymentSettings;
export const selectPaymentSettingsStatus = (state: RootState): UpsertPayoutSettingsStatus | undefined =>
  state.multipleMethodsHome.paymentSettingsSatus;
export const selectDeletePayerPaymentMethodStatus = (state: RootState): DeletePaymentMethodStatus | undefined =>
  state.multipleMethodsHome.deletePayerPaymentMethodStatus;
export const selectCreatePayerPaymentMethodStatus = (state: RootState): CreatePaymentMethodStatus | undefined =>
  state.multipleMethodsHome.createPayerPaymentMethodStatus;
export const selectVerifyPaymentMethodStatus = (state: RootState): VerifyPaymentMethodStatus | undefined =>
  state.multipleMethodsHome.verifyPaymentMethodStatus;
export const selectPaymentSettingsError = (state: RootState): Error | undefined => state.multipleMethodsHome.paymentSettingsError;
export const selectPaymentSettingsStatusSuccess = (state: RootState): boolean | undefined =>
  state.multipleMethodsHome.paymentSettingsSatus?.code === MutationStatusCode.Success;
export const selectErrorMessage = (state: RootState): string | undefined => state.multipleMethodsHome.paymentSettingsErrorMessage;
export const selectRecentTransactions = (state: RootState): PayerTransaction[] | undefined =>
  state.multipleMethodsHome.recentTransactions;
export const selectRecentTransactionsConnection = (state: RootState): PayerTransactionConnection | undefined =>
  state.multipleMethodsHome.recentTransactionsConnection;
export const selectPaymentsDue = (state: RootState): PayerTransaction[] | undefined => state.multipleMethodsHome.paymentsDue;
export const selectPaymentsDueConnection = (state: RootState): PayerTransactionConnection | undefined =>
  state.multipleMethodsHome.paymentsDueConnection;
export const selectUrlToken = (state: RootState): string | undefined => state.multipleMethodsHome.urlToken;
export const selectPayerProfile = (state: RootState): PayerProfile | undefined => state.multipleMethodsHome.payerProfile;
export const selectPaymentMethods = (state: RootState): PayerPaymentMethod[] | null | undefined => {
  return state.multipleMethodsHome.payerProfile?.paymentMethods;
};
export const selectDefaultPaymentMethod = (state: RootState): PayerPaymentMethod | null | undefined => {
  return state.multipleMethodsHome.payerProfile?.paymentMethods?.find(paymentMethod => {
    return !!paymentMethod?.isDefault;
  });
};
export const selectPaymentMethodForEdit = (state: RootState): PayerPaymentMethod | undefined =>
  state.multipleMethodsHome.paymentMethodForEdit;
export const selectSelectedPaymentMethod = (state: RootState): PayerPaymentMethod | null | undefined =>
  state.multipleMethodsHome.selectedPaymentMethod;
export const selectDeletePaymentMethodError = (state: RootState): Error | undefined =>
  state.multipleMethodsHome.deletePaymentMethodError;
export const selectPaymentDetails = (state: RootState): PayerTransaction | null | undefined => {
  return state.multipleMethodsHome.paymentDetails;
};
export const selectBillingAddresses = (state: RootState): PaymentMethodHolder[] | null | undefined => {
  return state.multipleMethodsHome.billingAddresses;
};
export const selectPaymentMethodError = (state: RootState): Error | undefined => state.multipleMethodsHome.paymentMethodError;
export const selectPaymentsDueError = (state: RootState): Error | undefined => state.multipleMethodsHome.paymentsDueError;
export const selectRecentPaymentsError = (state: RootState): Error | undefined => state.multipleMethodsHome.recentPaymentsError;
export const selectPaymentMethodAdded = (state: RootState): boolean => state.multipleMethodsHome.paymentMethodAdded;
export const selectSnackBarOpen = (state: RootState): boolean => state.multipleMethodsHome.snackBarOpen;
export const selectSnackBarAlert = (state: RootState): string | undefined => state.multipleMethodsHome.snackBarAlert;
export const selectSnackBarAlertSeverity = (state: RootState): string | undefined => state.multipleMethodsHome.snackBarAlertSeverity;
export const selectConfirmationVisible = (state: RootState): boolean => state.multipleMethodsHome.confirmationVisible;
export const selectLastTabPath = (state: RootState): string => state.multipleMethodsHome.lastTabPath;
export const selectPaymentRequestInfoList = (state: RootState): PaymentsDueRowData[] | undefined =>
  state.multipleMethodsHome.paymentRequestInfoList;
export const selectPaymentsDueData = (state: RootState): PaymentsDueRowData[] | undefined => state.multipleMethodsHome.paymentsDueData;
export const selectConsolidatedOrPartialCancelClicked = (state: RootState): boolean =>
  state.multipleMethodsHome.consolidatedOrPartialCancelClicked;
export const selectRecentPaymentDetailsVisible = (state: RootState): boolean => state.multipleMethodsHome.recentPaymentDetailsVisible;
export const selectRecentPaymentDetails = (state: RootState): PayerTransaction | null | undefined => {
  return state.multipleMethodsHome.recentPaymentDetails;
};
export const selectPaymentDetailsIndex = (state: RootState): number | undefined => {
  return state.multipleMethodsHome.paymentDetailsIndex;
};
export const selectPaymentDetailsSelected = (state: RootState): boolean | undefined => {
  return state.multipleMethodsHome.paymentDetailsSelected;
};
export const selectOpenCreditMemosError = (state: RootState): Error | undefined => state.multipleMethodsHome.openCreditMemosError;
export const selectCreditMemoBalance = (state: RootState): number => state.multipleMethodsHome.creditMemoBalance;
export const selectCreditMemoBalanceError = (state: RootState): Error | undefined => state.multipleMethodsHome.creditMemoBalanceError;
export const selectLoadingCreditMemoBalance = (state: RootState): boolean => state.multipleMethodsHome.loadingCreditMemoBalance;
export const selectOpenCreditMemoConnection = (state: RootState): PayerCreditMemoConnection | undefined =>
  state.multipleMethodsHome.openCreditMemosConnection;
export const selectOpenCreditMemos = (state: RootState): CreditMemo[] | undefined => state.multipleMethodsHome.openCreditMemos;
export const selectCreditMemoDetailsOpen = (state: RootState): boolean => state.multipleMethodsHome.creditMemoDetailsOpen;
export const selectCreditMemoHistoryError = (state: RootState): Error | undefined => state.multipleMethodsHome.creditMemoHistoryError;
export const selectCreditMemoHistoryConnection = (state: RootState): CreditMemoUsageConnection | undefined =>
  state.multipleMethodsHome.creditMemoHistoryConnection;
export const selectCreditMemoHistory = (state: RootState): CreditMemoHistoryData[] | undefined =>
  state.multipleMethodsHome.creditMemoHistory;
export const selectLoadingPaymentMethods = (state: RootState): boolean => state.multipleMethodsHome.loadingPaymentMethods;
export const selectDeletingPaymentMethod = (state: RootState): boolean => state.multipleMethodsHome.deletingPaymentMethod;
export const selectSortedPaymentMethods = (state: RootState): PayerPaymentMethod[] | null | undefined => {
  const methods = state.multipleMethodsHome.payerProfile?.paymentMethods;
  if (methods && methods.length > 1) {
    let defaultIndex = 0;
    let defaultMethod: PayerPaymentMethod | undefined;
    methods.forEach((paymentMethod, index) => {
      if (paymentMethod?.isDefault === true) {
        defaultIndex = index;
        defaultMethod = paymentMethod;
      }
    });

    if (defaultIndex > 0 && defaultMethod) {
      return [...methods.slice(defaultIndex, defaultIndex + 1), ...methods.slice(0, defaultIndex), ...methods.slice(defaultIndex + 1)];
    }
  }
  return methods;
};
export const selectPayerTransactionSummaryError = (state: RootState): Error | undefined =>
  state.multipleMethodsHome.payerTransactionSummaryError;
export const selectCustomerRole = (state: RootState): CustomerRole | undefined => state.multipleMethodsHome.customerRole;
export const selectOneTimeUsePaymentMethod = (state: RootState): Token | undefined =>
  state.multipleMethodsHome.oneTimeUsePaymentMethod;
export const selectUpdateFromPagination = (state: RootState): boolean => state.multipleMethodsHome.isUpdateFromPagination;
export const selectCalculatedConvenienceFees = (state: RootState): CardTypeConvenienceFee | undefined =>
  state.multipleMethodsHome.calculatedConvenienceFees;
export const selectIsCalculatedConvenienceFeesInFlight = (state: RootState): boolean =>
  state.multipleMethodsHome.isCalculatedConvenienceFeesInFlight;
export const selectGuestCheckoutPaymentMethodToken = (state: RootState): Token | undefined =>
  state.multipleMethodsHome.guestCheckoutPaymentMethodToken;
export const selectIsSpecificPaymentDetailRecordInFlight = (state: RootState): boolean =>
  state.multipleMethodsHome.isSpecificPaymentDetailRecordInFlight;
export const selectSpecificPaymentDetailRecord = (state: RootState): Payment | undefined =>
  state.multipleMethodsHome.specificPaymentDetailRecord;
export const selectCompletePaymentMethodRequestError = (state: RootState): Error | undefined =>
  state.multipleMethodsHome.completePaymentMethodRequestError;
export const selectCompletePaymentMethodRequestStatus = (state: RootState): CompletePaymentMethodRequestStatus | undefined =>
  state.multipleMethodsHome.completePaymentMethodRequestStatus;
export const selectCompletePaymentMethodRequestInFlight = (state: RootState): boolean =>
  state.multipleMethodsHome.completePaymentMethodRequestInFlight;
export const selectSortedCustomerPaymentMethods = (state: RootState): CustomerPaymentMethod[] | null | undefined => {
  return state.manageUsers.customer?.payerProfile?.paymentMethods;
};
export const selectCustomerMethodsAsPayerMethods = (state: RootState): PayerPaymentMethod[] | null | undefined => {
  const customerMethods = state.manageUsers.customer?.payerProfile?.paymentMethods;
  const methods: PayerPaymentMethod[] = [];
  customerMethods?.forEach(method => {
    methods.push({ isDefault: method.isDefault, paymentMethod: method.paymentMethod });
  });
  if (methods && methods.length > 1) {
    let defaultIndex = 0;
    let defaultMethod: PayerPaymentMethod | undefined;
    methods.forEach((paymentMethod, index) => {
      if (paymentMethod?.isDefault === true) {
        defaultIndex = index;
        defaultMethod = paymentMethod;
      }
    });

    if (defaultIndex > 0 && defaultMethod) {
      return [...methods.slice(defaultIndex, defaultIndex + 1), ...methods.slice(0, defaultIndex), ...methods.slice(defaultIndex + 1)];
    }
  }
  return methods;
};
export const selectTenantIntegrationSettings = (state: RootState): TenantIntegrationSettings | undefined => {
  return state.multipleMethodsHome.integrationSettings;
};

export const {
  fetchError,
  fetchPayerProfile,
  fetchPaymentError,
  fetchCreditToken,
  fetchUpdatePaymentMethodStatus,
  fetchCreatePaymentStatus,
  fetchDeletePayerPaymentMethodStatus,
  fetchCreatePayerPaymentMethodStatus,
  fetchVerifyPaymentMethodStatus,
  fetchPaymentRequestInfoSuccess,
  clearPaymentRequestInfo,
  fetchRecentTransactionsSuccess,
  fetchPaymentsDueSuccess,
  incrementRequestsInFlight,
  decrementRequestsInFlight,
  incrementPaymentStep,
  decrementPaymentStep,
  fetchPaymentStep,
  fetchDisplayRefundPolicy,
  fetchHasSearchString,
  fetchEditingBilling,
  fetchConfirmDeleteVisible,
  fetchPaymentAddVisible,
  fetchPaymentDetailsVisible,
  fetchPaymentListVisible,
  fetchVerificationVisible,
  fetchVerifyInfoVisible,
  fetchPaymentSettingsTokenErrorMessage,
  fetchPaymentSettingsStatus,
  fetchPaymentSettings,
  fetchPaymentSettingsError,
  fetchUrlToken,
  fetchPaymentMethodForEdit,
  fetchSelectedPaymentMethod,
  fetchDeletePaymentMethodError,
  fetchPaymentDetails,
  fetchBillingAddresses,
  addBillingAddress,
  fetchPaymentMethodError,
  fetchPaymentsDueError,
  fetchRecentPaymentsError,
  fetchPaymentMethodAdded,
  fetchSnackBarOpen,
  fetchSnackBarAlert,
  fetchSnackBarAlertSeverity,
  fetchConfirmationVisible,
  fetchLastTabPath,
  clearPaymentsDueConnection,
  clearPaymentsDue,
  clearRecentPayments,
  clearRecentPaymentsConnection,
  fetchPaymentRequestInfoList,
  fetchPaymentsDueData,
  fetchConsolidatedOrPartialCancelClicked,
  fetchRecentPaymentDetailsVisible,
  fetchRecentPaymentDetails,
  fetchPaymentDetailsIndex,
  fetchPaymentDetailsSelected,
  fetchOpenCreditMemoSuccess,
  fetchOpenCreditMemosError,
  fetchCreditMemoBalanceError,
  captureCreditMemoBalance,
  clearCreditMemoBalance,
  fetchLoadingCreditMemoBalance,
  clearOpenCreditMemoConnection,
  clearOpenCreditMemos,
  fetchCreditMemoDetailsOpen,
  fetchCreditMemoHistorySuccess,
  fetchCreditMemoHistoryError,
  clearCreditMemoHistory,
  clearCreditMemoHistoryConnection,
  fetchLoadingPaymentMethods,
  fetchDeletingPaymentMethod,
  fetchPayerTransactionSummaryError,
  fetchCustomerRole,
  fetchOneTimeUsePaymentMethod,
  clearRecentTransactions,
  captureUpdateFromPagination,
  captureCalculatedConvenienceFees,
  captureIsCalculatedConvenienceFeesInFlight,
  fetchGuestCheckoutPaymentMethodToken,
  captureIsSpecificPaymentDetailRecordInFlight,
  captureSpecificPaymentDetailRecord,
  fetchCompletePaymentMethodRequestError,
  fetchCompletePaymentMethodRequestStatus,
  fetchCompletePaymentMethodRequestInFlight,
  fetchTenantIntegrationSettings,
} = slice.actions;

export default slice.reducer;
