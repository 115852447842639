import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { VerifyPaymentMethodStatus } from '../gql-types.generated';
import { getStandardHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const VERIFY_PAYMENT_METHOD = gql`
  mutation verifyPaymentMethod($input: VerifyPaymentMethodInput!) {
    verifyPaymentMethod(input: $input) {
      code
      message
      error
    }
  }
`;

export const verifyPaymentMethod = async (
  client: ApolloClient<NormalizedCacheObject>,
  options: {
    paymentMethodId: string | undefined;
    microDeposits: number[];
  },
): Promise<VerifyPaymentMethodStatus | undefined> => {
  const headers = await getStandardHeadersWithAuth();
  const { data, errors } = await client.mutate<{ verifyPaymentMethod: VerifyPaymentMethodStatus }>({
    variables: { input: options },
    mutation: VERIFY_PAYMENT_METHOD,
    context: {
      headers,
    },
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the person.
    throw new Error('An error has occurred during the verify payment method.');
  }

  if (data && data.verifyPaymentMethod) {
    return data.verifyPaymentMethod;
  }

  return undefined;
};
