import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { CustomerRole, UpsertPersonStatus } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CUSTOMER_USER = gql`
  mutation upsertCustomerUser($input: UpsertCustomerUserInput!) {
    upsertCustomerUser(input: $input) {
      code
      message
      error
    }
  }
`;

export const mutationUpsertCustomerUser = async (
  client: ApolloClient<NormalizedCacheObject>,
  tenantId: string,
  customerId: string,
  email: string,
  role: CustomerRole,
  sendEmail: boolean,
): Promise<UpsertPersonStatus | undefined> => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.mutate<UpsertPersonStatus>({
    variables: { input: { customerId, email, role, sendEmail } },
    mutation: MUTATION_UPSERT_CUSTOMER_USER,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the person.
    throw new Error('An error has occurred during creation of user.');
  }

  if (data) {
    return data;
  }

  return undefined;
};
