import AddIcon from '@mui/icons-material/Add';
import {
  alpha,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import { PaymentMethodStatusData } from '../features/multipleMethodsHome/MultipleMethodsHome';
import {
  Address,
  CustomerRole,
  MerchantInfo,
  PayerPaymentMethod,
  PayFac,
  PaymentMethodHolder,
  PaymentMethodHolderInput,
  PaymentMethodRequest,
  PaymentMethodRequestRestriction,
  PaymentMethodRequestStatus,
  PaymentMethodType,
  Person,
  Token,
} from '../gql-types.generated';
import { checkIsFullNameValid } from '../util/Validators';
import { submitPaymentMethod } from '../util/WePay';
import AlertBanner from './AlertBanner';
import BillingEdit from './BillingEdit';
import CardInfo from './CardInfo';
import LoadingMask from './LoadingMask';
import PaymentMethodEdit from './PaymentMethodEdit';
import { MAX_CARDS } from './PaymentMethods';
import PaymentMethodShareSelection from './PaymentMethodShareSelection';
import InfoIcon from '@mui/icons-material/Info';
import {
  ApteanPaySDK,
  ApteanPaySDKBankComponent,
  ApteanPaySDKCardComponent,
  ApteanPaySDKCreateTokenData,
  ApteanPaySDKError,
  ApteanPaySDKToken,
} from '../util/ApteanPay';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoadingPaymentMethods, selectTenantIntegrationSettings } from '../features/multipleMethodsHome/MultipleMethodsHomeSlice';

interface PaymentMethodAddProps {
  isAddBankClicked: boolean;
  paymentAddVisible: boolean;
  cancel: () => void;
  continueText: string;
  isReplace: boolean;
  onMobile: boolean;
  payerProfileHolderInfo?: PaymentMethodHolder | null;
  selectedPaymentMethod?: PayerPaymentMethod;
  submit: (
    type: PaymentMethodType,
    token: string,
    attachToResourceId: string | undefined,
    holder: PaymentMethodHolderInput,
    isDefault: boolean,
    isWalletCard: boolean,
    merchantTenantId: string,
    shareWithMerchant: boolean,
  ) => void;
  viewerUser: Person | undefined;
  paymentMethods: PayerPaymentMethod[] | null | undefined;
  addPaymentMethodType?: PaymentMethodType;
  billingAddresses?: PaymentMethodHolder[] | null;
  addBillingAddress?: (billingAddress: PaymentMethodHolder) => void;
  paymentMethodAddStep: number;
  setPaymentMethodAddStep: (step: number) => void;
  isIFrameVisible: boolean;
  setIsIFrameVisible: (isIFrameVisible: boolean) => void;
  loadingPaymentMethods: boolean;
  addPaymentMethodError?: Error;
  clearAddPaymentMethodError?: () => void;
  customerRole: CustomerRole | undefined;
  isPaymentScreen: boolean;
  oneTimeUsePaymentMethod?: Token;
  customerId?: string;
  selectedMerchant?: string;
  paymentMethodRequest?: PaymentMethodRequest;
  selectedMerchantInfo?: MerchantInfo;
  completePaymentMethodRequestInFlight: boolean;
  completePaymentMethodRequest: (tenantId: string, id: string, paymentMethodId: string, customerId: string) => void;
  completePaymentMethodRequestError?: Error;
  clearCompletePaymentMethodRequestError?: () => void;
  sharedCards?: PayerPaymentMethod[];
  submitSDKPaymentMethod: (
    token: ApteanPaySDKToken,
    attachToResourceId: string | undefined,
    isDefault: boolean,
    isWalletCard: boolean,
    merchantTenantId: string,
    shareWithMerchant: boolean,
  ) => void;
  payfac?: PayFac;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioLabel: {
      paddingTop: theme.spacing(1.5),
    },
    buttonContainer: {
      padding: theme.spacing(1),
      width: '100%',
    },
    fieldsetContent: {
      minWidth: 400,
      width: '100%',
    },
    padding: {
      padding: theme.spacing(1),
    },
    doubleHorizontalPadding: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    bottomPadding: {
      paddingBottom: '8px',
    },
    displayNone: {
      display: 'none',
    },
    radio: {
      alignSelf: 'flex-start',
      paddingTop: theme.spacing(0.5),
    },
    addressFont: {
      fontSize: '13px',
    },
    numberFont: {
      fontSize: '12px',
    },
    dialogPadding: {
      padding: theme.spacing(0, 2, 1),
    },
    noPadding: {
      padding: 0,
    },
    label: {
      wordBreak: 'break-word',
    },
    infoText: {
      color: theme.palette.primary.main,
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
    },
    noneDisplay: {
      display: 'none',
    },
    headerContainer: {
      padding: theme.spacing(1, 1, 2, 2),
    },
    headerTopBorder: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      borderTop: `solid 1px ${theme.palette.uxGrey.border}`,
    },
    shareSelection: {
      padding: theme.spacing(0, 2, 1, 3),
    },
    shareSelectionBorder: {
      borderRight: `solid 1px ${theme.palette.uxGrey.border}`,
    },
    disabledView: {
      opacity: 0.5,
    },
    infoIcon: {
      fontSize: '18px',
      verticalAlign: 'text-bottom',
      marginRight: '5px',
    },
    infoTextContainer: {
      padding: theme.spacing(1.5, 1, 0, 1.5),
    },
  }),
);
const PaymentMethodAdd: React.FC<PaymentMethodAddProps> = props => {
  const classes = useStyles();
  const {
    isAddBankClicked,
    paymentAddVisible,
    cancel,
    onMobile,
    viewerUser,
    submit,
    addPaymentMethodType,
    payerProfileHolderInfo,
    billingAddresses,
    addBillingAddress,
    paymentMethodAddStep,
    setPaymentMethodAddStep,
    paymentMethods,
    isIFrameVisible,
    setIsIFrameVisible,
    loadingPaymentMethods,
    addPaymentMethodError,
    clearAddPaymentMethodError,
    customerRole,
    isPaymentScreen,
    oneTimeUsePaymentMethod,
    customerId,
    selectedMerchant,
    paymentMethodRequest,
    selectedMerchantInfo,
    completePaymentMethodRequestInFlight,
    completePaymentMethodRequest,
    completePaymentMethodRequestError,
    clearCompletePaymentMethodRequestError,
    sharedCards,
    submitSDKPaymentMethod,
    payfac,
  } = props;

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const integrationSettings = useSelector(selectTenantIntegrationSettings);

  const isCustomerPayer = customerRole && customerRole === CustomerRole.CustomerPayer;
  const cardAmount = paymentMethods?.filter(method => method.paymentMethod?.type === PaymentMethodType.CreditCard).length || 0;
  const addingCard = addPaymentMethodType === PaymentMethodType.CreditCard;
  const addingBank = addPaymentMethodType === PaymentMethodType.PaymentBankUs;
  const allowCardSave = addingCard && (paymentMethods?.length || 0) < MAX_CARDS;
  const mustShareMethod =
    !isPaymentScreen &&
    !!customerId &&
    !!paymentMethodRequest &&
    paymentMethodRequest.status === PaymentMethodRequestStatus.Pending &&
    addingCard;
  const canShareMethod =
    paymentMethodRequest?.restriction === PaymentMethodRequestRestriction.AddOrSelectExisting ||
    paymentMethodRequest?.restriction === PaymentMethodRequestRestriction.AddOrSelectExistingExcludingAttempted;

  const haveSharingCardOption = canShareMethod && mustShareMethod && sharedCards && sharedCards.length > 0;

  const [activeBankInfo, setActiveBankInfo] =
    useState<{
      holderName: string;
      accountNumber: string;
      routingNumber: string;
      accountType: string;
    }>();
  const getAddressValue = (address: Address, email?: string | null, phone?: string | null) => {
    return `${address?.line1 || ''}~${address?.postalCode || ''}~${email || ''}~${phone || ''}`;
  };
  const [apteanPay, setApteanPay] = useState<ApteanPaySDK>();
  const [sdkComponent, setSDKComponent] = useState<ApteanPaySDKCardComponent | ApteanPaySDKBankComponent>();
  const [paymentStatusData, setPaymentStatusData] = useState<PaymentMethodStatusData>({} as PaymentMethodStatusData);
  const [creditError, setCreditError] = useState<string>();
  const [selectedBillingAddress, setSelectedBillingAddress] = useState<PaymentMethodHolder | null | undefined>(payerProfileHolderInfo);
  const [selectedRadio, setSelectedRadio] = useState<string | null | undefined>(
    payerProfileHolderInfo
      ? getAddressValue(payerProfileHolderInfo.address as Address, payerProfileHolderInfo?.email, payerProfileHolderInfo.phone?.number)
      : null,
  );
  const [isAccountNumberValid, setIsAccountNumberValid] = useState(true);
  const [isRoutingNumberValid, setIsRoutingNumberValid] = useState(true);
  const [cardHolderName, setCardHolderName] = useState<string>('');
  const [accountHolderNameValid, setAccountHolderNameValid] = useState(true);
  const [cardHolderNameValid, setCardHolderNameValid] = useState(true);
  const [isDefault, setIsDefault] = useState(false);
  const [fieldsErrorMessage, setFieldsErrorMessage] = useState<string | undefined>(undefined);
  const [isWalletMethod, setIsWalletMethod] = useState(false);
  const [isShareWithMerchant, setIsShareWithMerchant] = useState(mustShareMethod);
  const [isMerchantDefault, setIsMerchantDefault] = useState(false);
  // the value can be either share-card or add-card
  const [sharingOrAddingCardRadio, setSharingOrAddingCardRadio] = useState('share-card');
  const [selectedSharingMethod, setSelectedSharingMethod] = useState<PayerPaymentMethod | undefined>(undefined);

  const isLoading = loadingPaymentMethods || completePaymentMethodRequestInFlight;

  const sharingExistingCard = sharingOrAddingCardRadio === 'share-card';
  const sharingNewCard = sharingOrAddingCardRadio === 'add-card';
  const existingSharedOptionNotSelected = haveSharingCardOption && sharingExistingCard && !selectedSharingMethod;
  const existingSharingOptionSelected = haveSharingCardOption && sharingExistingCard;
  const hasReachedMaxCardLimit = cardAmount >= MAX_CARDS && isWalletMethod;
  const cardOptionDisabled = existingSharingOptionSelected || hasReachedMaxCardLimit;

  const clearState = () => {
    if (clearAddPaymentMethodError) clearAddPaymentMethodError();
    if (clearCompletePaymentMethodRequestError) clearCompletePaymentMethodRequestError();
    setActiveBankInfo(undefined);
    setSelectedBillingAddress(null);
    setSelectedRadio(null);
    setPaymentStatusData({} as PaymentMethodStatusData);
    setCreditError(undefined);
    setIsAccountNumberValid(true);
    setIsRoutingNumberValid(true);
    setCardHolderName('');
    setAccountHolderNameValid(true);
    setCardHolderNameValid(true);
    setIsDefault(false);
    setIsMerchantDefault(false);
    setSelectedSharingMethod(undefined);
    setSharingOrAddingCardRadio('share-card');
  };

  useEffect(() => {
    if ((paymentAddVisible && !addingCard) || !isPaymentScreen) {
      setIsWalletMethod(true);
      setIsDefault(!!paymentMethods && paymentMethods.length === 0);
    } else {
      setIsWalletMethod(false);
      setIsDefault(false);
    }
    if (!paymentAddVisible) {
      clearState();
    } else if (payerProfileHolderInfo) {
      setSelectedBillingAddress(payerProfileHolderInfo);
      setSelectedRadio(
        getAddressValue(payerProfileHolderInfo.address as Address, payerProfileHolderInfo.email, payerProfileHolderInfo.phone?.number),
      );
    }
  }, [paymentAddVisible]);

  useEffect(() => {
    setIsShareWithMerchant(mustShareMethod);
  }, [mustShareMethod]);

  const handleAddBillingClick = () => {
    setPaymentMethodAddStep(1);
    if (addPaymentMethodError && clearAddPaymentMethodError) clearAddPaymentMethodError();
  };

  const handleCancelBillingClick = () => {
    setPaymentMethodAddStep(0);
  };

  const handleFinishAddingBilling = (billingAddress: PaymentMethodHolder) => {
    const isDuplicated = billingAddresses?.find(address => {
      return (
        address.address?.line1 &&
        address.address?.postalCode &&
        address.email &&
        address.phone?.number &&
        address.address?.line1.localeCompare(billingAddress.address?.line1 || '') === 0 &&
        address.address?.postalCode.localeCompare(billingAddress.address?.postalCode || '') === 0 &&
        address.email.localeCompare(billingAddress?.email || '') === 0 &&
        address.phone.number.localeCompare(billingAddress.phone?.number || '') === 0
      );
    });

    if (addBillingAddress && !isDuplicated) {
      addBillingAddress(billingAddress);
    }
    setSelectedBillingAddress(billingAddress);
    setSelectedRadio(getAddressValue(billingAddress.address as Address, billingAddress.email, billingAddress.phone?.number));
    setPaymentMethodAddStep(0);
  };

  const continueSavePaymentMethod = (type: PaymentMethodType, token: string, holderName: string) => {
    if (
      selectedBillingAddress &&
      selectedBillingAddress.address &&
      selectedBillingAddress.address.postalCode &&
      selectedBillingAddress.address.country &&
      selectedMerchant
    ) {
      const selectedCountryCode = selectedBillingAddress?.phone?.countryCode;
      const selectedPhoneNumber = selectedBillingAddress?.phone?.number;

      submit(
        type,
        token,
        customerId || viewerUser?.id,
        {
          email: selectedBillingAddress.email,
          name: holderName,
          address: {
            line1: selectedBillingAddress.address?.line1,
            line2: selectedBillingAddress.address?.line2,
            postalCode: selectedBillingAddress.address.postalCode,
            country: selectedBillingAddress.address.country,
            region: '',
            city: '',
          },
          // checking whether countrycode and phone number was proided, to handle null values
          phone:
            selectedCountryCode && selectedPhoneNumber
              ? {
                  countryCode: selectedCountryCode,
                  number: selectedPhoneNumber,
                }
              : undefined,
        },
        customerId ? isMerchantDefault : isDefault,
        isWalletMethod && (allowCardSave || addingBank),
        selectedMerchant,
        customerId ? isShareWithMerchant : false,
      );
    }
  };

  const onTokenizeCallback = (token?: ApteanPaySDKToken, errors?: ApteanPaySDKError[]) => {
    if (token && selectedMerchant) {
      submitSDKPaymentMethod(
        token,
        customerId || viewerUser?.id,
        customerId ? isMerchantDefault : isDefault,
        isWalletMethod && (allowCardSave || addingBank),
        selectedMerchant,
        !!customerId && isShareWithMerchant,
      );
    }
    if (errors) {
      const messages = errors.map(error => error.message).join('. ');
      setCreditError(messages);
      dispatch(fetchLoadingPaymentMethods(false));
    }
  };

  const onTokenize = (apteanPay: ApteanPaySDK, cardComponent: ApteanPaySDKCardComponent) => {
    if (selectedBillingAddress) {
      const { address, phone, email } = selectedBillingAddress;
      if (address && address.postalCode && address.country) {
        const data: ApteanPaySDKCreateTokenData = {
          addressZip: address.postalCode,
          addressCountry: address.country,
          name: cardHolderName ?? undefined,
          addressCity: address.city ?? undefined,
          addressLine1: address.line1 ?? undefined,
          addressLine2: address.line2 ?? undefined,
          addressState: address.region ?? undefined,
          phoneCountryCode: phone?.countryCode ?? undefined,
          phoneNumber: phone?.number ?? undefined,
          emailAddress: email ?? undefined,
        };
        dispatch(fetchLoadingPaymentMethods(true));
        apteanPay.createTokenCallback(cardComponent, data, onTokenizeCallback);
      }
    }
  };

  const submitBankFailure = (statusData: PaymentMethodStatusData) => {
    setPaymentStatusData(statusData);
    // to check if there is error in fields
    if (statusData?.invalidAccountNumber) {
      setIsAccountNumberValid(false);
    }
    if (statusData?.invalidRoutingNumber) {
      setIsRoutingNumberValid(false);
    }
  };
  const submitBankSuccess = (token: string) => {
    continueSavePaymentMethod(PaymentMethodType.PaymentBankUs, token, activeBankInfo?.holderName || '');
  };
  const checkBankAccountNumber = () => {
    if (!!activeBankInfo?.accountNumber && activeBankInfo.accountNumber.length >= 3 && activeBankInfo.accountNumber.length <= 17) {
      setIsAccountNumberValid(true);
    } else {
      setIsAccountNumberValid(false);
      return false;
    }
    return true;
  };
  const checkRoutingNumber = () => {
    if (!!activeBankInfo?.routingNumber && activeBankInfo.routingNumber.length > 0) {
      setIsRoutingNumberValid(true);
    } else {
      setIsRoutingNumberValid(false);
      return false;
    }
    return true;
  };
  const checkAccountHolderName = () => {
    if (activeBankInfo) {
      const isValidName = checkIsFullNameValid(activeBankInfo.holderName);
      setAccountHolderNameValid(isValidName);
      return isValidName;
    }
    return false;
  };
  const handleSavePaymentMethod = () => {
    if (addingCard && apteanPay && sdkComponent) {
      setCardHolderNameValid(checkIsFullNameValid(cardHolderName));
      if (checkIsFullNameValid(cardHolderName)) onTokenize(apteanPay, sdkComponent);
    } else if (addingBank && payfac === 'PTT' && apteanPay && sdkComponent) {
      onTokenize(apteanPay, sdkComponent);
    } else if (addingBank && payfac === 'WEPAY') {
      //clearing the state before submit
      setPaymentStatusData({} as PaymentMethodStatusData);
      setFieldsErrorMessage(undefined);
      // state change is taking time, so have to store the result and trigger the function accordingly
      const accountNumberValid = checkBankAccountNumber();
      const routingNumberValid = checkRoutingNumber();
      const accountNameValid = checkAccountHolderName();
      if (activeBankInfo && selectedBillingAddress && accountNumberValid && routingNumberValid && accountNameValid) {
        submitPaymentMethod(activeBankInfo, selectedBillingAddress, submitBankSuccess, submitBankFailure, true);
      }
      if (!accountNumberValid || !routingNumberValid || !accountNameValid) {
        let message = 'Please enter valid';
        let errorCount = 0;
        if (!accountNameValid) {
          message += ' Holder Name';
          errorCount += 1;
        }
        if (!routingNumberValid) {
          message += errorCount > 0 ? `${!accountNumberValid ? ', ' : 'and '}` : ' ';
          message += 'Routing Number';
          errorCount += 1;
        }
        if (!accountNumberValid) {
          message += errorCount > 0 ? ' and ' : ' ';
          message += 'Account Number';
        }
        message += '.';
        setFieldsErrorMessage(message);
      }
    }
  };

  const sharePaymentMethod = () => {
    const { id: paymentMethodRequestId } = paymentMethodRequest || {};
    const { id: paymentMethodId } = selectedSharingMethod?.paymentMethod || {};
    if (selectedMerchant && paymentMethodRequestId && paymentMethodId && customerId) {
      completePaymentMethodRequest(selectedMerchant, paymentMethodRequestId, paymentMethodId, customerId);
    }
  };

  const handlePaymentMethodSaveClick = () => {
    // Validate PTT card fields.
    if (integrationSettings?.payFacType === 'PTT') {
      if (addingCard && (!paymentMethodRequest || !haveSharingCardOption || sharingNewCard)) {
        setCreditError(undefined);
        const isCardNumberValid = !!document.querySelector('#CollectJSInlineccnumber.CollectJSValid');
        const isCardExpValid = !!document.querySelector('#CollectJSInlineccexp.CollectJSValid');
        const isCardCvvValid = !!document.querySelector('#CollectJSInlinecvv.CollectJSValid');
        if (!isCardNumberValid || !isCardExpValid || !isCardCvvValid) {
          setCreditError('Please ensure card information is valid.');
          return;
        }
      }
      if (addingBank) {
        const isAccountNameValid = !!document.querySelector('#CollectJSInlinecheckname.CollectJSValid');
        const isAccountNumberValid = !!document.querySelector('#CollectJSInlinecheckaccount.CollectJSValid');
        const isRoutingNumberValid = !!document.querySelector('#CollectJSInlinecheckaba.CollectJSValid');
        if (!isAccountNameValid || !isAccountNumberValid || !isRoutingNumberValid) {
          setFieldsErrorMessage('Please ensure account information is valid.');
          return;
        }
      }
    }

    if (haveSharingCardOption && sharingExistingCard) {
      // ad the existing call here
      sharePaymentMethod();
    } else {
      handleSavePaymentMethod();
    }
  };

  const onBankInfoChange = (bankInfo: { holderName: string; accountNumber: string; routingNumber: string; accountType: string }) => {
    setActiveBankInfo(bankInfo);
  };

  const onTokenizeInjectSuccess = (apteanPay: ApteanPaySDK, cardComponent: ApteanPaySDKCardComponent | ApteanPaySDKBankComponent) => {
    setApteanPay(apteanPay);
    setSDKComponent(cardComponent);
  };

  const handleSelectRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
    const addressValues = event.target.value.split('~');
    setSelectedBillingAddress(
      billingAddresses?.find(address => {
        // storing before hand to cover undefined & null values
        const line1 = address.address?.line1 || '';
        const postalCode = address.address?.postalCode || '';
        const email = address?.email || '';
        const phoneNumber = address?.phone?.number || '';

        return (
          addressValues.length === 4 &&
          postalCode &&
          line1.localeCompare(addressValues[0]) === 0 &&
          postalCode.localeCompare(addressValues[1]) === 0 &&
          email.localeCompare(addressValues[2]) === 0 &&
          phoneNumber.localeCompare(addressValues[3]) === 0
        );
      }),
    );
  };

  const handleIsDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDefault(event.target.checked);
  };

  const handleIsWalletMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsWalletMethod(checked);
    if (!checked && !customerId) {
      setIsDefault(false);
    } else if (!customerId) {
      setIsDefault(!!paymentMethods && paymentMethods.length === 0);
    }
    if (!checked && customerId) {
      setIsShareWithMerchant(false);
      setIsMerchantDefault(false);
    }
  };

  const handleIsShareWithMerchantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsShareWithMerchant(event.target.checked);
    if (!event.target.checked) {
      setIsMerchantDefault(false);
    }
  };

  const handleIsMerchantDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMerchantDefault(event.target.checked);
  };

  const getPaymentMethodAddAriaLabelledBy = (addStep: number, paymentMethodType: PaymentMethodType | undefined) => {
    if (addStep === 1) {
      return 'edit-billing-address';
    }
    if (addStep === 0 && paymentMethodType === PaymentMethodType.CreditCard) {
      return 'credit-card-information';
    }
    return 'add-bank-account';
  };

  const handleShareMethodTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSharingOrAddingCardRadio(value);
    if (value === 'share-card' && addPaymentMethodError && clearAddPaymentMethodError) {
      clearAddPaymentMethodError();
    }
    if (value === 'add-card' && completePaymentMethodRequestError && clearCompletePaymentMethodRequestError) {
      clearCompletePaymentMethodRequestError();
    }
  };

  const selectBillingRadio = (
    <FormControl>
      <RadioGroup
        color="primary"
        name="selectedBillingAddress"
        value={selectedRadio}
        onChange={handleSelectRadio}
        aria-label="billing addresses"
      >
        {billingAddresses &&
          billingAddresses.map((address, index) => {
            return (
              <FormControlLabel
                key={index}
                value={getAddressValue(address.address as Address, address.email, address.phone?.number)}
                disabled={cardOptionDisabled}
                control={<Radio size="small" color="primary" className={classes.radio} />}
                label={
                  <Grid item container direction="column">
                    <Typography variant="subtitle2">{address.email}</Typography>
                    <Typography className={classes.addressFont}>{`${address?.address?.line1 || ''} ${
                      address?.address?.line2 || ''
                    }`}</Typography>
                    <Typography
                      className={classes.addressFont}
                    >{`${address?.address?.country}, ${address?.address?.postalCode}`}</Typography>
                    {address?.phone?.number && (
                      <Typography color="textSecondary" className={classes.numberFont}>{`${
                        address?.phone?.countryCode
                      } (${address?.phone?.number?.slice(0, 3)}) ${address?.phone?.number?.slice(
                        3,
                        6,
                      )}-${address?.phone?.number?.slice(-4)}`}</Typography>
                    )}
                  </Grid>
                }
                className={classes.radioLabel}
                data-cy="address-details"
              />
            );
          })}
      </RadioGroup>
      {((paymentMethods && billingAddresses && paymentMethods?.length < MAX_CARDS && billingAddresses?.length < MAX_CARDS) ||
        (paymentMethods && billingAddresses && paymentMethods?.length === MAX_CARDS && billingAddresses?.length < MAX_CARDS + 1) ||
        (paymentMethods &&
          billingAddresses &&
          paymentMethods?.length === MAX_CARDS + 1 &&
          (billingAddresses?.length < MAX_CARDS + 3 || (oneTimeUsePaymentMethod && billingAddresses?.length < MAX_CARDS + 4))) ||
        (!paymentMethods && billingAddresses && billingAddresses.length < MAX_CARDS) ||
        (!paymentMethods && !billingAddresses)) && (
        <Grid container item xs={12}>
          <Button
            variant="text"
            color="primary"
            startIcon={<AddIcon />}
            disabled={cardOptionDisabled}
            onClick={handleAddBillingClick}
            data-cy="add-address"
          >
            ADD ADDRESS
          </Button>
        </Grid>
      )}
    </FormControl>
  );

  return (
    <Dialog
      open={paymentAddVisible}
      maxWidth={haveSharingCardOption ? 'lg' : 'sm'}
      fullWidth
      className={isIFrameVisible === false && isAddBankClicked === false ? classes.noneDisplay : ''}
      scroll="paper"
      data-cy="payment-method-add"
      aria-labelledby={getPaymentMethodAddAriaLabelledBy(paymentMethodAddStep, addPaymentMethodType)}
      aria-describedby={paymentMethodAddStep === 0 && addingBank ? 'add-bank-account-description' : ''}
    >
      <DialogTitle className={classes.noPadding}>
        {haveSharingCardOption && (
          <Grid container className={classes.headerContainer}>
            <Grid item xs={12}>
              <Typography variant="title">Payment Method</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">Choose an existing card or add a new card to complete this request.</Typography>
            </Grid>
          </Grid>
        )}
        {(addPaymentMethodError || completePaymentMethodRequestError) && (
          <AlertBanner message={addPaymentMethodError?.message || completePaymentMethodRequestError?.message || 'An Error occurred'} />
        )}
        {haveSharingCardOption && (
          <Grid container spacing={1} className={classes.headerTopBorder}>
            <Grid container item xs={12} md={6} alignItems="center">
              <Radio
                size="small"
                color="primary"
                value="share-card"
                onChange={handleShareMethodTypeChange}
                checked={sharingOrAddingCardRadio === 'share-card'}
                disabled={paymentMethodAddStep === 1}
                data-cy="share-card"
              />
              <Typography variant="subtitle2" className={paymentMethodAddStep === 1 ? classes.disabledView : ''}>
                Existing Shared Cards
              </Typography>
            </Grid>
            <Grid container item xs={12} md={6} alignItems="center">
              <Radio
                size="small"
                color="primary"
                value="add-card"
                onChange={handleShareMethodTypeChange}
                checked={sharingOrAddingCardRadio === 'add-card'}
                disabled={hasReachedMaxCardLimit}
                data-cy="add-card"
              />
              <Typography variant="subtitle2" className={hasReachedMaxCardLimit ? classes.disabledView : ''}>
                Add New Card
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogTitle>

      <DialogContent className={classes.noPadding}>
        <LoadingMask loading={isLoading} />
        {/* Show this error when there has been an error while creating a payment method */}

        <Grid container item>
          {haveSharingCardOption && (
            <Grid
              container
              item
              xs={12}
              md={6}
              className={`${classes.shareSelection} ${matches ? '' : classes.shareSelectionBorder} ${
                sharingNewCard ? classes.disabledView : ''
              } ${matches && sharingNewCard ? classes.displayNone : ''}`}
            >
              <PaymentMethodShareSelection
                paymentMethods={sharedCards}
                paymentMethodRequest={paymentMethodRequest}
                selectedMethod={selectedSharingMethod}
                setSelectedMethod={setSelectedSharingMethod}
                disabledView={sharingNewCard}
              />
            </Grid>
          )}
          <Grid container item xs={12} md={haveSharingCardOption ? 6 : 12}>
            {hasReachedMaxCardLimit && (
              <Grid item xs={12} className={classes.infoTextContainer}>
                <InfoIcon className={classes.infoIcon} />
                {'Maximum number of cards reached, please delete an existing card to Add new'}
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              className={`${paymentMethodAddStep === 1 ? classes.displayNone : classes.dialogPadding} ${
                sharingExistingCard && haveSharingCardOption ? `${classes.disabledView} ${matches ? classes.displayNone : ''}` : ''
              } ${hasReachedMaxCardLimit ? classes.disabledView : ''}`}
            >
              <Grid item xs={12}>
                {addingCard && (
                  <CardInfo
                    creditError={creditError}
                    setCreditError={setCreditError}
                    onTokenizeInjectSuccess={onTokenizeInjectSuccess}
                    viewerUser={viewerUser}
                    holderName={cardHolderName}
                    setHolderName={setCardHolderName}
                    holderNameValid={cardHolderNameValid}
                    setHolderNameValid={setCardHolderNameValid}
                    isIFrameVisible={isIFrameVisible}
                    setIsIFrameVisible={setIsIFrameVisible}
                    cardInfoDisabled={cardOptionDisabled}
                    aria-label="add credit card"
                    holderInformation={selectedBillingAddress}
                    merchantTenantId={selectedMerchant}
                    customerId={customerId}
                  />
                )}
                {addingBank && (
                  <PaymentMethodEdit
                    onBankInfoChange={onBankInfoChange}
                    statusData={paymentStatusData}
                    onMobile={onMobile}
                    paymentMethodExists={false}
                    isAccountNumberValid={isAccountNumberValid}
                    isRoutingNumberValid={isRoutingNumberValid}
                    holderNameValid={accountHolderNameValid}
                    setHolderNameValid={setAccountHolderNameValid}
                    fieldsErrorMessage={fieldsErrorMessage}
                    setFieldsErrorMessage={setFieldsErrorMessage}
                    payfac={payfac}
                    merchantTenantId={selectedMerchant}
                    customerId={customerId}
                    onTokenizeInjectSuccess={onTokenizeInjectSuccess}
                  />
                )}
              </Grid>

              {allowCardSave && isPaymentScreen && addingCard && !isCustomerPayer && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isWalletMethod}
                        onChange={handleIsWalletMethodChange}
                        disabled={cardAmount === MAX_CARDS}
                        color="primary"
                      />
                    }
                    label="Save this card to wallet"
                  />
                </Grid>
              )}

              {isPaymentScreen && addingCard && cardAmount === MAX_CARDS && (
                <AlertBanner message="You have added maximum of 25 saved cards to your wallet" />
              )}

              {allowCardSave && !customerId && addingCard && cardAmount < MAX_CARDS && !isCustomerPayer && (
                <Grid item xs={12} className={classes.doubleHorizontalPadding}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isDefault}
                        onChange={handleIsDefaultChange}
                        disabled={!isWalletMethod || cardOptionDisabled}
                        color="primary"
                      />
                    }
                    label="Make this my default payment method"
                  />
                </Grid>
              )}

              {allowCardSave && customerId && addingCard && cardAmount < MAX_CARDS && !isCustomerPayer && (
                <Grid item xs={12}>
                  <Grid item xs={12} className={classes.doubleHorizontalPadding}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isShareWithMerchant}
                          onChange={handleIsShareWithMerchantChange}
                          disabled={!isWalletMethod || mustShareMethod || cardOptionDisabled}
                          color="primary"
                        />
                      }
                      classes={{ label: classes.label }}
                      label={`Share this card with ${selectedMerchantInfo?.name || 'Merchant'}`}
                    />
                  </Grid>
                  <Grid item xs={12} className={`${classes.doubleHorizontalPadding} ${classes.bottomPadding}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isMerchantDefault}
                          onChange={handleIsMerchantDefaultChange}
                          disabled={!isShareWithMerchant || cardOptionDisabled}
                          color="primary"
                        />
                      }
                      label="Set as Default card for Merchant Initiated Payments"
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container item xs={12} className={classes.doubleHorizontalPadding}>
                <Grid container item xs={10} alignItems="center">
                  <Typography color="textSecondary">Billing Addresses</Typography>
                </Grid>
              </Grid>

              <Grid container item justifyContent="space-between" xs={10} className={classes.doubleHorizontalPadding}>
                <Grid container item direction="column" xs={12}>
                  {selectBillingRadio}
                </Grid>
              </Grid>

              {oneTimeUsePaymentMethod && !isWalletMethod && addingCard && (
                <AlertBanner
                  severity="warning"
                  message="This card will be replaced with current temporary card for this payment. Do you want to save?"
                />
              )}
            </Grid>
            <Grid container item xs={12} className={paymentMethodAddStep === 0 ? classes.displayNone : classes.dialogPadding}>
              <BillingEdit
                handleBack={handleCancelBillingClick}
                handleContinue={handleFinishAddingBilling}
                isAdd={true}
                onMobile={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={paymentMethodAddStep === 1 && !haveSharingCardOption ? classes.displayNone : undefined}>
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            cancel();
          }}
          disabled={paymentMethodAddStep === 1 && haveSharingCardOption}
          data-cy="cancel"
        >
          Cancel
        </Button>
        <Button
          id="js_sdk_button"
          variant="contained"
          color="primary"
          disabled={
            isLoading ||
            (!haveSharingCardOption && (!selectedBillingAddress || hasReachedMaxCardLimit)) ||
            (haveSharingCardOption &&
              (paymentMethodAddStep === 1 ||
                existingSharedOptionNotSelected ||
                (sharingNewCard && (!selectedBillingAddress || hasReachedMaxCardLimit))))
          }
          onClick={handlePaymentMethodSaveClick}
          aria-describedby={`${paymentStatusData?.tokenError?.message ? 'error-text-one' : undefined} ${
            paymentStatusData?.error?.message ? 'error-text-two' : undefined
          } ${fieldsErrorMessage ? 'error-text-three' : undefined}`}
          data-cy="continue-to-payment"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentMethodAdd;
