import { gqlClient } from '../../components/AppProvider';

import { AppDispatch } from '../../store';
import { CustomerRole } from '../../gql-types.generated';
import { mutationUpsertCustomerUser } from '../../gql/UpsertCustomerUserMutation';
import { mutationRemoveCustomerUser } from '../../gql/RemoveCustomerUserMutation';
import {
  fetchCustomerSuccess,
  fetchCustomerError,
  fetchCustomerUserStatus,
  fetchIsLoadingCustomer,
  fetchRemoveCustomerUserError,
  fetchRemovedUserStatus,
  fetchUpsertCustomerUserError,
  fetchIsUpsertCustomerUserInFlight,
  fetchIsRemoveCustomerUserInFlight,
} from './ManageUsersSlice';
import { queryPayerCustomer } from '../../gql/QueryPayerCustomer';

export const upsertCustomerUser =
  (tenantId: string, customerId: string, email: string, role: CustomerRole, sendEmail: boolean) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(fetchCustomerUserStatus(undefined));
      dispatch(fetchUpsertCustomerUserError(undefined));
      dispatch(fetchIsUpsertCustomerUserInFlight(true));
      const customerUser = await mutationUpsertCustomerUser(gqlClient, tenantId, customerId, email, role, sendEmail);
      if (customerUser) {
        dispatch(fetchCustomerUserStatus(customerUser));
      }
      dispatch(fetchIsUpsertCustomerUserInFlight(false));
    } catch (e) {
      dispatch(fetchUpsertCustomerUserError(e));
      dispatch(fetchIsUpsertCustomerUserInFlight(false));
    }
  };

export const removeCustomerUser =
  (tenantId: string, customerId: string, email: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(fetchRemovedUserStatus(undefined));
      dispatch(fetchRemoveCustomerUserError(undefined));
      dispatch(fetchIsRemoveCustomerUserInFlight(true));
      const removedUser = await mutationRemoveCustomerUser(gqlClient, tenantId, customerId, email);
      if (removedUser) {
        dispatch(fetchRemovedUserStatus(removedUser));
      }
      dispatch(fetchIsRemoveCustomerUserInFlight(false));
    } catch (e) {
      dispatch(fetchRemoveCustomerUserError(e));
      dispatch(fetchIsRemoveCustomerUserInFlight(false));
    }
  };

export const capturePayerCustomer =
  (customerId: string, tenantId: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch(fetchIsLoadingCustomer(true));
      dispatch(fetchCustomerError(undefined));
      const customer = await queryPayerCustomer(gqlClient, tenantId, customerId);
      if (customer) {
        dispatch(fetchCustomerSuccess(customer));
      }
      dispatch(fetchIsLoadingCustomer(false));
    } catch (e) {
      dispatch(fetchIsLoadingCustomer(false));
      dispatch(fetchCustomerError(e));
    }
  };
