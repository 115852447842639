import { Typography, Theme, Grid, Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import {
  CompletePaymentMethodRequestStatus,
  CurrencyType,
  CustomerRole,
  MutationStatusCode,
  PaymentMethodRequest,
  PaymentMethodRequestRestriction,
  PaymentMethodRequestStatus,
} from '../gql-types.generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentMethodRequestHyperText: {
      fontWeight: 500,
      color: theme.palette.caution.main,
    },
    textContainer: {
      wordBreak: 'break-word',
    },
    container: {
      padding: theme.spacing(1, 2),
      margin: theme.spacing(1, 0, 2, 0),
      backgroundColor: theme.palette.caution.light,
    },
    cautionButton: {
      color: theme.palette.caution.main,
      backgroundColor: 'white',
      fontWeight: 'bold',
      borderColor: theme.palette.caution.main,
      '&:hover': {
        borderColor: theme.palette.caution.dark,
        color: theme.palette.caution.dark,
      },
    },
  }),
);

interface PaymentMethodRequestBannerProps {
  paymentMethodRequest: PaymentMethodRequest | undefined;
  defaultCurrency?: CurrencyType | null;
  handleAddCardPaymentMethod: () => void;
  customerRole?: CustomerRole | null;
  isLoadingCustomer: boolean;
  completePaymentMethodRequestStatus?: CompletePaymentMethodRequestStatus;
}

const PaymentMethodRequestBanner: React.FC<PaymentMethodRequestBannerProps> = props => {
  const {
    paymentMethodRequest,
    defaultCurrency,
    handleAddCardPaymentMethod,
    customerRole,
    isLoadingCustomer,
    completePaymentMethodRequestStatus,
  } = props;
  const classes = useStyles();

  const onlyAddAllowed = paymentMethodRequest?.restriction === PaymentMethodRequestRestriction.AddOnly;

  const canCreateMethod = customerRole === CustomerRole.CustomerAdmin || customerRole === CustomerRole.CustomerManager;
  const attemptedPayment = paymentMethodRequest?.paymentAttempts.slice(-1)[0] || undefined; //choose the latest attempted payment
  const attemptedAmount = attemptedPayment?.amount || 0;
  const formattedAmount = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: defaultCurrency || 'USD',
    currencyDisplay: 'symbol',
  }).format(attemptedAmount / 100);
  const amountString = attemptedAmount > 0 ? `for ${formattedAmount}` : '';
  const paymentDetails: string[] = [];
  // push order number
  const orderNumber = attemptedPayment?.orderNumber?.trim();
  if (orderNumber) paymentDetails.push(`Order#: ${orderNumber}`);
  // push invoice number
  const invoiceNumber = attemptedPayment?.invoiceNumber?.trim();
  if (invoiceNumber) paymentDetails.push(`Invoice#: ${invoiceNumber}`);
  // push customer PO number
  const customerPONumber = attemptedPayment?.customerPONumber?.trim();
  if (customerPONumber) paymentDetails.push(`Customer Order#: ${customerPONumber}`);

  const messageWithAttempt = `Payment ${amountString} could not be completed. Please ${
    !onlyAddAllowed ? 'choose an existing shared card or ' : ''
  }add a new shared card. ${paymentDetails.length > 0 ? `(${paymentDetails.join(', ')})` : ''}`;

  const messageWithoutAttempt = `Please ${!onlyAddAllowed ? 'choose an existing shared card or ' : ''}add a new shared card.`;

  const handlePaymentMethodRequestClick = () => {
    if (canCreateMethod) {
      handleAddCardPaymentMethod();
    }
  };

  if (
    paymentMethodRequest &&
    paymentMethodRequest.status === PaymentMethodRequestStatus.Pending &&
    completePaymentMethodRequestStatus?.code !== MutationStatusCode.Success
  ) {
    return (
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12} className={classes.textContainer}>
          <Typography className={classes.paymentMethodRequestHyperText}>
            {attemptedPayment ? messageWithAttempt : messageWithoutAttempt}
          </Typography>
        </Grid>
        {canCreateMethod && (
          <Grid container item xs={12}>
            <Button
              variant="outlined"
              size="small"
              onClick={handlePaymentMethodRequestClick}
              disabled={isLoadingCustomer}
              classes={{ root: classes.cautionButton }}
            >
              continue
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  return null;
};

export default PaymentMethodRequestBanner;
