import BankIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Card, CardActionArea, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

import Amex from '../Amex.svg';
import Diners from '../Diners.svg';
import Discover from '../Discover.svg';
import EmptyWalletLogo from '../EmptyWalletLogo.svg';
import {
  CardBrand,
  Customer,
  CustomerInfo,
  CustomerPaymentMethod,
  CustomerRole,
  MerchantInfo,
  PayerPaymentMethod,
  PaymentMethodRequest,
  PaymentMethodRequestStatus,
  PaymentMethodStatus,
  PaymentMethodType,
  Token,
} from '../gql-types.generated';
import Jcb from '../Jcb.svg';
import Mastercard from '../Mastercard.svg';
import { checkIsSharedMethod } from '../util/CheckIsSharedMethod';
import Visa from '../Visa.svg';
import AlertBanner from './AlertBanner';
import PaymentMethodCard from './PaymentMethodCard';
import theme from '../Theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: theme.spacing(0.5, 2),
    },
    fontWeight: {
      fontWeight: 400,
    },
    cardActionContainer: {
      padding: theme.spacing(1),
    },
    addText: {
      textAlign: 'center',
      fontSize: '15px',
      fontWeight: 'bold',
      color: '#0275d8',
    },
    cardIcon: {
      fontSize: '48px',
      color: '#0275d8',
    },
    addIconContainer: {
      textAlign: 'center',
    },
    creditCardContainer: {
      padding: theme.spacing(1, 1, 1, 2),
    },
    bankCardContainer: {
      padding: theme.spacing(1, 2, 1, 1),
    },
    paymentMethodCard: {
      width: '100%',
      border: 'solid 1.5px #0275d8',
      borderRadius: theme.spacing(2),
      backgroundColor: '#FCFCFC',
    },
    methodsContainer: {
      height: '100%',
    },
    emptyWalletLogo: {
      height: '4rem',
    },
  }),
);
interface PaymentMethodsProps {
  deleteMethod: (paymentMethod: PayerPaymentMethod) => void;
  paymentMethods?: PayerPaymentMethod[] | null;
  setAsDefault: (paymentMethod: PayerPaymentMethod) => void;
  setAsSharedWithMerchant: (paymentMethod: PayerPaymentMethod) => void;
  deletePaymentMethodError?: Error;
  handleVerifyClick: (paymentMethod: PayerPaymentMethod) => void;
  handleAddCard: () => void;
  handleAddBank: () => void;
  paymentMethodError?: Error;
  customerRole: CustomerRole | undefined;
  customerInfo?: CustomerInfo;
  customerMethods: CustomerPaymentMethod[] | undefined | null;
  setSettingsMenuVisible: (isMenuVisible: boolean) => void;
  paymentMethodRequest?: PaymentMethodRequest;
  payerCustomer?: Customer;
  merchantInfo?: MerchantInfo;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MAX_CARDS = 25;
// eslint-disable-next-line @typescript-eslint/naming-convention
export const MAX_BANKS = 1;

export const getPaymentMethodText = (paymentMethod: PayerPaymentMethod): string => {
  if (paymentMethod.paymentMethod?.type === PaymentMethodType.CreditCard) {
    return `Card ending in ${paymentMethod.paymentMethod?.creditCard?.lastFour}`;
  }
  return `Account ending in ${paymentMethod.paymentMethod?.paymentBank?.lastFour}`;
};

export const getPaymentMethodIcon = (
  paymentMethod: PayerPaymentMethod | undefined,
  isDisabled: boolean,
  length: number,
  isCard?: boolean,
  methodToken?: Token,
): JSX.Element => {
  if (paymentMethod?.paymentMethod?.type === PaymentMethodType.PaymentBankUs) {
    return (
      <BankIcon
        style={{
          height: length,
          width: isCard ? length : length / 1.3,
          opacity: isDisabled ? 0.5 : 1,
          padding: isCard ? (length - length / 1.3) / 2 : 0,
        }}
      />
    );
  }
  const cardBrand = paymentMethod?.paymentMethod?.creditCard?.cardBrand || methodToken?.creditCard?.cardBrand;
  if (cardBrand === CardBrand.Amex) {
    return <img src={Amex} style={{ height: length, width: length, opacity: isDisabled ? 0.5 : 1 }} alt="Americant Express"></img>;
  }
  if (cardBrand === CardBrand.Diners) {
    return <img src={Diners} style={{ height: length, width: length, opacity: isDisabled ? 0.5 : 1 }} alt="Diners Club"></img>;
  }
  if (cardBrand === CardBrand.Discover) {
    return <img src={Discover} style={{ height: length, width: length, opacity: isDisabled ? 0.5 : 1 }} alt="Discover"></img>;
  }
  if (cardBrand === CardBrand.Jcb) {
    return <img src={Jcb} style={{ height: length, width: length, opacity: isDisabled ? 0.5 : 1 }} alt="JCB"></img>;
  }
  if (cardBrand === CardBrand.Mastercard) {
    return <img src={Mastercard} style={{ height: length, width: length, opacity: isDisabled ? 0.5 : 1 }} alt="Mastercard"></img>;
  }
  if (cardBrand === CardBrand.Visa) {
    return <img src={Visa} style={{ height: length, width: length, opacity: isDisabled ? 0.5 : 1 }} alt="Visa"></img>;
  }
  return <HelpOutlineIcon />;
};

const PaymentMethods: React.FC<PaymentMethodsProps> = props => {
  const classes = useStyles();
  const {
    deleteMethod,
    paymentMethods,
    setAsDefault,
    setAsSharedWithMerchant,
    deletePaymentMethodError,
    handleVerifyClick,
    handleAddCard,
    handleAddBank,
    customerRole,
    customerInfo,
    customerMethods,
    setSettingsMenuVisible,
    paymentMethodRequest,
    payerCustomer,
    merchantInfo,
  } = props;
  const [sortedMethods, setSortedMethods] = useState<PayerPaymentMethod[]>();
  const [selectedMethod, setSelectedMethod] = useState<PayerPaymentMethod | null>(null);
  const [cardCount, setCardCount] = useState<number>(0);
  const [bankCount, setBankCount] = useState<number>(0);

  const isRestrictedRole =
    customerRole && (customerRole === CustomerRole.CustomerReader || customerRole === CustomerRole.CustomerPayer);

  const [methodListClass, setMethodListClass] = useState<React.CSSProperties>({
    maxHeight: 'calc(65vh - 120px)',
    overflow: 'auto',
    paddingTop: theme.spacing(2),
  });

  const bankAccount = paymentMethods?.find(method => {
    return method.paymentMethod?.type === PaymentMethodType.PaymentBankUs;
  });

  const unverifiedBank = bankAccount && bankAccount.paymentMethod?.status === PaymentMethodStatus.Unverified;
  const verificationFailedBank = bankAccount && bankAccount.paymentMethod?.status === PaymentMethodStatus.VerificationFailed;

  useEffect(() => {
    if (payerCustomer) {
      const hasSharedMethods =
        (customerMethods || []).filter(method => {
          return method.shareWithMerchant;
        }).length > 0;

      if (!hasSharedMethods) {
        setSettingsMenuVisible(true);
      }
    }

    if (paymentMethodRequest && paymentMethodRequest?.status === PaymentMethodRequestStatus.Pending) {
      setSettingsMenuVisible(true);
    }
  }, [payerCustomer, paymentMethodRequest]);

  const findShareWithMerchant = (paymentMethodId: string | undefined): boolean => {
    return checkIsSharedMethod(paymentMethodId, customerMethods);
  };

  const sortMethods = () => {
    const methodsCopy = paymentMethods ? [...paymentMethods] : [];
    methodsCopy?.sort((a: PayerPaymentMethod, b: PayerPaymentMethod) => {
      if (a.isDefault) {
        return -1;
      }
      if (b.isDefault) {
        return 1;
      }
      const aShared = findShareWithMerchant(a.paymentMethod?.id);
      const bShared = findShareWithMerchant(b.paymentMethod?.id);
      if (aShared && !bShared) {
        return -1;
      }
      if (bShared) {
        return 1;
      }
      return 0;
    });
    setSortedMethods(methodsCopy);
  };
  const updateMethodCounts = () => {
    let cardCounter = 0;
    let bankCounter = 0;
    paymentMethods?.forEach(method => {
      if (method.paymentMethod?.type === PaymentMethodType.CreditCard) {
        cardCounter += 1;
      }
      if (method.paymentMethod?.type === PaymentMethodType.PaymentBankUs) {
        bankCounter += 1;
      }
    });
    setCardCount(cardCounter);
    setBankCount(bankCounter);
  };

  // Update counts and sorting when methods change.
  useEffect(() => {
    sortMethods();
    updateMethodCounts();
  }, [paymentMethods]);

  useEffect(() => {
    const appBarHeight = document.getElementById('primary-app-bar')?.clientHeight || 0;
    const creditMemoHeight = document.getElementById('merchant-credit-memo-card')?.clientHeight || 0;
    const addMethodOptionsHeight = isRestrictedRole ? 0 : document.getElementById('add-method-options')?.clientHeight || 120;
    const bannerHeight = document.getElementById('payment-method-banner-container')?.clientHeight || 0;

    const acquiredHeight = appBarHeight + creditMemoHeight + addMethodOptionsHeight + bannerHeight;

    setMethodListClass({
      maxHeight: `calc(100vh - ${acquiredHeight}px)`,
      overflow: 'auto',
      paddingTop: theme.spacing(2),
    });
  }, [deletePaymentMethodError, unverifiedBank, verificationFailedBank, isRestrictedRole, merchantInfo]);

  const handleCardClicked = (paymentMethod: PayerPaymentMethod) => {
    if (paymentMethod === selectedMethod) {
      setSelectedMethod(null);
    } else {
      setSelectedMethod(paymentMethod);
    }
  };

  const handleSetDefaultClick = () => {
    if (selectedMethod) {
      setAsDefault(selectedMethod);
    }
  };

  const handleShareWithMerchantClick = () => {
    if (selectedMethod) {
      setAsSharedWithMerchant(selectedMethod);
    }
  };

  const handleDeleteClick = () => {
    if (selectedMethod) {
      deleteMethod(selectedMethod);
    }
  };

  const isPayerOrCustomerSharedCard = (paymentMethodId: string | undefined): boolean => {
    if (!customerMethods) {
      return true;
    }
    return findShareWithMerchant(paymentMethodId);
  };

  return (
    <Grid container spacing={0} className={classes.methodsContainer}>
      <Grid item xs={12} id="payment-method-banner-container">
        {/* Show this error when there has been an error while deleting a payment method */}
        {deletePaymentMethodError && <AlertBanner message={deletePaymentMethodError.message} />}

        {/* Show this error when the bank account needs to be verified */}
        {unverifiedBank && <AlertBanner message="Please verify your bank account" />}

        {/* Show this error when the bank verification has failed and needs to be redone */}
        {verificationFailedBank && <AlertBanner message="Verification failed. Please verify bank account again." />}
      </Grid>

      <Grid item xs={12} container style={methodListClass}>
        {(!sortedMethods || sortedMethods.length === 0) && (
          <>
            <Grid item container xs={12} justifyContent="center" style={{ paddingTop: 24 }}>
              <img src={EmptyWalletLogo} className={classes.emptyWalletLogo} />
            </Grid>
            <Grid item container xs={12} justifyContent="center" style={{ padding: '24px 8px' }}>
              <Typography variant="title">Wallet</Typography>
              <Typography align="center">There are no payment methods in this wallet.</Typography>
            </Grid>
          </>
        )}
        {sortedMethods &&
          sortedMethods.map(method => {
            return (
              <PaymentMethodCard
                key={sortedMethods?.indexOf(method)}
                method={method}
                isSelectedMethod={selectedMethod === method}
                isRestrictedRole={isRestrictedRole}
                customerInfo={customerInfo}
                handleCardClicked={handleCardClicked}
                handleSetDefaultClick={handleSetDefaultClick}
                handleShareWithMerchantClick={handleShareWithMerchantClick}
                handleDeleteClick={handleDeleteClick}
                handleVerifyClick={handleVerifyClick}
                findShareWithMerchant={findShareWithMerchant}
                isPayerOrCustomerSharedCard={isPayerOrCustomerSharedCard}
              />
            );
          })}
      </Grid>

      <Grid item xs={12} container id="add-method-options">
        {cardCount < MAX_CARDS && !isRestrictedRole && merchantInfo?.supportedPaymentMethods?.includes(PaymentMethodType.CreditCard) && (
          <Grid item container xs={6} className={classes.creditCardContainer}>
            <Grid
              item
              xs={12}
              onClick={() => {
                handleAddCard();
              }}
              data-cy="add-credit-card"
            >
              <Card variant="outlined" className={classes.paymentMethodCard}>
                <CardActionArea className={classes.cardActionContainer}>
                  <Grid item xs={12} className={classes.addIconContainer}>
                    <CreditCardIcon className={classes.cardIcon} />
                  </Grid>
                  <Grid item xs={12} className={classes.addText}>
                    Add Card
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        )}
        {bankCount < MAX_BANKS &&
          !isRestrictedRole &&
          merchantInfo?.supportedPaymentMethods?.includes(PaymentMethodType.PaymentBankUs) && (
            <Grid item container xs={6} className={classes.bankCardContainer}>
              <Grid item xs={12} onClick={handleAddBank} data-cy="add-bank-account">
                <Card variant="outlined" className={classes.paymentMethodCard}>
                  <CardActionArea className={classes.cardActionContainer}>
                    <Grid item xs={12} className={classes.addIconContainer}>
                      <BankIcon className={classes.cardIcon} />
                    </Grid>
                    <Grid item xs={12} className={classes.addText}>
                      Add Account
                    </Grid>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          )}
      </Grid>
    </Grid>
  );
};

export default PaymentMethods;
