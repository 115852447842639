import { Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerSuccess: {
      backgroundColor: '#eaf9ee',
      color: '#3d9452',
      padding: theme.spacing(2),
    },
    upperHeaderText: {
      fontSize: 10,
      letterSpacing: 1.5,
      fontWeight: 500,
      color: '#3d9452',
    },
    mainHeaderText: {
      fontWeight: 500,
      color: '#3d9452',
    },
    successBody: {
      padding: theme.spacing(2),
    },
    refenceText: {
      wordBreak: 'break-all',
    },
  }),
);

interface PaymentSuccessProps {
  email?: string | null;
  referenceNumber?: string;
}

const PaymentSuccess: React.FC<PaymentSuccessProps> = props => {
  const classes = useStyles();
  const { email, referenceNumber } = props;
  return (
    <Box data-cy="payment-success-message">
      <Grid container className={classes.headerSuccess}>
        <Grid item xs={12} lg={12}>
          <Typography variant="body2" className={classes.upperHeaderText}>
            THANK YOU
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography className={classes.mainHeaderText}>
            Payment {referenceNumber?.trim() && <span className={classes.refenceText}>{referenceNumber?.trim()}</span>} has been
            successfully received
            <br />
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.successBody}>
        <Grid item xs={12} lg={12}>
          <Typography variant="body2" paragraph={true}>
            You will receive an email confirmation at
            <br />
            <b>{email}</b>
          </Typography>
          <Typography variant="body2">You can safely close this window.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentSuccess;
