import { DialogContent, DialogActions, Button, Typography } from '@mui/material';
import React from 'react';
interface ConfirmDeleteProps {
  handleCancel: () => void;
  handleDelete: () => void;
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = props => {
  const { handleCancel, handleDelete } = props;
  return (
    <>
      <DialogContent>
        <Typography variant="title" id="delete-payment-method">
          Delete this payment method?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleDelete} data-cy="delete">
          DELETE
        </Button>
        <Button color="primary" onClick={handleCancel} data-cy="cancel" variant="outlined">
          CANCEL
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmDelete;
