import { CustomerRole } from '../gql-types.generated';

export interface SelectCustomerRole {
  role: CustomerRole;
  label: string;
}

export const selectCustomerRoles: SelectCustomerRole[] = [
  { role: CustomerRole.CustomerAdmin, label: 'Admin' },
  { role: CustomerRole.CustomerManager, label: 'Manager' },
  { role: CustomerRole.CustomerPayer, label: 'Payer' },
  { role: CustomerRole.CustomerReader, label: 'View Only' },
];
