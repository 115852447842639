/* eslint-disable @typescript-eslint/naming-convention */
import { WePayTokenResponse } from '../custom-types/WePayTokenResponse';
import { IntegrationApiError } from '../custom-types/IntegrationApiError';
import { PaymentMethodHolder } from '../gql-types.generated';
import { PaymentMethodStatusData } from '../features/multipleMethodsHome/MultipleMethodsHome';

// appToken = 'stage_MzA5XzhkNDRlNzFmLTQ5NWEtNGJlZS05YjhiLTExMzliZTY3NDZjYw',
// url = 'https://stage-api.wepay.com/';

export interface KycIframeLib {
  tokenize: () => Promise<{ id: string }>;
}

export interface WePayErrorDetail {
  target: string;
  target_type: string;
  reason_code: string;
  message: string;
}

export interface WePayInvalidParamsError {
  error_code: string;
  error_message: string;
  details: WePayErrorDetail[];
}

export interface WePayLib {
  configure: (environment: string, appId: string, apiVersion: string) => WePayInvalidParamsError | void | undefined;
  createKycIframe: (id: string, options: { country_code: string; ssn4_enabled: boolean }) => WePayInvalidParamsError | KycIframeLib;
  documents: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    create: (body: object, headers: object, callback: Function) => WePayInvalidParamsError | undefined;
  };
  tokens: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    create: (body: object, headers: object, callback: Function) => WePayInvalidParamsError | undefined;
  };
}

declare global {
  interface Window {
    WePay?: WePayLib;
  }
}

let configure: (environment: string, appId: string, apiVersion: string) => WePayInvalidParamsError | void | undefined = (
  _environment,
  _appId,
  _apiVersion,
) => {
  console.log('Linter wants implementation so this console.log is here to satisfy it.');
};

let createKycIframe;

let createDocument;

let createToken;

if ((window as Window).WePay) {
  const wepay = (window as Window).WePay as WePayLib;
  configure = wepay.configure;
  createKycIframe = wepay.createKycIframe;
  createDocument = wepay.documents.create;
  createToken = wepay.tokens.create;
}

export const createOnboardingIframe = createKycIframe;
export const configureWePay = configure;
export const createWePayDocument = createDocument;
export const createWePayToken = createToken;

const handleTokenError = (integrationApiError: IntegrationApiError) => {
  const { details, message } = integrationApiError;
  const errors = {
    invalidRoutingNumber: false,
    invalidAccountNumber: false,
    error: {
      message,
    },
  };
  if (details) {
    // Handle the details of the error.
    details.forEach(detail => {
      const targetField = detail.target[detail.target.length - 1];
      if (targetField === 'routing_number') {
        errors.invalidRoutingNumber = true;
      } else if (targetField === 'account_number') {
        errors.invalidAccountNumber = true;
      }
    });
  }
  return errors as PaymentMethodStatusData;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const submitPaymentMethod = (
  paymentMethodData: {
    holderName: string;
    accountType: string;
    accountNumber: string;
    routingNumber: string;
  },
  holder: PaymentMethodHolder,
  successCallback: (token: string) => void,
  failureCallback: (data: PaymentMethodStatusData) => void,
  multipleMethods: boolean,
) => {
  if (paymentMethodData.accountType && paymentMethodData.accountNumber && paymentMethodData.routingNumber) {
    if (!createWePayToken) {
      console.log('WePay not initialized. Can not submit payout method.');
      return;
    }
    // Tokenize request for create/edit/delete payout method.
    // https://dev.wepay.com/docs/basic-integration/platform-setup#create-a-token
    // Create payout method token info
    // https://dev.wepay.com/docs/basic-integration/payout-merchants?keyword=%3A%20%22payout_methods#create-a-payout-method
    // Testing:
    // "account_number": "12345678", // Use any 3-17 digit bank account number for testing.
    // "routing_number": "021000021", // Testing US routing number
    if (multipleMethods) {
      createWePayToken(
        {
          resource: 'payment_methods',
          payment_methods: {
            type: 'payment_bank_us',
            payment_bank_us: {
              account_number: paymentMethodData.accountNumber,
              routing_number: paymentMethodData.routingNumber,
              account_type: paymentMethodData.accountType,
            },
          },
        },
        {},
        (response: WePayTokenResponse) => {
          if (response && response.error_code) {
            const { error_code: errorCode, error_message: errorMessage, details } = response;
            const integrationApiError: IntegrationApiError = {
              code: errorCode,
              message: errorMessage as string,
              details: details?.map(d => {
                return { target: d.target, targetType: d.target_type, reasonCode: d.reason_code, message: d.message };
              }),
            };
            failureCallback(handleTokenError(integrationApiError));
            return;
          }
          const { id: token } = response;
          if (!token) {
            const integrationApiError: IntegrationApiError = {
              code: 'TOKEN_ERROR',
              message: 'Token not present.',
            };
            failureCallback(handleTokenError(integrationApiError));
          }
          // If no errors, dispatch mutation to the backend with the token.
          // Dispatch gql and await to close.
          successCallback(token as string);
        },
      );
    } else {
      createWePayToken(
        {
          resource: 'payment_methods',
          payment_methods: {
            type: 'payment_bank_us',
            payment_bank_us: {
              account_holder: {
                holder_name: paymentMethodData.holderName,
                email: holder?.email,
                address: {
                  country: holder.address?.country,
                  postal_code: holder.address?.postalCode,
                },
              },
              account_number: paymentMethodData.accountNumber,
              routing_number: paymentMethodData.routingNumber,
              account_type: paymentMethodData.accountType,
            },
          },
        },
        {},
        (response: WePayTokenResponse) => {
          if (response && response.error_code) {
            const { error_code: errorCode, error_message: errorMessage, details } = response;
            const integrationApiError: IntegrationApiError = {
              code: errorCode,
              message: errorMessage as string,
              details: details?.map(d => {
                return { target: d.target, targetType: d.target_type, reasonCode: d.reason_code, message: d.message };
              }),
            };
            failureCallback(handleTokenError(integrationApiError));
            return;
          }
          const { id: token } = response;
          if (!token) {
            const integrationApiError: IntegrationApiError = {
              code: 'TOKEN_ERROR',
              message: 'Token not present.',
            };
            failureCallback(handleTokenError(integrationApiError));
          }
          // If no errors, dispatch mutation to the backend with the token.
          // Dispatch gql and await to close.
          successCallback(token as string);
        },
      );
    }
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const tokenizeCCInfo = (
  tokenize: () => Promise<WePayTokenResponse>,
  successCallback: (token: string) => void,
  failureCallback: (error: Error) => void,
) => {
  if (tokenize) {
    tokenize()
      .then((response: WePayTokenResponse) => {
        const { id: creditToken } = response;
        if (successCallback && creditToken) {
          successCallback(creditToken);
        }
      })
      // eslint-disable-next-line func-names
      .catch(function (error: WePayTokenResponse) {
        // Handle error.
        if (error) {
          failureCallback(error as Error);
        }
      });
  }
};
