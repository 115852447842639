import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Grid, IconButton, Paper, Tab, Tabs, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { CreditMemoHistoryData } from '../custom-types/CreditMemoHistoryData';
import { CreditMemo, CreditMemoUsageConnection, CurrencyType, MerchantInfo, PayerCreditMemoConnection } from '../gql-types.generated';
import CreditMemosHistoryList from './CreditMemosHistoryList';
import CreditMemosOpenList from './CreditMemosOpenList';
import TabPanel from './TabPanel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeGrid: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        '& .MuiIconButton-root': {
          paddingLeft: 0,
        },
      },
    },
    selectedTab: {
      backgroundColor: '#EEF4FE',
    },
    tabRoot: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.grey[300],
    },
    headingContainer: {
      padding: theme.spacing(1, 2),
      position: 'sticky',
      top: 0,
      background: 'white',
      zIndex: 1,
    },
    merchantText: {
      fontSize: '1.1rem',
      fontWeight: 500,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    creditMemoText: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  }),
);

interface CreditMemosDetailsProps {
  open: boolean;
  close: () => void;
  merchantInfo?: MerchantInfo;
  openCreditMemoConnection?: PayerCreditMemoConnection;
  openCreditMemos?: CreditMemo[];
  openCreditMemosError?: Error;
  loadOpenCreditMemosPage: (tenantId?: string, endCursor?: string) => void;
  creditMemoHistoryConnection?: CreditMemoUsageConnection;
  creditMemoHistory?: CreditMemoHistoryData[];
  creditMemoHistoryError?: Error;
  loadCreditMemoHistoryPage: (tenantId?: string, endCursor?: string) => void;
}

const CreditMemosDetails: React.FC<CreditMemosDetailsProps> = props => {
  const {
    open,
    close,
    merchantInfo,
    openCreditMemoConnection,
    openCreditMemos,
    openCreditMemosError,
    loadOpenCreditMemosPage,
    creditMemoHistoryConnection,
    creditMemoHistory,
    creditMemoHistoryError,
    loadCreditMemoHistoryPage,
  } = props;
  const classes = useStyles();

  const [tabValue, setTabValue] = useState<number>(0);
  const matchesLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const handleTabChange = (_event: unknown, value: number) => {
    setTabValue(value);
  };

  const handleLoadOpenCreditMemos = () => {
    loadOpenCreditMemosPage(merchantInfo?.owner?.tenantId, openCreditMemoConnection?.pageInfo?.endCursor as string);
  };

  const handleLoadCreditMemoHistory = () => {
    loadCreditMemoHistoryPage(merchantInfo?.owner?.tenantId, creditMemoHistoryConnection?.pageInfo?.endCursor as string);
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          close();
        }
      }}
      maxWidth={matchesLg ? 'lg' : 'md'}
      fullWidth
      aria-labelledby="credit-memos-heading"
    >
      <Grid container>
        <Grid item container xs={12} className={classes.headingContainer}>
          <Grid item xs={11} id="credit-memos-heading">
            <Typography variant="body1">{merchantInfo?.name}</Typography>
            <Typography variant="subtitle2">Credit Memos</Typography>
          </Grid>
          <Grid item xs={1} className={classes.closeGrid}>
            <IconButton color="inherit" onClick={close} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0}>
            <Tabs indicatorColor={'primary'} textColor={'primary'} variant="fullWidth" value={tabValue} onChange={handleTabChange}>
              <Tab classes={{ selected: classes.selectedTab, root: classes.tabRoot }} label="OPEN" id="credit-open-tab" />
              <Tab classes={{ selected: classes.selectedTab, root: classes.tabRoot }} label="HISTORY" id="credit-history-tab" />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <CreditMemosOpenList
                openCreditMemoConnection={openCreditMemoConnection}
                openCreditMemos={openCreditMemos}
                openCreditMemosError={openCreditMemosError}
                loadPage={handleLoadOpenCreditMemos}
                currency={merchantInfo?.defaultCurrency as CurrencyType}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <CreditMemosHistoryList
                creditMemoHistoryConnection={creditMemoHistoryConnection}
                creditMemoHistory={creditMemoHistory}
                creditMemoHistoryError={creditMemoHistoryError}
                loadPage={handleLoadCreditMemoHistory}
                currency={merchantInfo?.defaultCurrency as CurrencyType}
              />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CreditMemosDetails;
