import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { CustomerRole, CustomerUser, MutationStatusCode, UpsertPersonStatus } from '../gql-types.generated';
import { selectCustomerRoles } from '../util/SelectCustomerRole';
import { checkIsEmailValid } from '../util/Validators';
import LoadingMask from './LoadingMask';
import AlertBanner from './AlertBanner';

interface AddOrEditCustomerUserProps {
  open: boolean;
  onClose: () => void;
  user?: CustomerUser;
  handleSave: (email: string, role: CustomerRole, sendEmail: boolean) => void;
  isLoading: boolean;
  error?: Error;
  upsertCustomerUserStatus?: UpsertPersonStatus;
  reloadCustomer: () => void;
  userList?: CustomerUser[] | null;
}

const AddOrEditCustomerUser: React.FC<AddOrEditCustomerUserProps> = props => {
  const { open, onClose, isLoading, user, error, upsertCustomerUserStatus, reloadCustomer, handleSave, userList } = props;

  const selectRoles = selectCustomerRoles;

  const [email, setEmail] = useState<string>(user?.email || '');
  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [userValid, setUserValid] = useState<boolean>(true);
  const [role, setRole] = useState<CustomerRole>(user?.role || CustomerRole.CustomerReader);
  const [sendEmail, setSendEmail] = useState<boolean>(true);

  const handleOnClose = () => {
    onClose();
    setEmail('');
    setEmailValid(true);
    setUserValid(true);
    setRole(CustomerRole.CustomerReader);
    setSendEmail(true);
  };

  useEffect(() => {
    if (upsertCustomerUserStatus && upsertCustomerUserStatus?.code !== MutationStatusCode.Error) {
      reloadCustomer();
      handleOnClose();
    }
  }, [upsertCustomerUserStatus]);

  useEffect(() => {
    setEmail(user?.email || '');
    setRole(user?.role || CustomerRole.CustomerReader);
  }, [user]);

  // this is to check whether the new user we are adding is already part of customer or not
  const checkIsUserValid = () => {
    if (!user?.email) {
      const existingUser = userList?.find(u => u?.email?.toLowerCase() === email.toLowerCase());
      if (existingUser) {
        return false;
      }
    }
    return true;
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (!emailValid) setEmailValid(true);
    if (!userValid) setUserValid(true);
  };

  const onEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmailValid(checkIsEmailValid(emailValue));
    setUserValid(checkIsUserValid());
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const roleChangeValue = event.target.value || CustomerRole.CustomerReader;
    setRole(roleChangeValue as CustomerRole);
  };

  const handleSaveClick = () => {
    const isValidEmail = checkIsEmailValid(email);
    const isValidUser = checkIsUserValid();
    if (isValidEmail && isValidUser && user?.role !== role) {
      handleSave(email.trim(), role, sendEmail);
    }
  };

  const onSendEmailChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSendEmail(checked);
  };

  return (
    <Dialog
      aria-label={'modify user dialog'}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleOnClose();
        }
      }}
      maxWidth={'xs'}
      fullWidth
      disableEscapeKeyDown={isLoading}
      disableScrollLock
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      aria-hidden="false"
    >
      <LoadingMask loading={isLoading} />
      <DialogTitle>
        <Typography variant="title" id="modal-title">
          {user?.email ? 'Edit User' : 'New User'}
        </Typography>
      </DialogTitle>

      {error && <AlertBanner message={error.message} />}

      <DialogContent>
        <Box pt={1} pb={3}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={!(emailValid && userValid)}
            helperText={emailValid && userValid ? null : `${!userValid ? 'User already exists.' : 'Invalid Email Address.'}`}
            FormHelperTextProps={{ id: 'email-address-helper-text' }}
            value={email}
            onBlur={onEmailBlur}
            onChange={onEmailChange}
            autoFocus
            label="Email address"
            type="email"
            data-cy="email"
            disabled={Boolean(user) || isLoading}
            id="email-address"
            inputProps={{ 'aria-describedby': `${emailValid ? undefined : 'email-address-helper-text'}` }}
          />
        </Box>
        <FormControl variant="outlined" fullWidth defaultValue={CustomerRole.CustomerReader} onChange={handleRoleChange}>
          <InputLabel>Account Type</InputLabel>
          <Select
            native
            label="Account Type"
            disabled={isLoading}
            value={role}
            data-cy="role"
            inputProps={{ 'aria-label': 'account type' }}
          >
            {selectRoles.map((item, index) => {
              return (
                <option key={index} value={item.role}>
                  {item.label}
                </option>
              );
            })}
          </Select>
        </FormControl>

        <FormGroup aria-label={`${user?.email ? 'Confirm changes to user by email' : 'Send email to new customer user'}`}>
          <FormControlLabel
            control={<Checkbox checked={sendEmail} color="primary" onChange={onSendEmailChange} />}
            label={
              <Typography variant="body1">{`${
                user?.email ? 'Confirm changes to user by email' : 'Send email to new customer user'
              }`}</Typography>
            }
            labelPlacement="end"
          />
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleOnClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading || !(email && emailValid && userValid && role !== user?.role)}
          onClick={handleSaveClick}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrEditCustomerUser;
