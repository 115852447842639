import PeopleIcon from '@mui/icons-material/People';
import { Divider, Grid, MenuItem, MenuList, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Person } from '../gql-types.generated';
import logoutIconInverted from '../logoutIconInverted.svg';
import { authProvider } from '../util/AuthProvider';
import { RoutePath } from '../util/Routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'start',
    },
    userInfo: {
      padding: theme.spacing(3),
    },
    menuItem: {
      height: 55,
      textDecoration: 'none',
      color: theme.palette.text.primary,
    },
    menuItemIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.grey[700],
    },
    logoutIcon: {
      marginRight: theme.spacing(2),
      width: '1em',
      height: '1em',
      fontSize: '1.5rem',
      transform: 'rotate(180deg)',
      display: 'inline-block',
    },
  }),
);

interface UserMenuProps {
  afterClick: () => void;
  viewerUser?: Person;
  isCustomerUser: boolean;
}

const UserMenu: React.FC<UserMenuProps> = props => {
  const { afterClick, viewerUser, isCustomerUser } = props;
  const classes = useStyles();
  const history = useHistory();

  const handleLogout = () => {
    authProvider.logout();
    afterClick();
  };
  const usersNavClick = () => {
    history.push(RoutePath.UserManagement);
    afterClick();
  };
  return (
    <Grid container className={classes.root}>
      <Grid className={classes.userInfo} item container xs={12}>
        <Grid item xs={9}>
          <span style={visuallyHidden}>
            {viewerUser?.firstName} {viewerUser?.lastName}
          </span>
          <Typography variant="title">
            {viewerUser?.firstName} {viewerUser?.lastName}
          </Typography>
          <Typography variant="body1">{viewerUser?.email}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MenuList data-cy="user-menu">
          {isCustomerUser && (
            <MenuItem onClick={usersNavClick} className={classes.menuItem} data-cy="manage-users">
              <PeopleIcon className={classes.menuItemIcon} />
              Users
            </MenuItem>
          )}
          <MenuItem className={classes.menuItem} onClick={handleLogout} data-cy="sign-out">
            <img className={classes.logoutIcon} alt="logout" src={logoutIconInverted} />
            Sign out
          </MenuItem>
        </MenuList>
      </Grid>
    </Grid>
  );
};

export default UserMenu;
