import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  Tab,
  TableContainer,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { CustomerRole, Maybe, PayerTransaction, Payment, PaymentRequestStatus, PaymentStatus } from '../gql-types.generated';
import { paymentStatusDisplay } from '../util/PaymentStatus';
import DownloadInvoices from './DownloadInvoices';
import TabPanel from './TabPanel';
import InfoIcon from '@mui/icons-material/Info';
import SpecificPaymentsDetails from './SpecificPaymentsDetails';
import LoadingMask from './LoadingMask';

const useStylesDesktop = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(0, 0, 4, 0),
      position: 'relative',
    },
    infoSections: {
      margin: 0,
      padding: theme.spacing(1.5),
    },
    gridSections: {
      margin: 0,
      padding: theme.spacing(1.5, 0),
    },
    contentSectionMd: {
      margin: 0,
      padding: theme.spacing(0, 1.5, 0, 2),
      marginRight: 24,
    },
    contentSectionSm: {
      margin: 0,
      padding: theme.spacing(0, 1.5, 0, 2),
      marginRight: 36,
    },
    requestId: {
      padding: 0,
      margin: 0,
    },
    requestIdLink: {
      fontWeight: 'bold',
      margin: 0,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    closeButtonContainer: {
      textAlign: 'right',
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
    table: {
      width: '100%',
    },
    tooltip: {
      cursor: 'pointer',
      paddingBottom: 0,
    },
    selectedTab: {
      backgroundColor: '#EEF4FE',
    },
    tabRoot: {
      padding: theme.spacing(0, 6),
      minWidth: '50%',
    },
    tabsIndicator: {
      height: 0,
      width: 0,
    },
    tabsRoot: {
      borderBottom: 'solid 3px #0275D8',
    },
    cell: {
      color: theme.palette.grey[800],
    },
    headerCell: {
      color: theme.palette.grey[800],
      backgroundColor: '#FFFFFF',
    },
    paymentButton: {
      marginBottom: theme.spacing(1),
    },
    lastCellMd: {
      width: '20%',
      backgroundColor: '#FFFFFF',
    },
    remainingDiscount: {
      textDecoration: 'line-through',
      display: 'inline-block',
    },
    discountAmount: {
      display: 'inline-block',
      paddingLeft: theme.spacing(0.5),
    },
    referenceContent: {
      fontWeight: 'bold',
      margin: 0,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    copyIcon: {
      fontSize: '1rem',
      marginLeft: 8,
    },
    actionButtonsContainerSm: {
      paddingLeft: 32,
      paddingTop: theme.spacing(1),
    },
    merchantTextContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    merchantText: {
      paddingLeft: theme.spacing(0.5),
      wordBreak: 'break-word',
    },
    dialogWidth: {
      minWidth: 700,
      maxWidth: 700,
    },
    dataGrid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
      '& .MuiDataGrid-cell': {
        fontSize: '0.875rem',
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    noPadding: {
      padding: 0,
    },
    dialogTitlePadding: {
      padding: theme.spacing(1.5),
    },
  }),
);

interface PaymentDetailsProps {
  isMobileView: boolean;
  paymentDetailsVisible: boolean;
  payment: PayerTransaction | null | undefined;
  onClickPay: (payment: PayerTransaction) => void;
  closePaymentDetails: () => void;
  detailsSelected?: boolean;
  creditAllowed: boolean;
  customerRole: CustomerRole | undefined;
  isLoadingSpecificPayment: boolean;
  specificPaymentRecord?: Payment;
  fetchSpecificPaymentRecord: (id: string, tenantId: string) => void;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = props => {
  const classesDesktop = useStylesDesktop();
  const {
    paymentDetailsVisible,
    payment,
    onClickPay,
    closePaymentDetails,
    detailsSelected,
    creditAllowed,
    customerRole,
    isLoadingSpecificPayment,
    specificPaymentRecord,
    fetchSpecificPaymentRecord,
  } = props;
  const paymentRequest = payment?.paymentRequest;
  const paymentsHistoryCount = (paymentRequest?.payments.length || 0) > 0;
  const merchantText = paymentRequest?.additionalInfo;
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isRestrictedRole = customerRole && customerRole === CustomerRole.CustomerReader;

  const [referenceCopied, setReferenceCopied] = useState(false);
  const [tabValue] = useState(0);
  const [openSpecificPaymentRecord, setOpenSpecificPaymentRecord] = useState(false);

  //calculating discount amount
  const discountEndDate = DateTime.fromISO(payment?.paymentRequest?.discountEndDate);
  const discountEndValid = discountEndDate.isValid && discountEndDate > DateTime.utc();
  const discountAmount = (discountEndValid && payment?.paymentRequest?.discountAmount) || 0;

  const getPaymentMethod = (paymentRecord?: Payment | null) => {
    const isCreditPayment = (paymentRecord?.creditAmount &&
      paymentRecord.creditAmount > 0 &&
      (paymentRecord.amount === 0 || !paymentRecord?.amount)) as boolean;

    if (!isCreditPayment && paymentRecord?.paymentMethod?.creditCard) {
      return `${paymentRecord?.paymentMethod.creditCard.cardBrand?.substring(0, 1)}${paymentRecord?.paymentMethod.creditCard.cardBrand
        ?.substring(1)
        .toLowerCase()} ${paymentRecord?.paymentMethod.creditCard.lastFour}`;
    }
    if (!isCreditPayment && paymentRecord?.paymentMethod?.paymentBank) {
      return `Bank ${paymentRecord?.paymentMethod.paymentBank.lastFour}`;
    }
    return 'None';
  };

  const openSpecificPaymentsDetails = (id: string) => {
    if (specificPaymentRecord && specificPaymentRecord.id === id) {
      setOpenSpecificPaymentRecord(true);
    } else {
      const tenantId = payment?.paymentRequest?.owner.tenantId;
      if (id && tenantId) {
        fetchSpecificPaymentRecord(id, tenantId);
      }
    }
  };

  const closeSpecificPaymentsDetails = () => {
    setOpenSpecificPaymentRecord(false);
  };

  useEffect(() => {
    if (!paymentDetailsVisible) {
      setOpenSpecificPaymentRecord(false);
    }
  }, [paymentDetailsVisible]);

  useEffect(() => {
    if (specificPaymentRecord && paymentDetailsVisible) {
      setOpenSpecificPaymentRecord(true);
    }
  }, [specificPaymentRecord]);

  // Keep working towards all grids conversion to datagridpro in payer portal.
  const paymentHistoryColumns: GridColDef[] = [
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'paymentDate',
      sortable: false,
      headerName: 'Payment Date',
      minWidth: 130,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'paymentMethod',
      sortable: false,
      headerName: 'Payment Method',
      minWidth: 150,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'paid',
      headerName: 'Paid',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      minWidth: 90,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'creditApplied',
      sortable: false,
      hide: !creditAllowed,
      headerName: 'Credit Applied',
      headerAlign: 'right',
      align: 'right',
      minWidth: 120,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'total',
      sortable: false,
      hide: !creditAllowed,
      headerName: 'Total',
      headerAlign: 'right',
      align: 'right',
      minWidth: 120,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'balance',
      sortable: false,
      headerName: 'Balance',
      headerAlign: 'right',
      align: 'right',
      minWidth: 120,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'status',
      sortable: false,
      headerName: 'Status',
      minWidth: 90,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'detailsAction',
      sortable: false,
      headerName: '',
      width: 50,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        // eslint-disable-next-line no-underscore-dangle
        const row = params.row._raw;
        return (
          <IconButton
            size="small"
            onClick={() => {
              openSpecificPaymentsDetails(row?.id as string);
            }}
            color="primary"
          >
            <InfoIcon />
          </IconButton>
        );
      },
    },
  ];
  const actions = (
    <Grid item xs={12} md={4} className={matches ? classesDesktop.actionButtonsContainerSm : ''}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="small"
        onClick={() => {
          if (payment) {
            onClickPay(payment);
            closePaymentDetails();
          }
        }}
        disabled={detailsSelected === true || isRestrictedRole}
        className={classesDesktop.paymentButton}
        data-cy="make-payment"
      >
        {payment?.paymentRequest?.status === PaymentRequestStatus.Failed ? 'RETRY PAYMENT' : 'MAKE PAYMENT'}
      </Button>
      <DownloadInvoices
        isIconButton={false}
        invoiceUrl={payment?.paymentRequest?.invoiceLink}
        invoicesUrlList={payment?.paymentRequest?.invoiceLinks}
      />
    </Grid>
  );
  const getPaymentHistoryRows = () => {
    const paymentRequest = payment?.paymentRequest;
    const totalAmount = paymentRequest?.amount || 0;
    let totalPaid = 0;
    return paymentRequest?.payments.map((row: Maybe<Payment>, index: number) => {
      const node = row;
      if (!node) {
        return {} as GridRowModel;
      }
      if (
        node?.status === PaymentStatus.Pending ||
        node?.status === PaymentStatus.PendingAuthorization ||
        node?.status === PaymentStatus.Completed
      ) {
        totalPaid += (node?.amount || 0) + (node?.creditAmount || 0);
      }
      const due = totalAmount - totalPaid;
      return {
        _raw: node,
        id: `${node?.id}-${index}`,
        paymentDate: new Date(node?.attemptTimestamp || node?.createdAt).toLocaleDateString(),
        paymentMethod: getPaymentMethod(node),
        paid: new Intl.NumberFormat('en', {
          style: 'currency',
          currency: node?.currency || 'USD',
          currencyDisplay: 'symbol',
        }).format((node?.amount || 0) / 100),
        creditApplied: new Intl.NumberFormat('en', {
          style: 'currency',
          currency: node?.currency || 'USD',
          currencyDisplay: 'symbol',
        }).format((node?.creditAmount || 0) / 100),
        total: new Intl.NumberFormat('en', {
          style: 'currency',
          currency: node?.currency || 'USD',
          currencyDisplay: 'symbol',
        }).format(((node?.amount || 0) + (node?.creditAmount || 0)) / 100),
        balance: new Intl.NumberFormat('en', {
          style: 'currency',
          currency: node?.currency || 'USD',
          currencyDisplay: 'symbol',
        }).format(due / 100),
        status: node?.amountDisputed
          ? 'Disputed'
          : paymentStatusDisplay.find(item => {
              return item.name === node?.status;
            })?.display,
      } as GridRowModel;
    }) as GridRowModel[];
  };

  return (
    <>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closePaymentDetails();
          }
        }}
        open={paymentDetailsVisible}
        fullWidth={paymentsHistoryCount || matches}
        maxWidth={'md'}
        classes={{ paper: paymentsHistoryCount || matches ? '' : classesDesktop.dialogWidth }}
        aria-labelledby="modal-heading"
        data-cy="payment-details-modal"
      >
        <LoadingMask loading={isLoadingSpecificPayment} />
        <DialogTitle classes={{ root: classesDesktop.dialogTitlePadding }}>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="subtitle2" id="modal-heading">
                PAYMENT REQUEST
              </Typography>
              <Typography className={classesDesktop.requestId}>
                <Tooltip
                  className={classesDesktop.tooltip}
                  title={`${referenceCopied ? 'Copied' : 'Copy'} to clipboard`}
                  placement="bottom-start"
                >
                  <Link
                    className={classesDesktop.referenceContent}
                    color="primary"
                    onClick={() => {
                      setReferenceCopied(true);
                      navigator.clipboard.writeText(payment?.paymentRequest?.referenceNumber?.trim() || '');
                    }}
                    onMouseEnter={() => {
                      if (referenceCopied) setReferenceCopied(false);
                    }}
                    onFocus={() => {
                      if (referenceCopied) setReferenceCopied(false);
                    }}
                    href="#"
                    data-cy="payment-id-copy"
                    underline="hover"
                  >
                    <Typography variant="title">{payment?.paymentRequest?.referenceNumber?.trim()}</Typography>
                    <CopyIcon className={classesDesktop.copyIcon} />
                  </Link>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={2} className={classesDesktop.closeButtonContainer}>
              <IconButton
                aria-label="close"
                title="close"
                className={classesDesktop.closeButton}
                onClick={closePaymentDetails}
                data-cy="payment-details-close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classesDesktop.noPadding}>
          <Box className={classesDesktop.dialogContent}>
            <Box className={matches ? classesDesktop.contentSectionSm : classesDesktop.contentSectionMd}>
              <Grid container>
                <Grid container item xs={12} md={8}>
                  <Grid container spacing={1}>
                    {payment?.paymentRequest?.orderNumber && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography variant="bodyMediumEmphasis">Order#</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography variant="body1">{payment?.paymentRequest?.orderNumber}</Typography>
                        </Grid>
                      </>
                    )}

                    {payment?.paymentRequest?.customerPONumber && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography variant="bodyMediumEmphasis">Customer PO#</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography variant="body1">{payment?.paymentRequest?.customerPONumber}</Typography>
                        </Grid>
                      </>
                    )}

                    {payment?.paymentRequest?.invoiceNumber && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography variant="bodyMediumEmphasis">Invoice#</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography variant="body1">{payment?.paymentRequest?.invoiceNumber}</Typography>
                        </Grid>
                      </>
                    )}

                    {payment?.payment?.status === PaymentStatus.Canceled && payment?.payment?.cancelReason && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography variant="bodyMediumEmphasis">Cancelled Reason</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography variant="body1">{payment?.payment?.cancelReason}</Typography>
                        </Grid>
                      </>
                    )}

                    {payment?.payment?.status === PaymentStatus.Failed && payment?.payment?.failureReason && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography variant="bodyMediumEmphasis">Failure Reason</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography variant="body1">{payment?.payment?.failureReason}</Typography>
                        </Grid>
                      </>
                    )}

                    {payment?.payment?.status === PaymentStatus.Pending && payment?.payment?.pendingReason && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography variant="bodyMediumEmphasis">Pending Reason</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography variant="body1">{payment?.payment?.pendingReason}</Typography>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                      <Typography variant="bodyMediumEmphasis">Requested On</Typography>
                    </Grid>
                    <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                      <Typography variant="body1">{new Date(payment?.paymentRequest?.createdAt).toLocaleDateString()}</Typography>
                    </Grid>

                    {payment?.paymentRequest?.discountEndDate && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography variant="bodyMediumEmphasis">Discount End Date</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography variant="body1">
                            {new Date(payment?.paymentRequest?.discountEndDate).toLocaleDateString()}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {payment?.paymentRequest?.dueDate && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography variant="bodyMediumEmphasis">Final Payment Due</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography variant="body1">{new Date(payment?.paymentRequest?.dueDate).toLocaleDateString()}</Typography>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                      <Typography variant="bodyMediumEmphasis">Request Amount</Typography>
                    </Grid>
                    <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                      <Typography variant="body1">
                        {new Intl.NumberFormat('en', {
                          style: 'currency',
                          currency: payment?.currency || 'USD',
                          currencyDisplay: 'symbol',
                        }).format((payment?.paymentRequest?.amount || 0) / 100)}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                      <Typography variant="bodyMediumEmphasis">Remaining Balance</Typography>
                    </Grid>
                    <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                      {discountAmount ? (
                        <>
                          <Typography variant="body1" className={classesDesktop.remainingDiscount}>
                            {new Intl.NumberFormat('en', {
                              style: 'currency',
                              currency: payment?.currency || 'USD',
                              currencyDisplay: 'symbol',
                            }).format((payment?.paymentRequest?.totalDue || 0) / 100)}
                          </Typography>

                          <Typography variant="body1" color="error" className={classesDesktop.discountAmount}>
                            {new Intl.NumberFormat('en', {
                              style: 'currency',
                              currency: payment?.currency || 'USD',
                              currencyDisplay: 'symbol',
                            }).format(((payment?.paymentRequest?.totalDue || 0) - discountAmount) / 100)}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="body1">
                          {new Intl.NumberFormat('en', {
                            style: 'currency',
                            currency: payment?.currency || 'USD',
                            currencyDisplay: 'symbol',
                          }).format((payment?.paymentRequest?.totalDue || 0) / 100)}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                      <Typography variant="bodyMediumEmphasis">Status</Typography>
                    </Grid>
                    <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                      <Typography variant="body1">
                        {
                          paymentStatusDisplay.find(item => {
                            return item.name === payment?.paymentRequest?.status;
                          })?.display
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {!matches && actions}
                {merchantText && (
                  <Grid container xs={12} className={classesDesktop.merchantTextContainer}>
                    <Grid item xs={6} md={paymentsHistoryCount ? 2.65 : 4}>
                      <Typography variant="bodyMediumEmphasis">Merchant Text</Typography>
                    </Grid>
                    <Grid item xs={6} md={paymentsHistoryCount ? 9.35 : 8} className={classesDesktop.merchantText}>
                      <Typography variant="body1">{merchantText}</Typography>
                    </Grid>
                  </Grid>
                )}
                {matches && actions}
              </Grid>
            </Box>
            {paymentsHistoryCount && (
              <Box className={classesDesktop.gridSections}>
                <Paper elevation={0}>
                  <Tabs
                    indicatorColor={'primary'}
                    textColor={'primary'}
                    value={tabValue}
                    classes={{ root: classesDesktop.tabsRoot, indicator: classesDesktop.tabsIndicator }}
                  >
                    <Tab
                      classes={{ selected: classesDesktop.selectedTab, root: classesDesktop.tabRoot }}
                      label="PAYMENT HISTORY"
                      id="payment-history-tab"
                      data-cy="payment-history-tab"
                    />
                  </Tabs>
                  <TabPanel value={tabValue} index={0} data-cy="payment-history-panel">
                    <TableContainer className={classesDesktop.table}>
                      <DataGridPro
                        rowHeight={58}
                        aria-label="payment history"
                        hideFooterPagination
                        hideFooter
                        hideFooterSelectedRowCount
                        className={classesDesktop.dataGrid}
                        rows={getPaymentHistoryRows()}
                        columns={paymentHistoryColumns}
                        autoHeight
                      />
                    </TableContainer>
                  </TabPanel>
                </Paper>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      {specificPaymentRecord && (
        <SpecificPaymentsDetails
          open={openSpecificPaymentRecord}
          close={closeSpecificPaymentsDetails}
          paymentRecord={specificPaymentRecord}
          isCreditMemoEnabled={creditAllowed}
        />
      )}
    </>
  );
};

export default PaymentDetails;
