import { gqlClient } from '../../components/AppProvider';
import { Person } from '../../gql-types.generated';
import { queryPersonByEmail } from '../../gql/QueryPersonByEmail';
import { mutationUpsertPayer } from '../../gql/MutationUpsertPayerPerson';
import { AppDispatch } from '../../store';
import {
  incrementRequestsInFlight,
  fetchPayerAccountStatus,
  fetchViewerUserByEmailSuccess,
  fetchUserExists,
  decrementRequestsInFlight,
  fetchError,
} from './AppSlice';

export const upsertPayerAccount =
  (options: {
    email: string;
    id?: string;
    firstName?: string;
    lastName?: string;
    line1?: string;
    line2?: string;
    postalCode?: string;
    country?: string;
    phoneCountryCode?: string;
    phoneNumber?: string;
  }) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    try {
      const { email, id, firstName, lastName, line1, line2, postalCode, country, phoneCountryCode, phoneNumber } = options;
      const payerStatus = await mutationUpsertPayer(gqlClient, {
        email,
        id,
        firstName,
        lastName,
        line1,
        line2,
        postalCode,
        country,
        phoneCountryCode,
        phoneNumber,
      });
      if (payerStatus) {
        dispatch(fetchPayerAccountStatus(payerStatus));
      }
      dispatch(decrementRequestsInFlight());
    } catch (e) {
      dispatch(fetchError(e));
      dispatch(decrementRequestsInFlight());
    }
  };

export const fetchViewerUserByEmail =
  (email: string, tenantId?: string) =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementRequestsInFlight());
    try {
      const viewerUser = await queryPersonByEmail(gqlClient, email, tenantId);
      if (viewerUser) {
        dispatch(fetchViewerUserByEmailSuccess(viewerUser as Person));
        dispatch(fetchUserExists(true));
      } else {
        dispatch(fetchUserExists(false));
      }

      dispatch(decrementRequestsInFlight());
    } catch (e) {
      if (e.message.indexOf('Person not found') !== -1) {
        dispatch(fetchUserExists(false));
      }
      dispatch(fetchError(e));
      dispatch(decrementRequestsInFlight());
    }
  };
