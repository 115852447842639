import { Box, Dialog, DialogContent, Grid, Paper, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createStyles, makeStyles } from '@mui/styles';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { RoutePath } from '../../util/Routes';
import ConfirmDelete from '../../components/ConfirmDelete';
import CreditMemosDetails from '../../components/CreditMemosDetails';
import LoadingMask from '../../components/LoadingMask';
import MissingRefundPolicy from '../../components/MissingRefundPolicy';
import PaymentInfoNotFound from '../../components/PaymentInfoNotFound';
import PaymentMethodAdd from '../../components/PaymentMethodAdd';
import PaymentMethods from '../../components/PaymentMethods';
import PaymentMethodVerification from '../../components/PaymentMethodVerification';
import PaymentProcessing from '../../components/PaymentProcessing';
import PaymentReceived from '../../components/PaymentReceived';
import PaymentUnknown from '../../components/PaymentUnknown';
import PayPaymentRequest from '../../components/PayPaymentRequest';
import SettingsMenu from '../../components/SettingsMenu';
import {
  AcceptedPaymentMethod,
  Address,
  CardType,
  CreatePaymentStatus,
  CreditCard,
  CurrencyType,
  MerchantInfo,
  MutationStatusCode,
  PayerPaymentMethod,
  PaymentMethodHolder,
  PaymentMethodHolderInput,
  PaymentMethodRequestRestriction,
  PaymentMethodRequestStatus,
  PaymentMethodStatus,
  PaymentMethodType,
  PaymentRequestAllocationInput,
  PaymentRequestInfo,
  PaymentRequestStatus,
  PaymentStatus,
  PendingReasonCode,
  Person,
  Phone,
  UpsertPersonStatus,
} from '../../gql-types.generated';
import MapCardBrandToAcceptedPaymentMethod from '../../util/MapCardBrandToAcceptedPaymentMethod';
import { getIsRequestPayable } from '../../util/Util';
import { fetchViewerUserByEmail } from '../app/AppActions';
import {
  fetchIsOnHome,
  fetchMenuVisible,
  fetchPayerAccountStatus,
  fetchPaymentMethodRequest,
  fetchSelectedMerchant,
  fetchSelectedMerchantInfo,
  fetchSelectedMerchantName,
  fetchWalletButtonVisible,
  selectMenuVisible,
  selectPayerTransactionSummaryByMerchant,
  selectPendingPaymentMethodRequest,
  selectSelectedMerchant,
  selectSelectedMerchantInfo,
  selectUpsertingPayerBankAccount,
} from '../app/AppSlice';
import ManageUsers from '../manage-users/ManageUsers';
import { capturePayerCustomer } from '../manage-users/ManageUsersAction';
import {
  fetchCustomer,
  fetchCustomerInfo,
  selectCustomer,
  selectCustomerInfo,
  selectIsLoadingCustomer,
} from '../manage-users/ManageUsersSlice';
import MerchantRequestsView from '../merchantRequestsView/MerchantRequestsView';
import MerchantSummaryView from '../merchantSummaryView/MerchantSummaryView';
import {
  captureCompletePaymentMethodRequest,
  createOneTimeUsePaymentMethod,
  createPayerConsolidatedOrPartialPayment,
  createPayerPayment,
  createPayerPaymentMethod,
  createPaymentMethodSDK,
  deletePayerPaymentMethod,
  fetchCalculatedConvenienceFees,
  fetchCreditMemoBalance,
  fetchCreditMemosHistory,
  fetchOpenCreditMemos,
  fetchPaymentRequestInfo,
  fetchPaymentsDue,
  fetchRecentTransactions,
  getPayerTransactionSummaryByMerchant,
  getTenantIntegrationSettings,
  setDefaultPaymentMethod,
  setSharedWithMerchantPaymentMethod,
  verifyBankPaymentMethod,
} from './MultipleMethodsHomeActions';
import {
  addBillingAddress,
  clearCreditMemoHistory,
  clearCreditMemoHistoryConnection,
  clearOpenCreditMemoConnection,
  clearOpenCreditMemos,
  clearPaymentRequestInfo,
  clearPaymentsDue,
  clearRecentTransactions,
  fetchBillingAddresses,
  fetchConfirmDeleteVisible,
  fetchConsolidatedOrPartialCancelClicked,
  fetchCreditMemoDetailsOpen,
  fetchCustomerRole,
  fetchDeletePayerPaymentMethodStatus,
  fetchDeletePaymentMethodError,
  fetchDisplayRefundPolicy,
  fetchHasSearchString,
  fetchOneTimeUsePaymentMethod,
  fetchPayerProfile,
  fetchPaymentAddVisible,
  fetchPaymentError,
  fetchPaymentMethodError,
  fetchPaymentMethodForEdit,
  fetchPaymentRequestInfoList,
  fetchLastTabPath,
  fetchSelectedPaymentMethod,
  fetchSnackBarAlert,
  fetchSnackBarAlertSeverity,
  fetchSnackBarOpen,
  fetchUrlToken,
  fetchVerificationVisible,
  fetchVerifyInfoVisible,
  fetchVerifyPaymentMethodStatus,
  selectBillingAddresses,
  selectConfirmDeleteVisible,
  selectCreditMemoBalance,
  selectCreditMemoBalanceError,
  selectCreditMemoDetailsOpen,
  selectCreditMemoHistory,
  selectCreditMemoHistoryConnection,
  selectCreditMemoHistoryError,
  selectCustomerRole,
  selectDefaultPaymentMethod,
  selectDeletePayerPaymentMethodStatus,
  selectDeletePaymentMethodError,
  selectDeletingPaymentMethod,
  selectDisplayRefundPolicy,
  selectHasSearchString,
  selectLoadingCreditMemoBalance,
  selectLoadingPaymentMethods,
  selectNetworkBusy,
  selectOneTimeUsePaymentMethod,
  selectOpenCreditMemoConnection,
  selectOpenCreditMemos,
  selectOpenCreditMemosError,
  selectPaymentAddVisible,
  selectPaymentError,
  selectPaymentMethodAdded,
  selectPaymentMethodError,
  selectPaymentMethodForEdit,
  selectPaymentMethods,
  selectPaymentRequestInfo,
  selectPaymentRequestInfoList,
  selectSelectedPaymentMethod,
  selectSortedPaymentMethods,
  selectVerificationVisible,
  selectVerifyInfoVisible,
  selectVerifyPaymentMethodStatus,
  captureUpdateFromPagination,
  selectCalculatedConvenienceFees,
  selectIsCalculatedConvenienceFeesInFlight,
  captureCalculatedConvenienceFees,
  selectLastTabPath,
  fetchCompletePaymentMethodRequestError,
  selectCompletePaymentMethodRequestInFlight,
  selectCompletePaymentMethodRequestError,
  selectCustomerMethodsAsPayerMethods,
  selectSortedCustomerPaymentMethods,
  selectTenantIntegrationSettings,
} from './MultipleMethodsHomeSlice';
import Breadcrumbs from '../../components/Breadcrumbs';
import PaymentMethodRequestBanner from '../../components/PaymentMethodRequestBanner';
import { checkIsSharedMethod } from '../../util/CheckIsSharedMethod';
import { ApteanPaySDKToken } from '../../util/ApteanPay';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(1.5, 2, 0, 2),
    },
    paper: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    mobileManageAccount: {
      marginBottom: theme.spacing(2),
    },
    icon: {
      fontSize: '1.5rem',
      color: theme.palette.text.secondary,
    },
    homeView: {
      height: '100%',
    },
    crumbs: {
      height: '100%',
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up('xs')]: {
        padding: theme.spacing(1),
      },
    },
    crumbWrap: {
      flex: 0,
    },
    crumbsWithSettings: {
      marginLeft: 372,
    },
    crumbsOnPayment: {
      [theme.breakpoints.up('md')]: {
        maxWidth: 620,
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
      },
    },
    transitionHome: {
      alignSelf: 'center',
    },
    transition: {
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

export interface PaymentMethodStatusData {
  error: Error;
  tokenError: Error;
  statusSuccess: boolean;
  invalidRoutingNumber: boolean;
  invalidAccountNumber: boolean;
}

interface MultipleMethodsHomeProps {
  payerAccountStatus?: UpsertPersonStatus;
  userEmail?: string;
  viewerUser?: Person;
}

const MultipleMethodsHome: React.FC<MultipleMethodsHomeProps> = props => {
  const { viewerUser, userEmail, payerAccountStatus } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const lastTabPath = useSelector(selectLastTabPath);
  const paymentError = useSelector(selectPaymentError);
  const paymentRequestInfo = useSelector(selectPaymentRequestInfo);
  const hasSearchString = useSelector(selectHasSearchString);
  const displayRefundPolicy = useSelector(selectDisplayRefundPolicy);
  const deletePayerMethodStatus = useSelector(selectDeletePayerPaymentMethodStatus);
  const confirmDeleteVisible = useSelector(selectConfirmDeleteVisible);
  const verificationVisible = useSelector(selectVerificationVisible);
  const verifyInfoVisible = useSelector(selectVerifyInfoVisible);
  const paymentAddVisible = useSelector(selectPaymentAddVisible);
  const verifyPaymentMethodStatus = useSelector(selectVerifyPaymentMethodStatus);
  const upsertingPayerBankAccount = useSelector(selectUpsertingPayerBankAccount);
  const paymentMethods = useSelector(selectPaymentMethods);
  const customerMethodsAsPaymentMethods = useSelector(selectCustomerMethodsAsPayerMethods);
  const sortedPaymentMethods = useSelector(selectSortedPaymentMethods);
  const sortedCustomerMethods = useSelector(selectSortedCustomerPaymentMethods);
  const paymentMethodError = useSelector(selectPaymentMethodError);
  const paymentMethodAdded = useSelector(selectPaymentMethodAdded);
  const creditMemoBalance = useSelector(selectCreditMemoBalance);
  const creditMemoBalanceError = useSelector(selectCreditMemoBalanceError);
  const loadingCreditMemoBalance = useSelector(selectLoadingCreditMemoBalance);
  const openCreditMemoConnection = useSelector(selectOpenCreditMemoConnection);
  const openCreditMemos = useSelector(selectOpenCreditMemos);
  const openCreditMemosError = useSelector(selectOpenCreditMemosError);
  const creditMemoDetailsOpen = useSelector(selectCreditMemoDetailsOpen);
  const creditMemoHistoryConnection = useSelector(selectCreditMemoHistoryConnection);
  const creditMemoHistory = useSelector(selectCreditMemoHistory);
  const creditMemoHistoryError = useSelector(selectCreditMemoHistoryError);
  // This will be the default selected billing and payment method info.
  const defaultPaymentMethod = useSelector(selectDefaultPaymentMethod);
  const selectedPaymentMethod = useSelector(selectSelectedPaymentMethod);
  const deletePaymentMethodError = useSelector(selectDeletePaymentMethodError);
  const methodForEdit = useSelector(selectPaymentMethodForEdit);
  const networkBusy = useSelector(selectNetworkBusy);
  const billingAddresses = useSelector(selectBillingAddresses);
  const payerTransactionSummaryByMerchant = useSelector(selectPayerTransactionSummaryByMerchant);
  const settingsMenuVisible = useSelector(selectMenuVisible);
  const selectedMerchant = useSelector(selectSelectedMerchant);
  const selectedMerchantInfo = useSelector(selectSelectedMerchantInfo);
  const paymentRequestInfoList = useSelector(selectPaymentRequestInfoList);
  const loadingPaymentMethods = useSelector(selectLoadingPaymentMethods);
  const deletingPaymentMethod = useSelector(selectDeletingPaymentMethod);
  const customerRole = useSelector(selectCustomerRole);
  const oneTimeUsePaymentMethod = useSelector(selectOneTimeUsePaymentMethod);
  const customerInfo = useSelector(selectCustomerInfo);
  const paymentMethodRequest = useSelector(selectPendingPaymentMethodRequest);
  const payerCustomer = useSelector(selectCustomer);
  const calculatedConvenienceFees = useSelector(selectCalculatedConvenienceFees);
  const isCalculatedConvenienceFeesInFlight = useSelector(selectIsCalculatedConvenienceFeesInFlight);
  const completePaymentMethodRequestInFlight = useSelector(selectCompletePaymentMethodRequestInFlight);
  const completePaymentMethodRequestError = useSelector(selectCompletePaymentMethodRequestError);
  const isLoadingCustomer = useSelector(selectIsLoadingCustomer);
  const integrationSettings = useSelector(selectTenantIntegrationSettings);
  const isMobileSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [sendingPayment, setSendingPayment] = useState<boolean>(false);
  const [isAddCardClicked, setIsAddCardClicked] = useState<boolean>(false);
  const [isAddBankClicked, setIsAddBankClicked] = useState<boolean>(false);
  const [isIFrameVisible, setIsIFrameVisible] = useState<boolean>(false);
  const locationIsPayment = location.pathname === RoutePath.Payment || hasSearchString;
  // eCheckAllowed is in control of the state of allowing a bank account being added/used for payment.
  // If no payment request info, allow eCheck for managing the account.
  const [eCheckAllowed, setECheckAllowed] = useState(
    (!!paymentRequestInfo?.supportedPaymentMethods &&
      paymentRequestInfo?.supportedPaymentMethods.includes(PaymentMethodType.PaymentBankUs)) ||
      (!!selectedMerchantInfo?.supportedPaymentMethods &&
        selectedMerchantInfo?.supportedPaymentMethods.includes(PaymentMethodType.PaymentBankUs)),
  );
  const [cardAllowed, setCardAllowed] = useState(
    (!!paymentRequestInfo?.supportedPaymentMethods &&
      paymentRequestInfo?.supportedPaymentMethods.includes(PaymentMethodType.CreditCard)) ||
      (!!selectedMerchantInfo?.supportedPaymentMethods &&
        selectedMerchantInfo?.supportedPaymentMethods.includes(PaymentMethodType.CreditCard)),
  );
  const [bankForVerification, setBankForVerification] = useState<PayerPaymentMethod | undefined>();
  const [addPaymentMethodType, setAddPaymentMethodType] = useState<PaymentMethodType | undefined>();
  const [paymentMethodAddStep, setPaymentMethodAddStep] = useState<number>(0);
  const [useCreditMemo, setUseCreditMemo] = useState<boolean>(false);
  const [addingMethodOnPaymentScreen, setAddingMethodOnPaymentScreen] = useState<boolean>(false);
  const [sharedCards, setSharedCards] = useState<PayerPaymentMethod[] | undefined>(undefined);
  // Page size until infinite scroller loads more
  const pageSize = 15;

  useEffect(() => {
    if (settingsMenuVisible === false) {
      if (paymentMethodError) dispatch(fetchPaymentMethodError());
      if (deletePaymentMethodError) dispatch(fetchDeletePaymentMethodError());
    }
  }, [settingsMenuVisible]);

  useEffect(() => {
    if (paymentRequestInfo && paymentRequestInfo.supportedPaymentMethods && paymentRequestInfo.supportedPaymentMethods.length > 0) {
      setECheckAllowed(paymentRequestInfo.supportedPaymentMethods.includes(PaymentMethodType.PaymentBankUs));
      setCardAllowed(paymentRequestInfo.supportedPaymentMethods.includes(PaymentMethodType.CreditCard));
    }
    if (
      selectedMerchantInfo &&
      selectedMerchantInfo.supportedPaymentMethods &&
      selectedMerchantInfo.supportedPaymentMethods.length > 0
    ) {
      setECheckAllowed(selectedMerchantInfo.supportedPaymentMethods.includes(PaymentMethodType.PaymentBankUs));
      setCardAllowed(selectedMerchantInfo.supportedPaymentMethods.includes(PaymentMethodType.CreditCard));
    }
  }, [paymentRequestInfo, selectedMerchantInfo]);

  // detects changes to url and acts accordingly
  useEffect(() => {
    if (location.search === '') {
      dispatch(fetchHasSearchString(false));
      dispatch(clearPaymentRequestInfo());
    } else {
      dispatch(fetchHasSearchString(true));
      dispatch(fetchUrlToken(window.location.search.substring(1)));
      dispatch(fetchPaymentRequestInfo(window.location.search.substring(1)));
    }
    if (location.pathname !== RoutePath.Payment && !hasSearchString) {
      dispatch(fetchPaymentAddVisible(false));
    }
    dispatch(fetchIsOnHome(location.pathname === RoutePath.Payments || location.pathname === RoutePath.History));
  }, [location]);

  useEffect(() => {
    if (userEmail && payerAccountStatus?.code === MutationStatusCode.Success) {
      dispatch(fetchViewerUserByEmail(userEmail, selectedMerchant));
      dispatch(fetchPayerAccountStatus(undefined));
    }
  }, [payerAccountStatus]);

  useEffect(() => {
    if (userEmail && deletePayerMethodStatus?.code === MutationStatusCode.Success) {
      dispatch(fetchViewerUserByEmail(userEmail, selectedMerchant));
      dispatch(fetchDeletePayerPaymentMethodStatus(undefined));
    }
  }, [deletePayerMethodStatus]);

  useEffect(() => {
    if (userEmail && verifyPaymentMethodStatus?.code === MutationStatusCode.Success) {
      dispatch(fetchViewerUserByEmail(userEmail, selectedMerchant));
      dispatch(fetchVerifyPaymentMethodStatus(undefined));
    }
  }, [verifyPaymentMethodStatus]);

  const toggleVerifyInfo = () => {
    dispatch(fetchVerifyInfoVisible(!verifyInfoVisible));
  };

  const getPaymentBank = () => {
    if (customerInfo) {
      return sortedCustomerMethods?.find(method => {
        //This only works if there is maximum one bank account
        return method.paymentMethod?.type === PaymentMethodType.PaymentBankUs;
      });
    }
    return paymentMethods?.find(method => {
      //This only works if there is maximum one bank account
      return method.paymentMethod?.type === PaymentMethodType.PaymentBankUs;
    });
  };

  useEffect(() => {
    if (viewerUser) {
      if (viewerUser?.payerProfile) {
        dispatch(fetchPayerProfile(viewerUser.payerProfile));
      }
      const paymentBank = getPaymentBank();
      if (upsertingPayerBankAccount && paymentBank && paymentBank.paymentMethod?.status !== PaymentMethodStatus.ProcessingError) {
        // show verify on this upsert of bank account info if the account is not in an error state.
        toggleVerifyInfo();
      }
    }
  }, [viewerUser]);

  const setSelectedMerchant = (merchant: string | undefined) => {
    dispatch(fetchSelectedMerchant(merchant));
  };

  //If there is only one merchant, go directly to that merchant instead of showing the merchant cards/tiles
  useEffect(() => {
    const summary = payerTransactionSummaryByMerchant;
    if (summary && summary.merchantSummary && summary.merchantSummary.length === 1) {
      setSelectedMerchant(summary.merchantSummary[0].merchantInfo?.owner?.tenantId);
      if (!paymentRequestInfo) {
        history.push(RoutePath.Payments);
      }
    }
  }, [payerTransactionSummaryByMerchant]);

  useEffect(() => {
    const merchantInfo = payerTransactionSummaryByMerchant?.merchantSummary.find(merchant => {
      return selectedMerchant === merchant.merchantInfo?.owner?.tenantId;
    })?.merchantInfo;
    dispatch(fetchSelectedMerchantInfo(merchantInfo as MerchantInfo));
    dispatch(captureUpdateFromPagination(false));
  }, [selectedMerchant]);

  useEffect(() => {
    if (selectedMerchant && viewerUser && viewerUser.customers) {
      const customer = viewerUser.customers.find(c => {
        return c.tenantId === selectedMerchant;
      });
      dispatch(fetchCustomerInfo(customer));
      dispatch(fetchCustomerRole(customer?.role || undefined));
      if (customer) {
        dispatch(capturePayerCustomer(customer?.customerId as string, selectedMerchant));
      }
    } else {
      dispatch(fetchCustomerInfo(undefined));
      dispatch(fetchCustomerRole(undefined));
      dispatch(fetchCustomer(undefined));
    }
  }, [selectedMerchant]);

  // only show the wallet and the button to open the wallet if the user has selected a merchant
  useEffect(() => {
    dispatch(fetchWalletButtonVisible(!!selectedMerchant));
    if (!selectedMerchant) {
      dispatch(fetchMenuVisible(false));
    }
  }, [selectedMerchant]);

  // get payfac for tenant
  useEffect(() => {
    if (selectedMerchant) {
      dispatch(getTenantIntegrationSettings(selectedMerchant));
    }
  }, [selectedMerchant]);

  useEffect(() => {
    if (!customerInfo) {
      dispatch(fetchCustomer(undefined));
    }
  }, [customerInfo]);

  const requestPayable = getIsRequestPayable(paymentRequestInfo);

  useEffect(() => {
    dispatch(getPayerTransactionSummaryByMerchant());
  }, []);

  const setDefaultMethod = (paymentMethod: PayerPaymentMethod) => {
    if (paymentMethod && viewerUser && userEmail && selectedMerchant) {
      if (customerInfo) {
        dispatch(setDefaultPaymentMethod(paymentMethod, customerInfo.customerId as string, userEmail, selectedMerchant));
      } else {
        dispatch(setDefaultPaymentMethod(paymentMethod, viewerUser.id, userEmail, selectedMerchant));
      }
    }
  };

  const setSharedWithMerchantMethod = (paymentMethod: PayerPaymentMethod) => {
    if (paymentMethod && viewerUser && userEmail && customerInfo && selectedMerchant) {
      dispatch(setSharedWithMerchantPaymentMethod(paymentMethod, customerInfo.customerId as string, userEmail, selectedMerchant));
    }
  };

  useEffect(() => {
    if (viewerUser && selectedMerchant) {
      const merchant = payerTransactionSummaryByMerchant?.merchantSummary.find(merchant => {
        return selectedMerchant === merchant.merchantInfo?.owner?.tenantId;
      });
      const name = merchant?.merchantInfo?.name;
      dispatch(clearRecentTransactions());
      dispatch(clearPaymentsDue());
      dispatch(fetchSelectedMerchantName(name as string));
      dispatch(fetchRecentTransactions(undefined, pageSize, selectedMerchant));
      dispatch(fetchPaymentsDue(selectedMerchant));
      dispatch(fetchCreditMemoBalance(selectedMerchant));
    }
  }, [selectedMerchant]);

  useEffect(() => {
    if (payerTransactionSummaryByMerchant && selectedMerchant) {
      const pendingPaymentMethodRequest = payerTransactionSummaryByMerchant.merchantSummary.find(merchant => {
        return merchant.merchantInfo?.owner?.tenantId === selectedMerchant;
      })?.pendingPaymentMethodRequest;
      dispatch(fetchPaymentMethodRequest(pendingPaymentMethodRequest || undefined));
    } else {
      dispatch(fetchPaymentMethodRequest(undefined));
    }
  }, [payerTransactionSummaryByMerchant, selectedMerchant]);

  useEffect(() => {
    if (paymentMethodRequest && customerInfo) {
      const paymentAttempts = paymentMethodRequest?.paymentAttempts;
      const cards = customerMethodsAsPaymentMethods?.filter(method => {
        let isSharedMethod = checkIsSharedMethod(method?.paymentMethod?.id, sortedCustomerMethods);
        if (
          isSharedMethod &&
          paymentMethodRequest.restriction === PaymentMethodRequestRestriction.AddOrSelectExistingExcludingAttempted
        ) {
          const alreadyAttempted =
            paymentAttempts?.filter(
              attempt => attempt.paymentMethod && attempt.paymentMethod?.id && attempt.paymentMethod?.id === method.paymentMethod?.id,
            ).length > 0;
          isSharedMethod = isSharedMethod && !alreadyAttempted;
        }
        return isSharedMethod;
      });
      setSharedCards(cards);
    } else if (sharedCards) {
      setSharedCards(undefined);
    }
  }, [paymentMethodRequest, customerMethodsAsPaymentMethods]);

  useEffect(() => {
    const billingAddresses: PaymentMethodHolder[] = [];
    //Stores a list of unique billing addresses in state.
    const methods = customerInfo ? customerMethodsAsPaymentMethods : paymentMethods;
    if (methods) {
      methods.forEach(method => {
        const card = method.paymentMethod?.creditCard?.cardHolder;
        const bank = method.paymentMethod?.paymentBank?.accountHolder;

        const uniqueCard = !billingAddresses.find(address => {
          // storing before hand to cover undefined & null values
          const line1 = address.address?.line1 || '';
          const postalCode = address.address?.postalCode || '';
          const email = address?.email || '';
          const phoneNumber = address?.phone?.number || '';

          return (
            card &&
            postalCode &&
            line1.localeCompare(card.address?.line1 || '') === 0 &&
            postalCode.localeCompare(card.address?.postalCode || '') === 0 &&
            email.localeCompare(card?.email || '') === 0 &&
            phoneNumber.localeCompare(card.phone?.number || '') === 0
          );
        });

        const uniqueBank = !billingAddresses.find(address => {
          // storing before hand to cover undefined & null values
          const line1 = address.address?.line1 || '';
          const postalCode = address.address?.postalCode || '';
          const email = address?.email || '';
          const phoneNumber = address?.phone?.number || '';

          return (
            bank &&
            postalCode &&
            line1.localeCompare(bank.address?.line1 || '') === 0 &&
            postalCode.localeCompare(bank.address?.postalCode || '') === 0 &&
            email.localeCompare(bank?.email || '') === 0 &&
            phoneNumber.localeCompare(bank.phone?.number || '') === 0
          );
        });

        if (card && uniqueCard) {
          billingAddresses.push(card);
        }
        if (bank && uniqueBank) {
          billingAddresses.push(bank);
        }
      });

      const paymentMethodDefault = methods.find(item => item.isDefault === true);
      if (paymentMethodDefault && !defaultPaymentMethod && !customerInfo) setDefaultMethod(paymentMethodDefault);
    }

    if (oneTimeUsePaymentMethod) {
      const card = oneTimeUsePaymentMethod?.creditCard?.cardHolder;
      const uniqueCard = !billingAddresses.find(address => {
        // storing before hand to cover undefined & null values
        const line1 = address.address?.line1 || '';
        const postalCode = address.address?.postalCode || '';
        const email = address?.email || '';
        const phoneNumber = address?.phone?.number || '';

        return (
          card &&
          postalCode &&
          line1.localeCompare(card.address?.line1 || '') === 0 &&
          postalCode.localeCompare(card.address?.postalCode || '') === 0 &&
          email.localeCompare(card?.email || '') === 0 &&
          phoneNumber.localeCompare(card.phone?.number || '') === 0
        );
      });

      if (card && uniqueCard) {
        billingAddresses.push(card);
      }
    }

    dispatch(fetchBillingAddresses(billingAddresses));
  }, [paymentMethods, oneTimeUsePaymentMethod, customerInfo, sortedCustomerMethods]);

  // Return immediately if condition allows.
  if (networkBusy && !sendingPayment) {
    return <Paper className={classes.paper}>Loading!</Paper>;
  }
  const toggleAddingPayments = (isPaymentScreen?: boolean) => {
    dispatch(fetchPaymentMethodError(undefined));
    dispatch(fetchCompletePaymentMethodRequestError(undefined));
    dispatch(fetchPaymentAddVisible(!paymentAddVisible));
    setAddingMethodOnPaymentScreen(!!isPaymentScreen);
  };

  const toggleConfirmDelete = () => {
    dispatch(fetchConfirmDeleteVisible(!confirmDeleteVisible));
  };
  const toggleVerification = () => {
    dispatch(fetchVerificationVisible(!verificationVisible));
  };

  const getAlert = (status: PaymentStatus, pendingReason: PendingReasonCode | undefined) => {
    const paymentMethod = selectedPaymentMethod || defaultPaymentMethod;
    const email =
      paymentMethod?.paymentMethod?.creditCard?.cardHolder?.email || paymentMethod?.paymentMethod?.paymentBank?.accountHolder?.email;
    if (paymentRequestInfo && pendingReason === PendingReasonCode.BankVerification) {
      dispatch(fetchSnackBarAlert(`Your payment has been sent, but is awaiting bank account verification.`));
      dispatch(fetchSnackBarAlertSeverity('warning'));
    } else if (
      paymentRequestInfo &&
      (status === PaymentStatus.Completed ||
        pendingReason === PendingReasonCode.Processing ||
        pendingReason === PendingReasonCode.EcheckSubmission)
    ) {
      dispatch(
        fetchSnackBarAlert(
          `Your payment to ${paymentRequestInfo.merchantName} has been successfully received. You will receive an email receipt at ${email}.`,
        ),
      );
      dispatch(fetchSnackBarAlertSeverity('success'));
    } else {
      dispatch(fetchSnackBarAlert(`Your payment is pending. You will receive an email receipt at ${email}.`));
      dispatch(fetchSnackBarAlertSeverity('warning'));
    }
  };

  const handlePaymentSent = (createPaymentStatus: CreatePaymentStatus | null, merchant?: string, hasNoCompleteRequest?: boolean) => {
    const payment = createPaymentStatus?.payment;
    // Proceed if a payment was successful
    if (payment && payment.status !== PaymentStatus.Canceled && payment.status !== PaymentStatus.Failed) {
      // Takes the user back to the selected merchant
      if (selectedMerchant && selectedMerchant === merchant) {
        dispatch(fetchRecentTransactions(undefined, pageSize, selectedMerchant));
        dispatch(fetchPaymentsDue(selectedMerchant));
      }
      setSelectedMerchant(merchant);
      dispatch(fetchHasSearchString(false));
      dispatch(fetchUrlToken(''));
      // Generates the proper alert to show in the toast
      getAlert(payment.status, payment.pendingReasonCode as PendingReasonCode);
      dispatch(fetchSnackBarOpen(true));
      // Sets the table to show the recent payments list for the selected merchant
      if (hasNoCompleteRequest) {
        dispatch(fetchLastTabPath(RoutePath.Payments));
        history.push(RoutePath.Payments);
      } else {
        dispatch(fetchLastTabPath(RoutePath.History));
        history.push(RoutePath.History);
      }
      // clearing the temporary payment method once we reach the payments due screen
      dispatch(fetchOneTimeUsePaymentMethod(undefined));
    }
    setSendingPayment(false);
    if (
      selectedMerchant &&
      selectedMerchantInfo?.owner?.tenantId === selectedMerchant &&
      selectedMerchantInfo.features?.creditMemos.enabled
    ) {
      dispatch(fetchCreditMemoBalance(selectedMerchant));
    }
  };

  const getPaymentRequestInfoAmount = () => {
    const discountEndDate = DateTime.fromISO(paymentRequestInfo?.discountEndDate);
    const discountEndValid = discountEndDate.isValid && discountEndDate > DateTime.utc();
    const discountAmount = (discountEndValid && paymentRequestInfo?.discountAmount) || 0;

    return paymentRequestInfo?.totalDue ? paymentRequestInfo?.totalDue - discountAmount : 0;
  };

  const getSubtotalAmountToPay = () => {
    let amount = 0;
    if (paymentRequestInfoList) {
      paymentRequestInfoList.forEach(p => {
        if (p.amount) {
          amount += p.amount;
        }
      });
    }
    return amount;
  };

  const getCreditMemoAmountToPay = () => {
    if (!creditMemoBalanceError && useCreditMemo && creditMemoBalance > 0 && selectedMerchantInfo?.features?.creditMemos.enabled) {
      const subTotal = getSubtotalAmountToPay();
      const creditAmount = creditMemoBalance > subTotal ? subTotal : creditMemoBalance;
      return creditAmount;
    }
    return 0;
  };

  const getTotalAmountToPay = () => {
    const subTotalAmount = getSubtotalAmountToPay();
    const creditAmount = getCreditMemoAmountToPay();

    return subTotalAmount - creditAmount;
  };

  const finalizePaymentSubmit = (useSelectedPaymentMethod: boolean, convenienceFee: number) => {
    let paymentMethodId: string | undefined | null;

    let holderPostalCode: string | undefined | null;
    let holderCountry: string | undefined | null;
    let holderCountryCode: string | undefined | null;

    let holderPhone: Phone | undefined | null;
    let holderPhoneNumber: string | undefined | null;
    let holderAddress: Address | undefined | null;

    const isConvenienceFeeApplied = !!selectedMerchantInfo?.features?.payments?.convenienceFees && convenienceFee !== 0;

    // storing the payment method id and address details according to the payment method selected
    if (useSelectedPaymentMethod) {
      const paymentMethod = selectedPaymentMethod || defaultPaymentMethod;
      paymentMethodId = paymentMethod?.paymentMethod?.id;

      if (paymentMethod?.paymentMethod?.creditCard) {
        holderAddress = paymentMethod.paymentMethod.creditCard.cardHolder?.address;
        holderCountry = holderAddress?.country;
        holderPostalCode = holderAddress?.postalCode;

        holderPhone = paymentMethod.paymentMethod.creditCard.cardHolder?.phone;
        holderCountryCode = holderPhone?.countryCode;
        holderPhoneNumber = holderPhone?.number;
      }
      if (paymentMethod?.paymentMethod?.paymentBank) {
        holderAddress = paymentMethod.paymentMethod.paymentBank.accountHolder?.address;
        holderCountry = holderAddress?.country;
        holderPostalCode = holderAddress?.postalCode;

        holderPhone = paymentMethod.paymentMethod.paymentBank.accountHolder?.phone;
        holderCountryCode = holderPhone?.countryCode;
        holderPhoneNumber = holderPhone?.number;
      }
    } else if (oneTimeUsePaymentMethod) {
      paymentMethodId = oneTimeUsePaymentMethod.id;

      holderAddress = oneTimeUsePaymentMethod.creditCard?.cardHolder?.address;
      holderCountry = holderAddress?.country;
      holderPostalCode = holderAddress?.postalCode;

      holderPhone = oneTimeUsePaymentMethod.creditCard?.cardHolder?.phone;
      holderCountryCode = holderPhone?.countryCode;
      holderPhoneNumber = holderPhone?.number;
    }

    if (
      paymentMethodId &&
      holderAddress &&
      holderCountry &&
      holderPostalCode &&
      holderPhone &&
      holderCountryCode &&
      holderPhoneNumber
    ) {
      // for payment request that are being paid following the link provided in email
      if (paymentRequestInfo) {
        dispatch(
          createPayerPayment(
            paymentMethodId,
            paymentRequestInfo,
            true,
            {
              address: {
                line1: holderAddress.line1,
                line2: holderAddress.line2,
                city: holderAddress.city,
                region: holderAddress.region,
                postalCode: holderPostalCode,
                country: holderCountry,
              },
              phone: {
                countryCode: holderCountryCode,
                number: holderPhoneNumber,
              },
              lineItems: [],
            },
            //whether credit memo balance is applied or not
            !creditMemoBalanceError &&
              useCreditMemo &&
              creditMemoBalance > 0 &&
              (selectedMerchantInfo?.features?.creditMemos.enabled as boolean),
            creditMemoBalance,
            isConvenienceFeeApplied,
            convenienceFee,
            handlePaymentSent,
            customerInfo?.customerId as string,
          ),
        );
        setSendingPayment(true);
      }

      // for payment request that are being paid by payer portal routing
      if (paymentRequestInfoList && selectedMerchant && selectedMerchantInfo?.defaultCurrency) {
        let hasNoCompleteRequest = true;
        const paymentRequestAllocation: PaymentRequestAllocationInput[] = [];
        paymentRequestInfoList.forEach(p => {
          const { amount, paymentDue: allocationPaymentDue, partialReason: partialPaymentReason } = p;
          const { paymentRequest: allocationPaymentRequest } = allocationPaymentDue;
          const { id: paymentRequestId, totalDue: allocationTotalDue } = allocationPaymentRequest || {};
          if (amount && paymentRequestId) {
            paymentRequestAllocation.push({
              amount,
              paymentRequestId,
              partialPaymentReason,
            });
          }
          if (amount === allocationTotalDue && hasNoCompleteRequest) {
            hasNoCompleteRequest = false;
          }
        });

        let cardAmount = getTotalAmountToPay();
        let amountBeforeFees: number | undefined;
        if (isConvenienceFeeApplied) {
          amountBeforeFees = cardAmount;
          cardAmount += convenienceFee;
        }

        dispatch(
          createPayerConsolidatedOrPartialPayment(
            cardAmount,
            paymentMethodId,
            selectedMerchant,
            selectedMerchantInfo.defaultCurrency,
            true,
            {
              address: {
                line1: holderAddress.line1,
                line2: holderAddress.line2,
                city: holderAddress.city,
                region: holderAddress.region,
                postalCode: holderPostalCode,
                country: holderCountry,
              },
              phone: {
                countryCode: holderCountryCode,
                number: holderPhoneNumber,
              },
              lineItems: [],
            },
            paymentRequestAllocation,
            paymentRequestInfoList,
            getCreditMemoAmountToPay(),
            amountBeforeFees,
            isConvenienceFeeApplied ? convenienceFee : undefined,
            handlePaymentSent,
            customerInfo?.customerId as string,
            hasNoCompleteRequest,
          ),
        );
        setSendingPayment(true);
      }
    }
  };

  const handleDeletePaymentMethod = () => {
    if (selectedMerchant) {
      if (methodForEdit) {
        dispatch(deletePayerPaymentMethod(methodForEdit, customerInfo?.customerId ?? undefined, selectedMerchant));
      } else if (defaultPaymentMethod) {
        dispatch(deletePayerPaymentMethod(defaultPaymentMethod, customerInfo?.customerId ?? undefined, selectedMerchant));
      }
    }

    if (confirmDeleteVisible === true) {
      toggleConfirmDelete();
    }
  };

  const submitVerification = (paymentMethodId: string | undefined, micro1: number, micro2: number) => {
    // Do verify of the bank account. Outcome of this determines toggling verification.
    dispatch(verifyBankPaymentMethod(paymentMethodId, [micro1, micro2]));
    toggleVerification();
  };
  const handleToggleRefundPolicyDisplay = () => {
    dispatch(fetchDisplayRefundPolicy(!displayRefundPolicy));
  };
  const submitPaymentWithSelectedMethod = (useSelectedPaymentMethod: boolean, convenienceFee: number) => {
    // checking that the intended method is available
    if ((oneTimeUsePaymentMethod && !useSelectedPaymentMethod) || (selectedPaymentMethod && useSelectedPaymentMethod)) {
      // Toggle confirm - since we aren't using credit, it will default to eCheck route on confirmation.
      dispatch(fetchPaymentMethodError());
      dispatch(fetchPaymentError());
      finalizePaymentSubmit(useSelectedPaymentMethod, convenienceFee);
    }
  };
  const cancelPayment = (merchant?: string) => {
    if (window.location.pathname === RoutePath.Payment) {
      dispatch(fetchPaymentRequestInfoList(undefined));
      dispatch(fetchConsolidatedOrPartialCancelClicked(true));
      if (payerTransactionSummaryByMerchant) {
        history.push(RoutePath.Payments);
      } else {
        history.push(RoutePath.Home);
      }
    } else {
      setSelectedMerchant(merchant);

      history.push(RoutePath.Payments);
      dispatch(fetchHasSearchString(false));
      dispatch(clearPaymentRequestInfo());
      dispatch(fetchUrlToken(''));
    }
    // clearing the temporary payment method once we reach the payments due screen
    dispatch(fetchOneTimeUsePaymentMethod(undefined));
  };
  const createOrSharePaymentMethodCallback = () => {
    toggleAddingPayments();
    if (paymentMethodRequest && paymentMethodRequest.status === PaymentMethodRequestStatus.Pending) {
      dispatch(getPayerTransactionSummaryByMerchant()); //update merchantInfo for new payment method request status
    }
  };
  const createPaymentMethod = (
    type: PaymentMethodType,
    token: string,
    attachToResourceId: string | undefined,
    holder: PaymentMethodHolderInput,
    isDefault: boolean,
    isWalletCard: boolean,
    merchantTenantId: string,
    shareWithMerchant: boolean,
  ) => {
    if (isWalletCard) {
      // this is to indicate that we are showing payment method request snackbar only where banner is there
      const isCompletingPaymentMethodRequest = shareWithMerchant && !!paymentMethodRequest && !addingMethodOnPaymentScreen;
      dispatch(
        createPayerPaymentMethod(
          type,
          token,
          attachToResourceId,
          holder,
          isDefault,
          userEmail,
          merchantTenantId,
          shareWithMerchant,
          isCompletingPaymentMethodRequest,
          createOrSharePaymentMethodCallback,
        ),
      );
    }
  };

  const createSDKPaymentMethod = (
    token: ApteanPaySDKToken,
    attachToResourceId: string | undefined,
    isDefault: boolean,
    isWalletCard: boolean,
    merchantTenantId: string,
    shareWithMerchant: boolean,
  ) => {
    if (isWalletCard) {
      const isCompletingPaymentMethodRequest = shareWithMerchant && !!paymentMethodRequest && !addingMethodOnPaymentScreen;
      dispatch(
        createPaymentMethodSDK(
          token,
          attachToResourceId,
          isDefault,
          userEmail,
          merchantTenantId,
          shareWithMerchant,
          isCompletingPaymentMethodRequest,
          createOrSharePaymentMethodCallback,
        ),
      );
    } else {
      dispatch(createOneTimeUsePaymentMethod(token, toggleAddingPayments));
    }
  };

  const completePaymentMethodRequest = (tenantId: string, id: string, paymentMethodId: string, customerId: string) => {
    if (userEmail) {
      dispatch(
        captureCompletePaymentMethodRequest(userEmail, customerId, tenantId, id, paymentMethodId, createOrSharePaymentMethodCallback),
      );
    }
  };

  const hasActiveForm = verificationVisible || verifyInfoVisible;
  const editingOnMobile = isMobileSize && hasActiveForm;

  const selectPaymentMethodForDeleteHandler = (payerPaymentMethod: PayerPaymentMethod) => {
    if (payerPaymentMethod) {
      dispatch(fetchPaymentMethodForEdit(payerPaymentMethod));
      toggleConfirmDelete();
    }
  };

  const handleVerificationOpen = (paymentMethod: PayerPaymentMethod) => {
    toggleVerification();
    setBankForVerification(paymentMethod);
  };

  const handleAddCardPaymentMethod = (isPaymentScreen?: boolean, isLoading?: boolean) => {
    setIsAddCardClicked(!isLoading);
    setAddPaymentMethodType(PaymentMethodType.CreditCard);
    if (isIFrameVisible) {
      setIsIFrameVisible(false);
    }
    toggleAddingPayments(isPaymentScreen);
  };

  const handleAddBankPaymentMethod = () => {
    setIsAddBankClicked(true);
    setAddPaymentMethodType(PaymentMethodType.PaymentBankUs);
    toggleAddingPayments();
  };

  const handleAddPaymentMethodCancel = () => {
    if (isAddBankClicked) {
      setIsAddBankClicked(false);
    }

    if (isAddCardClicked) {
      setIsAddCardClicked(false);
    }
    if (isIFrameVisible) {
      setIsIFrameVisible(false);
    }

    toggleAddingPayments();
  };

  const handleSetSelectedPaymentMethod = (paymentMethod: PayerPaymentMethod | undefined) => {
    dispatch(fetchSelectedPaymentMethod(paymentMethod));
  };

  const isCreditCardAccepted = (
    creditCard: CreditCard | undefined | null,
    acceptedPaymentMethods: AcceptedPaymentMethod[] | null | undefined,
  ): boolean => {
    const cardBrand = creditCard?.cardBrand;
    const acceptedPaymentMethod = cardBrand ? MapCardBrandToAcceptedPaymentMethod(cardBrand) : undefined;
    if (acceptedPaymentMethod && acceptedPaymentMethods && acceptedPaymentMethods?.indexOf(acceptedPaymentMethod) === -1) return false;
    return true;
  };

  const setSettingsMenuVisible = (isMenuVisible: boolean) => {
    dispatch(fetchMenuVisible(isMenuVisible));
  };

  const handleCreditMemoCardOpen = () => {
    dispatch(fetchCreditMemoDetailsOpen(true));
    if (selectedMerchantInfo?.owner?.tenantId && selectedMerchantInfo.owner.tenantId.length > 0) {
      dispatch(fetchOpenCreditMemos(undefined, pageSize, selectedMerchantInfo.owner.tenantId));
      dispatch(fetchCreditMemosHistory(undefined, pageSize, selectedMerchantInfo.owner.tenantId));
    }
  };

  const handleCreditMemoCardClose = () => {
    dispatch(fetchCreditMemoDetailsOpen(false));
    dispatch(clearOpenCreditMemoConnection());
    dispatch(clearOpenCreditMemos());
    dispatch(clearCreditMemoHistory());
    dispatch(clearCreditMemoHistoryConnection());
  };

  const loadOpenCreditMemosPage = (tenantId?: string, endCursor?: string) => {
    if (tenantId && tenantId.length > 0) {
      dispatch(fetchOpenCreditMemos(endCursor, pageSize, tenantId));
    }
  };

  const loadCreditMemoHistoryPage = (tenantId?: string, endCursor?: string) => {
    if (tenantId && tenantId.length > 0) {
      dispatch(fetchCreditMemosHistory(endCursor, pageSize, tenantId));
    }
  };

  const getCreditMemoBalance = (tenantId?: string) => {
    if (tenantId && tenantId.length > 0) {
      dispatch(fetchCreditMemoBalance(tenantId));
    }
  };

  const setSelectedMerchantInfo = (tenantId?: string) => {
    if (tenantId && tenantId.length > 0) {
      setSelectedMerchant(tenantId);
      if (payerTransactionSummaryByMerchant?.merchantSummary) {
        const merchantInfo = payerTransactionSummaryByMerchant?.merchantSummary.find(merchant => {
          return tenantId === merchant.merchantInfo?.owner?.tenantId;
        })?.merchantInfo;
        dispatch(fetchSelectedMerchantInfo(merchantInfo as MerchantInfo));
      }
    }
  };

  const clearOneTimePaymentMethod = () => {
    if (oneTimeUsePaymentMethod) {
      dispatch(fetchOneTimeUsePaymentMethod(undefined));
    }
  };

  const calculateConvenienceFee = (amount: number, cardType: CardType) => {
    if (selectedMerchantInfo?.owner?.tenantId && selectedMerchantInfo.owner.tenantId.length > 0) {
      dispatch(
        fetchCalculatedConvenienceFees(
          false,
          amount,
          cardType,
          selectedMerchantInfo.owner.tenantId,
          customerInfo?.customerId as string | undefined,
        ),
      );
    }
  };

  const clearCalculatedConvenienceFees = () => {
    dispatch(captureCalculatedConvenienceFees(undefined));
  };

  const paymentMethodsList = (
    <PaymentMethods
      deleteMethod={selectPaymentMethodForDeleteHandler}
      paymentMethods={customerInfo ? customerMethodsAsPaymentMethods : sortedPaymentMethods}
      setAsDefault={setDefaultMethod}
      setAsSharedWithMerchant={setSharedWithMerchantMethod}
      deletePaymentMethodError={deletePaymentMethodError}
      handleVerifyClick={handleVerificationOpen}
      handleAddCard={handleAddCardPaymentMethod}
      handleAddBank={handleAddBankPaymentMethod}
      customerRole={customerRole}
      customerInfo={customerInfo}
      customerMethods={sortedCustomerMethods}
      paymentMethodRequest={paymentMethodRequest}
      setSettingsMenuVisible={setSettingsMenuVisible}
      payerCustomer={payerCustomer}
      merchantInfo={selectedMerchantInfo}
    />
  );

  const handleAddBillingAddress = (billingAddress: PaymentMethodHolder) => {
    dispatch(addBillingAddress(billingAddress));
  };

  const clearAddPaymentMethodError = () => {
    dispatch(fetchPaymentMethodError());
  };

  const clearCompletePaymentMethodRequestError = () => {
    dispatch(fetchCompletePaymentMethodRequestError(undefined));
  };

  const getDefaultHolderInfo = (): PaymentMethodHolder | null | undefined => {
    if (customerInfo && customerMethodsAsPaymentMethods) {
      const [customerMethod] = customerMethodsAsPaymentMethods;
      return customerMethod?.paymentMethod?.creditCard
        ? customerMethod.paymentMethod.creditCard.cardHolder
        : customerMethod?.paymentMethod?.paymentBank?.accountHolder;
    }
    return defaultPaymentMethod?.paymentMethod?.creditCard
      ? defaultPaymentMethod.paymentMethod.creditCard.cardHolder
      : defaultPaymentMethod?.paymentMethod?.paymentBank?.accountHolder;
  };

  const paymentMethodAdd = (
    <PaymentMethodAdd
      isAddBankClicked={isAddBankClicked}
      paymentAddVisible={paymentAddVisible}
      isReplace={false}
      submit={createPaymentMethod}
      continueText={'Add'}
      cancel={handleAddPaymentMethodCancel}
      onMobile={editingOnMobile}
      viewerUser={viewerUser}
      paymentMethods={customerInfo ? customerMethodsAsPaymentMethods : paymentMethods}
      payerProfileHolderInfo={getDefaultHolderInfo()}
      addPaymentMethodType={addPaymentMethodType}
      billingAddresses={billingAddresses}
      addBillingAddress={handleAddBillingAddress}
      paymentMethodAddStep={paymentMethodAddStep}
      setPaymentMethodAddStep={setPaymentMethodAddStep}
      isIFrameVisible={isIFrameVisible}
      setIsIFrameVisible={setIsIFrameVisible}
      loadingPaymentMethods={loadingPaymentMethods}
      addPaymentMethodError={paymentMethodError}
      clearAddPaymentMethodError={clearAddPaymentMethodError}
      customerRole={customerRole}
      isPaymentScreen={addingMethodOnPaymentScreen}
      oneTimeUsePaymentMethod={oneTimeUsePaymentMethod}
      customerId={customerInfo?.customerId as string}
      selectedMerchant={selectedMerchant}
      paymentMethodRequest={paymentMethodRequest}
      selectedMerchantInfo={selectedMerchantInfo}
      completePaymentMethodRequestInFlight={completePaymentMethodRequestInFlight}
      completePaymentMethodRequest={completePaymentMethodRequest}
      completePaymentMethodRequestError={completePaymentMethodRequestError}
      clearCompletePaymentMethodRequestError={clearCompletePaymentMethodRequestError}
      sharedCards={sharedCards}
      submitSDKPaymentMethod={createSDKPaymentMethod}
      payfac={integrationSettings?.payFacType}
    />
  );

  const paymentMethodVerification = (
    <PaymentMethodVerification
      handleBack={toggleVerification}
      onMobile={editingOnMobile}
      submit={submitVerification}
      merchantStatementDescription={'WePay'}
      paymentMethod={bankForVerification}
    />
  );
  const payPaymentRequest = (
    <PayPaymentRequest
      merchantInfo={selectedMerchantInfo}
      displayRefundPolicy={displayRefundPolicy}
      paymentRequestInfo={paymentRequestInfo}
      toggleRefundPolicyDisplay={handleToggleRefundPolicyDisplay}
      hasPaymentMethods={
        !!(sortedCustomerMethods && sortedCustomerMethods.length > 0) || !!(paymentMethods && paymentMethods.length > 0)
      }
      changePaymentMethod={handleSetSelectedPaymentMethod}
      allowCreditCard={(!!paymentRequestInfo && requestPayable && cardAllowed) || (!!paymentRequestInfoList && cardAllowed)}
      allowECheck={eCheckAllowed && (!paymentRequestInfo || paymentRequestInfo.currency === CurrencyType.Usd)}
      paymentMethod={selectedPaymentMethod}
      defaultPaymentMethod={defaultPaymentMethod}
      submitPayment={submitPaymentWithSelectedMethod}
      paymentMethods={customerInfo ? customerMethodsAsPaymentMethods : paymentMethods}
      sortedPaymentMethods={customerInfo ? customerMethodsAsPaymentMethods : sortedPaymentMethods}
      billingAddresses={billingAddresses}
      viewerUser={viewerUser}
      handleAddCard={handleAddCardPaymentMethod}
      handleAddBank={handleAddBankPaymentMethod}
      paymentError={paymentError}
      isCreditCardAccepted={isCreditCardAccepted}
      loading={networkBusy}
      paymentAddVisible={paymentAddVisible}
      paymentMethodAdded={paymentMethodAdded}
      cancelPayment={cancelPayment}
      paymentRequestInfoList={paymentRequestInfoList}
      isMobileView={isMobileSize}
      getPaymentRequestInfoAmount={getPaymentRequestInfoAmount}
      creditMemoBalance={creditMemoBalance}
      creditMemoBalanceError={creditMemoBalanceError}
      loadingCreditMemoBalance={loadingCreditMemoBalance}
      fetchCreditMemoBalance={getCreditMemoBalance}
      setMerchantInfo={setSelectedMerchantInfo}
      useCreditMemo={useCreditMemo}
      setUseCreditMemo={setUseCreditMemo}
      sendingPayment={sendingPayment}
      handleCreditMemoCardClose={handleCreditMemoCardClose}
      customerRole={customerRole}
      oneTimeUsePaymentMethod={oneTimeUsePaymentMethod}
      clearOneTimePaymentMethod={clearOneTimePaymentMethod}
      customerInfo={customerInfo}
      calculateConvenienceFee={calculateConvenienceFee}
      calculatedConveninceFee={calculatedConvenienceFees}
      isCalculatedConvenienceFeeInFlight={isCalculatedConvenienceFeesInFlight}
      clearCalculatedConvenienceFees={clearCalculatedConvenienceFees}
    />
  );

  const getPaymentRequestHeader = (paymentRequestInfo: PaymentRequestInfo | undefined): JSX.Element | undefined => {
    if (!paymentRequestInfo) {
      return <PaymentInfoNotFound />;
    }
    if (paymentRequestInfo) {
      if (!paymentRequestInfo.refundPolicy) {
        return <MissingRefundPolicy />;
      }
      if (paymentRequestInfo.status === PaymentRequestStatus.Completed) {
        return <PaymentReceived />;
      }
      if (paymentRequestInfo.status === PaymentRequestStatus.Unknown) {
        const paymentMethod = selectedPaymentMethod || defaultPaymentMethod;
        const email =
          paymentMethod?.paymentMethod?.creditCard?.cardHolder?.email ||
          paymentMethod?.paymentMethod?.paymentBank?.accountHolder?.email;
        return <PaymentUnknown email={email} />;
      }
      if (!requestPayable) {
        // Catch for status's which will not allow a payment to be processed for this request.
        // TODO: What should unknown be handled as?
        return <PaymentProcessing status={paymentRequestInfo.status} />;
      }
    }
    return undefined;
  };

  //The user is paying a payment request
  const getPaymentView = () => {
    if ((paymentRequestInfoList && paymentRequestInfoList.length > 0) || (!paymentRequestInfoList && !paymentRequestInfo)) {
      return (
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sm={settingsMenuVisible ? 6 : 12}
          md={4}
          className={`${classes.transition} ${classes.transitionHome}`}
        >
          {payPaymentRequest}
        </Grid>
      );
    }
    const paymentHeader = getPaymentRequestHeader(paymentRequestInfo);
    if (paymentHeader) {
      return (
        <Grid item xs={8} md={4}>
          <Paper>{paymentHeader}</Paper>
        </Grid>
      );
    }

    return (
      <Grid
        item
        container
        justifyContent="center"
        xs={12}
        sm={settingsMenuVisible ? 6 : 12}
        md={4}
        className={`${classes.transition} ${classes.transitionHome}`}
      >
        {payPaymentRequest}
      </Grid>
    );
  };

  const getPaymentMethodRequestBanner = () => {
    return (
      <PaymentMethodRequestBanner
        paymentMethodRequest={paymentMethodRequest}
        defaultCurrency={selectedMerchantInfo?.defaultCurrency}
        handleAddCardPaymentMethod={handleAddCardPaymentMethod}
        customerRole={customerInfo?.role}
        isLoadingCustomer={isLoadingCustomer}
      />
    );
  };

  //The user is not paying a payment request
  const getHomeView = () => {
    return (
      <Box className={classes.transition} style={{ width: settingsMenuVisible && !isMobileSize ? 'calc(100% - 372px)' : '100%' }}>
        <Grid item container xs={12} justifyContent="center" direction="column">
          <Switch>
            <Route path={RoutePath.UserManagement}>
              <ManageUsers userEmail={userEmail} settingsMenuVisible={settingsMenuVisible} />
            </Route>
            <Route path={RoutePath.Payments}>
              {getPaymentMethodRequestBanner()}
              <MerchantRequestsView tabPath={RoutePath.Payments} />
            </Route>
            <Route path={RoutePath.History}>
              {getPaymentMethodRequestBanner()}
              <MerchantRequestsView tabPath={RoutePath.History} />
            </Route>
            <Route path={RoutePath.Payment}>{getPaymentView()}</Route>
            <Route path={RoutePath.Home}>
              <MerchantSummaryView />
            </Route>
            <Route path="*">
              <Redirect to={RoutePath.Payments} />
            </Route>
          </Switch>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {/* {Loading Mask} */}
      <LoadingMask loading={isAddCardClicked === true && isIFrameVisible === false} />
      {/* {Dialogs} */}
      {paymentMethodAdd}
      <Dialog open={confirmDeleteVisible} maxWidth="sm" aria-labelledby="delete-payment-method">
        <ConfirmDelete handleCancel={toggleConfirmDelete} handleDelete={handleDeletePaymentMethod} />
      </Dialog>
      <Dialog open={verificationVisible} maxWidth="xs">
        <DialogContent>{paymentMethodVerification}</DialogContent>
      </Dialog>
      <CreditMemosDetails
        open={creditMemoDetailsOpen}
        close={handleCreditMemoCardClose}
        merchantInfo={selectedMerchantInfo}
        openCreditMemoConnection={openCreditMemoConnection}
        openCreditMemos={openCreditMemos}
        openCreditMemosError={openCreditMemosError}
        loadOpenCreditMemosPage={loadOpenCreditMemosPage}
        creditMemoHistoryConnection={creditMemoHistoryConnection}
        creditMemoHistory={creditMemoHistory}
        creditMemoHistoryError={creditMemoHistoryError}
        loadCreditMemoHistoryPage={loadCreditMemoHistoryPage}
      />

      {/* {Drawer / hamburger menu} */}
      <SettingsMenu
        viewerUser={viewerUser}
        paymentMethodsListView={paymentMethodsList}
        isMenuOpen={settingsMenuVisible}
        setIsMenuOpen={setSettingsMenuVisible}
        creditBalance={creditMemoBalance}
        creditMemoCardClick={handleCreditMemoCardOpen}
        loadingCreditBalance={loadingCreditMemoBalance}
        merchantInfo={selectedMerchantInfo}
        creditBalanceError={creditMemoBalanceError}
        deletingPaymentMethod={deletingPaymentMethod}
      />
      {!networkBusy && viewerUser && selectedMerchantInfo && (
        <Grid container xs={12} className={classes.crumbWrap} justifyContent={locationIsPayment ? 'center' : 'flex-start'}>
          <Grid
            item
            xs={12}
            justifyContent={!locationIsPayment || (locationIsPayment && isMobileSize) ? 'flex-end' : 'center'}
            className={`${settingsMenuVisible && !isMobileSize ? classes.crumbsWithSettings : ''} ${classes.crumbs} ${
              locationIsPayment ? classes.crumbsOnPayment : ''
            }`}
          >
            <Breadcrumbs lastTabPath={lastTabPath} tenantName={selectedMerchantInfo?.name || ''} />
          </Grid>
        </Grid>
      )}
      {/* {Main Content} */}
      <Grid
        container
        justifyContent={!hasSearchString || (hasSearchString && isMobileSize) ? 'flex-end' : 'center'}
        className={classes.homeView}
      >
        {hasSearchString ? getPaymentView() : getHomeView()}
      </Grid>
    </>
  );
};

export default MultipleMethodsHome;
