import { CustomerPaymentMethod } from '../gql-types.generated';

export const checkIsSharedMethod = (
  paymentMethodId: string | undefined,
  customerMethods?: CustomerPaymentMethod[] | null,
): boolean => {
  if (!paymentMethodId) {
    return false;
  }
  const method = customerMethods?.find(method => {
    return method.paymentMethod?.id === paymentMethodId;
  });
  return !!method && (method.shareWithMerchant as boolean);
};
