import { Box, CircularProgress, TableContainer, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DataGridPro, GridColDef, GridOverlay, GridRowModel } from '@mui/x-data-grid-pro';
import React from 'react';

import { CreditMemo, CurrencyType, Maybe, PayerCreditMemoConnection } from '../gql-types.generated';
import SkeletonGrid from './SkeletonGrid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      padding: theme.spacing(2, 6, 2, 0),
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderTop: '1px solid #e0e0e0',
    },
    memoText: {
      margin: theme.spacing(0, 3, 0, 1),
      fontSize: '2rem',
      fontWeight: 'lighter',
    },
    memoTotal: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    memoAmount: {
      fontSize: '2.125rem',
      fontWeight: 400,
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    tableContainer: {
      height: '65vh',
      minHeight: 350,
      width: '100%',
      overflow: 'auto',
      textAlign: 'center',
    },
    cell: {
      fontSize: 16,
      padding: '16px 4px',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    firstCell: {
      paddingLeft: '35px',
      fontSize: 16,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    emptyMessage: {
      fontSize: '14px',
      textAlign: 'center',
      fontStyle: 'oblique',
    },
    loadingSpinner: {
      height: 60,
      width: 60,
      display: 'inline-block',
    },
    errorMessage: {
      fontSize: '18px',
      textAlign: 'center',
      fontStyle: 'oblique',
      color: 'red',
    },
    ellipsisText: {
      wordWrap: 'break-word',
      lineClamp: 1,
      boxOrient: 'vertical',
      display: 'box',
      overflow: 'hidden',
      wordBreak: 'break-all',
    },
    tableContainerMobile: {
      height: '75vh',
      minHeight: 350,
      overflow: 'auto',
      textAlign: 'center',
    },
    footerMobile: {
      display: 'inline-flex',
    },
    memoAmountMobile: {
      marginLeft: 5,
    },
    overlay: {
      '&.MuiDataGrid-overlay': {
        display: 'inline',
      },
    },
    noRowsOverlayText: {
      fontStyle: 'italic',
      lineHeight: 2,
      borderBottom: '1px solid #e0e0e0',
    },
    dataGrid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-cell:first-child': {
        paddingLeft: 30,
      },
      '& .MuiDataGrid-columnHeader:first-child': {
        paddingLeft: 30,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#F5F5F5',
        maxHeight: 60,
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: 16,
      },
      '& .MuiDataGrid-cell': {
        fontSize: 16,
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    progressOverlay: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'flex',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      opacity: 0.02,
    },
  }),
);
interface CreditMemosOpenListProps {
  openCreditMemoConnection?: PayerCreditMemoConnection;
  openCreditMemos?: CreditMemo[];
  openCreditMemosError?: Error;
  loadPage: () => void;
  currency?: CurrencyType;
}

const CreditMemosOpenList: React.FC<CreditMemosOpenListProps> = props => {
  const { openCreditMemoConnection, openCreditMemos, loadPage, currency, openCreditMemosError } = props;
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const getOpenCreditMemoRows = () => {
    return openCreditMemos?.map((row: Maybe<CreditMemo>, index: number) => {
      const node = row;
      if (!node) {
        return {} as GridRowModel;
      }
      return {
        _raw: node,
        id: `${node?.referenceNumber}-${index}`,
        creditReference: node?.referenceNumber,
        issueDate: node.createdAt ? new Date(node.createdAt).toLocaleDateString() : '',
        reason: node.reason,
        amount:
          typeof node.amount === 'number'
            ? new Intl.NumberFormat('en', {
                style: 'currency',
                currency: node.currency || 'USD',
                currencyDisplay: 'symbol',
              }).format(node.amount / 100)
            : null,
        remaining:
          typeof node.remainingBalance === 'number'
            ? new Intl.NumberFormat('en', {
                style: 'currency',
                currency: node.currency || 'USD',
                currencyDisplay: 'symbol',
              }).format(node.remainingBalance / 100)
            : null,
      } as GridRowModel;
    }) as GridRowModel[];
  };

  // Keep working towards all grids conversion to datagridpro in payer portal.
  const openCreditMemoColumns: GridColDef[] = [
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'creditReference',
      sortable: false,
      headerName: 'Credit Memo #',
      minWidth: 220,
      flex: 0.2,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'issueDate',
      sortable: false,
      headerName: 'Date Issued',
      minWidth: 220,
      flex: 0.2,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'reason',
      sortable: false,
      headerName: 'Reason',
      minWidth: 180,
      flex: 0.2,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'amount',
      sortable: false,
      headerName: 'Amount',
      headerAlign: 'right',
      align: 'right',
      minWidth: 120,
      flex: 0.2,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'remaining',
      sortable: false,
      headerName: 'Remaining Balance',
      headerAlign: 'right',
      align: 'right',
      minWidth: 180,
      flex: 0.2,
    },
  ];

  const loadingOverlay = () => {
    return (
      <GridOverlay>
        <div className={classes.progressOverlay}></div>
        <CircularProgress aria-label={'progress spinner'} key={'payments-spinner'} size={42} />
      </GridOverlay>
    );
  };
  const noRowsOverlay = () => {
    return (
      <GridOverlay className={classes.overlay}>
        {openCreditMemosError && (
          <Typography className={classes.noRowsOverlayText} variant="body2">
            Unable to load data. Please try again later.
          </Typography>
        )}
        {!openCreditMemosError && openCreditMemos?.length === 0 && (
          <Typography className={classes.noRowsOverlayText} variant="body2">
            No Open Credit Memos.
          </Typography>
        )}
      </GridOverlay>
    );
  };
  const gridFooter = () => {
    return matches ? (
      <Box className={classes.footer}>
        <Typography variant="h5" className={classes.memoAmountMobile}>
          {new Intl.NumberFormat('en', {
            style: 'currency',
            currency: currency || 'USD',
            currencyDisplay: 'symbol',
          }).format((openCreditMemoConnection?.totalBalance || 0) / 100)}
        </Typography>
      </Box>
    ) : (
      <Box className={classes.footer}>
        <Typography className={classes.memoAmount}>
          {new Intl.NumberFormat('en', {
            style: 'currency',
            currency: currency || 'USD',
            currencyDisplay: 'symbol',
          }).format((openCreditMemoConnection?.totalBalance || 0) / 100)}
        </Typography>
      </Box>
    );
  };
  return (
    <TableContainer className={matches ? classes.tableContainerMobile : classes.tableContainer} data-cy="open-credit-memo-table">
      {!openCreditMemosError && !openCreditMemos && <SkeletonGrid headers={openCreditMemoColumns} rowCount={10}></SkeletonGrid>}
      {openCreditMemos && (
        <DataGridPro
          loading={false}
          rowHeight={58}
          aria-label="Open Credit Memo Table"
          paginationMode="server"
          pageSize={25}
          rowsPerPageOptions={[25]}
          hideFooterPagination
          hideFooterSelectedRowCount
          className={classes.dataGrid}
          rows={getOpenCreditMemoRows()}
          columns={openCreditMemoColumns}
          components={{
            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
            LoadingOverlay: loadingOverlay,
            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
            NoRowsOverlay: noRowsOverlay,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Footer: gridFooter,
          }}
          onRowsScrollEnd={loadPage}
        />
      )}
    </TableContainer>
  );
};

export default CreditMemosOpenList;
