import { PayerTransactionSummary, OrderDirection, PayerMerchantSummary } from '../gql-types.generated';

export const sortByName = (
  merchantData: PayerTransactionSummary | undefined,
  order: OrderDirection,
): PayerTransactionSummary | undefined => {
  if (!merchantData) return merchantData;
  const sortResOne = order === OrderDirection.Asc ? 1 : -1;
  const sortResTwo = order === OrderDirection.Asc ? -1 : 1;
  const { merchantSummary, payer } = merchantData;

  const sortedMerchantSummary = merchantSummary.slice().sort((a: PayerMerchantSummary, b: PayerMerchantSummary) => {
    const { merchantInfo: aMerchantInfo } = a;
    const { name: aName } = aMerchantInfo || {};

    const { merchantInfo: bMerchantInfo } = b;
    const { name: bName } = bMerchantInfo || {};

    if (aName && bName) {
      return aName.localeCompare(bName) > 0 ? sortResOne : sortResTwo;
    }
    return 0;
  });

  const sortedData: PayerTransactionSummary | undefined = {
    merchantSummary: sortedMerchantSummary,
    payer,
  };
  return sortedData;
};

// export const sortByUnpaidInvoices = (
//   merchantData: PayerTransactionSummary | undefined,
//   order: OrderDirection,
// ): PayerTransactionSummary | undefined => {
//   if (!merchantData) return merchantData;
//   const sortResOne = order === OrderDirection.Asc ? 1 : -1;
//   const sortResTwo = order === OrderDirection.Asc ? -1 : 1;
//   const { merchantSummary, payer } = merchantData;

//   const sortedMerchantSummary = merchantSummary.slice().sort((a: PayerMerchantSummary, b: PayerMerchantSummary) => {
//     const { numberOfUnpaidRequests: aInvoice } = a;
//     const { numberOfUnpaidRequests: bInvoice } = b;

//     return aInvoice > bInvoice ? sortResOne : sortResTwo;
//   });

//   const sortedData: PayerTransactionSummary | undefined = {
//     merchantSummary: sortedMerchantSummary,
//     payer,
//   };
//   return sortedData;
// };

// export const sortByEarliestCreatedDate = (
//   merchantData: PayerTransactionSummary | undefined,
//   order: OrderDirection,
// ): PayerTransactionSummary | undefined => {
//   if (!merchantData) return merchantData;
//   const sortResOne = order === OrderDirection.Asc ? 1 : -1;
//   const sortResTwo = order === OrderDirection.Asc ? -1 : 1;
//   const { merchantSummary, payer } = merchantData;

//   const sortedMerchantSummary = merchantSummary.slice().sort((a: PayerMerchantSummary, b: PayerMerchantSummary) => {
//     const aDate = DateTime.fromISO(a.earliestCreatedDate);
//     const bDate = DateTime.fromISO(b.earliestCreatedDate);

//     return aDate > bDate ? sortResOne : sortResTwo;
//   });

//   const sortedData: PayerTransactionSummary | undefined = {
//     merchantSummary: sortedMerchantSummary,
//     payer,
//   };
//   return sortedData;
// };

// export const sortByBalanceDue = (
//   merchantData: PayerTransactionSummary | undefined,
//   order: OrderDirection,
// ): PayerTransactionSummary | undefined => {
//   if (!merchantData) return merchantData;
//   const sortResOne = order === OrderDirection.Asc ? 1 : -1;
//   const sortResTwo = order === OrderDirection.Asc ? -1 : 1;
//   const merchantSummary = merchantData.merchantSummary;

//   const sortedMerchantSummary = merchantSummary.slice().sort((a: PayerMerchantSummary, b: PayerMerchantSummary) => {
//     const aBalance = a.totalDue;
//     const bBalance = b.totalDue;

//     return aBalance > bBalance ? sortResOne : sortResTwo;
//   });

//   const sortedData: PayerTransactionSummary | undefined = {
//     merchantSummary: sortedMerchantSummary,
//     payer: merchantData.payer,
//   };
//   return sortedData;
// };
