import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  alpha,
  Box,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DataGridPro, GridColDef, GridOverlay, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import AddOrEditCustomerUser from '../../components/AddOrEditCustomerUser';
import RemoveCustomerUser from '../../components/RemoveCustomerUser';
import SkeletonGrid from '../../components/SkeletonGrid';
import { CustomerRole, CustomerUser } from '../../gql-types.generated';
import { RoutePath } from '../../util/Routes';
import { selectCustomerRoles } from '../../util/SelectCustomerRole';
import { capturePayerCustomer, removeCustomerUser, upsertCustomerUser } from './ManageUsersAction';
import {
  fetchCustomerUserStatus,
  fetchRemoveCustomerUserError,
  fetchRemovedUserStatus,
  fetchUpsertCustomerUserError,
  selectCustomer,
  selectCustomerError,
  selectCustomerInfo,
  selectCustomerUserStatus,
  selectIsLoadingCustomer,
  selectIsRemoveCustomerUserInFlight,
  selectIsUpsertCustomerUserInFlight,
  selectRemoveCustomerUserError,
  selectRemovedUserStatus,
  selectUpsertCustomerUserError,
} from './ManageUsersSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      minWidth: '80%',
    },
    paperSm: {
      minWidth: '100%',
    },
    table: {
      minWidth: 650,
    },
    toolbar: {
      borderBottomWidth: 1,
      borderBottomColor: alpha(theme.palette.common.black, 0.15),
      borderBottomStyle: 'solid',
    },
    toolbarButton: {
      minWidth: 150,
      marginLeft: 4,
    },
    title: {
      flex: '1 1 100%',
    },
    tooltip: {
      fontSize: '10pt',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
    overlay: {
      '&.MuiDataGrid-overlay': {
        display: 'inline',
      },
    },
    noRowsOverlayText: {
      fontStyle: 'italic',
      lineHeight: 2,
      borderBottom: '1px solid #e0e0e0',
      textAlign: 'center',
    },
    noRowsOverlayErrorText: {
      fontStyle: 'italic',
      lineHeight: 2,
      borderBottom: '1px solid e0e0e0',
      textAlign: 'center',
      color: 'red',
    },
    dataGrid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:nth-child(1)': {
        paddingLeft: 20,
      },
      '& .MuiDataGrid-columnHeader:nth-child(1)': {
        paddingLeft: 20,
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell': {
        // Unfortunately the cell rendering has inline lineHeight styling, so !important is required.
        lineHeight: '100% !important',
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#F5F5F5',
        maxHeight: 60,
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: 16,
      },
      '& .MuiDataGrid-cell': {
        fontSize: 16,
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    dataGridMobile: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell': {
        padding: '8px 2px 8px 8px !important',
      },
      '& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell': {
        // Unfortunately the cell rendering has inline lineHeight styling, so !important is required.
        lineHeight: '100% !important',
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    gridWrap: {
      overflow: 'hidden',
      position: 'relative',
      height: 'calc(100vh - 195px)',
      minHeight: 'calc(100vh - 195px)',
      [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 220px)',
        minHeight: 'calc(100vh - 220px)',
      },
    },
    gridWrapMobile: {
      overflow: 'hidden',
      position: 'relative',
      height: '80vh',
    },
    none: {
      display: 'none',
    },
    gridBoxContainer: {
      height: '100%',
    },
    containerMobile: {
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
    },
    buttonContainerMobile: {
      width: '48px',
    },
    textContainerMobile: {
      width: '100%',
    },
    editableTextContainerMobile: {
      width: 'calc(100% - 96px)',
    },
    emailText: {
      fontSize: 14,
      fontWeight: 500,
      width: '100%',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      whiteSpace: 'break-spaces',
    },
    roleText: {
      fontSize: 13,
      width: '100%',
      marginTop: 4,
    },
  }),
);

interface ManageUsersProps {
  userEmail?: string;
  settingsMenuVisible: boolean;
}

const ManageUsers: React.FC<ManageUsersProps> = props => {
  const classes = useStyles();
  const { settingsMenuVisible } = props;
  let { userEmail } = props;
  userEmail = userEmail?.toLowerCase();
  const history = useHistory();
  const dispatch = useDispatch();
  const customerInfo = useSelector(selectCustomerInfo);

  // if there is no selected user info the view will redirect to home page
  if (!(customerInfo && customerInfo.tenantId && customerInfo.customerId)) {
    history.push(RoutePath.Home);
    return <></>;
  }

  const customer = useSelector(selectCustomer);
  const isLoadingCustomer = useSelector(selectIsLoadingCustomer);
  const isUpsertCustomerUserInFlight = useSelector(selectIsUpsertCustomerUserInFlight);
  const upsertCustomerUserStatus = useSelector(selectCustomerUserStatus);
  const upsertCustomerUserError = useSelector(selectUpsertCustomerUserError);
  const removeCustomerUserStatus = useSelector(selectRemovedUserStatus);
  const isRemoveCustomerUserInFlight = useSelector(selectIsRemoveCustomerUserInFlight);
  const removeCustomerUserError = useSelector(selectRemoveCustomerUserError);
  const customerListError = useSelector(selectCustomerError);

  const [isAddOrEditOpen, setIsAddOrEditOpen] = useState<boolean>(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<CustomerUser | undefined>(undefined);
  const [viewerUserCustomer, setViewerUserCustomer] = useState<CustomerUser | undefined>(undefined);

  // calculating whether mobile view or not
  const isMobileSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down(1400));
  const isMobileView = isMobileSize || (settingsMenuVisible && matches);

  const selectRoles = selectCustomerRoles;
  const canEdit = viewerUserCustomer?.role === CustomerRole.CustomerAdmin;

  const reloadCustomer = () => {
    if (customerInfo?.customerId && customerInfo?.tenantId) {
      dispatch(capturePayerCustomer(customerInfo.customerId, customerInfo.tenantId));
    }
  };

  useEffect(() => {
    reloadCustomer();
  }, []);

  useEffect(() => {
    if (customer && userEmail) {
      const user = customer?.customerUsers?.find(c => c.email?.toLowerCase() === userEmail);
      setViewerUserCustomer(user);
    } else {
      setViewerUserCustomer(undefined);
    }
  }, [customer]);

  const onAddUserClick = () => {
    setSelectedUser(undefined);
    setIsAddOrEditOpen(true);
  };

  const onEditUserClick = (user: CustomerUser) => {
    setSelectedUser(user);
    setIsAddOrEditOpen(true);
  };

  const onAddOrEditUserClose = () => {
    setIsAddOrEditOpen(false);
    if (upsertCustomerUserStatus) dispatch(fetchCustomerUserStatus(undefined));
    if (upsertCustomerUserError) dispatch(fetchUpsertCustomerUserError(undefined));
    setSelectedUser(undefined);
  };

  const onDeleteUserClick = (user: CustomerUser) => {
    setSelectedUser(user);
    setIsRemoveOpen(true);
  };

  const onDeleteUserClose = () => {
    setIsRemoveOpen(false);
    if (removeCustomerUserStatus) dispatch(fetchRemovedUserStatus(undefined));
    if (removeCustomerUserError) dispatch(fetchRemoveCustomerUserError(undefined));
    setSelectedUser(undefined);
  };

  const handleSaveUser = (email: string, role: CustomerRole, sendEmail: boolean) => {
    if (customerInfo?.customerId && customerInfo?.tenantId && email && role) {
      dispatch(upsertCustomerUser(customerInfo.tenantId, customerInfo.customerId, email, role, sendEmail));
    }
  };

  const handleDeleteUser = () => {
    if (customerInfo?.customerId && customerInfo?.tenantId && selectedUser && selectedUser?.email) {
      dispatch(removeCustomerUser(customerInfo.tenantId, customerInfo.customerId, selectedUser.email));
    }
  };

  const mobileSkeleton = () => {
    const rows: JSX.Element[] = [];
    for (let i = 0; i < 7; i += 1) {
      rows.push(
        <TableRow>
          <TableCell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: 'calc(100% - 96px)' }}>
                <Skeleton height="1.5em" width="80%" />
                <Skeleton height="1.5em" width="30%" />
              </div>
              <div style={{ width: '96px' }}>
                <Skeleton height="1.5em" width="90%" />
              </div>
            </div>
          </TableCell>
        </TableRow>,
      );
    }

    return (
      <TableContainer>
        <Table>{rows}</Table>
      </TableContainer>
    );
  };

  const noRowsOverlay = () => {
    if (customerListError) {
      return (
        <GridOverlay className={classes.overlay}>
          <Typography className={classes.noRowsOverlayErrorText} variant="body2">
            Unable to load data. Please try again later.
          </Typography>
        </GridOverlay>
      );
    }
    return (
      <GridOverlay className={classes.overlay}>
        <Typography className={classes.noRowsOverlayText} variant="body2">
          No User Data to show.
        </Typography>
      </GridOverlay>
    );
  };

  const getUserRows = () => {
    const rows = customer?.customerUsers;
    return rows?.map((row: Maybe<CustomerUser>, index) => {
      const node = row;
      if (!node) {
        return {} as GridRowModel;
      }
      return {
        _raw: node,
        id: index,
        email: node.email,
        accountType: selectRoles.find(r => r.role === node.role)?.label || '',
      } as GridRowModel;
    }) as GridRowModel[];
  };

  // Column titles for mapping and sorting
  const userColumns: GridColDef[] = [
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      hide: !isMobileView,
      field: 'mobileRender',
      sortable: false,
      headerName: 'mobileRender',
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        // eslint-disable-next-line no-underscore-dangle
        const user = params.row._raw;
        const isNotViewerUser = user.email?.toLowerCase() !== userEmail;
        return (
          <div className={classes.containerMobile}>
            <div className={canEdit ? classes.editableTextContainerMobile : classes.textContainerMobile}>
              <div className={classes.emailText}>{row.email}</div>
              <div className={classes.roleText}>{row.accountType}</div>
            </div>
            <div className={classes.buttonContainerMobile}>
              {canEdit && isNotViewerUser && (
                <Tooltip
                  title={<span className={classes.tooltip}>Edit</span>}
                  PopperProps={{
                    popperOptions: {
                      modifiers: [
                        {
                          offset: {
                            enabled: true,
                            offset: '0px, -85px',
                          },
                        },
                      ],
                    },
                  }}
                >
                  <IconButton aria-label="edit" onClick={() => onEditUserClick(user as CustomerUser)} data-cy="edit-row" size="large">
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className={classes.buttonContainerMobile}>
              {canEdit && isNotViewerUser && (
                <Tooltip
                  title={<span className={classes.tooltip}>Delete</span>}
                  PopperProps={{
                    popperOptions: {
                      modifiers: [
                        {
                          offset: {
                            enabled: true,
                            offset: '0px, -85px',
                          },
                        },
                      ],
                    },
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    onClick={() => onDeleteUserClick(user as CustomerUser)}
                    data-cy="delete-row"
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      hide: isMobileView,
      field: 'email',
      sortable: true,
      headerName: 'Email',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      hide: isMobileView,
      field: 'accountType',
      sortable: false,
      headerName: 'Account Type',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      sortable: false,
      hide: isMobileView,
      field: 'action',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        // eslint-disable-next-line no-underscore-dangle
        const user = params.row._raw;
        const isNotViewerUser = user.email?.toLowerCase() !== userEmail;
        return (
          <>
            {canEdit && isNotViewerUser && (
              <Tooltip
                title={<span className={classes.tooltip}>Edit</span>}
                PopperProps={{
                  popperOptions: {
                    modifiers: [
                      {
                        offset: {
                          enabled: true,
                          offset: '0px, -85px',
                        },
                      },
                    ],
                  },
                }}
              >
                <IconButton aria-label="edit" onClick={() => onEditUserClick(user as CustomerUser)} data-cy="edit-row" size="large">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {canEdit && isNotViewerUser && (
              <Tooltip
                title={<span className={classes.tooltip}>Delete</span>}
                PopperProps={{
                  popperOptions: {
                    modifiers: [
                      {
                        offset: {
                          enabled: true,
                          offset: '0px, -85px',
                        },
                      },
                    ],
                  },
                }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={() => onDeleteUserClick(user as CustomerUser)}
                  data-cy="delete-row"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];
  return (
    <>
      <AddOrEditCustomerUser
        open={isAddOrEditOpen}
        onClose={onAddOrEditUserClose}
        user={selectedUser}
        isLoading={isUpsertCustomerUserInFlight}
        error={upsertCustomerUserError}
        reloadCustomer={reloadCustomer}
        upsertCustomerUserStatus={upsertCustomerUserStatus}
        handleSave={handleSaveUser}
        userList={customer?.customerUsers}
      />
      {selectedUser && (
        <RemoveCustomerUser
          open={isRemoveOpen}
          onClose={onDeleteUserClose}
          user={selectedUser}
          isLoading={isRemoveCustomerUserInFlight}
          customerName={customer?.name || ''}
          reloadCustomer={reloadCustomer}
          removeCustomerUserStatus={removeCustomerUserStatus}
          handleDelete={handleDeleteUser}
        />
      )}
      <Paper className={isMobileSize ? classes.paperSm : classes.paper} role="region" aria-label="Manage Users">
        <Toolbar className={classes.toolbar} variant="regular">
          <Box className={classes.title} data-cy="manage-users-header">
            <Typography variant="title">Users</Typography>
          </Box>
          {canEdit && (
            <Button
              color="primary"
              variant="outlined"
              aria-label="add new user"
              className={classes.toolbarButton}
              onClick={onAddUserClick}
              startIcon={<AddIcon />}
              data-cy="add-new-user"
            >
              NEW USER
            </Button>
          )}
        </Toolbar>
        <Box
          className={isMobileView ? classes.gridWrapMobile : classes.gridWrap}
          data-cy="users-table"
          role="region"
          aria-label="All Users"
        >
          <Box hidden={!isLoadingCustomer}>
            {isMobileView ? mobileSkeleton() : <SkeletonGrid hasCheckbox={false} headers={userColumns} rowCount={10} />}
          </Box>
          <Box className={classes.gridBoxContainer} hidden={isLoadingCustomer}>
            <DataGridPro
              headerHeight={isMobileView ? 0 : 56}
              rowHeight={isMobileView ? 64 : 58}
              aria-label="users table"
              hideFooterPagination
              hideFooter
              hideFooterSelectedRowCount
              className={isMobileView ? classes.dataGridMobile : classes.dataGrid}
              rows={customerListError ? ([] as GridRowModel[]) : getUserRows()}
              columns={userColumns}
              components={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                NoRowsOverlay: noRowsOverlay,
              }}
              sortingOrder={['desc', 'asc']}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ManageUsers;
