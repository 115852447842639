import { Button, Card, CardActionArea, Grid, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { CustomerInfo, PayerPaymentMethod, PaymentMethodStatus, PaymentMethodType, Token } from '../gql-types.generated';
import { createStyles, makeStyles } from '@mui/styles';
import { CardBrandForDisplay } from '../util/CardBrandForDisplay';
import { getPaymentMethodIcon } from './PaymentMethods';
import CheckIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import VerifyIcon from '@mui/icons-material/CreditScore';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    methodCard: {
      width: '100%',
      height: '100%',
      border: 'solid 1.5px #0275d8',
      borderRadius: theme.spacing(2),
    },
    methodCardSelected: {
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(2),
      backgroundColor: '#EEF4FE',
    },
    methodCardVerifying: {
      width: '100%',
      height: '100%',
      border: 'solid 1.5px #0275d8',
      borderRadius: theme.spacing(2),
      backgroundColor: '#F3F3F3',
    },
    methodCardSelectedVerifying: {
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(2),
      backgroundColor: '#F3F3F3',
    },
    methodCardUnverified: {
      width: '100%',
      height: '100%',
      border: 'solid 1.5px #0275d8',
      borderRadius: theme.spacing(2),
      backgroundColor: '#FFF7F7',
    },
    methodCardSelectedUnverified: {
      width: '100%',
      height: '100%',
      borderRadius: theme.spacing(2),
      backgroundColor: '#FFF7F7',
    },
    methodCardActionContainer: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    cardText: {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    cardTextGrey: {
      fontSize: '14px',
      color: 'black',
      fontWeight: 'bold',
    },
    methodIcon: {
      textAlign: 'end',
    },
    methodActionButtons: {
      color: theme.palette.getContrastText('#000000'),
      padding: theme.spacing(1),
      height: 36,
      '&:hover': {
        backgroundColor: '#757575',
      },
    },
    closeActionButton: {
      color: theme.palette.getContrastText('#000000'),
      '&:hover': {
        backgroundColor: '#757575',
      },
    },
    paymentMethodCard: {
      width: '100%',
      height: '100%',
      border: 'solid 1.5px #0275d8',
      borderRadius: theme.spacing(2),
      backgroundColor: '#FCFCFC',
    },
    cardBorder: {
      margin: theme.spacing(0, -2, 0, 0),
      paddingRight: theme.spacing(2),
    },
    cardTextBlue: {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#0275d8',
      display: 'flex',
      alignItems: 'center',
    },
    unverifiedText: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: theme.palette.error.main,
    },
    verifyingText: {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    brandArea: {
      alignItems: 'center',
      paddingBottom: theme.spacing(2),
    },
    cardRelative: {
      position: 'relative',
    },
    selectedCardAction: {
      position: 'absolute',
      background: '#000000DE',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      padding: theme.spacing(1.5),
      color: '#ffffff',
    },
    restricted: {
      cursor: 'default',
    },
    closeIconContainer: {
      textAlign: 'right',
      width: 36,
    },
    shareCardText: {
      textAlign: 'left',
    },
  }),
);

interface PaymentMethodCardProps {
  method?: PayerPaymentMethod;
  token?: Token;
  isSelectedMethod: boolean;
  handleCardClicked?: (paymentMethod: PayerPaymentMethod) => void;
  handleDeleteClick?: () => void;
  handleSetDefaultClick?: () => void;
  handleShareWithMerchantClick?: () => void;
  handleVerifyClick?: (paymentMethod: PayerPaymentMethod) => void;
  findShareWithMerchant?: (paymentMethodId?: string) => boolean;
  isRestrictedRole?: boolean;
  customerInfo?: CustomerInfo;
  isPayerOrCustomerSharedCard?: (paymentMethodId?: string) => boolean;
}

const PaymentMethodCard: React.FC<PaymentMethodCardProps> = props => {
  const {
    method,
    token,
    isSelectedMethod,
    isRestrictedRole,
    customerInfo,
    handleCardClicked,
    handleSetDefaultClick,
    handleShareWithMerchantClick,
    handleDeleteClick,
    handleVerifyClick,
    findShareWithMerchant,
    isPayerOrCustomerSharedCard,
  } = props;
  const classes = useStyles();
  const matchesXsDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (method || token) {
    const unverifiedBankAccount = (method?.paymentMethod?.paymentBank &&
      (method.paymentMethod?.status === PaymentMethodStatus.Unverified ||
        method.paymentMethod?.status === PaymentMethodStatus.VerificationFailed)) as boolean;

    const pendingVerificationAccount = (method?.paymentMethod?.paymentBank &&
      method.paymentMethod?.status === PaymentMethodStatus.VerificationPending) as boolean;

    const verificationFailedMethod = method?.paymentMethod?.status === PaymentMethodStatus.VerificationFailed;

    const unverifiedCardClasses = isSelectedMethod ? classes.methodCardSelectedUnverified : classes.methodCardUnverified;

    const verifyingCardClasses = isSelectedMethod ? classes.methodCardSelectedVerifying : classes.methodCardVerifying;

    const verifyClasses = unverifiedBankAccount || verificationFailedMethod ? unverifiedCardClasses : verifyingCardClasses;

    const cardClasses = isSelectedMethod ? classes.methodCardSelected : classes.methodCard;

    const allowDefault = !(
      unverifiedBankAccount ||
      method?.isDefault ||
      verificationFailedMethod ||
      isRestrictedRole ||
      (isPayerOrCustomerSharedCard && !isPayerOrCustomerSharedCard(method?.paymentMethod?.id)) ||
      false
    );

    const allowShare =
      method?.paymentMethod?.type === PaymentMethodType.CreditCard &&
      !(
        unverifiedBankAccount ||
        method?.isDefault ||
        verificationFailedMethod ||
        isRestrictedRole ||
        (isPayerOrCustomerSharedCard && isPayerOrCustomerSharedCard(method?.paymentMethod?.id)) ||
        false
      );

    return (
      <Grid item container className={classes.cardContainer}>
        <Grid
          item
          container
          xs={12}
          onClick={() => {
            if (!isSelectedMethod && !isRestrictedRole && method && handleCardClicked) {
              handleCardClicked(method);
            }
          }}
          data-cy="payment-method-card"
        >
          <Card
            variant="outlined"
            className={`${
              unverifiedBankAccount || pendingVerificationAccount || verificationFailedMethod ? verifyClasses : cardClasses
            } ${isSelectedMethod ? classes.cardRelative : ''}`}
          >
            <CardActionArea className={`${classes.methodCardActionContainer} ${isRestrictedRole || token ? classes.restricted : ''}`}>
              <Grid item container xs={12} className={classes.brandArea}>
                <Grid item xs={8}>
                  <Typography className={classes.cardTextGrey}>
                    {method?.paymentMethod?.creditCard?.cardBrand &&
                      CardBrandForDisplay.find(data => data.key === method.paymentMethod?.creditCard?.cardBrand)?.value}
                    {method?.paymentMethod?.paymentBank && 'Bank Account'}
                    {token?.creditCard?.cardBrand &&
                      CardBrandForDisplay.find(data => data.key === token?.creditCard?.cardBrand)?.value}
                  </Typography>
                  {verificationFailedMethod && <Typography className={classes.unverifiedText}>VERIFICATION FAILED</Typography>}
                  {unverifiedBankAccount && <Typography className={classes.unverifiedText}>UNVERIFIED</Typography>}
                  {pendingVerificationAccount && <Typography className={classes.verifyingText}>VERIFYING</Typography>}
                  <Grid item xs={12} className={classes.cardBorder}>
                    {!customerInfo && method?.isDefault && <Typography className={classes.cardTextBlue}>DEFAULT</Typography>}
                    {findShareWithMerchant && (
                      <>
                        {customerInfo && method?.isDefault && !findShareWithMerchant(method?.paymentMethod?.id) && (
                          <Typography className={classes.cardTextBlue}>DEFAULT</Typography>
                        )}
                        {customerInfo && method?.isDefault && findShareWithMerchant(method?.paymentMethod?.id) && (
                          <Typography className={classes.cardTextBlue}>
                            {<PeopleIcon color="primary" style={{ marginRight: 4 }} />} SHARED - DEFAULT
                          </Typography>
                        )}
                        {customerInfo && !method?.isDefault && findShareWithMerchant(method?.paymentMethod?.id) && (
                          <Typography className={classes.cardTextBlue}>
                            {<PeopleIcon color="primary" style={{ marginRight: 4 }} />} SHARED
                          </Typography>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={4} className={classes.methodIcon}>
                  {getPaymentMethodIcon(method, false, matchesXsDown ? 60 : 65, true, token)}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.cardText}>{`****  ****  ****  ${
                  method?.paymentMethod?.creditCard?.lastFour ||
                  method?.paymentMethod?.paymentBank?.lastFour ||
                  token?.creditCard?.lastFour
                }`}</Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={8}>
                  <Typography className={classes.cardTextGrey} noWrap>
                    {method?.paymentMethod?.creditCard?.cardHolder?.holderName ||
                      method?.paymentMethod?.paymentBank?.accountHolder?.holderName ||
                      token?.creditCard?.cardHolder?.holderName}
                  </Typography>
                </Grid>
              </Grid>
            </CardActionArea>
            {method && isSelectedMethod && (
              <Grid container className={classes.selectedCardAction}>
                <Grid container xs alignContent="flex-start">
                  {/* verify account button */}
                  {unverifiedBankAccount && !isRestrictedRole && (
                    <Grid item xs={12}>
                      <Button
                        variant="text"
                        size="small"
                        startIcon={<VerifyIcon />}
                        onClick={() => {
                          if (method && handleVerifyClick) {
                            handleVerifyClick(method);
                          }
                        }}
                        classes={{ root: classes.methodActionButtons }}
                        data-cy="verify-account"
                      >
                        VERIFY
                      </Button>
                    </Grid>
                  )}
                  {/* make default button */}
                  {allowDefault && (
                    <Grid item xs={12}>
                      <Button
                        variant="text"
                        size="small"
                        startIcon={<CheckIcon />}
                        onClick={() => {
                          if (handleSetDefaultClick) handleSetDefaultClick();
                        }}
                        classes={{ root: classes.methodActionButtons }}
                        data-cy="make-default"
                      >
                        MAKE DEFAULT
                      </Button>
                    </Grid>
                  )}
                  {/* share card with merchant button */}
                  {allowShare && (
                    <Grid item xs={12}>
                      <Button
                        variant="text"
                        size="small"
                        startIcon={<CheckIcon />}
                        onClick={() => {
                          if (handleShareWithMerchantClick) handleShareWithMerchantClick();
                        }}
                        classes={{ root: `${classes.methodActionButtons} ${classes.shareCardText}` }}
                        data-cy="share-card"
                      >
                        SHARE CARD WITH MERCHANT
                      </Button>
                    </Grid>
                  )}
                  {/* delete payment method button */}
                  {!isRestrictedRole && (
                    <Grid item xs={12}>
                      <Button
                        variant="text"
                        size="small"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          if (handleDeleteClick) handleDeleteClick();
                        }}
                        classes={{ root: classes.methodActionButtons }}
                        data-cy="delete-payment-method"
                      >
                        DELETE
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid item className={classes.closeIconContainer}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (method && handleCardClicked) {
                        handleCardClicked(method);
                      }
                    }}
                    classes={{ root: classes.closeActionButton }}
                    data-cy="unselect-method"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default PaymentMethodCard;
