import { Box, Button, Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { Token } from '../gql-types.generated';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AlertBanner from './AlertBanner';
import { CardTypeConvenienceFee } from '../custom-types/CardTypeConvenienceFee';
import PaymentMethodCard from './PaymentMethodCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    creditCardInformationWrap: {
      padding: theme.spacing(1.5),
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    paymentAction: {
      marginTop: 6,
      marginLeft: 4,
    },
  }),
);

interface GuestCardDetailsProps {
  token: Token;
  handleBack: () => void;
  convenienceFee?: CardTypeConvenienceFee;
}

const GuestCardDetails: React.FC<GuestCardDetailsProps> = props => {
  const { token, handleBack, convenienceFee } = props;
  const classes = useStyles();

  return (
    <Box>
      <Grid container justifyContent="space-between" spacing={1} className={classes.creditCardInformationWrap}>
        <Grid item container xs={12} sm={6} md={12} lg={7}>
          <PaymentMethodCard isSelectedMethod={false} token={token} />
        </Grid>
        {convenienceFee && convenienceFee.rateBps > 0 && (
          <Grid item xs={12}>
            <AlertBanner
              severity="info"
              message={`A convenience fee of ${(convenienceFee?.rateBps || 0) / 100}% will apply to this transaction`}
            />
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="space-between" className={classes.padding}>
        <Button
          className={classes.paymentAction}
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            handleBack();
          }}
          data-cy="back"
        >
          Back
        </Button>
      </Grid>
    </Box>
  );
};

export default GuestCardDetails;
