/* eslint-disable react/no-unescaped-entities */
import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogContent, DialogTitle, Grid, IconButton, TextField, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ChangeEvent, useState } from 'react';

import { PayerPaymentMethod } from '../gql-types.generated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: 0,
    },
    closeIcon: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    text: {
      padding: 0,
      marginTop: theme.spacing(1),
    },
    textfield: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  }),
);

interface PaymentMethodVerificationProps {
  onMobile: boolean;
  submit: (paymentMethodId: string | undefined, micro1: number, micro2: number) => void;
  handleBack: () => void;
  merchantStatementDescription: string;
  paymentMethod: PayerPaymentMethod | undefined;
}

const PaymentMethodVerification: React.FC<PaymentMethodVerificationProps> = props => {
  const classes = useStyles();
  const { submit, handleBack, merchantStatementDescription, paymentMethod } = props;
  const [micro1, setMicro1] = useState<string | undefined>();
  const [micro2, setMicro2] = useState<string | undefined>();
  const handleMicro1Change = (event: ChangeEvent<HTMLInputElement>) => {
    setMicro1(event.target.value);
  };
  const handleMicro2Change = (event: ChangeEvent<HTMLInputElement>) => {
    setMicro2(event.target.value);
  };
  const paymentMethodId = paymentMethod?.paymentMethod?.id;

  return (
    <>
      <DialogTitle className={classes.padding}>
        <Typography variant="title">Verify Bank Account</Typography>
        <IconButton className={classes.closeIcon} onClick={handleBack} size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.text}>
        <Grid container item xs={12}>
          <Typography>
            {`Please confirm the amounts of the two test deposits on your statement. They'll be labeled "${merchantStatementDescription}", and each is less than $1.00.`}
          </Typography>
        </Grid>
        <Grid item container xs={12} className={classes.textfield}>
          <TextField
            variant={'outlined'}
            fullWidth
            autoFocus
            value={micro1}
            onChange={handleMicro1Change}
            label="$ First Deposit"
            data-cy="first-deposit"
          />
        </Grid>
        <Grid item container xs={12} className={classes.textfield}>
          <TextField
            variant={'outlined'}
            fullWidth
            value={micro2}
            onChange={handleMicro2Change}
            label="$ Second Deposit"
            data-cy="second-deposit"
          />
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end" className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            disabled={!(micro1 && micro2)}
            onClick={() => {
              if (micro1 && micro2) {
                // Math.round is necessary here because float multiplication can give non-integers for some values e.g. 0.56
                submit(paymentMethodId, Math.round(parseFloat(micro1) * 100), Math.round(parseFloat(micro2) * 100));
              }
            }}
            data-cy="continue-verify"
          >
            Verify
          </Button>
        </Grid>
      </DialogContent>
    </>
  );
};

export default PaymentMethodVerification;
