import { PaymentRequestInfo, PaymentRequestStatus } from '../gql-types.generated';

export const getPaymentErrorMessage = (paymentError: Error): string => {
  //500 error indicates something is down/unavailable
  if ((paymentError.message && paymentError.message.includes('500')) || (paymentError.name && paymentError.name.includes('500'))) {
    return 'Payments are temporarily not available, please try again later';
  }
  //WePay sends an error Invalid parameter(s) if billing information is invalid
  if (
    (paymentError.message && paymentError.message.includes('Invalid parameter')) ||
    (paymentError.name && paymentError.name.includes('Invalid parameter'))
  ) {
    return 'Please verify your billing information and try again';
  }
  return paymentError.message ? paymentError.message : 'An error occured, please try again.';
};

export const getIsRequestPayable = (paymentRequestInfo?: PaymentRequestInfo): boolean => {
  return !!(
    paymentRequestInfo &&
    paymentRequestInfo.refundPolicy &&
    (paymentRequestInfo.status === PaymentRequestStatus.Unpaid ||
      paymentRequestInfo.status === PaymentRequestStatus.Failed ||
      paymentRequestInfo.status === PaymentRequestStatus.Canceled ||
      paymentRequestInfo.status === PaymentRequestStatus.Unknown)
  );
};
