import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  Tab,
  TableContainer,
  Tabs,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';

import { Maybe, PayerTransaction, Payment, PaymentMethod, PaymentMethodType, PaymentStatus } from '../gql-types.generated';
import { paymentStatusDisplay } from '../util/PaymentStatus';
import DownloadInvoices from './DownloadInvoices';
import SpecificPaymentsDetails from './SpecificPaymentsDetails';
import InfoIcon from '@mui/icons-material/Info';
import TabPanel from './TabPanel';
import LoadingMask from './LoadingMask';

const useStylesDesktop = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: theme.spacing(0, 0, 4, 0),
      position: 'relative',
    },
    infoSections: {
      margin: 0,
      padding: theme.spacing(1.5, 0),
    },
    contentSectionMd: {
      margin: 0,
      padding: theme.spacing(0, 1.5, 0, 2),
      marginRight: 48,
    },
    contentSectionSm: {
      margin: 0,
      padding: theme.spacing(0, 1.5, 0, 2),
      marginRight: 36,
    },
    referenceId: {
      padding: 0,
      margin: 0,
    },
    referenceContent: {
      fontWeight: 'bold',
      margin: 0,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    closeButtonContainer: {
      textAlign: 'right',
    },
    closeButton: {
      color: theme.palette.grey[500],
    },
    selectedTab: {
      backgroundColor: '#EEF4FE',
    },
    tabRoot: {
      padding: theme.spacing(0, 6),
      minWidth: '50%',
    },
    tabsIndicator: {
      height: 0,
      width: 0,
    },
    tabsRoot: {
      borderBottom: 'solid 3px #0275D8',
    },
    headingContent: {
      color: 'grey',
    },
    detailsContent: {
      color: 'black',
    },
    copyIcon: {
      fontSize: '1rem',
      marginLeft: 15,
    },
    table: {
      width: '100%',
    },
    tooltip: {
      cursor: 'pointer',
      paddingBottom: 0,
    },
    cell: {
      color: theme.palette.grey[800],
    },
    headerCell: {
      color: theme.palette.grey[800],
      backgroundColor: '#FFFFFF',
    },
    paymentButton: {
      padding: theme.spacing(1, 0),
      marginBottom: theme.spacing(1),
    },
    actionButtonsContainerSm: {
      paddingLeft: 32,
      paddingTop: theme.spacing(1),
    },
    dialogWidth: {
      minWidth: 700,
      maxWidth: 700,
    },
    dataGrid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
      '& .MuiDataGrid-cell': {
        fontSize: '0.875rem',
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    noPadding: {
      padding: 0,
    },
    dialogTitlePadding: {
      padding: theme.spacing(1.5),
    },
  }),
);

interface RecentPaymentDetailsProps {
  recentPaymentDetailsVisible: boolean;
  payment: PayerTransaction | null | undefined;
  closeRecentPaymentDetails: () => void;
  creditMemosEnabled: boolean;
  isLoadingSpecificPayment: boolean;
  specificPaymentRecord?: Payment;
  fetchSpecificPaymentRecord: (id: string, tenantId: string) => void;
}

const RecentPaymentDetails: React.FC<RecentPaymentDetailsProps> = props => {
  const classesDesktop = useStylesDesktop();
  const {
    recentPaymentDetailsVisible,
    payment,
    closeRecentPaymentDetails,
    creditMemosEnabled,
    isLoadingSpecificPayment,
    specificPaymentRecord,
    fetchSpecificPaymentRecord,
  } = props;
  const paymentsHistoryCount = (payment?.paymentRequest?.payments.length || 0) > 0;
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [referenceCopied, setReferenceCopied] = useState(false);
  const [tabValue] = useState(0);
  const [openSpecificPaymentRecord, setOpenSpecificPaymentRecord] = useState(false);

  const openSpecificPaymentsDetails = (id: string) => {
    if (specificPaymentRecord && specificPaymentRecord.id === id) {
      setOpenSpecificPaymentRecord(true);
    } else {
      const tenantId = payment?.paymentRequest?.owner.tenantId;
      if (id && tenantId) {
        fetchSpecificPaymentRecord(id, tenantId);
      }
    }
  };

  const closeSpecificPaymentsDetails = () => {
    setOpenSpecificPaymentRecord(false);
  };

  useEffect(() => {
    if (!recentPaymentDetailsVisible) {
      setOpenSpecificPaymentRecord(false);
    }
  }, [recentPaymentDetailsVisible]);

  useEffect(() => {
    if (recentPaymentDetailsVisible && specificPaymentRecord) {
      setOpenSpecificPaymentRecord(true);
    }
  }, [specificPaymentRecord]);

  const getPaymentMethodString = (
    paymentMethod: Maybe<PaymentMethod> | undefined,
    amount: Maybe<number> | undefined,
    creditAmount: Maybe<number> | undefined,
  ): string => {
    if (!amount && creditAmount) return 'None';
    switch (paymentMethod?.type) {
      case PaymentMethodType.CreditCard:
        return `${paymentMethod?.creditCard?.cardBrand} ${paymentMethod?.creditCard?.lastFour}`;
      case PaymentMethodType.PaymentBankUs:
        return `Bank ${paymentMethod?.paymentBank?.lastFour}`;
      default:
        return 'None';
    }
  };

  const paymentHistoryColumns: GridColDef[] = [
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'paymentDate',
      sortable: false,
      headerName: 'Payment Date',
      minWidth: 130,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'paymentMethod',
      sortable: false,
      headerName: 'Payment Method',
      minWidth: 150,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'paid',
      headerName: 'Paid',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      minWidth: 90,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'creditApplied',
      sortable: false,
      hide: !creditMemosEnabled,
      headerName: 'Credit Applied',
      headerAlign: 'right',
      align: 'right',
      minWidth: 120,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'total',
      sortable: false,
      hide: !creditMemosEnabled,
      headerName: 'Total',
      headerAlign: 'right',
      align: 'right',
      minWidth: 120,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'balance',
      sortable: false,
      headerName: 'Balance',
      headerAlign: 'right',
      align: 'right',
      minWidth: 120,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'status',
      sortable: false,
      headerName: 'Status',
      minWidth: 90,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'detailsAction',
      sortable: false,
      headerName: '',
      width: 50,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        // eslint-disable-next-line no-underscore-dangle
        const row = params.row._raw;
        return (
          <IconButton
            size="small"
            onClick={() => {
              openSpecificPaymentsDetails(row?.id as string);
            }}
            color="primary"
          >
            <InfoIcon />
          </IconButton>
        );
      },
    },
  ];
  const getPaymentHistoryRows = () => {
    let remainingBalance = 0;
    const paymentRequest = payment?.paymentRequest;
    const thisPayment = payment?.payment;
    if (paymentRequest?.amount) {
      remainingBalance = paymentRequest.amount;
    }

    // checking whether we have any authorization payment
    const authorizationPayment =
      paymentRequest?.payments?.filter(
        record =>
          record?.status !== PaymentStatus.Failed && record?.status !== PaymentStatus.Canceled && record?.immediateCapture === false,
      ).length || 0;

    if (
      authorizationPayment < 1 &&
      paymentRequest?.discountAmount &&
      new Date(thisPayment?.completedTimestamp) < new Date(paymentRequest?.discountEndDate)
    ) {
      remainingBalance -= paymentRequest.discountAmount;
    }
    return paymentRequest?.payments.map((row: Maybe<Payment>, index: number) => {
      const node = row;
      if (!node) {
        return {} as GridRowModel;
      }
      if (node?.amount) {
        if (node?.status === PaymentStatus.Completed || node?.status === PaymentStatus.Pending) {
          remainingBalance -= node.amount;
        }
      }
      if (node?.creditAmount) {
        remainingBalance -= node.creditAmount;
      }

      const currencyFormatter = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: node?.currency || 'USD',
        currencyDisplay: 'symbol',
      });

      return {
        _raw: node,
        id: `${node?.id}-${index}`,
        paymentDate: new Date(node?.attemptTimestamp || node?.createdAt).toLocaleDateString(),
        paymentMethod: getPaymentMethodString(node?.paymentMethod, node?.amount, node?.creditAmount),
        paid: currencyFormatter.format(typeof node?.amount === 'number' ? node?.amount / 100 : 0),
        creditApplied: currencyFormatter.format(typeof node?.creditAmount === 'number' ? node.creditAmount / 100 : 0),
        total: currencyFormatter.format(((node?.amount || 0) + (node?.creditAmount || 0)) / 100),
        balance: currencyFormatter.format(remainingBalance / 100),
        status: node?.amountDisputed
          ? 'Disputed'
          : paymentStatusDisplay.find(item => {
              return item.name === node?.status;
            })?.display,
      } as GridRowModel;
    }) as GridRowModel[];
  };

  return (
    <>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeRecentPaymentDetails();
          }
        }}
        open={recentPaymentDetailsVisible}
        fullWidth={paymentsHistoryCount || matches}
        maxWidth={'md'}
        classes={{ paper: paymentsHistoryCount || matches ? '' : classesDesktop.dialogWidth }}
        aria-labelledby="modal-heading"
        data-cy="recent-payment-details-modal"
      >
        <LoadingMask loading={isLoadingSpecificPayment} />
        <DialogTitle classes={{ root: classesDesktop.dialogTitlePadding }}>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="subtitle2" id="modal-heading">
                PAYMENT REQUEST
              </Typography>
              <Typography className={classesDesktop.referenceId}>
                <Tooltip
                  className={classesDesktop.tooltip}
                  title={`${referenceCopied ? 'Copied' : 'Copy'} to clipboard`}
                  placement="bottom-start"
                >
                  <Link
                    className={classesDesktop.referenceContent}
                    color="primary"
                    underline="hover"
                    onClick={() => {
                      setReferenceCopied(true);
                      navigator.clipboard.writeText(payment?.paymentRequest?.referenceNumber?.trim() || '');
                    }}
                    onMouseEnter={() => {
                      if (referenceCopied) setReferenceCopied(false);
                    }}
                    onFocus={() => {
                      if (referenceCopied) setReferenceCopied(false);
                    }}
                    href="#"
                    data-cy="payment-id-copy"
                  >
                    {payment?.paymentRequest?.referenceNumber?.trim()}
                    <CopyIcon className={classesDesktop.copyIcon} />
                  </Link>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={2} className={classesDesktop.closeButtonContainer}>
              <IconButton
                aria-label="close"
                title="close"
                className={classesDesktop.closeButton}
                onClick={closeRecentPaymentDetails}
                data-cy="recent-payment-details-close"
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classesDesktop.noPadding}>
          <Box className={classesDesktop.dialogContent}>
            <Box className={matches ? classesDesktop.contentSectionSm : classesDesktop.contentSectionMd}>
              <Grid container>
                <Grid container item xs={12} md={9}>
                  <Grid container spacing={1}>
                    {payment?.paymentRequest?.orderNumber && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography className={classesDesktop.headingContent}>Order#</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography className={classesDesktop.detailsContent}>{payment.paymentRequest.orderNumber}</Typography>
                        </Grid>
                      </>
                    )}
                    {payment?.paymentRequest?.customerPONumber && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography className={classesDesktop.headingContent}>Customer PO#</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography className={classesDesktop.detailsContent}>{payment.paymentRequest.customerPONumber}</Typography>
                        </Grid>
                      </>
                    )}
                    {payment?.paymentRequest?.invoiceNumber && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography className={classesDesktop.headingContent}>Invoice#</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography className={classesDesktop.detailsContent}>{payment.paymentRequest.invoiceNumber}</Typography>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                      <Typography className={classesDesktop.headingContent}>Request Amount</Typography>
                    </Grid>
                    <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                      <Typography className={classesDesktop.detailsContent}>
                        {typeof payment?.paymentRequest?.amount === 'number'
                          ? new Intl.NumberFormat('en', {
                              style: 'currency',
                              currency: payment?.currency || 'USD',
                              currencyDisplay: 'symbol',
                            }).format(payment?.paymentRequest?.amount / 100)
                          : null}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                      <Typography className={classesDesktop.headingContent}>Requested On</Typography>
                    </Grid>
                    <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                      <Typography className={classesDesktop.detailsContent}>
                        {new Date(payment?.paymentRequest?.createdAt).toLocaleDateString()}
                      </Typography>
                    </Grid>

                    {payment?.paymentRequest?.discountEndDate && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography className={classesDesktop.headingContent}>Discount End Date</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography className={classesDesktop.detailsContent}>
                            {new Date(payment.paymentRequest.discountEndDate).toLocaleDateString()}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {payment?.paymentRequest?.dueDate && (
                      <>
                        <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                          <Typography className={classesDesktop.headingContent}>Final Payment Due</Typography>
                        </Grid>
                        <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                          <Typography className={classesDesktop.detailsContent}>
                            {new Date(payment?.paymentRequest?.dueDate).toLocaleDateString()}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={6} md={paymentsHistoryCount ? 4 : 6}>
                      <Typography className={classesDesktop.headingContent}>Status</Typography>
                    </Grid>
                    <Grid item xs={6} md={paymentsHistoryCount ? 8 : 6}>
                      <Typography className={classesDesktop.detailsContent}>
                        {
                          paymentStatusDisplay.find(item => {
                            return item.name === payment?.paymentRequest?.status;
                          })?.display
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3} className={matches ? classesDesktop.actionButtonsContainerSm : ''}>
                  <DownloadInvoices
                    isIconButton={false}
                    invoiceUrl={payment?.paymentRequest?.invoiceLink}
                    invoicesUrlList={payment?.paymentRequest?.invoiceLinks}
                  />
                </Grid>
              </Grid>
            </Box>
            {paymentsHistoryCount && (
              <Box className={classesDesktop.infoSections}>
                <Paper elevation={0}>
                  <Tabs
                    indicatorColor={'primary'}
                    textColor={'primary'}
                    value={tabValue}
                    classes={{ root: classesDesktop.tabsRoot, indicator: classesDesktop.tabsIndicator }}
                  >
                    <Tab
                      classes={{ selected: classesDesktop.selectedTab, root: classesDesktop.tabRoot }}
                      label="PAYMENT HISTORY"
                      id="payment-history-tab"
                      data-cy="payment-history-tab"
                    />
                  </Tabs>
                  <TabPanel value={tabValue} index={0} data-cy="payment-history-panel">
                    <TableContainer className={classesDesktop.table}>
                      <DataGridPro
                        rowHeight={58}
                        aria-label="payment history"
                        hideFooterPagination
                        hideFooter
                        hideFooterSelectedRowCount
                        className={classesDesktop.dataGrid}
                        rows={getPaymentHistoryRows()}
                        columns={paymentHistoryColumns}
                        autoHeight
                      />
                    </TableContainer>
                  </TabPanel>
                </Paper>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      {specificPaymentRecord && (
        <SpecificPaymentsDetails
          open={openSpecificPaymentRecord}
          close={closeSpecificPaymentsDetails}
          paymentRecord={specificPaymentRecord}
          isCreditMemoEnabled={creditMemosEnabled}
        />
      )}
    </>
  );
};

export default RecentPaymentDetails;
