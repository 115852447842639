import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { CompletePaymentMethodRequestStatus } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_COMPLETE_PAYMENT_METHOD_REQUEST = gql`
  mutation completePaymentMethodRequest($input: CompletePaymentMethodRequestInput!) {
    completePaymentMethodRequest(input: $input) {
      code
      message
      error
    }
  }
`;

export const completePaymentMethodRequest = async (
  client: ApolloClient<NormalizedCacheObject>,
  tenantId: string,
  id: string,
  paymentMethodId: string,
): Promise<CompletePaymentMethodRequestStatus | undefined> => {
  const headers = await getTenantHeadersWithAuth(tenantId);

  const { data, errors } = await client.mutate<{ completePaymentMethodRequest: CompletePaymentMethodRequestStatus }>({
    variables: { input: { id, paymentMethodId } },
    mutation: MUTATION_COMPLETE_PAYMENT_METHOD_REQUEST,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the person.
    throw new Error('An error has occurred during completing the payment method request.');
  }

  if (data && data.completePaymentMethodRequest) {
    return data.completePaymentMethodRequest;
  }

  return undefined;
};
