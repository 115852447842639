import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DeletePaymentMethodStatus } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($input: DeletePaymentMethodInput!) {
    deletePaymentMethod(input: $input) {
      code
      message
      error
    }
  }
`;

export const deletePaymentMethod = async (
  client: ApolloClient<NormalizedCacheObject>,
  paymentMethodId: string,
  tenantId: string,
): Promise<DeletePaymentMethodStatus | undefined> => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.mutate<{ deletePaymentMethod: DeletePaymentMethodStatus }>({
    variables: { input: { paymentMethodId } },
    mutation: MUTATION_DELETE_PAYMENT_METHOD,
    context: {
      headers,
    },
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the person.
    throw new Error('An error has occurred during payment method deletion.');
  }

  if (data && data.deletePaymentMethod) {
    return data.deletePaymentMethod;
  }

  return undefined;
};
