import { KeyboardArrowDown } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Popover,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import { MerchantInfo, PayerTransactionSummary, Person } from '../gql-types.generated';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      boxShadow: '0px 3px 6px #00000012',
      maxHeight: 64,
    },
    logo: {
      height: '27px',
      marginLeft: theme.spacing(0),
      marginTop: '8px',
    },
    linearProgress: {
      marginTop: -4,
    },
    menuIcon: {
      paddingRight: theme.spacing(2),
    },
    mdIndexGrow: {
      width: '100%',
      zIndex: 1,
      position: 'relative',
    },
    toolbar: {
      minHeight: 64,
    },
    avatar: {
      backgroundColor: '#E0E0E0',
      color: '#757575',
      fontWeight: 500,
    },
    settingsBox: {
      width: 400,
    },
    companyLogo: {
      maxHeight: 50,
      maxWidth: 200,
      [theme.breakpoints.down('md')]: {
        maxWidth: 125,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 75,
      },
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      fontWeight: 400,
    },
    nameWrap: {
      maxHeight: 50,
      maxWidth: 225,
      [theme.breakpoints.down('md')]: {
        maxWidth: 125,
      },
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      fontWeight: 400,
    },
    menuItem: {
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      width: '300px',
      whiteSpace: 'normal',
    },
    selectedTenant: {
      backgroundColor: '#EEF4FE',
    },
    walletButton: {
      marginLeft: -8,
      marginRight: 16,
    },
    apteanLogo: {
      marginRight: 8,
    },
  }),
);

interface PrimaryAppBarProps {
  viewerUser?: Person;
  networkBusy?: boolean;
  userLoggedIn: boolean;
  handleMenuIconClick: () => void;
  menuVisible: boolean;
  isCustomerUser: boolean;
  walletButtonVisible: boolean;
  payerTransactionSummary?: PayerTransactionSummary;
  selectedMerchantInfo?: MerchantInfo;
  onMerchantMenuChange: (merchant: string | undefined) => void;
  isOnHome: boolean;
}

const PrimaryAppBar: React.FC<PrimaryAppBarProps> = props => {
  const classes = useStyles();
  const {
    viewerUser,
    networkBusy,
    userLoggedIn,
    handleMenuIconClick,
    menuVisible,
    isCustomerUser,
    walletButtonVisible,
    payerTransactionSummary,
    selectedMerchantInfo,
    isOnHome,
    onMerchantMenuChange,
  } = props;
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const merchantSummary = payerTransactionSummary?.merchantSummary;

  const [userAnchorEl, setUserAnchorEl] = useState<Element | null>();
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>();

  const handleUserIconClick = (event: { currentTarget: Element }) => {
    setUserAnchorEl(event.currentTarget);
  };
  const handleMerchantMenuClick = (event: { currentTarget: Element }) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };
  const handleMerchantMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const getCompanyLogo = (companyLogoUrl: string | undefined, companyName: string | undefined) => {
    if (companyLogoUrl && companyName) {
      return (
        <Box style={{ display: 'flex' }}>
          <img className={classes.companyLogo} data-cy={'company-logo'} src={companyLogoUrl} alt={companyName} />
        </Box>
      );
    }
    if (companyName) {
      return (
        <Box>
          <Typography variant="title" align="left" className={classes.nameWrap}>
            {companyName}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  let initials = <PersonIcon />;
  if (viewerUser && (viewerUser?.firstName || viewerUser?.lastName)) {
    let userInitials = viewerUser.firstName ? viewerUser.firstName[0] : '';
    userInitials += viewerUser.lastName ? viewerUser.lastName[0] : '';

    initials = <span>{userInitials}</span>;
  }

  return (
    <div className={classes.mdIndexGrow} id="primary-app-bar">
      <AppBar position="static" color="inherit" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center">
            {userLoggedIn && walletButtonVisible && (
              <div className={classes.walletButton}>
                <IconButton
                  size="medium"
                  color="default"
                  onClick={handleMenuIconClick}
                  data-cy="menu-icon"
                  aria-label="app menu"
                  aria-expanded={menuVisible}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            )}
            <a href="/" data-cy="home-logo" className={classes.apteanLogo}>
              <img className={classes.logo} src={matches ? 'logoMobile.png' : 'logo.png'} alt="ApteanPay Logo" />
            </a>
            {selectedMerchantInfo &&
              (merchantSummary?.length === 1 || !isOnHome) &&
              getCompanyLogo(selectedMerchantInfo.logoUrl as string, selectedMerchantInfo.name as string)}
            {selectedMerchantInfo && merchantSummary && merchantSummary.length > 1 && isOnHome && (
              <>
                <Button
                  id="merchant-select-button"
                  data-cy="merchant-select-button"
                  aria-label={`${selectedMerchantInfo.name || ''} merchant menu`}
                  aria-controls="merchant-select-menu"
                  aria-haspopup="true"
                  aria-expanded={Boolean(menuAnchorEl)}
                  endIcon={<KeyboardArrowDown color="primary" />}
                  onClick={handleMerchantMenuClick}
                >
                  {getCompanyLogo(selectedMerchantInfo.logoUrl as string, selectedMerchantInfo.name as string)}
                </Button>
                <Menu
                  role="navigation"
                  aria-labelledby="merchant-menu-button"
                  data-cy="merchant-select-menu"
                  open={Boolean(menuAnchorEl)}
                  anchorEl={menuAnchorEl}
                  onClose={handleMerchantMenuClose}
                  MenuListProps={{
                    id: 'merchant-select-menu',
                    'aria-labelledby': 'merchant-menu-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  autoFocus={false}
                >
                  {merchantSummary &&
                    merchantSummary.map((merchant, i) => {
                      const isSelected = selectedMerchantInfo.owner?.tenantId === merchant.merchantInfo?.owner?.tenantId;
                      return (
                        <MenuItem
                          data-cy={`merchant-select-menu-item-${i}`}
                          className={isSelected ? classes.selectedTenant : undefined}
                          autoFocus={isSelected}
                          key={i}
                          onClick={() => {
                            onMerchantMenuChange(merchant?.merchantInfo?.owner?.tenantId);
                            handleMerchantMenuClose();
                          }}
                        >
                          <Typography className={classes.menuItem} color={isSelected ? 'primary' : undefined}>
                            {merchant.merchantInfo?.name}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </Menu>
              </>
            )}
            {userLoggedIn && (
              <>
                <div className={classes.grow} />
                <IconButton
                  id="user-menu-button"
                  aria-label="user menu button"
                  onClick={handleUserIconClick}
                  title="User Settings"
                  data-cy="user-settings"
                  aria-haspopup="true"
                  aria-controls="user-menu"
                  aria-expanded={Boolean(userAnchorEl)}
                  size="large"
                >
                  <Avatar className={classes.avatar} data-cy="user-avatar">
                    {initials}
                  </Avatar>
                </IconButton>
                <Popover
                  aria-hidden={true}
                  anchorEl={userAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  role="navigation"
                  aria-labelledby="settings-menu-button"
                  open={Boolean(userAnchorEl)}
                  onClose={handleUserMenuClose}
                >
                  <Box className={classes.settingsBox}>
                    <UserMenu afterClick={handleUserMenuClose} viewerUser={viewerUser} isCustomerUser={isCustomerUser} />
                  </Box>
                </Popover>
              </>
            )}
          </Grid>
        </Toolbar>
        {networkBusy && <LinearProgress className={classes.linearProgress} color="primary" />}
      </AppBar>
    </div>
  );
};

export default PrimaryAppBar;
