import { CircularProgress, TableContainer, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DataGridPro, GridColDef, GridOverlay, GridRowModel } from '@mui/x-data-grid-pro';
import React from 'react';

import { CreditMemoHistoryData } from '../custom-types/CreditMemoHistoryData';
import { CreditMemoUsageConnection, CurrencyType, Maybe } from '../gql-types.generated';
import SkeletonGrid from './SkeletonGrid';

const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      height: '65vh',
      minHeight: 350,
      overflow: 'auto',
      textAlign: 'center',
    },
    cell: {
      fontSize: 16,
      padding: '16px 4px',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    firstCell: {
      paddingLeft: '35px',
      fontSize: 16,
      wordWrap: 'break-word',
      wordBreak: 'break-all',
    },
    emptyMessage: {
      fontSize: '14px',
      textAlign: 'center',
      fontStyle: 'oblique',
    },
    loadingSpinner: {
      height: 60,
      width: 60,
      display: 'inline-block',
    },
    errorMessage: {
      fontSize: '18px',
      textAlign: 'center',
      fontStyle: 'oblique',
      color: 'red',
    },
    reasonText: {
      wordWrap: 'break-word',
      lineClamp: 1,
      boxOrient: 'vertical',
      display: 'box',
      overflow: 'hidden',
    },
    tableContainerMobile: {
      height: '75vh',
      overflow: 'auto',
      textAlign: 'center',
    },
    overlay: {
      '&.MuiDataGrid-overlay': {
        display: 'inline',
      },
    },
    noRowsOverlayText: {
      fontStyle: 'italic',
      lineHeight: 2,
      borderBottom: '1px solid #e0e0e0',
    },
    dataGrid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-cell:first-child': {
        paddingLeft: 30,
      },
      '& .MuiDataGrid-columnHeader:first-child': {
        paddingLeft: 30,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#F5F5F5',
        maxHeight: 60,
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: 16,
      },
      '& .MuiDataGrid-cell': {
        fontSize: 16,
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    progressOverlay: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'flex',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      opacity: 0.02,
    },
  }),
);

interface CreditMemosHistoryListProps {
  creditMemoHistoryConnection?: CreditMemoUsageConnection;
  creditMemoHistory?: CreditMemoHistoryData[];
  creditMemoHistoryError?: Error;
  loadPage: () => void;
  currency?: CurrencyType;
}

const CreditMemosHistoryList: React.FC<CreditMemosHistoryListProps> = props => {
  const { creditMemoHistory, creditMemoHistoryError, currency, loadPage } = props;
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const getCreditMemoHistoryRows = () => {
    return creditMemoHistory?.map((row: Maybe<CreditMemoHistoryData>, index: number) => {
      const node = row;
      if (!node) {
        return {} as GridRowModel;
      }
      return {
        _raw: node,
        id: `${node?.creditMemoReference}-${index}`,
        creditReference: node?.creditMemoReference,
        reference: node?.paymentRequestReference,
        usedDate: node?.dateUsed ? new Date(node?.dateUsed).toLocaleDateString() : '',
        amountUsed:
          typeof node?.amountUsed === 'number'
            ? new Intl.NumberFormat('en', {
                style: 'currency',
                currency: currency || 'USD',
                currencyDisplay: 'symbol',
              }).format(node?.amountUsed / 100)
            : null,
      } as GridRowModel;
    }) as GridRowModel[];
  };

  // Keep working towards all grids conversion to datagridpro in payer portal.
  const creditHistoryColumns: GridColDef[] = [
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'creditReference',
      sortable: false,
      headerName: 'Credit Memo #',
      minWidth: 180,
      flex: 0.25,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'reference',
      sortable: false,
      headerName: 'Reference #',
      minWidth: 180,
      flex: 0.25,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'usedDate',
      sortable: false,
      headerName: 'Date Used',
      minWidth: 180,
      flex: 0.25,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'amountUsed',
      sortable: false,
      headerName: 'Amount Used',
      headerAlign: 'right',
      align: 'right',
      minWidth: 180,
      flex: 0.25,
    },
  ];

  const loadingOverlay = () => {
    return (
      <GridOverlay>
        <div className={classes.progressOverlay}></div>
        <CircularProgress aria-label={'progress spinner'} key={'payments-spinner'} size={42} />
      </GridOverlay>
    );
  };
  const noRowsOverlay = () => {
    return (
      <GridOverlay className={classes.overlay}>
        {creditMemoHistoryError && (
          <Typography className={classes.noRowsOverlayText} variant="body2">
            Unable to load data. Please try again later.
          </Typography>
        )}
        {!creditMemoHistoryError && creditMemoHistory?.length === 0 && (
          <Typography variant="body2" className={classes.noRowsOverlayText}>
            No Credit Memos in History.
          </Typography>
        )}
      </GridOverlay>
    );
  };
  return (
    <TableContainer className={matches ? classes.tableContainerMobile : classes.tableContainer} data-cy="credit-memo-history-table">
      {!creditMemoHistoryError && !creditMemoHistory && <SkeletonGrid headers={creditHistoryColumns} rowCount={10}></SkeletonGrid>}
      {creditMemoHistory && (
        <DataGridPro
          loading={false}
          rowHeight={58}
          aria-label="Credit Memo History Table"
          paginationMode="server"
          pageSize={25}
          rowsPerPageOptions={[25]}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          className={classes.dataGrid}
          rows={getCreditMemoHistoryRows()}
          columns={creditHistoryColumns}
          components={{
            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
            LoadingOverlay: loadingOverlay,
            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
            NoRowsOverlay: noRowsOverlay,
          }}
          onRowsScrollEnd={loadPage}
        />
      )}
    </TableContainer>
  );
};

export default CreditMemosHistoryList;
