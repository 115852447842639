import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetPersonByEmailQuery, Person } from '../gql-types.generated';
import { getStandardHeadersWithAuth, getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_PERSON_BY_EMAIL = gql`
  query getPersonByEmail($email: String!) {
    person(email: $email) {
      email
      firstName
      id
      owner {
        tenantId
        personId
      }
      lastName
      phone {
        phoneNumber
        countryCode
      }
      payerProfile {
        paymentMethods {
          isDefault
          paymentMethod {
            id
            creditCard {
              cardBrand
              displayName
              expirationMonth
              expirationYear
              lastFour
              recurring
              details {
                type
              }
              cardHolder {
                address {
                  city
                  country
                  line1
                  line2
                  postalCode
                  region
                }
                email
                holderName
                phone {
                  countryCode
                  number
                }
              }
            }
            createdAt
            createdBy
            paymentBank {
              accountType
              lastFour
              accountHolder {
                address {
                  city
                  country
                  line1
                  line2
                  postalCode
                  region
                }
                email
                holderName
                phone {
                  countryCode
                  number
                }
              }
            }
            status
            type
            updatedAt
            updatedBy
            isLongLived
          }
        }
      }
      customers {
        role
        tenantId
        customerId
      }
    }
  }
`;

export const queryPersonByEmail = async (
  client: ApolloClient<NormalizedCacheObject>,
  email: string,
  tenantId?: string,
): Promise<Partial<Person> | undefined> => {
  const headers = tenantId ? await getTenantHeadersWithAuth(tenantId) : await getStandardHeadersWithAuth();
  const { data, errors } = await client.query<GetPersonByEmailQuery>({
    variables: { email },
    query: QUERY_PERSON_BY_EMAIL,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }
  return data?.person as Person;
};
