import { Box, Button, Divider, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import AlertBanner from './AlertBanner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    options: {
      width: '100%',
      padding: theme.spacing(2),
    },
    noUserOptions: {
      width: '100%',
      padding: 0,
      margin: 0,
    },
    guestButton: {
      width: '100%',
    },
    createAccountButton: {
      width: '100%',
    },
    signInButton: {
      width: '100%',
    },
    orWrapper: {
      paddingTop: 8,
    },
    orText: {
      textAlign: 'center',
      width: 40,
    },
    orWrap: {
      flexGrow: 0,
    },
    dividerWrap: {
      flexGrow: 1,
      paddingTop: 12,
    },
    divider: {
      backgroundColor: theme.palette.grey[500],
    },
  }),
);
interface UserOptionsProps {
  continueAsGuest: () => void;
  createAccount: () => void;
  requestPayable: boolean;
  signIn: () => void;
}
const UserOptions: React.FC<UserOptionsProps> = props => {
  const classes = useStyles();
  const { continueAsGuest, createAccount, signIn, requestPayable } = props;
  return (
    <Box className={classes.options}>
      <Grid container spacing={1}>
        {requestPayable && (
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Button
                className={classes.guestButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  continueAsGuest();
                }}
                data-cy="checkout-as-guest"
              >
                CHECKOUT AS GUEST
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.noUserOptions} spacing={1}>
          {requestPayable && (
            <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={12} className={classes.orWrapper}>
              <Grid item xs={'auto'} className={classes.dividerWrap}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={'auto'} className={classes.orWrap}>
                <Typography className={classes.orText}>or</Typography>
              </Grid>
              <Grid item xs={'auto'} className={classes.dividerWrap}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
          )}
          <Grid item xs={8}>
            <Button
              className={classes.createAccountButton}
              color="primary"
              variant="outlined"
              onClick={() => {
                createAccount();
              }}
              data-cy="create-an-account"
            >
              CREATE AN ACCOUNT
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              className={classes.signInButton}
              color="primary"
              variant="outlined"
              onClick={() => {
                signIn();
              }}
              data-cy="sign-in"
            >
              SIGN IN
            </Button>
          </Grid>
          <Grid item xs={12}>
            {requestPayable && <AlertBanner severity="info" message="To enable eCheck, you must have an account" />}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserOptions;
