import { CardBrand, AcceptedPaymentMethod } from '../gql-types.generated';

const MapCardBrandToAcceptedPaymentMethod = (cardBrand: CardBrand): AcceptedPaymentMethod => {
  switch (cardBrand) {
    case CardBrand.Amex:
      return AcceptedPaymentMethod.Amex;
    case CardBrand.Diners:
      return AcceptedPaymentMethod.Diners;
    case CardBrand.Discover:
      return AcceptedPaymentMethod.Discover;
    case CardBrand.Jcb:
      return AcceptedPaymentMethod.Jcb;
    case CardBrand.Mastercard:
      return AcceptedPaymentMethod.Mastercard;
    case CardBrand.Visa:
      return AcceptedPaymentMethod.Visa;
    default:
      return AcceptedPaymentMethod.Unknown;
  }
};

export default MapCardBrandToAcceptedPaymentMethod;
