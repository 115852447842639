import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { getStandardHeaders } from '../util/StandardRequestHeaders';
import { GetPaymentRequestFromUrlTokenQuery, PaymentRequestInfo } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_PAYMENT_REQUEST = gql`
  query getPaymentRequestFromUrlToken($urlToken: String!) {
    paymentRequestFromUrlToken(urlToken: $urlToken) {
      acceptedPaymentMethods
      supportedPaymentMethods
      amount
      currency
      invoiceLink
      invoiceLinks
      merchantName
      merchantStatementDescription
      paymentRequestId
      referenceNumber
      refundPolicy
      status
      statusReason
      tenantId
      supportedPaymentMethods
      totalDue
      dueDate
      discountEndDate
      discountAmount
      customerId
      features {
        payments {
          convenienceFees
        }
      }
    }
  }
`;

export const getPaymentRequestFromUrlToken = async (
  client: ApolloClient<NormalizedCacheObject>,
  urlToken: string,
): Promise<PaymentRequestInfo | undefined> => {
  const headers = getStandardHeaders();
  const { data, errors } = await client.query<GetPaymentRequestFromUrlTokenQuery>({
    variables: { urlToken },
    query: QUERY_PAYMENT_REQUEST,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  return data?.paymentRequestFromUrlToken as PaymentRequestInfo;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const watchGetPaymentRequestFromUrlToken = async (client: ApolloClient<NormalizedCacheObject>, urlToken: string) => {
  const headers = getStandardHeaders();
  return client.watchQuery<GetPaymentRequestFromUrlTokenQuery>({
    variables: { urlToken },
    query: QUERY_PAYMENT_REQUEST,
    context: {
      headers,
    },
    pollInterval: 2000,
  });
};
