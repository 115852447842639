import { PaymentMethodHolder } from '../gql-types.generated';

export interface PostalCodeValidation {
  name: string;
  maxLength: number;
}

export const checkIsEmailValid = (emailValue: string): boolean => {
  if (emailValue.length > 0 && emailValue.trim().length === 0) {
    // Invalid if just spaces
    return false;
  }
  // Valid if no value.
  if (!emailValue) {
    return true;
  }

  return !!emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

export const checkIsPhoneNumberValid = (phoneNumberValue: string): boolean => {
  if (phoneNumberValue.length !== 10) {
    return false;
  }

  return true;
};

export const checkIsTextValid = (text: string, allowEmpty?: boolean): boolean => {
  if (text.length > 0 && text.trim().length === 0) {
    // Invalid if just spaces
    return false;
  }
  if (text.length === 0 && !allowEmpty) {
    // Invalid if empty and not allowed to be
    return false;
  }
  return true;
};

export const checkIsPostalCodeValid = (zipCodeValue: string, country: string | undefined | null): boolean => {
  if (zipCodeValue.length > 0 && zipCodeValue.trim().length === 0) {
    // Invalid if just spaces
    return false;
  }

  if (country === 'US') {
    return !!zipCodeValue.match(/(^\d{5}$)/);
  }
  if (country === 'CA') {
    return !!zipCodeValue.match(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i); //https://stackoverflow.com/a/46761018
  }
  if (country === 'GB') {
    return !!zipCodeValue.match(/^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i); //https://stackoverflow.com/a/51885364
  }
  // TODO: add cases for other commonly used countries
  return true;
};

export const checkIsCountryCodeValid = (countryCodeValue: string): boolean => {
  return !!countryCodeValue.match(/^\+?[0-9]{1,3}$/);
};

export const getPostalValidation = (countryCode: string): { name: string; maxLength: number } => {
  const validation = {
    name: 'Postal Code',
    maxLength: 10,
  };

  switch (countryCode) {
    case 'CA':
      validation.maxLength = 7;
      validation.name = 'Postal Code';
      break;
    case 'GB':
      validation.maxLength = 8;
      validation.name = 'Post Code';
      break;
    case 'US':
      validation.maxLength = 5;
      validation.name = 'Zip Code';
      break;
    default:
      // Use the default config
      break;
  }
  return validation;
};

export const checkIsFullNameValid = (fullName: string): boolean => {
  const name = fullName.trim();
  const fullNameLength = name.length;

  // eslint-disable-next-line no-control-regex
  const nameFormat = RegExp(/^[^\x00-\x1f]+$/);
  const validFormat = nameFormat.test(name);

  if (validFormat === false || fullNameLength < 3 || fullNameLength > 26) {
    return false;
  }

  return true;
};

export const checkHolderInfoValid = (holder: PaymentMethodHolder): boolean => {
  return !!(
    holder?.phone?.countryCode &&
    holder?.phone?.number &&
    holder?.email &&
    holder?.address?.country &&
    holder?.address?.postalCode &&
    holder?.address?.line1 &&
    checkIsEmailValid(holder.email) &&
    checkIsPostalCodeValid(holder?.address?.postalCode, holder?.address?.country) &&
    checkIsPhoneNumberValid(holder?.phone?.number) &&
    checkIsCountryCodeValid(holder?.phone?.countryCode) &&
    checkIsTextValid(holder?.address?.line1) &&
    (!holder?.address?.line2 || holder?.address?.line2 === '' || checkIsTextValid(holder?.address?.line2))
  );
};

export const checkAddBillingAddressValid = (holder: PaymentMethodHolder): boolean => {
  return !!(
    holder?.address?.country &&
    holder?.address?.postalCode &&
    holder?.address?.line1 &&
    checkIsPostalCodeValid(holder?.address?.postalCode, holder?.address?.country) &&
    checkIsTextValid(holder?.address?.line1) &&
    (!holder?.address?.line2 || holder?.address?.line2 === '' || checkIsTextValid(holder?.address?.line2))
  );
};
