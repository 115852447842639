import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { KeyValuePair, PayFac, TenantIntegrationSettings } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_TENANT_INTEGRATION_SETTINGS = gql`
  query getTenantIntegrationSettings {
    integrationSettings {
      payFacType
      data {
        key
        value
      }
      owner {
        tenantId
      }
    }
  }
`;

interface GetTenantIntegrationSettingsResponse {
  integrationSettings: {
    data: KeyValuePair[];
    payFacType: PayFac;
    owner: {
      tenantId: string;
    };
  };
}

export const queryTenantIntegrationSettings = async (
  client: ApolloClient<NormalizedCacheObject>,
  tenantId: string,
): Promise<TenantIntegrationSettings | undefined> => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.query<GetTenantIntegrationSettingsResponse>({
    query: QUERY_TENANT_INTEGRATION_SETTINGS,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }
  return data.integrationSettings as TenantIntegrationSettings;
};
