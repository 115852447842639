import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Card, CardActionArea, Divider, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { MerchantInfo } from '../gql-types.generated';
import LoadingMask from './LoadingMask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      padding: theme.spacing(0, 2, 0, 4),
      marginBottom: theme.spacing(1),
    },
    creditMemoCard: {
      width: '100%',
      height: '100%',
      border: 'solid 1.5px #0275d8',
      borderRadius: theme.spacing(2),
      position: 'relative',
    },
    cardActionContainer: {
      padding: theme.spacing(2),
    },
    merchantName: {
      lineClamp: 2,
      boxOrient: 'vertical',
      display: 'box',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },
    amountText: {
      fontWeight: 'bold',
    },
    arrowContainer: {
      textAlign: 'right',
    },
    arrowIcon: {
      fontSize: '16px',
      color: 'grey',
    },
    dividerContainer: {
      paddingBottom: theme.spacing(2),
    },
    divider: {
      height: '2px',
    },
  }),
);

interface CreditMemoCardProps {
  creditBalance: number;
  cardClick: () => void;
  merchantInfo?: MerchantInfo;
  isLoadingBalance: boolean;
  creditBalanceError?: Error;
}

const CreditMemoCard: React.FC<CreditMemoCardProps> = props => {
  const { creditBalance, cardClick, merchantInfo, isLoadingBalance } = props;
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const getCompanyLogo = () => {
    if (merchantInfo?.logoUrl && merchantInfo.logoUrl.length > 0 && merchantInfo?.name) {
      return (
        <Box>
          <img
            style={{
              maxHeight: matches ? 40 : 55,
              maxWidth: matches ? 80 : 110,
              fontWeight: 'bold',
              lineClamp: 2,
              boxOrient: 'vertical',
              display: 'box',
              overflow: 'hidden',
              wordBreak: 'break-word',
            }}
            src={merchantInfo.logoUrl}
            alt={merchantInfo.name}
          />
        </Box>
      );
    }
    if (merchantInfo?.name) {
      return (
        <Typography variant="title" className={classes.merchantName}>
          {merchantInfo.name}
        </Typography>
      );
    }
    return null;
  };

  if (merchantInfo?.features?.creditMemos.enabled) {
    return (
      <Grid container className={classes.cardContainer}>
        <Grid item xs={12} className={classes.dividerContainer}>
          <Divider orientation="horizontal" className={classes.divider} />
        </Grid>
        <Grid item container xs={12} onClick={cardClick} data-cy="credit-memo-card">
          <Card variant="outlined" className={classes.creditMemoCard}>
            <LoadingMask loading={isLoadingBalance} />
            <CardActionArea className={classes.cardActionContainer}>
              <Grid item container xs={12}>
                <Grid item container xs={11}>
                  <Grid item xs={6}>
                    {getCompanyLogo()}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Credits</Typography>
                    <Typography variant="body1" className={classes.amountText}>
                      {new Intl.NumberFormat('en', {
                        style: 'currency',
                        currency: merchantInfo?.defaultCurrency || 'USD',
                        currencyDisplay: 'symbol',
                      }).format(creditBalance / 100)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={1} className={classes.arrowContainer}>
                  <ArrowIcon className={classes.arrowIcon} />
                </Grid>
              </Grid>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return <></>;
};

export default CreditMemoCard;
