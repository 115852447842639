import { Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerError: {
      backgroundColor: '#ffefee',
      color: '#ff574d',
      padding: theme.spacing(2),
    },
    upperHeaderText: {
      fontSize: 10,
      letterSpacing: 1.5,
      fontWeight: 500,
    },
    mainHeaderText: {
      fontWeight: 500,
    },
    body: {
      padding: theme.spacing(2),
    },
  }),
);

const PaymentInfoNotFound: React.FC = () => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container className={classes.headerError}>
        <Grid item xs={12} lg={12}>
          <Typography variant="body2" className={classes.upperHeaderText} data-cy="payment-not-found-error-message">
            PAYMENT REQUEST NOT FOUND
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Typography className={classes.mainHeaderText}>Unable to locate the request.</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.body}>
        <Grid item xs={12} lg={12}>
          <Typography variant="body2">Ensure the URL has not been altered.</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentInfoNotFound;
