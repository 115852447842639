import {
  CircularProgress,
  Grid,
  Link,
  Skeleton,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { DataGridPro, GridColDef, GridOverlay, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';

import { Maybe, PayerTransaction, PayerTransactionConnection, PaymentRequestStatus, PaymentStatus } from '../gql-types.generated';
import { paymentStatusDisplay } from '../util/PaymentStatus';
import DownloadInvoices from './DownloadInvoices';
import SkeletonGrid from './SkeletonGrid';
import { HeaderCell } from './TableHeader';

const useStylesMobile = makeStyles(() =>
  createStyles({
    dataContainer: {
      paddingTop: 15,
      height: '100%',
    },
    dataContainerBox: {
      textAlign: 'left',
    },
    renderedData: {
      padding: 4,
    },
    skeletonWrap: {
      width: '100%',
      display: 'table',
    },
    table: {
      overflow: 'auto',
      margin: '0px',
    },
    tableWrap: {
      maxHeight: '75vh',
      overflow: 'auto',
    },
    cellData: {
      display: 'flex',
      padding: '2px',
    },
    cellDetails: {
      width: '75%',
      fontSize: '16px',
    },
    cellDateStatus: {
      fontSize: '14px',
      width: '100%',
      textAlign: 'right',
    },
    cellReferenceNumber: {
      color: '#276EF1',
      wordBreak: 'break-word',
      marginTop: '2px',
    },
    cellAmount: {
      color: '#000000DE',
    },
    emptyMessage: {
      fontSize: '14px',
      textAlign: 'center',
      fontStyle: 'oblique',
    },
    loadingSpinner: {
      height: 60,
      width: 60,
      display: 'inline-block',
    },
    errorMessage: {
      fontSize: '18px',
      textAlign: 'center',
      fontStyle: 'oblique',
      color: 'red',
    },
  }),
);

const useStyles = makeStyles(() =>
  createStyles({
    tableContainer: {
      height: '600px',
      overflow: 'auto',
      textAlign: 'center',
    },
    table: {
      overflow: 'auto',
      margin: '0px',
      minWidth: 1000,
    },
    tableWrap: {
      maxHeight: '55vh',
      overflow: 'auto',
    },
    tableCell: {
      fontSize: 16,
      wordWrap: 'break-word',
      padding: '16px 4px',
    },
    emptyMessage: {
      fontSize: '14px',
      textAlign: 'center',
      fontStyle: 'oblique',
    },
    loadingSpinner: {
      height: 60,
      width: 60,
      display: 'inline-block',
    },
    errorMessage: {
      fontSize: '18px',
      textAlign: 'center',
      fontStyle: 'oblique',
      color: 'red',
    },
    referenceCell: {
      fontSize: 16,
      wordWrap: 'break-word',
      color: '#276EF1',
      wordBreak: 'break-word',
    },
    overlay: {
      '&.MuiDataGrid-overlay': {
        display: 'inline',
      },
    },
    noRowsOverlayText: {
      fontStyle: 'italic',
      lineHeight: 2,
      borderBottom: '1px solid #e0e0e0',
    },
    gridWrap: {
      overflow: 'hidden',
      position: 'relative',
      flexGrow: 1,
      height: '100%',
    },
    dataGrid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
      '& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell': {
        // Unfortunately the cell rendering has inline lineHeight styling, so !important is required.
        lineHeight: '100% !important',
      },
      '& .MuiDataGrid-cell:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-columnHeader:last-child': {
        paddingRight: 20,
      },
      '& .MuiDataGrid-cell:first-child': {
        paddingLeft: 30,
      },
      '& .MuiDataGrid-columnHeader:first-child': {
        paddingLeft: 30,
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#F5F5F5',
        maxHeight: 60,
      },
      '& .MuiDataGrid-columnSeparator': {
        display: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: 16,
      },
      '& .MuiDataGrid-cell': {
        fontSize: 16,
      },
      '& div.MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    progressOverlay: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      display: 'flex',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      opacity: 0.02,
    },
  }),
);

// Table for the recent transactions list
interface RecentTransactionsListProps {
  platform: string;
  recentTransactions: PayerTransaction[] | undefined;
  recentTransactionsConnection: PayerTransactionConnection | undefined;
  loadPage: () => void;
  settingsMenuVisible: boolean;
  showRecentPaymentDetails: (paymentDetails: PayerTransaction | null | undefined) => void;
  recentPaymentsError: Error | undefined;
  creditMemosEnabled: boolean;
}

const RecentTransactionsList: React.FC<RecentTransactionsListProps> = props => {
  const {
    platform,
    recentTransactions,
    loadPage,
    settingsMenuVisible,
    showRecentPaymentDetails,
    recentPaymentsError,
    creditMemosEnabled,
  } = props;
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down(1400));
  const isMobileView = platform === 'mobile' || (settingsMenuVisible && matches);
  const classes = useStyles();
  const classesMobile = useStylesMobile();

  const [hasCreditApplied, setHasCreditApplied] = useState<boolean>(false);

  const recentTransactionsHeaderCells: HeaderCell[] = [
    { id: 'reference', label: 'Reference #' },
    { id: 'invoice', label: 'Download Invoice', align: 'center' },
    { id: 'date', label: 'Date Paid' },
    { id: 'paid', label: 'Paid', align: 'right' },
    ...(creditMemosEnabled ? [{ id: 'credit', label: 'Credits Applied', align: 'right' }] : []),
    { id: 'discount', label: 'Discount', align: 'right' },
    { id: 'amount', label: 'Total', align: 'right' },
    { id: 'action', label: '' },
  ];
  // Keep working towards all grids conversion to datagridpro in payer portal.
  const recentTransactionColumns: GridColDef[] = [
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'mobileRender',
      headerName: 'mobileRender',
      flex: 1,
      hide: !isMobileView,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        // eslint-disable-next-line no-underscore-dangle
        const rowData = row._raw;
        return (
          <Grid container className={classesMobile.dataContainer} alignItems={'stretch'} alignContent={'stretch'}>
            <Grid container xs={8} className={classesMobile.dataContainerBox} alignContent={'stretch'} alignItems={'stretch'}>
              <Grid item xs={12} className={classesMobile.renderedData}>
                <Link
                  href="#"
                  underline="hover"
                  onClick={() => {
                    showRecentPaymentDetails(rowData);
                  }}
                  aria-label={`open payment request details${row?.reference ? ` reference ${row?.reference}` : ''}`}
                >
                  {row?.reference}
                </Link>
              </Grid>
              <Grid item xs={12} className={classesMobile.renderedData}>
                <div className={classesMobile.cellAmount}>
                  {new Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: row.currency || 'USD',
                    currencyDisplay: 'symbol',
                  }).format((rowData?.paymentRequest?.totalPaid || 0) / 100)}
                </div>
              </Grid>
            </Grid>
            <Grid container xs={4} alignItems={'stretch'} alignContent={'stretch'}>
              <Grid item xs={12} className={classesMobile.renderedData}>
                <div className={classesMobile.cellDateStatus}>
                  {row?.date}
                  <div>
                    {
                      paymentStatusDisplay.find(item => {
                        return item.name === rowData?.paymentRequest?.status;
                      })?.display
                    }
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'reference',
      sortable: false,
      hide: isMobileView,
      headerName: 'Reference #',
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        // eslint-disable-next-line no-underscore-dangle
        const paymentRequest = params.row._raw;
        return (
          <Link
            href="#"
            underline="hover"
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              showRecentPaymentDetails(paymentRequest);
            }}
            aria-label={`open payment request details ${params.value ? ` reference ${params.value}` : ''}`}
          >
            {params?.value}
          </Link>
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'invoice',
      sortable: false,
      headerName: 'Download Invoice',
      flex: 1,
      hide: isMobileView,
      headerAlign: 'center',
      align: 'center',
      // eslint-disable-next-line react/display-name
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <DownloadInvoices
            isIconButton={true}
            invoiceUrl={row?.invoiceLink}
            invoicesUrlList={row?.invoiceLinks}
            stringWhenEmpty={true}
          />
        );
      },
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'date',
      hide: isMobileView,
      sortable: false,
      headerName: 'Date Paid',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'paid',
      hide: isMobileView,
      sortable: false,
      headerName: 'Paid',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'credit',
      sortable: false,
      hide: isMobileView || !creditMemosEnabled || !hasCreditApplied,
      headerName: 'Credits Applied',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'discount',
      hide: isMobileView,
      sortable: false,
      headerName: 'Discount',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
    },
    {
      resizable: false,
      disableReorder: true,
      disableColumnMenu: true,
      field: 'amount',
      hide: isMobileView,
      sortable: false,
      headerName: 'Total',
      headerAlign: 'right',
      align: 'right',
      flex: 1,
    },
  ];

  const loadingOverlay = () => {
    return (
      <GridOverlay>
        <div className={classes.progressOverlay}></div>
        <CircularProgress aria-label={'progress spinner'} key={'payments-spinner'} size={42} />
      </GridOverlay>
    );
  };
  const noRowsOverlay = () => {
    return (
      <GridOverlay className={classes.overlay}>
        {recentPaymentsError && (
          <Typography className={classes.noRowsOverlayText} variant="body2">
            Unable to load data. Please try again later.
          </Typography>
        )}
        {!recentPaymentsError && recentTransactions?.length === 0 && (
          <Typography variant="body2" className={classes.noRowsOverlayText}>
            No payments completed recently.
          </Typography>
        )}
      </GridOverlay>
    );
  };
  const getRecentTransactionRows = () => {
    let creditApplied = false;
    const recentTransactionsData = recentTransactions?.map((row: Maybe<PayerTransaction>, index: number) => {
      const node = row;
      if (!node) {
        return {} as GridRowModel;
      }
      const { paymentRequest, payment } = node;
      const lastPaymentDate = payment?.completedTimestamp;
      const discountEndDate = paymentRequest?.discountEndDate;
      const paymentRequestCompletedBeforeDiscountEndDate = new Date(lastPaymentDate) < new Date(discountEndDate);
      const currencyFormatter = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: payment?.currency || node?.currency || 'USD',
        currencyDisplay: 'symbol',
      });

      let paidAmount = 0;
      let creditAmount = 0;
      node.paymentRequest?.payments.forEach(transactionPayment => {
        if (transactionPayment?.status === PaymentStatus.Completed || transactionPayment?.status === PaymentStatus.Pending) {
          paidAmount += transactionPayment?.amount || 0;
          creditAmount += transactionPayment?.creditAmount || 0;
        }
      });

      if (creditAmount > 0 && !creditApplied) {
        creditApplied = true;
      }

      // storing the dates to populate the date paid field according to status of the request
      const attemptDate = payment?.attemptTimestamp ? new Date(payment.attemptTimestamp).toLocaleDateString() : '';
      const completedDate = payment?.completedTimestamp ? new Date(payment?.completedTimestamp).toLocaleDateString() : '';

      // shortening the reference number in table(user can see whole reference number in details modal)
      let referenceNumber = paymentRequest?.referenceNumber?.trim();
      referenceNumber = referenceNumber && referenceNumber.length > 9 ? `***${referenceNumber.slice(-9)}` : referenceNumber;

      return {
        _raw: node,
        id: `${payment?.id}-${index}`,
        reference: referenceNumber,
        invoiceLink: paymentRequest?.invoiceLink,
        invoiceLinks: paymentRequest?.invoiceLinks,
        date: paymentRequest?.status === PaymentRequestStatus.Pending && attemptDate ? attemptDate : completedDate,
        paid: currencyFormatter.format(paidAmount / 100),
        credit: currencyFormatter.format(creditAmount / 100),
        discount: paymentRequestCompletedBeforeDiscountEndDate
          ? currencyFormatter.format(typeof paymentRequest?.discountAmount === 'number' ? paymentRequest.discountAmount / 100 : 0)
          : currencyFormatter.format(0),
        amount: currencyFormatter.format((node.paymentRequest?.amount || 0) / 100),
        currency: payment?.currency || node?.currency,
      } as GridRowModel;
    }) as GridRowModel[];

    if (creditApplied && !hasCreditApplied) {
      setHasCreditApplied(true);
    } else if (!creditApplied && hasCreditApplied) {
      setHasCreditApplied(false);
    }
    return recentTransactionsData;
  };

  const skeletonMobile: JSX.Element[] = [];
  for (let i = 0; i <= recentTransactionsHeaderCells.length; i += 1) {
    skeletonMobile.push(
      <TableRow>
        <TableCell>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '80%' }}>
              <Skeleton height="1.5em" width="8em" />
              <Skeleton height="1.5em" width="6em" />
              <Skeleton height="1.5em" width="4em" />
            </div>
            <div style={{ width: '20%', textAlign: 'right', alignItems: 'end' }}>
              <Skeleton height="1.5em" width="5em" />
              <Skeleton height="1.5em" width="5em" />
            </div>
          </div>
        </TableCell>
      </TableRow>,
    );
  }

  return (
    <TableContainer className={classes.gridWrap} data-cy="recent-payments-table">
      {isMobileView && !recentPaymentsError && !recentTransactions && (
        <div className={classesMobile.skeletonWrap}>{skeletonMobile}</div>
      )}
      {!isMobileView && !recentPaymentsError && !recentTransactions && (
        <SkeletonGrid headers={recentTransactionColumns} rowCount={10}></SkeletonGrid>
      )}
      {recentTransactions && (
        <DataGridPro
          loading={false}
          headerHeight={isMobileView ? 0 : 56}
          rowHeight={isMobileView ? 84 : 58}
          aria-label="Recent Transactions List"
          paginationMode="server"
          pageSize={25}
          rowsPerPageOptions={[25]}
          hideFooterPagination
          hideFooter
          hideFooterSelectedRowCount
          className={classes.dataGrid}
          rows={getRecentTransactionRows()}
          columns={recentTransactionColumns}
          components={{
            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
            LoadingOverlay: loadingOverlay,
            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
            NoRowsOverlay: noRowsOverlay,
          }}
          onRowsScrollEnd={loadPage}
        />
      )}
    </TableContainer>
  );
};

export default RecentTransactionsList;
