import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { CreditMemoStatus, CreditMemoUsageConnection, CreditMemoUsageHistoryOrder } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_CREDITMEMOS_USAGE_CONNECTION = gql`
  query creditMemoHistory(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: CreditMemoUsageHistoryOrder!
    $statusFilter: [CreditMemoStatus!]
  ) {
    creditMemoHistory(after: $after, before: $before, first: $first, last: $last, orderBy: $orderBy, statusFilter: $statusFilter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        amount
        createdAt
        creditMemoReferenceNumber
        paymentId
        remainingBalance
        paymentRequestCreditAllocation {
          amount
          paymentRequestId
          paymentRequestReferenceNumber
        }
      }
    }
  }
`;

export const queryCreditMemoUsageConnection = async (
  client: ApolloClient<NormalizedCacheObject>,
  after: string | undefined,
  before: string | undefined,
  first: number | undefined,
  last: number | undefined,
  orderBy: CreditMemoUsageHistoryOrder,
  statusFilter: CreditMemoStatus[],
  tenantId: string,
): Promise<CreditMemoUsageConnection> => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.query<{ creditMemoHistory: CreditMemoUsageConnection }>({
    variables: { after, before, first, last, orderBy, statusFilter },
    query: QUERY_CREDITMEMOS_USAGE_CONNECTION,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  return data.creditMemoHistory;
};
