import { Card, CardActionArea, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { PayerPaymentMethod, Token } from '../gql-types.generated';
import { getPaymentMethodIcon } from './PaymentMethods';
import AlarmIcon from '@mui/icons-material/AccessAlarm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentMethodCardContainer: {
      width: '88px',
      height: '58px',
      margin: theme.spacing(0.5, 1, 0.5, 0),
    },
    paymentMethodCardContainerSelected: {
      width: '88px',
      height: '58px',
      margin: theme.spacing(0.5, 1, 0.5, 0),
      border: 'solid 2px #0275d8',
    },
    paymetMethodCardAction: {
      padding: theme.spacing(0.5),
    },
    cardText: {
      fontWeight: 1000,
      fontSize: '14px',
      textAlign: 'right',
    },
    cardTextDisabled: {
      fontWeight: 1000,
      fontSize: '14px',
      textAlign: 'right',
      color: '#9E9E9E',
    },
    timeIcon: {
      fontSize: '20px',
    },
    timeIconSelected: {
      color: '#0275d8',
    },
    timeIconDisabled: {
      opacity: 0.5,
    },
  }),
);

interface PaymentMethodTileProps {
  method?: PayerPaymentMethod;
  token?: Token;
  isSelected: boolean;
  isDisabled: boolean;
  handleMethodSelectChange?: (methodId: string | undefined, isOneTimeUsePaymentMethod?: boolean) => void;
  handlePaymentMethodSelectChange?: (method: PayerPaymentMethod | undefined) => void;
  dataCy: string;
}

const PaymentMethodTile: React.FC<PaymentMethodTileProps> = props => {
  const { method, token, isDisabled, isSelected, handleMethodSelectChange, dataCy, handlePaymentMethodSelectChange } = props;
  const classes = useStyles();

  const handlePaymentMethodTileClick = () => {
    if (handleMethodSelectChange) {
      if (method) {
        handleMethodSelectChange(method?.paymentMethod?.id);
      } else if (token) {
        handleMethodSelectChange(token?.id, true);
      }
    } else if (method && handlePaymentMethodSelectChange) {
      handlePaymentMethodSelectChange(method);
    }
  };

  if (method || token) {
    return (
      <Card
        variant={isSelected ? 'elevation' : 'outlined'}
        className={isSelected ? classes.paymentMethodCardContainerSelected : classes.paymentMethodCardContainer}
      >
        <CardActionArea
          disabled={isDisabled}
          className={classes.paymetMethodCardAction}
          onClick={handlePaymentMethodTileClick}
          data-cy={dataCy}
        >
          <Grid item container xs={12}>
            <Grid item xs={4}>
              {token !== undefined ? (
                <AlarmIcon
                  className={`${classes.timeIcon} ${isSelected ? classes.timeIconSelected : undefined} ${
                    isDisabled ? classes.timeIconDisabled : undefined
                  }`}
                />
              ) : null}
            </Grid>
            <Grid item container xs={8} justifyContent="flex-end">
              {method && getPaymentMethodIcon(method, isDisabled, 32)}
              {token && getPaymentMethodIcon(undefined, isDisabled, 32, undefined, token)}
            </Grid>
            <Grid item xs={12}>
              <Typography className={isDisabled ? classes.cardTextDisabled : classes.cardText}>{`**** ${
                method?.paymentMethod?.creditCard?.lastFour ||
                method?.paymentMethod?.paymentBank?.lastFour ||
                token?.creditCard?.lastFour
              }`}</Typography>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    );
  }
  return null;
};

export default PaymentMethodTile;
