import { Alert, Badge, Button, Grid, Paper, Snackbar, Tab, Tabs, Theme, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PaymentDetails from '../../components/PaymentDetails';
import PaymentsDueList, { PaymentsDueListHandles } from '../../components/PaymentsDueList';
import RecentPaymentDetails from '../../components/RecentPaymentDetails';
import RecentTransactionsList from '../../components/RecentTransactionsList';
import TabPanel from '../../components/TabPanel';
import { PaymentsDueRowData } from '../../custom-types/PaymentsDueRowData';
import { OrderDirection, PayerTransaction } from '../../gql-types.generated';
import {
  sortPaymentsDueByCreditAmount,
  sortPaymentsDueByDiscountEndDate,
  sortPaymentsDueByDueDate,
  sortPaymentsDueByRecievedDate,
  sortPaymentsDueByReference,
  sortPaymentsDueByStatus,
  sortPaymentsDueByTotalAmount,
  sortPaymentsDueByTotalDueAmount,
  sortPaymentsDueByTotalPaidAmount,
} from '../../util/SortPaymentsDueData';
import {
  fetchMenuVisible,
  fetchIsPaymentMethodRequestCompleted,
  selectIsPaymentMethodRequestCompleted,
  selectMenuVisible,
  selectSelectedMerchant,
  selectSelectedMerchantInfo,
  selectSelectedMerchantName,
  selectPendingPaymentMethodRequest,
} from '../app/AppSlice';
import {
  fetchPaymentsDue,
  fetchRecentTransactions,
  fetchSpecificPaymentDetailRecord,
} from '../multipleMethodsHome/MultipleMethodsHomeActions';
import {
  clearCreditMemoHistory,
  clearCreditMemoHistoryConnection,
  clearOpenCreditMemoConnection,
  clearOpenCreditMemos,
  clearPaymentsDue,
  clearPaymentsDueConnection,
  clearRecentTransactions,
  fetchConsolidatedOrPartialCancelClicked,
  fetchCreditMemoDetailsOpen,
  fetchPaymentDetails,
  fetchPaymentDetailsIndex,
  fetchPaymentDetailsSelected,
  fetchPaymentDetailsVisible,
  fetchPaymentError,
  fetchPaymentMethodError,
  fetchPaymentRequestInfoList,
  fetchPaymentsDueData,
  fetchRecentPaymentDetails,
  fetchRecentPaymentDetailsVisible,
  fetchLastTabPath,
  fetchSnackBarAlert,
  fetchSnackBarAlertSeverity,
  fetchSnackBarOpen,
  selectConsolidatedOrPartialCancelClicked,
  selectCustomerRole,
  selectPaymentDetails,
  selectPaymentDetailsSelected,
  selectPaymentDetailsVisible,
  selectPaymentsDue,
  selectPaymentsDueConnection,
  selectPaymentsDueData,
  selectPaymentsDueError,
  selectRecentPaymentDetails,
  selectRecentPaymentDetailsVisible,
  selectRecentPaymentsError,
  selectRecentTransactions,
  selectRecentTransactionsConnection,
  selectSnackBarAlert,
  selectSnackBarAlertSeverity,
  selectSnackBarOpen,
  captureUpdateFromPagination,
  selectIsSpecificPaymentDetailRecordInFlight,
  selectSpecificPaymentDetailRecord,
  captureSpecificPaymentDetailRecord,
} from '../multipleMethodsHome/MultipleMethodsHomeSlice';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { RoutePath } from '../../util/Routes';
import PaymentMethodRequestSnackBar from '../../components/PaymentMethodRequestSnackBar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedTab: {
      borderBottomWidth: 0,
      backgroundColor: '#EEF4FE',
    },
    tabRoot: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.grey[300],
    },
    tabsPaper: {
      textAlign: 'center',
      flexGrow: 1,
      maxHeight: '92vh',
      overflow: 'auto hidden',
      display: 'flex',
      flexDirection: 'column',

      '& [role="tabpanel"]': {
        flexGrow: 1,

        '& > .MuiBox-root': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    labelWhitespace: {
      width: '20px',
      height: '1px',
    },
    snackBar: {
      minWidth: '100%',
      justifyContent: 'center',
    },
    snackBarMobile: {
      minWidth: '100%',
      justifyContent: 'center',
      left: 0,
      right: 0,
      bottom: 0,
    },
    toolbarButton: {
      marginRight: 4,
      [theme.breakpoints.down('xs')]: {
        minWidth: 40,
        minHeight: 48,
        marginRight: 0,
        padding: 0,
      },
    },
  }),
);
interface MerchantRequestsViewProps {
  tabPath?: string;
}

const MerchantRequestsView: React.FC<MerchantRequestsViewProps> = (props: MerchantRequestsViewProps) => {
  const { tabPath } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobileSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const iconSize = isMobileSize ? 'medium' : 'small';
  const paymentDetails = useSelector(selectPaymentDetails);
  const paymentDetailsVisible = useSelector(selectPaymentDetailsVisible);
  const paymentsDue = useSelector(selectPaymentsDue);
  const paymentsDueConnection = useSelector(selectPaymentsDueConnection);
  const paymentsDueError = useSelector(selectPaymentsDueError);
  const recentPaymentsError = useSelector(selectRecentPaymentsError);
  const recentTransactions = useSelector(selectRecentTransactions);
  const recentTransactionsConnection = useSelector(selectRecentTransactionsConnection);
  const settingsMenuVisible = useSelector(selectMenuVisible);
  const selectedMerchant = useSelector(selectSelectedMerchant);
  const selectedMerchantName = useSelector(selectSelectedMerchantName);
  const selectedMerchantInfo = useSelector(selectSelectedMerchantInfo);
  const snackBarAlert = useSelector(selectSnackBarAlert);
  const snackBarAlertSeverity = useSelector(selectSnackBarAlertSeverity);
  const snackBarOpen = useSelector(selectSnackBarOpen);
  const getTabValue = () => {
    switch (tabPath) {
      case RoutePath.History:
        return 1;
      case RoutePath.Payments:
      default:
        return 0;
    }
  };
  const [tabValue, setTabValue] = useState<number>(getTabValue());
  const paymentsDueData = useSelector(selectPaymentsDueData);
  const consolidatedOrPartialCancelClicked = useSelector(selectConsolidatedOrPartialCancelClicked);
  const recentPaymentDetails = useSelector(selectRecentPaymentDetails);
  const recentPaymentDetailsVisible = useSelector(selectRecentPaymentDetailsVisible);
  const paymentDetailsSelected = useSelector(selectPaymentDetailsSelected);
  const customerRole = useSelector(selectCustomerRole);
  const specificPaymentDetailRecord = useSelector(selectSpecificPaymentDetailRecord);
  const isSpecificPaymentDetailRecordInFlight = useSelector(selectIsSpecificPaymentDetailRecordInFlight);
  const isPaymentMethodRequestCompleted = useSelector(selectIsPaymentMethodRequestCompleted);
  const paymentMethodRequest = useSelector(selectPendingPaymentMethodRequest);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestTabRef = useRef<any>(null);
  const paymentsDueListRef = useRef<PaymentsDueListHandles>(null);

  const paymentsDueApiRef = useGridApiRef();

  const [switchedToRecentPayments, setSwitchedToRecentPayments] = useState<boolean>(false);

  const closePaymentDetails = () => {
    dispatch(fetchPaymentDetailsIndex(undefined));
    dispatch(fetchPaymentDetailsSelected(undefined));
    dispatch(fetchPaymentDetailsVisible(false));
    dispatch(fetchPaymentDetails(null));
  };

  const closeRecentPaymentDetails = () => {
    dispatch(fetchRecentPaymentDetailsVisible(false));
    dispatch(fetchRecentPaymentDetails(null));
  };

  const handleCreditMemoCardClose = () => {
    dispatch(fetchCreditMemoDetailsOpen(false));
    dispatch(clearOpenCreditMemoConnection());
    dispatch(clearOpenCreditMemos());
    dispatch(clearCreditMemoHistory());
    dispatch(clearCreditMemoHistoryConnection());
  };

  useEffect(() => {
    return () => {
      if (consolidatedOrPartialCancelClicked) {
        dispatch(fetchConsolidatedOrPartialCancelClicked(false));
      }
      if (switchedToRecentPayments) {
        setSwitchedToRecentPayments(false);
      }

      //closing credit details modals in case user clicks browser back button
      handleCreditMemoCardClose();
      closePaymentDetails();
      closeRecentPaymentDetails();
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      requestTabRef?.current?.updateIndicator();
    }, theme.transitions.duration.enteringScreen);
    return () => {
      clearTimeout(timeout);
    };
  }, [settingsMenuVisible, theme]);

  useEffect(() => {
    if (selectedMerchant) {
      if (consolidatedOrPartialCancelClicked) {
        dispatch(fetchConsolidatedOrPartialCancelClicked(false));
      }
      if (switchedToRecentPayments) {
        setSwitchedToRecentPayments(false);
      }
    }
  }, [selectedMerchant]);

  // Page size until infinite scroller loads more
  const pageSize = 15;
  const historyPageSize = 25;

  // if there is no selected merchant the view will redirect to home page
  if (!selectedMerchant) {
    history.push(RoutePath.Home);
    return <></>;
  }

  const handleTabChange = (_event: unknown, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 1 && paymentsDue && paymentsDueConnection && recentTransactions && recentTransactionsConnection) {
      setSwitchedToRecentPayments(true);
    } else {
      setSwitchedToRecentPayments(false);
    }
  };
  // When the tab path changes, set the visible tab.
  useEffect(() => {
    switch (tabPath) {
      case RoutePath.History:
        if (tabValue !== 1) {
          setTabValue(1);
          dispatch(fetchLastTabPath(RoutePath.History));
        }
        break;
      case RoutePath.Payments:
      default:
        if (tabValue !== 0) {
          setTabValue(0);
          dispatch(fetchLastTabPath(RoutePath.Payments));
        }
        break;
    }
  }, [tabPath]);
  useEffect(() => {
    // When the tab path changes, set the correct url path
    switch (tabValue) {
      case 1:
        if (tabPath !== RoutePath.History) {
          history.push(RoutePath.History);
          dispatch(fetchLastTabPath(RoutePath.History));
        }
        break;
      case 0:
      default:
        if (tabPath !== RoutePath.Payments) {
          history.push(RoutePath.Payments);
          dispatch(fetchLastTabPath(RoutePath.Payments));
        }
        break;
    }
  }, [tabValue]);

  /* In case need to use the token process again */
  // const onClickPay = (urlToken: string | null | undefined) => {
  //   dispatch(fetchPaymentMethodError());
  //   dispatch(fetchPaymentError());
  //   history.push(`/?${urlToken}`);

  //   dispatch(fetchHasSearchString(true));
  //   dispatch(fetchUrlToken(window.location.search.substring(1)));
  //   dispatch(fetchPaymentRequestInfo(window.location.search.substring(1)));

  //   if (settingsMenuVisible) {
  //     dispatch(fetchMenuVisible(false));
  //   }
  // };

  const onClickPayConsolidatedOrPartial = (selectedData: PaymentsDueRowData[]) => {
    dispatch(fetchPaymentMethodError());
    dispatch(fetchPaymentError());

    dispatch(fetchPaymentRequestInfoList(selectedData));
    history.push(RoutePath.Payment);

    if (settingsMenuVisible) {
      dispatch(fetchMenuVisible(false));
    }
  };

  const loadPaymentsDuePage = () => {
    if (!paymentsDueConnection || !paymentsDueConnection.pageInfo.hasNextPage || !paymentsDueConnection.pageInfo.endCursor) {
      return;
    }
    if (selectedMerchant) {
      dispatch(fetchPaymentsDue(selectedMerchant, paymentsDueConnection.pageInfo.endCursor));
      dispatch(captureUpdateFromPagination(true));
    }
  };

  const loadRecentTransactionsPage = () => {
    if (
      !recentTransactionsConnection ||
      !recentTransactionsConnection.pageInfo.hasNextPage ||
      !recentTransactionsConnection.pageInfo.endCursor
    ) {
      return;
    }
    if (selectedMerchant) {
      dispatch(fetchRecentTransactions(recentTransactionsConnection.pageInfo.endCursor, pageSize, selectedMerchant));
    }
  };

  const clearSpecifcPaymentDetailRecord = () => {
    // clearing the specific payment detail record while opening model so useEffect inside model does ot trigger specific payment details modal
    dispatch(captureSpecificPaymentDetailRecord(undefined));
  };

  const showPaymentDetails = (
    paymentDetail: PayerTransaction | null | undefined,
    detailsIndex?: number,
    detailsSelected?: boolean,
  ) => {
    dispatch(fetchPaymentDetailsIndex(detailsIndex));
    dispatch(fetchPaymentDetailsSelected(detailsSelected));
    dispatch(fetchPaymentDetailsVisible(true));
    dispatch(fetchPaymentDetails(paymentDetail));
    clearSpecifcPaymentDetailRecord();
  };

  const showRecentPaymentDetails = (recentPaymentDetail: PayerTransaction | null | undefined) => {
    dispatch(fetchRecentPaymentDetailsVisible(true));
    dispatch(fetchRecentPaymentDetails(recentPaymentDetail));
    clearSpecifcPaymentDetailRecord();
  };

  const handleFetchSpecificPaymentRecord = (id: string, tenantId: string) => {
    dispatch(fetchSpecificPaymentDetailRecord(id, tenantId));
  };

  const handlePaymentsDueDataSort = (paymentsDueData: PaymentsDueRowData[] | undefined, order: OrderDirection, orderBy: string) => {
    let sortedpaymentsDueData = paymentsDueData;
    if (orderBy === 'receivedDate') {
      sortedpaymentsDueData = sortPaymentsDueByRecievedDate(paymentsDueData, order);
    } else if (orderBy === 'amount') {
      sortedpaymentsDueData = sortPaymentsDueByTotalAmount(paymentsDueData, order);
    } else if (orderBy === 'status') {
      sortedpaymentsDueData = sortPaymentsDueByStatus(paymentsDueData, order);
    } else if (orderBy === 'reference') {
      sortedpaymentsDueData = sortPaymentsDueByReference(paymentsDueData, order);
    } else if (orderBy === 'dueDate') {
      sortedpaymentsDueData = sortPaymentsDueByDueDate(paymentsDueData, order);
    } else if (orderBy === 'paid') {
      sortedpaymentsDueData = sortPaymentsDueByTotalPaidAmount(paymentsDueData, order);
    } else if (orderBy === 'balance') {
      sortedpaymentsDueData = sortPaymentsDueByTotalDueAmount(paymentsDueData, order);
    } else if (orderBy === 'discountEnd') {
      sortedpaymentsDueData = sortPaymentsDueByDiscountEndDate(paymentsDueData, order);
    } else if (orderBy === 'credit') {
      sortedpaymentsDueData = sortPaymentsDueByCreditAmount(paymentsDueData, order);
    }
    return sortedpaymentsDueData;
  };

  const handleFetchPaymentsDueData = (paymentsDue: PaymentsDueRowData[] | undefined) => {
    dispatch(fetchPaymentsDueData(paymentsDue));
  };

  const handlePaymentDetailsPay = (payment: PayerTransaction) => {
    if (payment) {
      if (paymentsDueListRef && paymentsDueListRef.current && paymentsDueListRef.current.consolidatedOrPartialOnPay) {
        paymentsDueListRef.current.consolidatedOrPartialOnPay(payment);
      }
    }
  };

  const clearPaymentRequestInfoList = () => {
    dispatch(fetchPaymentRequestInfoList(undefined));
  };

  const handleActiveTabReload = () => {
    if (tabValue === 0) {
      if (paymentsDueApiRef?.current) {
        // Clear selection state.
        paymentsDueApiRef?.current.setSelectionModel([]);
      }
      dispatch(clearPaymentsDue());
      dispatch(clearPaymentsDueConnection());
      dispatch(fetchPaymentsDue(selectedMerchant));
      dispatch(captureUpdateFromPagination(false));
    } else {
      dispatch(clearRecentTransactions());
      dispatch(fetchRecentTransactions(undefined, historyPageSize, selectedMerchant));
    }
  };

  const clearIsPaymentMethodRequestCompleted = () => {
    dispatch(fetchIsPaymentMethodRequestCompleted(false));
  };

  const toolbarRefereshButton = () => {
    return (
      <Button
        startIcon={isMobileSize ? null : <RefreshIcon />}
        size={iconSize}
        variant={'text'}
        aria-label={'refresh'}
        color={'primary'}
        onClick={handleActiveTabReload}
        data-cy="refresh"
        className={classes.toolbarButton}
      >
        {isMobileSize ? <RefreshIcon /> : 'Refresh'}
      </Button>
    );
  };

  return (
    <>
      {/* {Dialogs} */}
      <PaymentDetails
        isMobileView={isMobileSize}
        paymentDetailsVisible={paymentDetailsVisible}
        payment={paymentDetails}
        onClickPay={handlePaymentDetailsPay}
        closePaymentDetails={closePaymentDetails}
        detailsSelected={paymentDetailsSelected}
        creditAllowed={!!selectedMerchantInfo?.features?.creditMemos.enabled}
        customerRole={customerRole}
        specificPaymentRecord={specificPaymentDetailRecord}
        isLoadingSpecificPayment={isSpecificPaymentDetailRecordInFlight}
        fetchSpecificPaymentRecord={handleFetchSpecificPaymentRecord}
      />

      <RecentPaymentDetails
        recentPaymentDetailsVisible={recentPaymentDetailsVisible}
        payment={recentPaymentDetails}
        closeRecentPaymentDetails={closeRecentPaymentDetails}
        creditMemosEnabled={!!selectedMerchantInfo?.features?.creditMemos.enabled}
        specificPaymentRecord={specificPaymentDetailRecord}
        isLoadingSpecificPayment={isSpecificPaymentDetailRecordInFlight}
        fetchSpecificPaymentRecord={handleFetchSpecificPaymentRecord}
      />

      {/* {Main Content} */}
      <Paper className={classes.tabsPaper}>
        <Tabs
          indicatorColor={'primary'}
          textColor={'primary'}
          variant="fullWidth"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="home page tabs"
          action={requestTabRef}
        >
          <Tab
            classes={{ selected: classes.selectedTab, root: classes.tabRoot }}
            label={
              <div>
                {'PAYMENTS DUE'}
                {paymentsDueConnection && paymentsDueConnection.totalCount > 0 && (
                  <Badge badgeContent={paymentsDueConnection?.totalCount} color="error">
                    <div className={classes.labelWhitespace} />
                  </Badge>
                )}
              </div>
            }
            id="payment-requests-tab"
            aria-controls="payments-due-tab-panel"
          />
          <Tab
            classes={{ selected: classes.selectedTab, root: classes.tabRoot }}
            label="HISTORY"
            id="disputes-tab"
            aria-controls="recent-transactions-tab-panel"
          />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Toolbar variant="dense">
            <Grid item>{toolbarRefereshButton()}</Grid>
          </Toolbar>
          <PaymentsDueList
            ref={paymentsDueListRef}
            gridApiRef={paymentsDueApiRef}
            platform={isMobileSize ? 'mobile' : 'desktop'}
            paymentsDue={paymentsDue}
            paymentsDueConnection={paymentsDueConnection}
            loadPage={loadPaymentsDuePage}
            showPaymentDetails={showPaymentDetails}
            paymentsDueError={paymentsDueError}
            merchantName={selectedMerchantName}
            paymentsDueDataSort={handlePaymentsDueDataSort}
            settingsMenuVisible={settingsMenuVisible}
            onClickPayConsolidatedOrPartial={onClickPayConsolidatedOrPartial}
            paymentsDueData={paymentsDueData}
            fetchPaymentsDueData={handleFetchPaymentsDueData}
            consolidatedOrPartialCancelClicked={consolidatedOrPartialCancelClicked}
            selectedMerchantInfo={selectedMerchantInfo}
            switchedToRecentPayments={switchedToRecentPayments}
            clearPaymentRequestInfoList={clearPaymentRequestInfoList}
            customerRole={customerRole}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Toolbar variant="dense">
            <Grid container item>
              {toolbarRefereshButton()}
            </Grid>
          </Toolbar>
          <RecentTransactionsList
            platform={isMobileSize ? 'mobile' : 'desktop'}
            recentTransactions={recentTransactions}
            recentTransactionsConnection={recentTransactionsConnection}
            loadPage={loadRecentTransactionsPage}
            settingsMenuVisible={settingsMenuVisible}
            showRecentPaymentDetails={showRecentPaymentDetails}
            recentPaymentsError={recentPaymentsError}
            creditMemosEnabled={!!selectedMerchantInfo?.features?.creditMemos.enabled}
          />
        </TabPanel>
      </Paper>

      {/* {Payment Toasts} */}
      {snackBarAlert && snackBarAlertSeverity && (
        <Snackbar
          className={isMobileSize ? classes.snackBarMobile : classes.snackBar}
          open={snackBarOpen}
          autoHideDuration={5000}
          onClose={() => {
            dispatch(fetchSnackBarOpen(false));
            dispatch(fetchSnackBarAlert(undefined));
            dispatch(fetchSnackBarAlertSeverity(undefined));
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert className={classes.snackBar} severity={snackBarAlertSeverity === 'success' ? 'success' : 'warning'}>
            {snackBarAlert}
          </Alert>
        </Snackbar>
      )}

      {/* {Payment Method Request Toast} */}
      <PaymentMethodRequestSnackBar
        paymentMethodRequest={paymentMethodRequest}
        isPaymentMethodRequestCompleted={isPaymentMethodRequestCompleted}
        clearIsPaymentMethodRequestCompleted={clearIsPaymentMethodRequestCompleted}
      />
    </>
  );
};

export default MerchantRequestsView;
