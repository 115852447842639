/**
 * This component is intended for use within components to display alerts
 */
import { Alert } from '@mui/material';
import React from 'react';

interface AlertProps {
  message: string;
  severity?: 'error' | 'info' | 'success' | 'warning';
  variant?: 'filled' | 'outlined' | 'standard';
  onClose?: (event?: React.SyntheticEvent) => void;
}

const AlertBanner: React.FC<AlertProps> = props => {
  const { message, severity = 'error', variant = 'standard', onClose } = props;
  return (
    <Alert
      severity={severity}
      variant={variant}
      onClose={onClose}
      sx={{ width: '100%', borderRadius: '0', padding: '0px 24px' }}
      icon={false}
    >
      {message}
    </Alert>
  );
};

export default AlertBanner;
