import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Select,
  SvgIcon,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';

import { PaymentMethodStatusData } from '../features/multipleMethodsHome/MultipleMethodsHome';
import { checkIsFullNameValid } from '../util/Validators';
import { PayFac } from '../gql-types.generated';
import { ApteanPaySDK, ApteanPaySDKBankComponent, useApteanPay } from '../util/ApteanPay';
import LoadingMask from './LoadingMask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paymentMethodAction: {
      width: '100%',
    },
    padding: {
      padding: theme.spacing(2, 2, 0, 2),
    },
    buttonPadding: {
      padding: theme.spacing(1),
    },
    paymentMethodForm: {
      padding: theme.spacing(2),
    },
    mainHeaderText: {
      fontWeight: 500,
    },
    upperHeaderText: {
      fontSize: 10,
      letterSpacing: 1.5,
      fontWeight: 500,
    },
    arrowBack: {
      cursor: 'pointer',
    },
    textField: {
      backgroundColor: theme.palette.grey[100],
    },
    bankIframe: {
      width: '100%',
      padding: theme.spacing(1, 0, 0, 2),
      minHeight: 208,
    },
  }),
);
interface PaymentMethodEditProps {
  handleBack?: () => void;
  onBankInfoChange?: (bankInfo: { holderName: string; accountNumber: string; routingNumber: string; accountType: string }) => void;
  onMobile: boolean;
  statusData: PaymentMethodStatusData;
  includeHolder?: boolean;
  submit?: (bankInfo: { holderName?: string; accountNumber: string; routingNumber: string; accountType: string }) => void;
  paymentMethodExists: boolean;
  isAccountNumberValid: boolean;
  isRoutingNumberValid: boolean;
  holderNameValid: boolean;
  setHolderNameValid: (holderNameValid: boolean) => void;
  fieldsErrorMessage: string | undefined;
  setFieldsErrorMessage: (fieldsErrorMessage: string | undefined) => void;
  payfac?: PayFac;
  onTokenizeInjectSuccess?: (apteanPay: ApteanPaySDK, bankComponent: ApteanPaySDKBankComponent) => void;
  merchantTenantId?: string;
  customerId?: string;
}
/*
  This component allows the input of US bank account information
    - onBankInfoChange: Configure a function to handle changes to the information in the fields.
        This allows the owner of this component the ability to handle changes at their own pace.

  Error state is handled by the status data obtained by the owning component
*/
const PaymentMethodEdit: FC<PaymentMethodEditProps> = props => {
  const classes = useStyles();
  const {
    handleBack,
    includeHolder,
    onMobile,
    statusData,
    onBankInfoChange,
    submit,
    paymentMethodExists,
    isAccountNumberValid,
    isRoutingNumberValid,
    holderNameValid,
    setHolderNameValid,
    fieldsErrorMessage,
    setFieldsErrorMessage,
    payfac,
    onTokenizeInjectSuccess,
    merchantTenantId,
    customerId,
  } = props;
  const [bankInfo] = useState<{ holderName: string; accountNumber: string; accountType: string; routingNumber: string }>({
    holderName: '',
    accountNumber: '',
    accountType: 'checking',
    routingNumber: '',
  });
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountType, setAccountType] = useState('checking');
  const [showAccount, setShowAccount] = useState(false);
  const [showRouter, setShowRouter] = useState(false);
  const [methodValid, setMethodValid] = useState(false);
  const [tokenErrorMessage, setTokenErrorMessage] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [showLoading, setShowLoading] = useState(false);

  const { apteanPay, sdkComponent, configure, mountComponent } = useApteanPay();

  useEffect(() => {
    if (merchantTenantId && payfac === 'PTT') {
      configure(merchantTenantId, customerId, false, 'bank');
    }
  }, [merchantTenantId, customerId, payfac]);

  useEffect(() => {
    if (apteanPay && sdkComponent) {
      setShowLoading(true);
      mountComponent('js_sdk_bank', '#js_sdk_button', () => {
        setShowLoading(false);
      });
      if (onTokenizeInjectSuccess) {
        onTokenizeInjectSuccess(apteanPay, sdkComponent);
      }
    }
  }, [sdkComponent]);

  useEffect(() => {
    if (onBankInfoChange) {
      onBankInfoChange(bankInfo);
    }
    if (submit) {
      setMethodValid(!!((!includeHolder || accountHolder) && accountNumber && routingNumber && accountType));
    }
  }, [accountHolder, accountNumber, routingNumber, accountType]);

  useEffect(() => {
    if (statusData?.tokenError?.message) {
      setTokenErrorMessage(statusData?.tokenError?.message);
    } else {
      setTokenErrorMessage(undefined);
    }

    if (statusData?.error?.message) {
      let message = statusData?.error?.message.trim();
      if (message && message.toLowerCase() === 'invalid parameter(s).') {
        let errorCount = 0;
        message = 'Invalid';

        if (statusData?.invalidRoutingNumber) {
          message += ' Routing Number';
          errorCount += 1;
        }
        if (statusData?.invalidAccountNumber) {
          message += errorCount > 0 ? ' and ' : ' ';
          message += 'Account Number';
        }
        message += '.';
      }
      setErrorMessage(message);
    } else {
      setErrorMessage(undefined);
    }
  }, [statusData?.tokenError?.message, statusData?.error?.message]);

  const handleClickShowAccount = () => {
    setShowAccount(!showAccount);
  };
  const handleClickShowRouter = () => {
    setShowRouter(!showRouter);
  };
  const handleMouseDownInput = (event: MouseEvent) => {
    event.preventDefault();
  };
  const clearErrorMessageOnChange = () => {
    if (errorMessage) setErrorMessage(undefined);
    if (tokenErrorMessage) setTokenErrorMessage(undefined);
    if (fieldsErrorMessage) setFieldsErrorMessage(undefined);
  };
  const onAccountHolderChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearErrorMessageOnChange();
    setAccountHolder(event.target.value);
    bankInfo.holderName = event.target.value;
    setHolderNameValid(checkIsFullNameValid(event.target.value));
  };
  const onAccountTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAccountType(event.target.value);
    bankInfo.accountType = event.target.value;
  };
  const onAccountNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearErrorMessageOnChange();
    setAccountNumber(event.target.value);
    bankInfo.accountNumber = event.target.value;
  };
  const onRoutingNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    clearErrorMessageOnChange();
    setRoutingNumber(event.target.value);
    bankInfo.routingNumber = event.target.value;
  };
  return (
    <Box>
      <LoadingMask loading={showLoading} />
      <Grid container className={classes.padding}>
        {onMobile && handleBack && (
          <Grid item xs={2}>
            <SvgIcon
              color={'primary'}
              fontSize={'large'}
              className={classes.arrowBack}
              onClick={() => {
                handleBack();
              }}
              data-cy="back"
            >
              <ArrowBackIcon />
            </SvgIcon>
          </Grid>
        )}
        <Grid item xs={onMobile ? 10 : 12}>
          <Typography variant="title" className={classes.mainHeaderText} id="add-bank-account">
            {paymentMethodExists ? 'Edit Payment Method' : 'Add Bank Account'}
          </Typography>
          <Typography id="add-bank-account-description">U.S. bank accounts only</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.paymentMethodForm}>
        {payfac === 'WEPAY' && (
          <>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                variant={'outlined'}
                autoFocus
                fullWidth
                id="filled-error-helper-text"
                value={accountHolder}
                error={!holderNameValid}
                onChange={onAccountHolderChange}
                inputProps={{
                  maxLength: 26,
                }}
                label="Account Holder Name"
                data-cy="account-holder-name"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth onChange={onAccountTypeChange}>
                <InputLabel>Account Type</InputLabel>
                <Select
                  native
                  label="Account Type"
                  value={accountType}
                  className={classes.textField}
                  inputProps={{ 'aria-label': 'account type' }}
                  data-cy="select-account-type"
                >
                  <option key={'checking'} value={'checking'}>
                    Checking
                  </option>
                  <option key={'savings'} value={'savings'}>
                    Savings
                  </option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth onChange={onRoutingNumberChange}>
                <InputLabel htmlFor="payment-routing" error={!isRoutingNumberValid}>
                  Routing Number
                </InputLabel>
                <OutlinedInput
                  className={classes.textField}
                  data-cy="payment-routing"
                  value={routingNumber}
                  label="Routing Number"
                  id="payment-routing"
                  type={showRouter ? 'text' : 'password'}
                  error={!isRoutingNumberValid}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle routing number visibility"
                        onClick={handleClickShowRouter}
                        onMouseDown={handleMouseDownInput}
                        size="large"
                      >
                        {showRouter ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth onChange={onAccountNumberChange}>
                <InputLabel htmlFor="payment-account" error={!isAccountNumberValid}>
                  Account Number
                </InputLabel>
                <OutlinedInput
                  className={classes.textField}
                  value={accountNumber}
                  label="Account Number"
                  id="payment-account"
                  data-cy="payment-account"
                  type={showAccount ? 'text' : 'password'}
                  error={!isAccountNumberValid}
                  inputProps={{
                    maxLength: 17,
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle account number visibility"
                        onClick={handleClickShowAccount}
                        onMouseDown={handleMouseDownInput}
                        size="large"
                      >
                        {showAccount ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </>
        )}
        {payfac === 'PTT' && <Box id="js_sdk_bank" className={classes.bankIframe}></Box>}
        <Grid container className={classes.buttonPadding} justifyContent={onMobile ? 'flex-end' : 'space-between'} spacing={1}>
          {handleBack && !onMobile && (
            <Grid item>
              <Button
                className={classes.paymentMethodAction}
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={() => {
                  handleBack();
                }}
                data-cy="back"
              >
                Back
              </Button>
            </Grid>
          )}
          {submit && (
            <Grid item>
              <Button
                className={classes.paymentMethodAction}
                variant="contained"
                color="primary"
                onClick={() => {
                  submit(bankInfo);
                }}
                disabled={!methodValid}
                aria-describedby={`${tokenErrorMessage ? 'error-text-one' : undefined} ${
                  errorMessage ? 'error-text-two' : undefined
                } ${fieldsErrorMessage ? 'error-text-three' : undefined}`}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
        {tokenErrorMessage && (
          <FormHelperText error id="error-text-one">
            {tokenErrorMessage}
          </FormHelperText>
        )}
        {errorMessage && (
          <FormHelperText error id="error-text-two">
            {errorMessage}
          </FormHelperText>
        )}
        {fieldsErrorMessage && (
          <FormHelperText error id="error-text-three">
            {fieldsErrorMessage}
          </FormHelperText>
        )}
      </Grid>
    </Box>
  );
};

export default PaymentMethodEdit;
