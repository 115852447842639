import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertPersonStatus } from '../gql-types.generated';
import { getStandardHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_PAYER = gql`
  mutation upsertPayer($input: UpsertPayerInput!) {
    upsertPayer(input: $input) {
      code
      message
      error
    }
  }
`;

export const mutationUpsertPayer = async (
  client: ApolloClient<NormalizedCacheObject>,
  options: {
    id?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    line1?: string;
    line2?: string;
    postalCode?: string;
    country?: string;
    phoneCountryCode?: string;
    phoneNumber?: string;
    paymentMethodToken?: string;
  },
): Promise<UpsertPersonStatus | undefined> => {
  const headers = await getStandardHeadersWithAuth();
  const { data, errors } = await client.mutate<{ upsertPayer: UpsertPersonStatus }>({
    variables: { input: options },
    mutation: MUTATION_UPSERT_PAYER,
    context: {
      headers,
    },
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the person.
    throw new Error('An error has occurred during the person upsert.');
  }

  if (data && data.upsertPayer) {
    return data.upsertPayer;
  }

  return undefined;
};
