import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PaymentConnection } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_PAYMENTS = gql`
  query payments($id: String) {
    payments(id: $id) {
      nodes {
        id
        status
        pendingReason
        amount
        amountBeforeFees
        amountDisputed
        amountRefunded
        attemptTimestamp
        completedTimestamp
        createdAt
        cancelReason
        currency
        convenienceFee
        creditAmount
        description
        failureReason
        feeAmount
        initiatedBy
        authorizationCode
        customerPONumber
        orderNumber
        invoiceNumber
        paymentMethod {
          creditCard {
            cardBrand
            lastFour
            cardHolder {
              email
            }
          }
          paymentBank {
            lastFour
            accountHolder {
              email
            }
          }
        }
        owner {
          tenantId
          paymentRequestId
          customerId
        }
        customer {
          id
          name
          customerNumber
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const queryPaymentConnection = async (client: ApolloClient<NormalizedCacheObject>, id: string, tenantId: string) => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.query<{ payments: PaymentConnection }>({
    variables: {
      id,
    },
    query: QUERY_PAYMENTS,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }
  return data?.payments;
};
