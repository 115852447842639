import ErrorIcon from '@mui/icons-material/Error';
import { Card, CardActionArea, Grid, Paper, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';

import ErrorDesktopIcon from '../errorDesktopIcon.svg';
import { OrderDirection, PayerTransactionSummary, PaymentMethodRequestStatus } from '../gql-types.generated';
import logoReplacement from '../logoReplacement.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    merchantGridCard: {
      minWidth: 390,
    },
    merchantCard: {
      padding: theme.spacing(2),
      textAlign: 'initial',
      border: '1px solid #CCCCCC',
      '&:hover': {
        border: '1px solid #276EF1',
      },
    },
    merchantCardError: {
      padding: theme.spacing(2),
      textAlign: 'initial',
      border: '1px solid #c70a00',
    },
    merchantName: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    merchantLogo: {
      maxHeight: 48,
      maxWidth: 96,
    },
    merchantTotalDue: {
      fontWeight: 300,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    merchantUnpaidInvoices: {
      fontWeight: 500,
      color: '#0000008A',
    },
    mainGrid: {
      padding: theme.spacing(2),
      maxHeight: '92vh',
      overflow: 'auto',
    },
    sortList: {
      verticalAlign: 'middle',
    },
    doneIcon: {
      color: '#0275d8',
      fontSize: '1.3rem',
    },
    menuItem: {
      width: '280px',
      fontSize: '14px',
    },
    menuText: {
      whiteSpace: 'normal',
    },
    nameContainer: {
      width: 'calc(100% - 100px)',
      minHeight: 50,
      maxHeight: 50,
      alignContent: 'center',
    },
    logoContainer: {
      width: '96px',
    },
    viewDetailsContainer: {
      paddingTop: theme.spacing(2),
    },
    balanceDue: {
      paddingTop: theme.spacing(2),
    },
    messagePaper: {
      padding: theme.spacing(2),
    },
    errorPaperSm: {
      padding: theme.spacing(2),
    },
    errorPaper: {
      padding: '90px',
    },
    errorHeading: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
    },
    viewDetailsText: {
      fontWeight: 500,
      fontSize: '0.875rem',
      color: '#276EF1',
      padding: 0,
      margin: 0,
      textAlign: 'center',
    },
    balanceDueText: {
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
  }),
);

interface MerchantGridProps {
  payerTransactionSummaryByMerchant: PayerTransactionSummary | undefined;
  handleMerchantSelect: (id: string | undefined) => void;
  merchantDataSort: (merchantData: PayerTransactionSummary | undefined, order: OrderDirection) => PayerTransactionSummary | undefined;
  settingsMenuVisible: boolean;
  merchantDataError?: Error;
}

const MerchantGrid: React.FC<MerchantGridProps> = props => {
  const { payerTransactionSummaryByMerchant, handleMerchantSelect, merchantDataSort, settingsMenuVisible, merchantDataError } = props;
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [merchantDataList] = useState<PayerTransactionSummary | undefined>(
    merchantDataSort(payerTransactionSummaryByMerchant, OrderDirection.Asc),
  );

  if (merchantDataError || !merchantDataList?.merchantSummary || merchantDataList.merchantSummary.length === 0) {
    return (
      <Grid container className={classes.mainGrid} justifyContent="center">
        {merchantDataError ? (
          <Paper className={matches ? classes.errorPaperSm : classes.errorPaper}>
            <img src={ErrorDesktopIcon} alt="Desktop Error Icon" />
            <Typography className={classes.errorHeading}>Unable to Connect</Typography>
            <Typography>{`Sorry, there was a problem connecting to the server and couldn't complete your request.`}</Typography>
            <Typography>Make sure you are connected to the internet and try again.</Typography>
          </Paper>
        ) : (
          <Paper className={classes.messagePaper}>
            <Typography>Hooray, you currently have no Payments due! </Typography>
            <Typography>Merchants will display here when you receive a request for payment.</Typography>
          </Paper>
        )}
      </Grid>
    );
  }

  return (
    <Grid container className={classes.mainGrid}>
      <Grid item container spacing={2} role="list" aria-label="merchant">
        {merchantDataList &&
          merchantDataList.merchantSummary &&
          merchantDataList.merchantSummary?.map(merchant => {
            const pendingPaymentMethodRequest =
              merchant.pendingPaymentMethodRequest &&
              merchant.pendingPaymentMethodRequest.status === PaymentMethodRequestStatus.Pending;
            return (
              <Grid
                item
                lg={settingsMenuVisible ? 4 : 3}
                md={settingsMenuVisible ? 6 : 4}
                sm={settingsMenuVisible ? 12 : 6}
                xs={12}
                key={merchant.merchantInfo?.id}
                role="listitem"
              >
                <Card variant="outlined">
                  <CardActionArea
                    onClick={() => {
                      handleMerchantSelect(merchant?.merchantInfo?.owner?.tenantId);
                    }}
                    data-cy="card-company-name"
                  >
                    <Paper className={pendingPaymentMethodRequest ? classes.merchantCardError : classes.merchantCard}>
                      <Grid item container xs={12}>
                        <Grid item container className={classes.nameContainer}>
                          <Typography className={classes.merchantName} noWrap>
                            {merchant?.merchantInfo?.name || ''}
                          </Typography>
                        </Grid>
                        <Grid item container justifyContent="flex-end" className={classes.logoContainer}>
                          <img
                            className={classes.merchantLogo}
                            src={merchant?.merchantInfo?.logoUrl || logoReplacement}
                            data-cy="card-company-logo"
                            alt={merchant?.merchantInfo?.name || 'merchant name'}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className={classes.balanceDue}>
                        <Typography gutterBottom className={classes.balanceDueText}>
                          Balance Due
                        </Typography>
                      </Grid>
                      <Grid item container xs={12}>
                        <Grid item xs={11}>
                          <Typography className={classes.merchantTotalDue} noWrap gutterBottom>
                            {new Intl.NumberFormat('en', {
                              style: 'currency',
                              currency: merchant?.merchantInfo?.defaultCurrency || 'USD',
                              currencyDisplay: 'symbol',
                              maximumFractionDigits: 0,
                            }).format(merchant.totalDue / 100)}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {pendingPaymentMethodRequest && (
                            <Tooltip title="Outstanding request for new payment method">
                              <ErrorIcon color="error" fontSize="large" />
                            </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} className={classes.viewDetailsContainer}>
                        <Grid item container xs={7} alignContent="center">
                          <Typography
                            variant="body2"
                            className={classes.merchantUnpaidInvoices}
                          >{`${merchant.numberOfUnpaidRequests} Unpaid Invoices`}</Typography>
                        </Grid>
                        <Grid item container xs={5} justifyContent="flex-end">
                          <Typography className={classes.viewDetailsText}>VIEW DETAILS</Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default MerchantGrid;
