import 'core-js-pure/stable';
import 'regenerator-runtime/runtime';

import { Container, Paper, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Account } from 'msal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { ConnectivityIssueDetails } from '../../components/ConnectivityIssueDetails';
import PrimaryAppBar from '../../components/PrimaryAppBar';
import { authProvider } from '../../util/AuthProvider';
import { WEPAY_API_VERSION, WEPAY_APP_ID, WEPAY_ENVIRONMENT } from '../../util/Constants';
import { WePayInvalidParamsError } from '../../util/WePay';
import GuestHome from '../guestHome/GuestHome';
import { selectCustomerInfo } from '../manage-users/ManageUsersSlice';
import MultipleMethodsHome from '../multipleMethodsHome/MultipleMethodsHome';
import { fetchViewerUserByEmail, upsertPayerAccount } from './AppActions';
import {
  fetchMenuVisible,
  fetchSelectedMerchant,
  fetchUserNoEmail,
  selectIsOnHome,
  selectMenuVisible,
  selectMultiplePaymentMethodsEnabled,
  selectNetworkBusy,
  selectPayerAccountStatus,
  selectPayerTransactionSummaryByMerchant,
  selectSelectedMerchant,
  selectSelectedMerchantInfo,
  selectUserExists,
  selectUserNoEmail,
  selectViewerUser,
  selectWalletButtonVisible,
} from './AppSlice';

interface AppProps {
  wepayInit: (environment: string, appId: string, apiVersion: string) => WePayInvalidParamsError | void | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    root: {
      minHeight: '100%',
      width: '100%',
      maxWidth: '100%',
      padding: 0,
      margin: 0,
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    canvas: {
      maxWidth: '100%',
      display: 'flex',
      flexGrow: 1,
      alignContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      [theme.breakpoints.up('xs')]: {
        margin: theme.spacing(3, 0),
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1, 0),
        padding: 0,
      },
    },
    canvasColumn: {
      [theme.breakpoints.up('xs')]: {
        margin: theme.spacing(0, 0, 3, 0),
      },
    },
  }),
);

const App: React.FC<AppProps> = props => {
  const { wepayInit } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const [accountInfo, setAccountInfo] = useState<Account | undefined>();
  const userExists = useSelector(selectUserExists);
  const viewerUser = useSelector(selectViewerUser);
  const networkBusy = useSelector(selectNetworkBusy);
  const payerAccountStatus = useSelector(selectPayerAccountStatus);
  const userNoEmail = useSelector(selectUserNoEmail);
  const multiplePaymentMethodsEnabled = useSelector(selectMultiplePaymentMethodsEnabled);
  const menuVisible = useSelector(selectMenuVisible);
  const customerInfo = useSelector(selectCustomerInfo);
  const walletButtonVisible = useSelector(selectWalletButtonVisible);
  const payerTransactionSummary = useSelector(selectPayerTransactionSummaryByMerchant);
  const selectedMerchantInfo = useSelector(selectSelectedMerchantInfo);
  const isOnHome = useSelector(selectIsOnHome);
  const selectedMerchant = useSelector(selectSelectedMerchant);
  const [hasNetworkConnection, setHasNetworkConnection] = useState(true);

  const isCustomerUser = Boolean(customerInfo && location.pathname !== '/make-payment');

  const setNotConnected = () => setHasNetworkConnection(false);
  const setConnected = () => setHasNetworkConnection(true);
  const interval = async () => {
    if (!window.navigator.onLine) {
      setNotConnected();
      return;
    }

    try {
      const res = await fetch('/pixel.png', { cache: 'no-store' });
      if (res) setConnected();
    } catch (e: unknown) {
      setNotConnected();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(interval, 60000);

    wepayInit(WEPAY_ENVIRONMENT, WEPAY_APP_ID, WEPAY_API_VERSION);
    // Atempt silent token acquisition to see if the user is already authenticated with an account.
    const accountInfo = authProvider.getAccount();
    if (!viewerUser && accountInfo?.idToken) {
      setAccountInfo(accountInfo);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const email = accountInfo?.idToken?.emails ? accountInfo?.idToken?.emails[0] : null;
    if (email) {
      dispatch(fetchViewerUserByEmail(email, selectedMerchant));
    } else if (accountInfo) {
      dispatch(fetchUserNoEmail(true));
    }
  }, [accountInfo, selectedMerchant]);

  useEffect(() => {
    if (userExists === false && accountInfo?.idToken) {
      const email = accountInfo.idToken.emails ? accountInfo.idToken.emails[0] : null;
      if (email) {
        dispatch(
          upsertPayerAccount({
            email,
            firstName: accountInfo.idToken.given_name,
            lastName: accountInfo.idToken.family_name,
          }),
        );
      } else {
        dispatch(fetchUserNoEmail(true));
      }
    }
  }, [userExists]);

  const toggleMenuVisible = () => {
    dispatch(fetchMenuVisible(!menuVisible));
  };

  const onMerchantMenuChange = (merchant: string | undefined) => {
    dispatch(fetchSelectedMerchant(merchant));
  };

  const getAppContent = () => {
    if (userNoEmail === true) {
      return <Paper className={classes.paper}>Email not found. Contact administration.</Paper>;
    }
    if (viewerUser) {
      return (
        <>
          {multiplePaymentMethodsEnabled && (
            <MultipleMethodsHome
              payerAccountStatus={payerAccountStatus}
              userEmail={accountInfo?.idToken?.emails ? accountInfo?.idToken?.emails[0] : undefined}
              viewerUser={viewerUser}
            />
          )}
        </>
      );
    }
    return <GuestHome />;
  };

  return (
    <Container className={classes.root}>
      <PrimaryAppBar
        networkBusy={networkBusy}
        viewerUser={viewerUser}
        userLoggedIn={!!accountInfo}
        handleMenuIconClick={toggleMenuVisible}
        menuVisible={menuVisible}
        isCustomerUser={isCustomerUser}
        walletButtonVisible={walletButtonVisible}
        payerTransactionSummary={payerTransactionSummary}
        selectedMerchantInfo={selectedMerchantInfo}
        onMerchantMenuChange={onMerchantMenuChange}
        isOnHome={isOnHome}
      />
      <Container className={`${classes.canvas} ${!networkBusy && viewerUser && selectedMerchantInfo ? classes.canvasColumn : ''}`}>
        {getAppContent()}
      </Container>
      <ConnectivityIssueDetails isOpen={!hasNetworkConnection} handleReconnect={interval} />
    </Container>
  );
};

export default App;
