import { authProvider } from './AuthProvider';
import { PaymentRequestInfo } from '../gql-types.generated';
import { PaymentsDueRowData } from '../custom-types/PaymentsDueRowData';
import { MSAL_CLIENT_ID } from './Constants';

interface StandardRequestHeaders {
  authorization?: string;
  'x-ezpay-pmt': string;
}

export const getStandardHeaders = (): StandardRequestHeaders => {
  const headers: StandardRequestHeaders = {
    'x-ezpay-pmt': MSAL_CLIENT_ID,
  };
  return headers;
};

export const getStandardHeadersWithAuth = async (): Promise<StandardRequestHeaders> => {
  const tokenResponse = await authProvider.getAccessToken();
  const headers: StandardRequestHeaders = {
    authorization: `Bearer ${tokenResponse.accessToken}`,
    'x-ezpay-pmt': MSAL_CLIENT_ID,
  };

  return headers;
};

interface CreatePaymentHeaders {
  authorization?: string;
  'x-ezpay-pmt': string;
  'x-aptean-tenant': string;
  'idempotency-key': string;
}

export const getCreatePaymentHeaders = async (paymentRequestInfo: PaymentRequestInfo): Promise<CreatePaymentHeaders> => {
  const headers: CreatePaymentHeaders = {
    'x-ezpay-pmt': MSAL_CLIENT_ID,
    'x-aptean-tenant': paymentRequestInfo.tenantId,
    // future change: for idempotency-key, the counter after paymentRequestId is currently hardcoded to 0 - should eventually change to be the count of payments on the payment request to account for partial payments
    'idempotency-key': `${paymentRequestInfo.paymentRequestId}~0`,
  };

  return headers;
};

export const getCreatePaymentHeadersWithAuth = async (paymentRequestInfo: PaymentRequestInfo): Promise<CreatePaymentHeaders> => {
  const tokenResponse = await authProvider.getAccessToken();
  const headers: CreatePaymentHeaders = {
    authorization: `Bearer ${tokenResponse.accessToken}`,
    'x-ezpay-pmt': MSAL_CLIENT_ID,
    'x-aptean-tenant': paymentRequestInfo.tenantId,
    // future change: for idempotency-key, the counter after paymentRequestId is currently hardcoded to 0 - should eventually change to be the count of payments on the payment request to account for partial payments
    'idempotency-key': `${paymentRequestInfo.paymentRequestId}~0`,
  };

  return headers;
};

export const getCreateConsolidatedOrPartialPaymentHeadersWithAuth = async (
  tenantId: string,
  paymentRequestInfoList: PaymentsDueRowData[],
): Promise<CreatePaymentHeaders> => {
  let idempotencyKey = '';
  const list = [...paymentRequestInfoList].sort((a, b) => {
    const refNumberA = a.paymentDue.paymentRequest?.referenceNumber;
    const refNumberB = b.paymentDue.paymentRequest?.referenceNumber;
    return refNumberA && refNumberB ? refNumberA.localeCompare(refNumberB) : 0;
  });
  list.forEach(p => {
    const referenceNumber = p.paymentDue.paymentRequest?.referenceNumber;
    const totalDue = p.paymentDue.paymentRequest?.totalDue;
    if (referenceNumber && totalDue) {
      idempotencyKey += `${referenceNumber}<${totalDue}>`;
    }
  });
  const tokenResponse = await authProvider.getAccessToken();
  const headers: CreatePaymentHeaders = {
    authorization: `Bearer ${tokenResponse.accessToken}`,
    'x-ezpay-pmt': MSAL_CLIENT_ID,
    'x-aptean-tenant': tenantId,
    // future change: for idempotency-key, the counter after paymentRequestId is currently hardcoded to 0 - should eventually change to be the count of payments on the payment request to account for partial payments
    'idempotency-key': `${idempotencyKey}`,
  };

  return headers;
};

interface TenantHeaders {
  authorization?: string;
  'x-ezpay-pmt': string;
  'x-aptean-tenant': string;
}

export const getTenantHeaders = (tenantId: string): TenantHeaders => {
  const headers: TenantHeaders = {
    'x-ezpay-pmt': MSAL_CLIENT_ID,
    'x-aptean-tenant': tenantId,
  };
  return headers;
};

export const getTenantHeadersWithAuth = async (tenantId: string): Promise<TenantHeaders> => {
  const tokenResponse = await authProvider.getAccessToken();
  const headers: TenantHeaders = {
    authorization: `Bearer ${tokenResponse.accessToken}`,
    'x-ezpay-pmt': MSAL_CLIENT_ID,
    'x-aptean-tenant': tenantId,
  };
  return headers;
};
