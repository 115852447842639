import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { Customer } from '../gql-types.generated';
import { getTenantHeadersWithAuth } from '../util/StandardRequestHeaders';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_PAYER_CUSTOMER = gql`
  query payerCustomer($customerId: String!) {
    payerCustomer(customerId: $customerId) {
      id
      name
      customerNumber
      customerUsers {
        email
        role
      }
      payerProfile {
        paymentMethods {
          isDefault
          shareWithMerchant
          paymentMethod {
            id
            creditCard {
              cardBrand
              displayName
              expirationMonth
              expirationYear
              lastFour
              recurring
              details {
                type
              }
              cardHolder {
                address {
                  city
                  country
                  line1
                  line2
                  postalCode
                  region
                }
                email
                holderName
                phone {
                  countryCode
                  number
                }
              }
            }
            createdAt
            createdBy
            paymentBank {
              accountType
              lastFour
              accountHolder {
                address {
                  city
                  country
                  line1
                  line2
                  postalCode
                  region
                }
                email
                holderName
                phone {
                  countryCode
                  number
                }
              }
            }
            status
            type
            updatedAt
            updatedBy
            isLongLived
          }
        }
      }
    }
  }
`;

export const queryPayerCustomer = async (
  client: ApolloClient<NormalizedCacheObject>,
  tenantId: string,
  customerId: string,
): Promise<Customer | undefined> => {
  const headers = await getTenantHeadersWithAuth(tenantId);
  const { data, errors } = await client.query<{ payerCustomer: Customer }>({
    variables: { customerId },
    query: QUERY_PAYER_CUSTOMER,
    context: {
      headers,
    },
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the person.
    throw new Error('An error has occurred during the payerCustomer query.');
  }

  if (data && data.payerCustomer) {
    return data.payerCustomer;
  }

  return undefined;
};
