import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Theme,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import { CurrencyType, PaymentRequestInfo } from '../gql-types.generated';
import DownloadInvoices from './DownloadInvoices';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padding: {
      padding: theme.spacing(2, 2, 1, 2),
    },
    requestHeaderSmall: {
      padding: theme.spacing(1, 2, 1, 2),
    },
    requestHeader: {
      padding: theme.spacing(3),
    },
    requestFooterSmall: {
      padding: theme.spacing(2),
    },
    requestFooter: {
      padding: theme.spacing(3),
    },
    footerActionSmall: {
      padding: theme.spacing(0, 2, 2, 2),
    },
    footerAction: {
      padding: theme.spacing(0, 3, 2, 3),
    },
    desktopDivider: {
      marginLeft: 16,
      marginRight: 16,
    },
    upperHeaderText: {
      fontSize: 10,
      letterSpacing: 1.5,
      fontWeight: 500,
    },
    referenceText: {
      fontSize: 14,
      fontWeight: 500,
    },
    refundPolicyLink: {
      fontSize: 14,
      fontWeight: 400,
      cursor: 'pointer',
    },
    refundPolicyContent: {
      marginTop: 14,
      padding: 8,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: 'lightgrey',
      borderStyle: 'solid',
    },
    invoiceLink: {
      fontSize: 14,
      fontWeight: 400,
      cursor: 'pointer',
    },
    refundContent: {
      padding: theme.spacing(2, 3),
    },
    refundTitle: {
      padding: theme.spacing(2),
    },
    refundActions: {
      padding: theme.spacing(2),
    },
    closeRefundPolicyButton: {
      width: '100%',
    },
    mainHeaderText: {
      fontWeight: 500,
      fontSize: 18,
    },
    total: {
      fontWeight: 500,
    },
    amount: {
      display: 'inline-block',
      paddingLeft: theme.spacing(0.5),
      fontSize: '16px',
      fontWeight: 'bold',
    },
    dueAmount: {
      display: 'inline-block',
      textDecoration: 'line-through',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    amountTotal: {
      display: 'inline-block',
      paddingLeft: theme.spacing(0.5),
      fontSize: '24px',
      fontWeight: 'bold',
    },
    dueAmountTotal: {
      display: 'inline-block',
      textDecoration: 'line-through',
      fontSize: '24px',
      fontWeight: 'bold',
    },
    amountHeadingText: {
      textAlign: 'right',
      fontSize: '16px',
    },
    amountValueText: {
      textAlign: 'right',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    totalText: {
      textAlign: 'right',
      fontSize: '24px',
    },
    totalAmountText: {
      textAlign: 'right',
      fontSize: '24px',
      fontWeight: 'bold',
    },
  }),
);

interface PaymentRequestProps {
  displayRefundPolicy: boolean;
  paymentRequestInfo: PaymentRequestInfo;
  toggleRefundPolicyDisplay: () => void;
  getPaymentRequestInfoAmount?: () => number;
  convenienceFee?: number;
  makePayment?: () => void;
  inFlight?: boolean;
}

const PaymentRequest: React.FC<PaymentRequestProps> = props => {
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const {
    paymentRequestInfo,
    displayRefundPolicy,
    toggleRefundPolicyDisplay,
    getPaymentRequestInfoAmount,
    convenienceFee,
    makePayment,
    inFlight,
  } = props;
  const currency = (paymentRequestInfo.currency as CurrencyType) || 'USD';

  const getAmountText = (isTotal: boolean) => {
    return (
      <>
        {getPaymentRequestInfoAmount && paymentRequestInfo?.totalDue && getPaymentRequestInfoAmount() < paymentRequestInfo.totalDue && (
          <Typography
            className={isTotal ? classes.dueAmountTotal : classes.dueAmount}
            color="error"
            align="right"
            data-cy="due-amount"
          >
            {new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
              (paymentRequestInfo?.totalDue || 0) / 100,
            )}
          </Typography>
        )}
        <Typography className={isTotal ? classes.amountTotal : classes.amount} align="right" data-cy="amount">
          {new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
            (getPaymentRequestInfoAmount ? getPaymentRequestInfoAmount() : paymentRequestInfo?.totalDue || 0) / 100,
          )}
        </Typography>
      </>
    );
  };

  return (
    <Box>
      <Grid container className={classes.padding}>
        <Grid item xs={10} lg={10}>
          <Typography variant="body2" className={classes.upperHeaderText}>
            PAYMENT DUE
          </Typography>
          <Typography className={classes.mainHeaderText} data-cy="merchant-name">
            {paymentRequestInfo.merchantName}
          </Typography>
        </Grid>
      </Grid>
      {!matches && <Divider />}
      <Box>
        <Grid container className={matches ? classes.requestHeaderSmall : classes.requestHeader}>
          <Grid item xs={12} lg={12}>
            <Typography variant="body2" className={classes.referenceText} data-cy="reference-number">
              # {paymentRequestInfo.referenceNumber?.trim()}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={6}>
            <Link
              variant="body2"
              className={classes.refundPolicyLink}
              onClick={() => {
                toggleRefundPolicyDisplay();
              }}
              hidden={!paymentRequestInfo.refundPolicy}
              data-cy="view-refund-policy"
              underline="hover"
            >
              {displayRefundPolicy ? 'Hide refund policy' : 'View refund policy'}
            </Link>
          </Grid>
          <Grid item xs={6} lg={6}>
            <DownloadInvoices
              invoiceUrl={paymentRequestInfo?.invoiceLink}
              invoicesUrlList={paymentRequestInfo?.invoiceLinks}
              isGuestUser={true}
              isLink={true}
            />
          </Grid>
          {matches && (
            <Dialog open={displayRefundPolicy}>
              <DialogTitle className={classes.refundTitle}>
                {paymentRequestInfo.merchantName} {'Refund Policy'}
              </DialogTitle>
              <DialogContent className={classes.refundContent}>
                <DialogContentText color={'textPrimary'}>{paymentRequestInfo.refundPolicy}</DialogContentText>
              </DialogContent>
              <DialogActions className={classes.refundActions}>
                <Button
                  className={classes.closeRefundPolicyButton}
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => {
                    toggleRefundPolicyDisplay();
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {!matches && (
            <Grid item xs={12} lg={12} hidden={!displayRefundPolicy} className={classes.refundPolicyContent} data-cy="refund-policy">
              <span>{paymentRequestInfo.refundPolicy}</span>
            </Grid>
          )}
        </Grid>
        <Divider className={matches ? '' : classes.desktopDivider} light={!matches} />
        <Grid container className={matches ? classes.requestFooterSmall : classes.requestFooter}>
          {convenienceFee ? (
            <>
              <Grid item xs={7} sm={8} lg={9} className={classes.amountHeadingText}>
                Invoice Amount
              </Grid>
              <Grid item xs={5} sm={4} lg={3} className={classes.amountValueText}>
                {getAmountText(false)}
              </Grid>
              <Grid item xs={7} sm={8} lg={9} className={classes.amountHeadingText}>
                Convenience Fees (non-refundable)
              </Grid>
              <Grid item xs={5} sm={4} lg={3} className={classes.amountValueText}>
                {new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
                  (convenienceFee || 0) / 100,
                )}
              </Grid>
            </>
          ) : null}
          <Grid item xs={7} sm={8} lg={9} className={classes.totalText}>
            Total
          </Grid>
          <Grid item xs={5} sm={4} lg={3} className={classes.totalAmountText}>
            {convenienceFee ? (
              <Typography className={classes.amountTotal} align="right" data-cy="amount">
                {new Intl.NumberFormat('en', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(
                  ((getPaymentRequestInfoAmount ? getPaymentRequestInfoAmount() : paymentRequestInfo?.totalDue || 0) +
                    (convenienceFee || 0)) /
                    100,
                )}
              </Typography>
            ) : (
              getAmountText(true)
            )}
          </Grid>
        </Grid>
        {convenienceFee !== undefined && makePayment && (
          <Grid container justifyContent={'flex-end'} className={matches ? classes.footerActionSmall : classes.footerAction}>
            <Button
              variant="contained"
              color="primary"
              onClick={makePayment}
              disabled={inFlight}
              data-cy="make-payment-with-convenience"
            >
              Make Payment
            </Button>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default PaymentRequest;
